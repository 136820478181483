/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'

import { CircularProgress, Fade, Grid, TextField } from '@material-ui/core'

import InputMask from 'react-input-mask'
import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import useValidationForm from '../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../hooks/useServiceCallApi.hook'

import MessageComponent from '../../components/templates/Message/Message.component'
import FCaptcha from '../../components/FCaptcha/FCaptcha.component'
import Callout from '../../components/templates/Callout/Callout'

import { maskNIFUtil, getUrlParam } from '../../utils'
import { INITIAL_STATE, ENDPOINT } from './constants'

import { SButton, SCircular, SLogo, SPaper, STitle } from './TimestampUser.styles'

function TimestampUser() {
    const [timestampId, setTimestampId] = useState(null)
    const [requestState, callService] = useServiceCallApi(null)
    const refCaptcha = useRef()
    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE)

    useEffect(() => {
        const id = getUrlParam('timestampId')
        setTimestampId(id)
    }, [])

    function handleSubmit() {
        // 1. build payload
        const payload = {
            nipc: values.nipc.value.replace(/\s/g, ''),
            nifRequester: values.nif.value.replace(/\s/g, ''),
            orderNumber: values.documentNumber.value,
            password: values.password.value,
            timestampId,
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
        }

        // 2. call service
        callService(ENDPOINT.CREATE_TIMESTAMP_MAIN_USER, { requestType: 'post', data: payload })
    }

    function renderContent() {
        // Show loading spinner
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                    <span>A processar o pedido...</span>
                </SCircular>
            )
        }
        // show success screen
        if (
            requestState.url &&
            requestState.data &&
            requestState.url === ENDPOINT.CREATE_TIMESTAMP_MAIN_USER &&
            Object.keys(requestState.data).length > 0
        ) {
            return <MessageComponent type='success' message={requestState.data.message} textBack='Login' />
        }
        // show form
        return (
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputMask
                            mask={maskNIFUtil}
                            value={values.nipc.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maskPlaceholder={null}
                        >
                            {() => (
                                <TextField
                                    id='nipc'
                                    name='nipc'
                                    label='NIPC da Entidade'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    error={touched.nipc ? Boolean(errors.nipc) : false}
                                    helperText={touched.nipc ? errors.nipc : ''}
                                    required
                                    fullWidth
                                />
                            )}
                        </InputMask>
                        <InputMask
                            mask={maskNIFUtil}
                            value={values.nif.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maskPlaceholder={null}
                        >
                            {() => (
                                <TextField
                                    id='nif'
                                    name='nif'
                                    label='NIF do utilizador que registou o pedido'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    error={touched.nif ? Boolean(errors.nif) : false}
                                    helperText={touched.nif ? errors.nif : ''}
                                    required
                                    fullWidth
                                />
                            )}
                        </InputMask>
                        <TextField
                            id='documentNumber'
                            name='documentNumber'
                            label='Número do pedido'
                            type='text'
                            margin='dense'
                            variant='outlined'
                            value={values.documentNumber.value}
                            error={touched.documentNumber ? Boolean(errors.documentNumber) : false}
                            helperText={touched.documentNumber ? errors.documentNumber : ''}
                            inputProps={{ maxLength: 10 }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <STitle>Defina uma palavra-passe para consumo do Selo Temporal</STitle>
                        <TextField
                            id='password'
                            name='password'
                            label='Nova palavra-passe'
                            type='password'
                            margin='dense'
                            variant='outlined'
                            value={values.password.value}
                            error={touched.password ? Boolean(errors.password) : false}
                            helperText={touched.password ? errors.password : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                        <TextField
                            id='repeatPassword'
                            name='repeatPassword'
                            label='Repita palavra-passe'
                            type='password'
                            margin='dense'
                            variant='outlined'
                            value={values.repeatPassword.value}
                            error={touched.repeatPassword ? Boolean(errors.repeatPassword) : false}
                            helperText={touched.repeatPassword ? errors.repeatPassword : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                        <Callout>
                            A palavra-passe deve conter um mínimo de 9 caracteres, entre os quais letras maiúsculas
                            (A...Z), minúsculas (a...z) e números (0...9).
                        </Callout>
                    </Grid>
                </Grid>
                <Grid container direction='row' alignItems='flex-end' spacing={1}>
                    <Grid item xs={9}>
                        <FCaptcha
                            refCaptcha={refCaptcha}
                            value={values.yourFirstCaptchaUserInput.value}
                            error={
                                touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false
                            }
                            helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SButton
                            variant='contained'
                            color='primary'
                            disabled={!isValidForm}
                            onClick={handleSubmit}
                            fullWidth
                        >
                            Guardar
                        </SButton>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                <h3>Registo de utilizador principal do Selo Temporal</h3>
                {renderContent()}
            </SPaper>
        </Fade>
    )
}

export default TimestampUser
