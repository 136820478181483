export const INITIAL_STATE = {
    SMARTCARD_AUTH: {
        fullName: { value: '', validation: ['validateNotEmptyUtil'] },
        taxIdentificationNumber: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'],
            mask: 'maskNIFUtil',
        },
        smartcardNumber: {
            value: '',
            validation: ['validateNotEmptyUtil'],
        },
        numberIdentificationDocument: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateAlphaLatinNumberUtil'],
        },
        expirationDateDocument: {
            value: null,
            validation: ['validateDateUtil'],
        },
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
    },
}

export const ENDPOINTS = {
    SMARTCART_AUTHENTICATION: 'smartcard/authentication',
    SMARTCART_ACTIVATION: 'smartcard/activation',
}
