import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import LinearProgress from '@material-ui/core/LinearProgress'

export const SCircular = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-top: 16px;
        font-size: 16px;
    }
`

export const SCardWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
`

export const SLink = styled.div`
    display: flex;
    flex-flow: column wrap;
    width: calc(25% - 16px);
    align-items: center;
    justify-content: center;
    min-height: 250px;
    padding: 15px 30px;
    margin: 8px;
    border-radius: 6px;
    text-decoration: none;
    background-color: ${props => props.theme.palette.grey.xLight};
    transition: 0.3s linear;

    &&.link-list {
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column wrap;
    }

    h3 {
        color: ${props => props.theme.palette.grey.dark};
        font-size: 14px;
        text-transform: uppercase;
    }

    h2 {
        color: ${props => props.theme.palette.primary.main}
        font-size: 30px;
        display: block;
        font-weight: 500;
        margin: 4px 0;
    }

    @media (max-width: 1124px) {
        width: calc(100% - 20px);
    }

    svg {
        font-size: 38px;
        margin-bottom: 10px;
        color: ${props => props.theme.palette.grey.light};
    }

    @media print {
      min-height: auto;
    }

    &:hover {
        background-color: ${props => props.theme.palette.primary.main};

        h2,
        h3,
        span,
        p {
            color: ${props => props.theme.palette.grey.xLight};
        }
        svg {
            color: ${props => props.theme.palette.grey.xLight};
        }
    }

`

export const SList = styled(List)`
    && {
        width: 100%;
        padding: 0;
    }
`

export const SListItem = styled(ListItem)`
    && {
        padding: 8px 4px;
        justify-content: space-between;
        border-bottom: 1px solid ${props => props.theme.palette.grey.light};

        &:last-child {
            border: none;
        }
    }
`

export const SListItemText = styled(ListItemText)`
    && {
        justify-content: space-between;
        width: 100%;
        padding: 0;
        display: contents;
    }
    .MuiListItemText-primary {
        color: ${props => props.theme.palette.grey.dark};
    }
    .MuiListItemText-secondary {
        font-size: 16px;
    }
`

export const SLinearProgress = styled(LinearProgress)`
    && {
        height: 10px;
        margin: 10px 0 5px;
        background-color: ${props => props.theme.palette.grey.light};
    }
    .MuiLinearProgress-bar {
        background-color: ${props =>
            props.value >= 50 ? props.theme.palette.success.main : props.theme.palette.warning.light};
    }
`

export const WrapperPrimary = styled.div`
    max-width: 80%;
    word-wrap: break-word;

    .MuiListItemText-primary {
        color: ${props => (props.primaryColor ? props.theme.palette.error.dark : props.theme.palette.grey.dark)};
    }
`
