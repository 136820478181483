import PROFILE_TYPES from './Profile.types'

export const initialState = {
    profile: {},
    isEmailChangedRequestSuccess: null,
    isLoading: false,
}

export function ProfileReducer(state = initialState, action = {}) {
    switch (action.type) {
        case PROFILE_TYPES.RESET_DATA.SUCCESS:
            return initialState
        case PROFILE_TYPES.PROFILE.GET.SUCCESS:
            return { ...state, profile: action.payload }
        case PROFILE_TYPES.UPDATE_EMAIL.POST.SUCCESS:
            return { ...state, isEmailChangedRequestSuccess: true }
        case PROFILE_TYPES.UPDATE_PHONE_NUMBER.POST.SUCCESS:
            return { ...state, profile: action.payload }
        case PROFILE_TYPES.VALIDATE_CODE.POST.SUCCESS:
            return { ...state, profile: action.payload }
        case PROFILE_TYPES.UPDATE_PROFILE.POST.SUCCESS:
            return { ...state, profile: action.payload }
        case PROFILE_TYPES.SHOW_LOADING.SUCCESS:
            return { ...state, isLoading: true }
        case PROFILE_TYPES.HIDE_LOADING.SUCCESS:
            return { ...state, isLoading: false }
        default:
            return state
    }
}
