import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { SAvatarEditor, InputRange } from './ScaleImage.styles'

const propTypes = {
    handleScaledImg: PropTypes.func,
    img: PropTypes.string,
}

const defaultProps = {
    handleScaledImg: () => {},
    img: '',
}

function ScaleImageComponent({ handleScaledImg, img }) {
    const [scale, setScale] = useState(1)
    const editorRef = useRef(null)

    function handleSave() {
        if (editorRef && scale !== 1) {
            // Get img resized to the canvas size
            const canvasScaled = editorRef.current.getImageScaledToCanvas()
            // Get png img from canvas in base64 format
            const base64Img = canvasScaled.toDataURL('image/jpeg', 1.0)
            // Return base64 to parent
            handleScaledImg(base64Img)
        } else {
            handleScaledImg(img)
        }
    }

    function handleSliderChange(event) {
        setScale(Number(event.target.value))
    }
    return (
        <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12} lg={12}>
                <SAvatarEditor
                    ref={editorRef}
                    image={img}
                    crossOrigin='anonymous'
                    border={30}
                    scale={scale}
                    width={175}
                    // fullWidth
                    color={[0, 0, 0, 0.6]} // RGBA
                />
            </Grid>
            <Grid item xs={12}>
                <InputRange
                    type='range'
                    name='scale'
                    step='0.01'
                    min='1'
                    max='2'
                    defaultValue={scale}
                    onChange={e => handleSliderChange(e)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant='outlined' size='small' color='primary' onClick={handleSave}>
                    Confirmar
                </Button>
            </Grid>
        </Grid>
    )
}

ScaleImageComponent.propTypes = propTypes
ScaleImageComponent.defaultProps = defaultProps

export default ScaleImageComponent
