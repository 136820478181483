const types = ['REQUEST', 'SUCCESS', 'ERROR']
export function createRequestTypes(base) {
    const requests = ['GET', 'POST', 'PUT']
    const newReq = {}

    requests.forEach(req => {
        const res = {}
        const newRequests = `${base}_${req}`

        types.forEach(type => {
            res[type] = `${newRequests}_${type}`
        })
        newReq[req] = res
    })

    return newReq
}

export function createTypes(base) {
    const res = {}

    types.forEach(type => {
        res[type] = `${base}_${type}`
    })

    return res
}
