import LOGIN_TYPES from './Login.types'
import apiService from '../../services/api.service'
import AuthService from '../../services/auth.service'

const auth = new AuthService(sessionStorage)

// * Defines the applyMiddleware reducer in the end of the file,
// * because it needs to come after the functions declaration
async function getUserPayload(dispatch, history) {
    const response = await apiService.get(`users/loginpayload`)
    const { payload } = response.data
    auth.setScopes(payload.scopes)
    if (payload.scopes.length > 0) {
        auth.setActiveScopeId(payload.scopes[0].id)
        auth.setPermissions(payload.scopes[0].permissions)
    }
    auth.setRefreshToken(payload.refresh_token)
    auth.setUser({
        name: payload.name,
        nif: payload.nif,
        email: payload.email,
        twoFactor: payload.twoFactor,
    })
    dispatch({ type: LOGIN_TYPES.CHECK.POST.SUCCESS, payload })

    // Add check auth to session storage
    auth.set2SToken('1')

    //! PUSH TO /new-order ROUTE
    history.push('/new-order')
}

async function asyncDoLogin(dispatch, actionsAlert, props) {
    const { history, login, refCaptcha } = props
    const { captcha } = login

    try {
        // Show loading
        dispatch({ type: LOGIN_TYPES.SHOW_LOADING.SUCCESS })

        let payload = null
        if (login.typeAuthentication === 2) {
            const { token } = login
            const { data } = await apiService.post('users/login', {
                metodoAutenticacao: login.typeAuthentication,
                tokenSamlResponse: token,
            })

            payload = data.payload
        } else {
            const { nif, password } = login
            const requestPayload = {
                login: {
                    metodoAutenticacao: 1,
                    nif,
                    password,
                },
                captcha,
            }

            const { data } = await apiService.post('loginCaptcha', requestPayload)
            payload = data.payload
        }

        // Add login data to session storage
        auth.setToken(payload.jwt)

        dispatch({ type: LOGIN_TYPES.LOGIN.POST.SUCCESS, payload })

        if (login.typeAuthentication === 2) {
            getUserPayload(dispatch, history)
        } else {
            //! (use history inside a component) PUSH TO /confirmation-key ROUTE
            history.push('/confirmation-key')
        }
    } catch (err) {
        const { message } = err.response.data
        if (refCaptcha) {
            refCaptcha.reloadImage()
        }

        // Clear session storage data
        auth.clearSessionStorage()

        // Show alert error with error message
        if (err.response && err.response.data) {
            if (login.typeAuthentication === 2) {
                dispatch({ type: LOGIN_TYPES.LOGIN.POST.ERROR, payload: { message } })
            } else {
                actionsAlert.showAlert(err.response.data)
            }
        }
    } finally {
        // Hide loading
        dispatch({ type: LOGIN_TYPES.HIDE_LOADING.SUCCESS })
    }
}

async function asyncDoCheck(dispatch, actionsAlert, payload) {
    const { history, code } = payload

    try {
        // Show loading
        dispatch({ type: LOGIN_TYPES.SHOW_LOADING.SUCCESS })

        const { data } = await apiService.post('check/loginCode', {
            timestamp: new Date().getTime(),
            loginCode: code,
        })
        getUserPayload(dispatch, history)

        // Show alert OK with success message
        actionsAlert.showAlert(data)
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    } finally {
        // Hide loading
        dispatch({ type: LOGIN_TYPES.HIDE_LOADING.SUCCESS })
    }
}

async function asyncDoLogout(dispatch, actionsAlert, payload) {
    // const { history } = payload

    try {
        window.location.href = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/${
            process.env.REACT_APP_API_CONTEXT
        }/users/saml/requestLogoutSamlToken?accessToken=${auth.getToken()}`

        // Clear session storage data
        auth.clearSessionStorage()

        dispatch({ type: LOGIN_TYPES.CLEAR.SUCCESS })

        // //! (use history inside a component) PUSH TO / ROUTE
        // // Redirect router to root
        // history.push('/')
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

async function asyncSetScope(dispatch, actionsAlert, payload) {
    const { history, scope } = payload
    try {
        // Add scope auth to session storage
        auth.setActiveScopeId(scope.id)
        dispatch({ type: LOGIN_TYPES.SET_ACTIVE_SCOPE.SUCCESS, scope })

        //! (use history inside a component) PUSH TO / ROUTE
        // Redirect router to root
        history.push('/')
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

function getAuthenticated(dispatch, payload) {
    const { state } = payload

    const authenticated = auth.get2SToken() && (auth.getToken() || state.isLogged)
    dispatch({ type: LOGIN_TYPES.AUTHENTICATED.GET.SUCCESS, payload: authenticated })
}

//* applyMiddleware Receives the action and triggers the correspondent function
const applyMiddleware = (dispatch, actionsAlert) => action => {
    switch (action.type) {
        case LOGIN_TYPES.LOGIN.POST.REQUEST:
            return asyncDoLogin(dispatch, actionsAlert, action.payload)
        case LOGIN_TYPES.CHECK.POST.REQUEST:
            return asyncDoCheck(dispatch, actionsAlert, action.payload)
        case LOGIN_TYPES.CLEAR.REQUEST:
            return asyncDoLogout(dispatch, actionsAlert, action.payload)
        case LOGIN_TYPES.SET_ACTIVE_SCOPE.REQUEST:
            return asyncSetScope(dispatch, actionsAlert, action.payload)
        case LOGIN_TYPES.AUTHENTICATED.GET.REQUEST:
            return getAuthenticated(dispatch, action.payload)
        default:
            return dispatch(action)
    }
}
export default applyMiddleware
