import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'

export const ItemChip = styled(Chip)`
    && {
        font-size: 12px;
        font-weight: 600;
        width: 100px;
        color: ${props => props.theme.palette.white};
        background-color: ${props => {
            switch (props.type) {
                case 1:
                    return props.theme.palette.success.dark
                case 2:
                    return props.theme.palette.error.dark
                default:
                    return props.theme.palette.grey.light
            }
        }};
    }
`
