import { transformInt2Cases } from './numeral.util'
import { validateNotEmpty } from './validate.util'

export const validateDate = value => {
    return !Number.isNaN(value) ? validateNotEmpty(value) : 'Data Inválida'
}

export const transformTimestampToDate = tmstamp => {
    const datetime = new Date(tmstamp)
    return `${datetime.toLocaleDateString('pt-pt')} ${datetime.toLocaleTimeString('pt-pt')}`
}

export const transformTimestampToSimpleDate = tmstamp => {
    const datetime = new Date(tmstamp)
    return `${datetime.toLocaleDateString('pt-pt')}`
}

export const transformTimestampToNewDate = tmstamp => {
    const datetime = new Date(tmstamp).toLocaleDateString()
    const dateSplit = datetime.split('/')
    return `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`
}
export const transformTimestampToReversedNewDate = tmstamp => {
    const datetime = new Date(tmstamp).toLocaleDateString('pt-pt')
    const dateSplit = datetime.split('/')
    return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
}

export const transformDateToDatetime = time => {
    const date = new Date(time)
    const dateString = `${transformInt2Cases(date.getDate())}/${transformInt2Cases(
        date.getMonth() + 1
    )}/${date.getFullYear()} ${transformInt2Cases(date.getHours())}:${transformInt2Cases(
        date.getMinutes()
    )}:${transformInt2Cases(date.getSeconds())}`
    return dateString
}

export const transformDateDashStringToDate = dateString => {
    const dateSplit = dateString.split('-')
    const date = new Date(`${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`)
    return date
}
export const transformDateSlashStringToDate = dateString => {
    const dateSplit = dateString.split('-')
    const date = new Date(`${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`)
    return date
}
export const transformDateSlashStringToNewDate = dateString => {
    const dateSplit = dateString.split('-')
    const date = new Date(`${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`)
    return date
}

export const removeTimeFromTimestamp = timestamp => {
    const dateObject = new Date(timestamp)
    new Date(dateObject.toDateString()).getTime()
}
