/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { Button } from '@material-ui/core'

import ModalComponent from '../../../components/templates/Modal/Modal.component'
import ModalBodyComponent from '../../../components/templates/Modal/ModalBody.component'

import UploadFileComponent from '../../../components/templates/UploadFile/UploadFile.template'

import { useAlertStore } from '../../../store/Alert/Alert.store'

import apiService from '../../../services/api.service'

import { SFooter } from './ModalFilesDownload.styles'

function ModalFilesDownload({ open, endpoint, orderId, onCloseModal }) {
    const { actionsAlert } = useAlertStore()

    const [files, setFiles] = useState([])
    const url = `${endpoint}/uploadMaintenanceDoc/${orderId}`

    async function handleUploadFile() {
        const file = {
            base64: files[0].base64,
            name: files[0].name,
        }

        try {
            const response = await apiService.post(url, file)

            actionsAlert.showAlert(response.data)

            if (response.data.result === 0) {
                onCloseModal()
            }
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data.message)
            }
        }
    }

    return (
        <ModalComponent
            title='Anexar ficheiro preenchido e assinado com o pedido de manutenção'
            open={open}
            onClick={() => onCloseModal()}
        >
            <ModalBodyComponent>
                <UploadFileComponent
                    id='files'
                    initialFiles={files}
                    handleFiles={fileList => setFiles(fileList)}
                    isSingleFile
                />

                <SFooter>
                    <Button variant='contained' size='small' onClick={() => onCloseModal()}>
                        Cancelar
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => handleUploadFile()}
                        disabled={!files.length}
                    >
                        Confirmar
                    </Button>
                </SFooter>
            </ModalBodyComponent>
        </ModalComponent>
    )
}

export default ModalFilesDownload
