import React from 'react'
import PropTypes from 'prop-types'

import { SPageHeader } from './Content.style'

const propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
}

const defaultProps = {
    title: '',
    description: '',
    children: [],
}

const PageTitleComponent = ({ title, description, children }) => {
    return (
        <SPageHeader>
            <h2 className='page-title'>{title}</h2>
            <span className='page-description'>{description}</span>
            {children}
        </SPageHeader>
    )
}

PageTitleComponent.propTypes = propTypes
PageTitleComponent.defaultProps = defaultProps

export default PageTitleComponent
