import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import DataTablePagination from '../../../components/templates/DataTablePagination/DataTablePagination.component'
import DownloadFileCSV from '../../../components/templates/DownloadFile/DownloadFileCSV'

import { ItemChip } from '../Timestamp.styles'

function TableUsers(props) {
    const { timestampId } = props

    const COLUMNS = [
        {
            field: 'email',
            title: 'email',
            // headerStyle: {
            //     width: '85%',
            //     maxWidth: '85%',
            // },
            // cellStyle: {
            //     width: '85%',
            //     maxWidth: '85%',
            // },
        },
        {
            field: 'status.label',
            title: 'estado',
            lookup: { Ativo: 'Ativo', Inativo: 'Inativo' },
            sorting: false,
            headerStyle: {
                width: '15%',
                maxWidth: '15%',
                textAlign: 'center',
            },
            cellStyle: {
                width: '15%',
                maxWidth: '15%',
                textAlign: 'center',
            },
            // eslint-disable-next-line react/prop-types
            render: rowData => {
                return rowData && rowData.status ? (
                    <ItemChip label={rowData.status.label} type={rowData.status.id} />
                ) : (
                    ''
                )
            },
        },
    ]

    return (
        <>
            <Grid container justify='flex-end'>
                <DownloadFileCSV
                    endpoint={`timestamps/client/${timestampId}/users`}
                    exportCSV={{ isFromAPI: true, filename: 'utilizadores-selo-temporal' }}
                />
            </Grid>
            <DataTablePagination
                columns={COLUMNS}
                endPoint={`timestamps/client/${timestampId}/users`}
                options={{
                    filtering: true,
                    sorting: true,
                    filterCellStyle: {
                        padding: '16px 16px 16px 6px',
                    },
                }}
            />
        </>
    )
}

TableUsers.propTypes = {
    timestampId: PropTypes.string.isRequired,
}

export default memo(TableUsers)
