/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import { Button, Checkbox, Fade, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'

import { useOrdersStore } from '../../store/Orders/Orders.store'
import { useLoginStore } from '../../store/Login/Login.store'
import useValidationForm from '../../hooks/useValidationForm.hook'

import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'

import { maskNIFUtil, maskPostalCodeUtil } from '../../utils'

function NewOrderPaymentInfoForm({ setActiveStep }) {
    const { stateLogin } = useLoginStore()
    const { stateOrders, actionsOrders } = useOrdersStore()
    const [showEntity, setShowEntity] = useState(
        (stateLogin.activeScope.paymentInfo === 2 && stateLogin.activeScope.protocolType === 'FORMAL'
            ? false
            : stateOrders.paymentInfo.showEntity) || false
    )
    const [files, setFiles] = useState(
        stateOrders.paymentInfo.file && stateOrders.paymentInfo.file.length !== 0 ? [stateOrders.paymentInfo.file] : []
    )

    const INITIAL_STATE = {
        commitmentNumber: {
            value: stateOrders.paymentInfo.commitmentNumber || '',
            validation: stateLogin.activeScope.paymentInfo === 1 ? [] : ['validateNotEmptyUtil'],
        },
        orderNumber: { value: stateOrders.paymentInfo.orderNumber || '' },
        docketNumber: { value: stateOrders.paymentInfo.docketNumber || '' },
        nipc: {
            value: showEntity ? stateOrders.paymentInfo.nipc || '' : '',
            mask: 'maskNIFUtil',
        },
        entityName: { value: showEntity ? stateOrders.paymentInfo.entityName || '' : '' },
        entityAddress: { value: showEntity ? stateOrders.paymentInfo.entityAddress || '' : '' },
        entityLocation: {
            value: showEntity ? stateOrders.paymentInfo.entityLocation || '' : '',
        },
        postalCode: {
            value: showEntity ? stateOrders.paymentInfo.postalCode || '' : '',
            mask: 'maskPostalCodeUtil',
        },
    }

    const {
        values,
        errors,
        touched,
        isValidForm,
        handleChange,
        handleBlur,
        handleErrors,
        setValues,
        setTouched,
    } = useValidationForm(INITIAL_STATE)

    useEffect(() => {
        // * If the validation is added to NIPC, triggers handleErros to update de
        // * form validation and enable/disable the submit button
        handleErrors({
            target: {
                name: 'commitmentNumber',
            },
        })
    }, [values.nipc.validation])

    function handleToggleEntity() {
        // * If the fields will be shown, add their validations.
        // * If they will be hidden reset their state, so the form can be valid without them.
        if (!showEntity) {
            setValues({
                ...values,
                nipc: { ...values.nipc, validation: ['validateNotEmptyUtil', 'validateNipcUtil'] },
                entityName: { ...values.entityName, validation: ['validateNotEmptyUtil'] },
                entityAddress: { ...values.entityAddress, validation: ['validateNotEmptyUtil'] },
                entityLocation: { ...values.entityLocation, validation: ['validateNotEmptyUtil'] },
                postalCode: { ...values.postalCode, validation: ['validateNotEmptyUtil', 'validatePostalCodeUtil'] },
            })
        } else {
            setValues({
                ...values,
                nipc: {
                    value: '',
                    mask: 'maskNIFUtil',
                },
                entityName: { value: '' },
                entityAddress: { value: '' },
                entityLocation: {
                    value: '',
                },
                postalCode: {
                    value: '',
                    mask: 'maskPostalCodeUtil',
                },
            })
            setTouched({
                ...touched,
                nipc: false,
                entityName: false,
                entityAddress: false,
                entityLocation: false,
                postalCode: false,
            })
        }
        setShowEntity(!showEntity)
    }

    function getSubmitPayload() {
        // TODO: Build the expected payload to backend
        return showEntity
            ? {
                  commitmentNumber: values.commitmentNumber.value,
                  orderNumber: values.orderNumber.value,
                  docketNumber: values.docketNumber.value,
                  nipc: values.nipc.value,
                  entityName: values.entityName.value,
                  entityAddress: values.entityAddress.value,
                  entityLocation: values.entityLocation.value,
                  postalCode: values.postalCode.value,
                  file: files.length === 1 ? files[0] : [],
                  showEntity,
              }
            : {
                  commitmentNumber: values.commitmentNumber.value,
                  orderNumber: values.orderNumber.value,
                  docketNumber: values.docketNumber.value,
                  nipc: stateOrders.entity.nipc,
                  entityName: stateOrders.entity.entityName,
                  entityAddress: stateOrders.entity.entityAddress,
                  entityLocation: stateOrders.entity.entityLocation,
                  postalCode: stateOrders.entity.postalCode,
                  file: files.length === 1 ? files[0] : [],
                  showEntity,
              }
    }

    function handleBack() {
        actionsOrders.persistPaymentInfoOnState(getSubmitPayload())
        setActiveStep(0)
    }

    function handleNext() {
        actionsOrders.persistPaymentInfoOnState(getSubmitPayload())
        setActiveStep(2)
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        id='commitmentNumber'
                        name='commitmentNumber'
                        label='N˚ de compromisso'
                        margin='dense'
                        variant='outlined'
                        value={values.commitmentNumber.value}
                        error={touched.commitmentNumber ? Boolean(errors.commitmentNumber) : false}
                        helperText={touched.commitmentNumber ? errors.commitmentNumber : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        id='orderNumber'
                        name='orderNumber'
                        label='N˚ da Encomenda'
                        margin='dense'
                        variant='outlined'
                        value={values.orderNumber.value}
                        error={touched.orderNumber ? Boolean(errors.orderNumber) : false}
                        helperText={touched.orderNumber ? errors.orderNumber : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        id='docketNumber'
                        name='docketNumber'
                        label='N˚ de Cabimento'
                        margin='dense'
                        variant='outlined'
                        value={values.docketNumber.value}
                        error={touched.docketNumber ? Boolean(errors.docketNumber) : false}
                        helperText={touched.docketNumber ? errors.docketNumber : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showEntity}
                                color='primary'
                                onChange={handleToggleEntity}
                                value='entity'
                                //* Business logic, if Payment Info = 2 and Protocol Type = 'FORMAL'
                                //* then the check for entity should be disabled.
                                disabled={
                                    stateLogin.activeScope.paymentInfo === 2 &&
                                    stateLogin.activeScope.protocolType === 'FORMAL'
                                }
                            />
                        }
                        label='Faturar em nome de outra entidade'
                    />
                    {showEntity && (
                        <Fade in={showEntity}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <InputMask
                                        mask={maskNIFUtil}
                                        value={values.nipc.value}
                                        maskPlaceholder={null}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        {() => (
                                            <TextField
                                                id='nipc'
                                                name='nipc'
                                                label='NIPC'
                                                margin='dense'
                                                variant='outlined'
                                                error={touched.nipc ? Boolean(errors.nipc) : false}
                                                helperText={touched.nipc ? errors.nipc : ''}
                                                fullWidth
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        id='entityName'
                                        name='entityName'
                                        label='Nome da Entidade'
                                        margin='dense'
                                        variant='outlined'
                                        value={values.entityName.value}
                                        error={touched.entityName ? Boolean(errors.entityName) : false}
                                        helperText={touched.entityName ? errors.entityName : ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id='entityAddress'
                                        name='entityAddress'
                                        label='Morada'
                                        margin='dense'
                                        variant='outlined'
                                        value={values.entityAddress.value}
                                        error={touched.entityAddress ? Boolean(errors.entityAddress) : false}
                                        helperText={touched.entityAddress ? errors.entityAddress : ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <InputMask
                                        mask={maskPostalCodeUtil}
                                        value={values.postalCode.value}
                                        maskPlaceholder={null}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        {() => (
                                            <TextField
                                                id='postalCode'
                                                name='postalCode'
                                                label='Código Postal'
                                                margin='dense'
                                                variant='outlined'
                                                error={touched.postalCode ? Boolean(errors.postalCode) : false}
                                                helperText={touched.postalCode ? errors.postalCode : ''}
                                                fullWidth
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <TextField
                                        id='entityLocation'
                                        name='entityLocation'
                                        label='Localidade'
                                        margin='dense'
                                        variant='outlined'
                                        value={values.entityLocation.value}
                                        error={touched.entityLocation ? Boolean(errors.entityLocation) : false}
                                        helperText={touched.entityLocation ? errors.entityLocation : ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Fade>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Adicionar Ficheiro .PDF' />
                        <Typography variant='caption'>
                            Clique ou arraste o ficheiro (PDF) do Documento de Compromisso para faturação
                        </Typography>
                        <UploadFileComponent
                            id='files'
                            initialFiles={files}
                            handleFiles={fileList => setFiles(fileList)}
                            isSingleFile
                        />
                    </PaperComponent>
                </Grid>
            </Grid>
            <ActionBarComponent backText='Voltar' handleBack={handleBack}>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValidForm || (stateLogin.activeScope.paymentInfo !== 1 && files.length !== 1)}
                    endIcon={<ArrowForward />}
                    onClick={() => {
                        handleNext()
                    }}
                >
                    Seguinte
                </Button>
            </ActionBarComponent>
        </form>
    )
}

NewOrderPaymentInfoForm.propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}

export default NewOrderPaymentInfoForm
