import { createGlobalStyle } from 'styled-components'
import Lato from 'lato-font'
import { createMuiTheme } from '@material-ui/core'

import MaterialIconsRegularTtf from '../assets/fonts/MaterialIcons-Regular.ttf'
import MaterialIconsRegularWoff2 from '../assets/fonts/MaterialIcons-Regular.woff2'
import MaterialIconsRegularWoff from '../assets/fonts/MaterialIcons-Regular.woff'
import MaterialIconsRegularEot from '../assets/fonts/MaterialIcons-Regular.eot'

export const GlobalStyles = createGlobalStyle`

    html {
      scroll-behavior: smooth;
    }

    body{
      font-family: Lato, 'sans-serif';
      font-size: 0.85rem;
      background-color: ${props => props.theme.palette.white} !important;
    }

    @media screen and (prefers-reduced-motion: reduce) {
        html {
          scroll-behavior: auto;
        }
    }

    @font-face {
        font-family: 'MaterialIconsRegular';
        src: url('${MaterialIconsRegularEot}'); /* For IE6-8 */
        src: url('${MaterialIconsRegularTtf}') format('truetype');
        src: url('${MaterialIconsRegularWoff2}') format('woff2');
        src: url('${MaterialIconsRegularWoff}') format('woff');
    }

    .material-icons {
        font-family: 'MaterialIconsRegular';
        font-weight: 400;
        font-style: normal;
        font-size: 24px;  /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: 'liga';
    }

    .MuiCheckbox-colorPrimary.Mui-disabled {
        color: rgba(0, 0, 0, 0.03) !important;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color : ${props => props.theme.palette.primary.main} !important;
    }
`

export const StyledTheme = {
    palette: {
        white: '#FFFFFF',
        black: '#000000',
        primary: {
            light: '#FFB8BC',
            main: '#960008',
            hover: '#F6EEEE',
        },
        error: {
            light: '#F2BBB8',
            main: '#F28B71',
            dark: '#D84C45',
        },
        success: {
            light: '#A5D6A7',
            main: '#00C853',
            dark: '#189618',
        },
        warning: {
            light: '#FBC02D',
            main: '#F9A825',
            dark: '#F57F17',
        },
        grey: {
            xLight: '#F7F9FA',
            midLight: '#F4F4F4',
            light: '#C0C5D2',
            main: '#7F92A6',
            dark: '#304358',
        },
        blue: {
            xLight: '#D7EAFB',
            light: '#6EB4F1',
            main: '#3597EC',
            dark: '#1E3D58',
        },
    },
}

export const MaterialTheme = createMuiTheme({
    typography: {
        fontFamily: [Lato, 'sans-serif'],
        useNextVariants: true,
    },
    palette: StyledTheme.palette,
})

export const customSelectColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: StyledTheme.palette.primary.main,
        primary25: StyledTheme.palette.primary.light,
    },
})
