import ORDERS_TYPES from './Orders.types'

const useActions = (state, dispatch) => ({
    resetData: () => {
        dispatch({ type: ORDERS_TYPES.RESET_DATA.SUCCESS })
    },
    showLoading: () => {
        dispatch({ type: ORDERS_TYPES.SHOW_LOADING.SUCCESS })
    },
    hideLoading: () => {
        dispatch({ type: ORDERS_TYPES.HIDE_LOADING.SUCCESS })
    },
    createNewService: name => {
        dispatch({ type: ORDERS_TYPES.SERVICE_TYPE.POST.REQUEST, payload: { state, name: name } })
    },
    deleteServiceByIdAndType: (id, type) => {
        dispatch({ type: ORDERS_TYPES.SERVICE.PUT.REQUEST, payload: { state, id, type } })
    },
    getServices: () => {
        dispatch({ type: ORDERS_TYPES.SERVICES.GET.REQUEST })
    },
    setServiceByType: (item, type, history) => {
        dispatch({ type: ORDERS_TYPES.SERVICE.POST.REQUEST, payload: { state, item, type, history } })
    },
    getOrgUnits: () => {
        dispatch({ type: ORDERS_TYPES.ORG_UNITS.GET.REQUEST })
    },
    getCartPrice: () => {
        dispatch({ type: ORDERS_TYPES.CART.GET.REQUEST, payload: { state } })
    },
    updateCart: () => {
        dispatch({ type: ORDERS_TYPES.CART.POST.REQUEST, payload: { state } })
    },
    persistPaymentInfoOnState: values => {
        dispatch({ type: ORDERS_TYPES.PAYMENT.POST.REQUEST, payload: { ...values } })
    },
    getEntity: () => {
        dispatch({ type: ORDERS_TYPES.ENTITY.GET.REQUEST })
    },
    registerOrders: orders => {
        dispatch({ type: ORDERS_TYPES.ORDERS.POST.REQUEST, payload: { orders } })
    },
    setPreviewOrders: data => dispatch({ type: ORDERS_TYPES.PREVIEW_ORDERS.SUCCESS, data }),
})

export default useActions
