import styled from 'styled-components'
import { Check, Warning } from '@material-ui/icons'

export const SCardItem = styled.div`
    border: 1px solid ${props => props.theme.palette.grey.light};
    background-color: ${props => props.theme.palette.grey.xLight};
    color: ${props => props.theme.palette.grey.main};
    padding: 16px 8px 40px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 150px;
    margin: 8px;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover {
        border: 1px solid ${props => props.theme.palette.primary.main};
        background-color: ${props => props.theme.palette.primary.hover};
    }

    .card-avatar {
        margin-bottom: 8px;
    }

    .card-name {
        display: block;
        width: 100%;
        text-align: center;
        color: ${props => props.theme.palette.grey.dark};
        font-weight: 600;
    }

    .card-role {
        font-size: 12px;
        display: block;
        width: 100%;
        text-align: center;
        color: ${props => props.theme.palette.primary.main};
    }

    .card-entity {
        font-size: 11px;
        display: block;
        width: 100%;
        text-align: center;
        color: ${props => props.theme.palette.grey.dark};
    }

    .card-actions {
        position: absolute;
        bottom: 0;
        height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.palette.grey.light} !important;
    }

    .card-status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 5px;
        right: 5px;

        svg {
            font-size: 16px;
        }
    }
`

export const SSuccessIcon = styled(Check)`
    && {
        color: ${props => props.theme.palette.success.main};
    }
`
export const SWarningIcon = styled(Warning)`
    && {
        color: ${props => props.theme.palette.warning.main};
    }
`
