import React, { memo } from 'react'
import PropTypes from 'prop-types'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import api from '../../../services/api.service'
import { useAlertStore } from '../../../store/Alert/Alert.store'
import { useTableStore } from '../../../store/Table/Table.store'

const CSV_METADATA = 'data:text/csv;charset=utf-8;base64,77u/'

function DownloadFileCSV({ exportCSV, endpoint }) {
    const { actionsAlert } = useAlertStore()
    const { tableState } = useTableStore()

    async function downloadCSV() {
        const exportURL = `${endpoint}/export${tableState.tableQueryToExportCSV}`

        try {
            const response = await api.get(exportURL)
            const { payload } = response.data
            if (Object.keys(payload).length !== 0) {
                const linkSource = `${CSV_METADATA}${payload}`

                const downloadLink = document.createElement('a')
                downloadLink.href = linkSource
                downloadLink.download = `${exportCSV.filename}.csv`

                downloadLink.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
            }
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data.message)
            }
        }
    }

    return (
        <Tooltip title='Exportar CSV' placement='left'>
            <IconButton color='primary' component='span' onClick={() => downloadCSV()}>
                <CloudDownloadIcon />
            </IconButton>
        </Tooltip>
    )
}

DownloadFileCSV.propTypes = {
    exportCSV: PropTypes.objectOf(PropTypes.any),
    endpoint: PropTypes.string,
}

DownloadFileCSV.defaultProps = { endpoint: '' }

export default memo(DownloadFileCSV)
