/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import { CircularProgress, Fade, Grid, TextField } from '@material-ui/core'

import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import useValidationForm from '../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../hooks/useServiceCallApi.hook'

import MessageComponent from '../../components/templates/Message/Message.component'
import Callout from '../../components/templates/Callout/Callout'

import { getUrlParam } from '../../utils'
import { INITIAL_STATE, ENDPOINT } from './constants'

import { SButton, SCircular, SLogo, SPaper, STitle, SAccessActions, SLink } from './TimestampPassword.styles'

function TimestampPassword() {
    const [hash, setHash] = useState(null)
    const [requestState, callService] = useServiceCallApi(null)
    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE)

    useEffect(() => {
        const hashParam = getUrlParam('hash')
        setHash(hashParam)
    }, [])

    function handleSubmit() {
        // 1. build payload
        const payload = {
            password: values.password.value,
            hash,
        }

        // 2. call service
        callService(ENDPOINT.RECOVER_TIMESTAMP_PASSWORD, { requestType: 'post', data: payload })
    }

    function renderContent() {
        // Show loading spinner
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                    <span>A processar o pedido...</span>
                </SCircular>
            )
        }
        // show success screen
        if (
            requestState.url &&
            requestState.data &&
            requestState.url === ENDPOINT.RECOVER_TIMESTAMP_PASSWORD &&
            Object.keys(requestState.data).length > 0
        ) {
            return <MessageComponent type='success' message={requestState.data.message} textBack='Login' />
        }
        // show form
        return (
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <STitle>Por favor, informe a nova palavra-passe</STitle>
                        <TextField
                            id='password'
                            name='password'
                            label='Nova palavra-passe'
                            type='password'
                            margin='dense'
                            variant='outlined'
                            value={values.password.value}
                            error={touched.password ? Boolean(errors.password) : false}
                            helperText={touched.password ? errors.password : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                        <TextField
                            id='repeatPassword'
                            name='repeatPassword'
                            label='Repita palavra-passe'
                            type='password'
                            margin='dense'
                            variant='outlined'
                            value={values.repeatPassword.value}
                            error={touched.repeatPassword ? Boolean(errors.repeatPassword) : false}
                            helperText={touched.repeatPassword ? errors.repeatPassword : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <SAccessActions>
                    <SLink to='/'>Voltar para o Login</SLink>
                    <SButton variant='contained' color='primary' disabled={!isValidForm} onClick={handleSubmit}>
                        Guardar
                    </SButton>
                </SAccessActions>
                <Callout>
                    A palavra-passe deve conter um mínimo de 9 caracteres, entre os quais letras maiúsculas (A...Z),
                    minúsculas (a...z) e números (0...9).
                </Callout>
            </form>
        )
    }

    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                <h3>Recuperação de Palavra-passe de utilizador do Selo Temporal</h3>
                {renderContent()}
            </SPaper>
        </Fade>
    )
}

export default TimestampPassword
