import React, { useEffect, useState, useReducer } from 'react'
import { useLocation } from 'react-router-dom'

import { Checkbox, FormControlLabel, Grid, CircularProgress, Fade, Typography } from '@material-ui/core'

import { transformTimestampToSimpleDate } from '../../utils/date.util'

import PaperComponent from '../../components/templates/Paper/Paper.component'
import FItemData from '../../components/templates/ItemData/ItemData.component'
import MessageComponent from '../../components/templates/Message/Message.component'

import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import { ENDPOINTS } from './constants'
import { reducer, Types, INITIAL_STATE } from './reducers'

import useServiceCallApi from '../../hooks/useServiceCallApi.hook'

import { SMARTCARD_ACTIVATION } from '../../constants/dictionary/dictionary'

import { SLogo, SPaper, SButton, SCircular, SPaperRow } from './Smartcard.styles'

function SmartcardActivation() {
    const location = useLocation()
    const [pageState, dispatch] = useReducer(reducer, INITIAL_STATE)
    const [requestState, callService] = useServiceCallApi(null)
    const [confirmCheckbox, setConfirmCheckbox] = useState(false)

    useEffect(() => {
        if (location?.state?.hash && location?.state?.validationHash) {
            dispatch({
                type: Types.SET_INITIAL_DATA,
                payload: {
                    hash: location.state.hash,
                    validationHash: location.state.validationHash,
                    distinguishedName: location.state.distinguishedName,
                    email: location.state.email,
                    smartcardNumber: location.state.smartcardNumber,
                    expirationDate: location.state.expirationDate,
                    issueDate: location.state.issueDate,
                },
            })
        }
    }, [location])

    useEffect(() => {
        if (!requestState.isLoading && requestState.error.hasError) setConfirmCheckbox(false)
    }, [requestState])

    function handleSubmit() {
        let payload = {
            hash: pageState.hash,
            validationHash: pageState.validationHash,
        }

        callService(ENDPOINTS.SMARTCART_ACTIVATION, {
            requestType: 'post',
            data: payload,
        })
    }

    function renderContent() {
        if (requestState.url === ENDPOINTS.SMARTCART_ACTIVATION) {
            if (requestState.isLoading) {
                return (
                    <SCircular>
                        <CircularProgress size={30} />
                    </SCircular>
                )
            }

            if (requestState.data && !requestState.error.hasError) {
                return <MessageComponent type='success' message={requestState.data.message} />
            }

            if (!requestState.isLoading && requestState.error.hasError) {
                return <MessageComponent type='error' message={requestState.error.message.message} />
            }
        }

        return (
            <>
                <h3>
                    Confirme os dados para ativar o seu <i>Smartcard</i>
                </h3>
                <PaperComponent noborder>
                    <SPaperRow>
                        <FItemData vertical itemKey='Número do Cartão:' itemValue={pageState.smartcardNumber} />
                    </SPaperRow>
                    <SPaperRow>
                        <FItemData
                            vertical
                            itemKey='Data da Emissão:'
                            itemValue={transformTimestampToSimpleDate(pageState.issueDate)}
                        />
                        <FItemData
                            vertical
                            itemKey='Data de Validade:'
                            itemValue={transformTimestampToSimpleDate(pageState.expirationDate)}
                        />
                    </SPaperRow>
                    <SPaperRow>
                        <FItemData vertical itemKey='Nome:' itemValue={pageState.distinguishedName.commonName} />
                    </SPaperRow>
                    <SPaperRow>
                        <FItemData vertical itemKey='Entidade:' itemValue={pageState.distinguishedName.organization} />
                    </SPaperRow>
                    <SPaperRow>
                        <FItemData
                            vertical
                            itemKey='Unidade Organizacional:'
                            itemValue={pageState.distinguishedName.organizationUnit}
                        />
                    </SPaperRow>
                    <SPaperRow>
                        <FItemData vertical itemKey='Cargo:' itemValue={pageState.distinguishedName.title} />
                        <FItemData vertical itemKey='País:' itemValue={pageState.distinguishedName.country} />
                    </SPaperRow>

                    <SPaperRow>
                        <FItemData itemKey='E-mail do Certificado:' itemValue={pageState.email} />
                    </SPaperRow>
                </PaperComponent>

                <Grid item xs={12}>
                    <Typography variant='subtitle2' gutterBottom={true}>
                        {SMARTCARD_ACTIVATION}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox checked={confirmCheckbox} onChange={() => setConfirmCheckbox(!confirmCheckbox)} />
                        }
                        label={
                            <Typography variant='subtitle2'>
                                Confirmo que os dados estão corretos e solicito a ativação do cartão.
                            </Typography>
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={3} style={{ float: 'right' }}>
                        <SButton
                            variant='contained'
                            color='primary'
                            disabled={!confirmCheckbox}
                            onClick={handleSubmit}
                            fullWidth
                        >
                            Guardar
                        </SButton>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                {renderContent()}
            </SPaper>
        </Fade>
    )
}

export default SmartcardActivation
