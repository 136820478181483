import { Link as LinkRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'

const display = css`
    display: flex;
    justify-content: center;
`

export const LoginHeader = styled.section`
    width: 500px;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    .logo-ecce {
        width: 55px;
        margin-right: 32px;
    }
    .logo-instituicao {
        width: 150px;
    }
`

export const Paper = styled.section`
    width: 500px;
    max-width: 100%;
    margin-bottom: 48px;
`

export const SLoginForm = styled.div`
    margin-bottom: 10px;
`
export const Title = styled.h1`
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 16px;
`
export const AccessRecovery = styled.div`
    margin-top: 16px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`
export const Register = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const SButton = styled.button`
    margin: auto;
    padding: 5px 10px;
    ${display};
    align-items: center;
    background-color: #3c5dbc;
    color: ${props => props.theme.palette.white};
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    span {
        padding-left: 7px;
        text-transform: uppercase;
    }
`

export const Link = styled(LinkRouter)`
    width: 100%;
    height: 50px;
    ${display};
    align-items: center;
    background-color: ${props => props.color};
    color: ${props => props.theme.palette.white};
    margin-top: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
`
export const Divider = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
    &::after {
        position: absolute;
        background-color: ${props => props.theme.palette.grey.light};
        width: 100%;
        height: 1px;
        content: '';
        left: 0;
        top: 50%;
        z-index: 0;
    }
`
export const DividerText = styled.span`
    display: block;
    background-color: ${props => props.theme.palette.white};
    padding: 0 10px;
    z-index: 1;
    color: ${props => props.theme.palette.grey.main};
    font-size: 14px;
`

export const LoginFooter = styled.div`
    width: 500px;
    margin: 0 auto;
    max-width: 100%;
`

export const LogoList = styled.section`
    display: flex;
    max-width: 460px;
    .footer-logo {
        max-width: 100%;
        height: 55px;
        @media (max-width: 1440px) {
            height: 48px;
        }
    }
`

export const FooterBlank = styled.div`
    width: 100%;
    margin-top: 55px;
    background: #fff;
`
