import REQUEST_USER_TYPES from './RequestUser.types'

export const initialState = {
    user: {},
    entity: {},
    success: {},
    error: {},
}

export function RequestUserReducer(state = initialState, action = {}) {
    switch (action.type) {
        case REQUEST_USER_TYPES.RESET_DATA.SUCCESS:
            return initialState
        case REQUEST_USER_TYPES.USER.GET.SUCCESS:
            return { ...state, user: action.payload }
        case REQUEST_USER_TYPES.USER.POST.REQUEST:
            return { ...state, user: action.payload }
        case REQUEST_USER_TYPES.ENTITY.GET.SUCCESS:
            return { ...state, entity: action.payload }
        case REQUEST_USER_TYPES.ENTITY.POST.REQUEST:
            return { ...state, entity: action.payload }
        case REQUEST_USER_TYPES.REGISTER.POST.SUCCESS:
            return { ...state, success: action.success }
        case REQUEST_USER_TYPES.REGISTER.POST.ERROR:
            return { ...state, error: action.error }
        case REQUEST_USER_TYPES.REGISTER.PUT.REQUEST:
            return { ...state, error: {}, success: {} }
        default:
            return state
    }
}
