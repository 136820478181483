import styled from 'styled-components'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Button } from '@material-ui/core'

export const SPaper = styled.div`
    max-width: 500px;
`

export const SLogo = styled.div`
    margin-bottom: 10px;
    width: 55px;
    height: auto;
    @media (max-width: 1024px) {
        width: 50px;
        margin-bottom: 16px;
    }
    img {
        max-width: 100%;
    }
`

const SelectStyle = `
    z-index: 10;
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : '0')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
`
export const SSelect = styled(Select)`
    ${SelectStyle};
`

export const SCreatableSelect = styled(CreatableSelect)`
    ${SelectStyle};
    z-index: 9;
`

export const SButton = styled(Button)`
    && {
        margin: 8px 0 4px 0;
    }
`
export const SCircular = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 16px;
        font-size: 16px;
    }
`

export const STitle = styled.div`
    font-weight: 300;
    margin-top: 20px;
`
