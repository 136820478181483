/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { MTableToolbar } from 'material-table'
import { transformTimestampToDate } from '../../../utils/date.util'
import DataTableComponent from '../../../components/templates/DataTable/DataTable.component'

import { ItemChip, SToolbar } from '../Order.styles'

const COLUMNS = [
    {
        field: 'service',
        title: 'serviço',
    },
    {
        field: 'serviceType',
        title: 'tipo de serviço',
    },

    {
        field: 'validity',
        title: 'validade (anos)',
    },
    {
        field: 'price',
        title: 'valor',
        render: ({ price }) => (price ? `${price.toFixed(2)} €` : ''),
    },
    {
        field: 'emailCertificate',
        title: 'Email do Certificado',
    },
    {
        field: 'supportType',
        title: 'suporte',
    },
    {
        field: 'stepLabel',
        title: 'Etapa',
        render: ({ step }) => (step ? step.label : ''),
    },
    {
        field: 'updatedDate',
        title: 'data do estado',
        render: ({ status }) => (status.updatedDate ? transformTimestampToDate(status.updatedDate) : ''),
    },
    {
        field: 'statusLabel',
        title: 'estado',
        headerStyle: {
            textAlign: 'center',
        },
        render: rowData => <ItemChip typecolor='orderItens' label={rowData.status.label} type={rowData.status.id} />,
    },
]

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
    data: null,
}

function ElectronicSealTable(props) {
    const { data } = props
    return (
        <DataTableComponent
            title='Selo Eletrónico Qualificado'
            options={{ showTitle: true }}
            columns={COLUMNS}
            data={data}
            components={{
                Toolbar: tableProps => (
                    <SToolbar>
                        <MTableToolbar {...tableProps} />
                    </SToolbar>
                ),
            }}
        />
    )
}

ElectronicSealTable.propTypes = propTypes
ElectronicSealTable.defaultProps = defaultProps

export default ElectronicSealTable
