/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import { TextField, Grid } from '@material-ui/core'

import ModalComponent, { ModalBodyComponent } from '../../components/templates/Modal/Modal.component'

import { useAlertStore } from '../../store/Alert/Alert.store'
import { useOrdersStore } from '../../store/Orders/Orders.store'

import api from '../../services/api.service'

import { validateNumber } from '../../utils/validate.util'

import { SStepperModalContainer, SButton } from './NewOrderModal.styles'

// eslint-disable-next-line react/prop-types
function NewOrderModal({ onCloseModal, isPreviousOrderModalOpen }) {
    const [previousOrderNumber, setPreviousOrderNumber] = useState('')
    const { actionsAlert } = useAlertStore()

    // ORDERS STORE
    const { stateOrders, actionsOrders } = useOrdersStore()

    async function handleGetPreviousOrder() {
        try {
            const response = await api.get(`orders/getConfigs/${previousOrderNumber}`)
            const { payload } = response.data

            actionsOrders.setPreviewOrders(payload)

            onCloseModal(false)

            payload.map(order => {
                switch (order.basicType) {
                    case 'TOKEN':
                        if (stateOrders.services.cards) {
                            const cardIds = stateOrders.services.cards.map(card => card.id)
                            cardIds.map(cardId => actionsOrders.deleteServiceByIdAndType(cardId, 'cards'))
                        }
                        break
                    case 'SSL':
                        if (stateOrders.services.ssls) {
                            const sslIds = stateOrders.services.cards.map(ssl => ssl.id)
                            sslIds.map(sslId => actionsOrders.deleteServiceByIdAndType(sslId, 'ssls'))
                        }
                        break
                    case 'WILDCARD':
                        if (stateOrders.services.wildcards) {
                            const wildcardIds = stateOrders.services.cards.map(wildcard => wildcard.id)
                            wildcardIds.map(wildcardId =>
                                actionsOrders.deleteServiceByIdAndType(wildcardId, 'wildcards')
                            )
                        }

                        break
                    case 'ELECTRONIC_SEAL':
                        if (stateOrders.services.electronicSeals) {
                            const electronicSealIds = stateOrders.services.electronicSeals.map(
                                electronicSeals => electronicSeals.id
                            )
                            electronicSealIds.map(electronicSealsId =>
                                actionsOrders.deleteServiceByIdAndType(electronicSealsId, 'electronicSeals')
                            )
                        }

                        break
                    default:
                        if (stateOrders.services.timestamps) {
                            const timestampsIds = stateOrders.services.timestamps.map(timestamp => timestamp.id)
                            timestampsIds.map(timestampId =>
                                actionsOrders.deleteServiceByIdAndType(timestampId, 'timestamps')
                            )
                        }
                        break
                }
            })

            actionsOrders.createNewService(payload)

            onCloseModal(false)
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data)
            }
        }
    }

    return (
        <ModalComponent
            open={isPreviousOrderModalOpen}
            title='Adicionar outro pedido já submetido'
            onClick={() => onCloseModal(false)}
        >
            <ModalBodyComponent>
                <SStepperModalContainer>
                    <p>Preencher automaticamente com os dados de um outro pedido já submetido:</p>
                    <Grid item xs={10} sm={10} md={10} lg={5} xl={7}>
                        <TextField
                            id='previousOrderId'
                            name='previousOrder'
                            label='Nº do Pedido'
                            margin='dense'
                            variant='outlined'
                            value={previousOrderNumber}
                            error={previousOrderNumber ? Boolean(validateNumber(previousOrderNumber)) : false}
                            helperText={previousOrderNumber ? validateNumber(previousOrderNumber) : ''}
                            inputProps={
                                previousOrderNumber && previousOrderNumber.length === 1
                                    ? { maxLength: 12 }
                                    : { maxLength: 9 }
                            }
                            onChange={e => setPreviousOrderNumber(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <SButton
                        disabled={!previousOrderNumber || !Number(previousOrderNumber)}
                        onClick={handleGetPreviousOrder}
                    >
                        PREENCHER
                    </SButton>
                </SStepperModalContainer>
            </ModalBodyComponent>
        </ModalComponent>
    )
}

export default NewOrderModal
