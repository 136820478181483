import React from 'react'
import PropTypes from 'prop-types'

import { SFooter } from './Modal.style'

const propTypes = {
    children: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    children: {},
}

const ModalFooterComponent = ({ children }) => {
    return <SFooter>{children}</SFooter>
}

ModalFooterComponent.propTypes = propTypes
ModalFooterComponent.defaultProps = defaultProps

export default ModalFooterComponent
