import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'

export const SItem = styled.div`
    background-color: ${props => props.theme.palette.grey.xLight};
    border: 1px solid ${props => props.theme.palette.grey.light};
    border-radius: 4px;
    padding: 0 20px;
    width: 100%;
    position: relative;
    transition: all 3s;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`
export const SHeader = styled.div`
    display: flex;
    align-items: center;
`
export const STitle = styled.h3`
    color: ${props => props.theme.palette.grey.dark};
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
`
export const SContent = styled.div`
    border-top: 1px solid ${props => props.theme.palette.grey.light};
    color: ${props => props.theme.palette.grey.main};
    margin-top: 10px;
    padding: 20px 0;
    line-height: 25px;
    font-size: 16px;
`
export const SIconButton = styled(IconButton)`
    width: 45px;
    height: 45px;
`
