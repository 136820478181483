import Select from 'react-select'
import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

export const SCircular = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-top: 50px;
        font-size: 20px;
    }
`

const SelectStyle = `
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : 'none')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
    div:nth-child(3){
        z-index: 11 !important;
    }
`
const List = `
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
`
const ListItem = `
    position: relative;
    width: 100%;
    padding: 0 16px 8px;
    margin-bottom: 8px;
`
export const SFileMessage = styled.div`
    display: flex;
    align-items: right;
    padding: 8px;
    justify-content: space-between;
    color: ${props => props.theme.palette.grey.dark};
    font-weight: bold;
    .warning {
        color: ${props => props.theme.palette.warning.main};
    }
    .success {
        color: ${props => props.theme.palette.success.main};
    }
    .error {
        color: ${props => props.theme.palette.primary.main};
    }
`
export const SErrorMessage = styled(Typography)`
    display: flex;
    padding: 8px;
    justify-content: space-between;
    color: ${props => props.theme.palette.error.main};
`
export const SPaperRow = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
    @media (max-width: 768px) {
        min-width: auto;
        width: 100%;
    }
    .success {
        color: ${props => props.theme.palette.success.main};
    }
`
export const SSelect = styled(Select)`
    ${SelectStyle};
    ${props => {
        if (props.isDisabled) {
            return css`
                & > div {
                    background: #ffffff;
                    border-color: rgba(118, 118, 118, 0.3);
                    color: rgba(84, 84, 84, 0.3);
                }
            `
        }
    }}
`

export const SCreatableSelect = styled(Select)`
    ${SelectStyle};
    z-index: 10;
`

export const SDomainList = styled.ul`
    ${List};
`
export const SDomainListItem = styled.li`
    ${ListItem};
`
