import styled from 'styled-components'

const SStepperContainer = styled.div`
    position: relative;
    width: 70%;
    height: 110px;
    margin: 0 auto 50px auto;
    text-transform: uppercase;

    @media (max-width: 992px) {
        width: 100%;
    }
`

export default SStepperContainer
