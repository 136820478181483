/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    Fragment,
    // TODO: Commented while entities select logic is unavailable
    // useState,
    useEffect,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// TODO: Commented while entities select logic is unavailable
// import Select from 'react-select'
import { uniqBy } from 'lodash'
import {
    // TODO: Commented while entities select logic is unavailable
    // Grid,
    Button,
    Tooltip,
} from '@material-ui/core'
import { ArrowForward, ShoppingCart, GetApp } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'

import CircularProgress from '@material-ui/core/CircularProgress'

import api from '../../services/api.service'

import { useOrdersStore } from '../../store/Orders/Orders.store'
import { useLoginStore } from '../../store/Login/Login.store'

import NewOrderModal from './NewOrderModal.step'

import SectionHeader from '../../components/templates/SectionHeader/SectionHeader.component'
import ProductItemComponent from '../../components/ProductItem/ProductItem.component'
import ProductTypeCardComponent from '../../components/ProductTypeCard/ProductTypeCard.component'
import ProductTypeSSLComponent from '../../components/ProductTypeSSL/ProductTypeSSL.component'
import ProductTypeElectronicSealComponent from '../../components/ProductTypeElectronicSeal/ProductTypeElectronicSeal.component'
import ProductTypeWildcardComponent from '../../components/ProductTypeWildcard/ProductTypeWildcard.component'
import ProductTypeTimestampComponent from '../../components/ProductTypeTimestamp/ProductTypeTimestamp.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import Callout from '../../components/templates/Callout/Callout'

import { NEW_ORDER_CALLOUT } from '../../constants/dictionary/dictionary'

import {
    // TODO: Commented while entities select logic is unavailable
    // SEntitiesSelect,
    SProductsBar,
    SProductsList,
    SCardList,
    SSslList,
    SPriceOrder,
    SWrapperButton,
} from './StartNewOrder.styles'
import FConfirmationModal from '../../components/FConfirmModal/FConfirmModal'

// TODO: Commented while entities select logic is unavailable
// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
// ]

const ProductDescription = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f0f0c2',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(13),
        border: '1px solid #dadde9',
    },
}))(Tooltip)
function StartNewOrderStep({ setActiveStep }) {
    // TODO: Commented while entities select logic is unavailable
    // SELECT CONFIGS
    // const [isClearable] = useState(true)
    // const [isSearchable] = useState(true)
    // LOGIN STORE
    const { stateLogin } = useLoginStore()
    // ORDERS STORE
    const { stateOrders, actionsOrders } = useOrdersStore()
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [isPreviousOrderModalOpen, setIsPreviousOrderModalOpen] = useState(false)
    const { entity } = stateOrders

    const history = useHistory()

    useEffect(() => {
        actionsOrders.updateCart()
    }, [stateOrders.services])

    useEffect(() => {
        actionsOrders.getCartPrice()
    }, [stateOrders.services.cart])

    useEffect(() => {
        if (Object.keys(stateLogin.activeScope) !== 0) {
            actionsOrders.getEntity()
            actionsOrders.getServices()
        }
    }, [])

    function handleDisabled() {
        const { services } = stateOrders
        let areAllItemsActive = false
        const keys = Object.keys(services)
        //* Starts on the second position of the array, because the first position is always Types
        //* and Types isn't included in this business logic.
        for (let i = 1; keys.length > i; i++) {
            if (services[keys[i]].length > 0) {
                areAllItemsActive = !services[keys[i]].find(item => item.isValid === false)

                if (!areAllItemsActive) {
                    break
                }
            }
        }

        return areAllItemsActive
    }

    function handleNext() {
        if (stateLogin.activeScope.paymentInfo === 0) {
            setActiveStep(2)
        } else {
            setActiveStep(1)
        }
    }

    function handleCloseModal() {
        setIsPreviousOrderModalOpen(false)
    }
    function handleConfirmCloseModal() {
        setIsConfirmModalOpen(false)
    }

    function handleConfirmModal() {
        setIsConfirmModalOpen(false)
        setIsPreviousOrderModalOpen(true)
    }

    function verifyIfHasOrderUnfinished() {
        if (
            (stateOrders.services.cards && stateOrders.services.cards.length > 0) ||
            (stateOrders.services.ssls && stateOrders.services.ssls.length > 0) ||
            (stateOrders.services.electronicSeals &&
                stateOrders.services.electronicSeals.length > 0 &&
                isConfirmModalOpen) ||
            (stateOrders.services.wildcards && stateOrders.services.wildcards.length > 0) ||
            (stateOrders.services.timestamps && stateOrders.services.timestamps.length > 0)
        ) {
            setIsConfirmModalOpen(true)
        } else if (!isConfirmModalOpen) {
            setIsPreviousOrderModalOpen(true)
        }
    }

    function handleDownload(cardConfig) {
        const payloadSend = {
            nipc: entity.nipc,
            config: {
                address: {
                    address: cardConfig.address.address,
                    location: cardConfig.address.location,
                    postalCode: cardConfig.address.postalCode,
                },
                givenName: cardConfig.givenName,
                aliasReason: cardConfig.aliasReason,
                surname: cardConfig.surname,
                cardReaderRequest: cardConfig.cardReaderRequest,
                documentNumber: cardConfig.documentNumber,
                documentType: {
                    id: cardConfig.documentType.id,
                    label: cardConfig.documentType.label,
                },
                durationId: cardConfig.duration.id,
                documentValidity: cardConfig.documentValidity,
                nameOnCard: cardConfig.nameOnCard,
                personEmail: cardConfig.personEmail,
                personName: cardConfig.personName,
                personPhoneNumber: cardConfig.personPhoneNumber,
                personPhoto: cardConfig.personPhoto,
                pinPukSendType: {
                    id: cardConfig.pinPukSendType.id,
                },
                role: cardConfig.role,
                personTaxIdentificationNumber: cardConfig.taxIdentificationNumber,
                useAlias: cardConfig.useAlias,
                orgUnit:
                    cardConfig.orgUnitSelected === null
                        ? {
                              name: null,
                              value: null,
                              label: null,
                          }
                        : {
                              name: cardConfig.orgUnitSelected.name,
                              value: cardConfig.orgUnitSelected.name,
                              label: cardConfig.orgUnitSelected.name,
                          },
                pinPukAddress: cardConfig.pinPukAddress,
                pinPukPostalCode: cardConfig.pinPukPostalCode,
                pinPukLocation: cardConfig.pinPukLocation,
                settingFile: {
                    name: cardConfig.file.name,
                    base64: cardConfig.file.base64,
                },
            },
        }

        api.post('orders/cardConfig', payloadSend).then(response => {
            const { payload } = response.data

            const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${payload.docx}`

            // Download file
            const anchorElement = document.createElement('a')
            anchorElement.href = linkSource
            anchorElement.download = 'Configuracoes_Pedido.docx'
            anchorElement.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
        })
    }

    return (
        <Fragment>
            <SPriceOrder>
                <ShoppingCart />
                {`${stateOrders.cart.totalPrice || 0} €`}
            </SPriceOrder>

            {/*
            // TODO: Create logic for entities selection
            <SEntitiesSelect>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Select
                            options={options}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            name='color'
                            placeholder='Escolher Entidade'
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Select
                            options={options}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            name='color'
                            placeholder='Protocolo'
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Select
                            options={options}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            name='color'
                            placeholder='Nº Compromisso'
                        />
                    </Grid>
                </Grid>
            </SEntitiesSelect> */}
            <Callout>
                {`Antes de iniciar o preenchimento de um novo pedido de serviço veja `}
                <a href='https://www.ecce.gov.pt/portal-cliente/acesso/' target='_blank' rel='noopener noreferrer'>
                    aqui
                </a>
                {` os requisitos necessários para submeter o mesmo.`}
            </Callout>
            <PaperHeaderComponent title='Escolha o serviço' />
            <SWrapperButton>
                <ProductDescription title={'Preencher automaticamente com dados de outro pedido'} placement='left'>
                    <Button onClick={() => verifyIfHasOrderUnfinished()}>
                        <GetApp />
                    </Button>
                </ProductDescription>
            </SWrapperButton>

            {isConfirmModalOpen && (
                <FConfirmationModal
                    open={isConfirmModalOpen}
                    handleModalClose={handleConfirmCloseModal}
                    handleModalConfirm={handleConfirmModal}
                    titleText={'Informação'}
                    confirmText={'Sim'}
                    cancelationText={'Não'}
                    bodyText={
                        'Existem dados preenchidos nos formulários atuais que não foram enviados. Obter dados de um pedido anterior irá substituir os dados nos formulários. Deseja continuar?'
                    }
                />
            )}
            {isPreviousOrderModalOpen && (
                <NewOrderModal onCloseModal={handleCloseModal} isPreviousOrderModalOpen={isPreviousOrderModalOpen} />
            )}

            <SProductsBar noborder>
                <SProductsList>
                    {stateOrders.services.types ? (
                        uniqBy(stateOrders.services.types, 'basicType').map(item => (
                            <ProductItemComponent
                                disabled={stateLogin.activeScope.paymentInfo === 3}
                                key={item.id}
                                name={item.title}
                                description={item.description}
                                type={item.basicType}
                                handleClick={() => {
                                    actionsOrders.createNewService(item.basicType)
                                }}
                            />
                        ))
                    ) : (
                        <CircularProgress />
                    )}
                </SProductsList>
            </SProductsBar>
            {stateLogin.activeScope.paymentInfo === 3 && <Callout>{NEW_ORDER_CALLOUT}</Callout>}
            {stateOrders.services.cards && stateOrders.services.cards.length > 0 ? (
                <Fragment>
                    <SectionHeader title='Cartões' />
                    <SCardList id='token-list'>
                        {stateOrders.services.cards &&
                            stateOrders.services.cards.map(card => (
                                <ProductTypeCardComponent
                                    name={card.personName}
                                    image={
                                        card.personPhoto && card.personPhoto.includes('data:image')
                                            ? card.personPhoto
                                            : `data:image/jpeg;base64,${card.personPhoto}`
                                    }
                                    entity={card.entityName}
                                    key={card.id}
                                    cardId={card.id}
                                    role={card.role}
                                    isValid={card.isValid}
                                    handleEdit={() => {
                                        history.push(`/new-order/card-config/${card.id}`)
                                    }}
                                    handleDelete={() => {
                                        actionsOrders.deleteServiceByIdAndType(card.id, 'cards')
                                    }}
                                    handleDownload={() => handleDownload(card)}
                                />
                            ))}
                    </SCardList>
                </Fragment>
            ) : null}

            {stateOrders.services.ssls && stateOrders.services.ssls.length > 0 ? (
                <Fragment>
                    <SectionHeader title='SSL' />
                    <SSslList id='ssl-list'>
                        {stateOrders.services.ssls &&
                            stateOrders.services.ssls.map(ssl => (
                                <ProductTypeSSLComponent
                                    key={ssl.id}
                                    title={ssl.domainList ? ssl.domainList[0] : undefined}
                                    isValid={ssl.isValid}
                                    handleEdit={() => {
                                        history.push(`/new-order/ssl-config/${ssl.id}`)
                                    }}
                                    handleDelete={() => {
                                        actionsOrders.deleteServiceByIdAndType(ssl.id, 'ssls')
                                    }}
                                    handleDownload={() => handleDownload(ssl)}
                                />
                            ))}
                    </SSslList>
                </Fragment>
            ) : null}

            {stateOrders.services.electronicSeals && stateOrders.services.electronicSeals.length > 0 ? (
                <Fragment>
                    <SectionHeader title='Selo Eletrónico Qualificado' />
                    <SSslList id='electronic-seal-list'>
                        {stateOrders.services.electronicSeals &&
                            stateOrders.services.electronicSeals.map(electronicSeal => (
                                <ProductTypeElectronicSealComponent
                                    key={electronicSeal.id}
                                    title={electronicSeal.domainList ? electronicSeal.domainList[0] : undefined}
                                    isValid={electronicSeal.isValid}
                                    handleEdit={() => {
                                        history.push(`/new-order/electronic-seal-config/${electronicSeal.id}`)
                                    }}
                                    handleDelete={() => {
                                        actionsOrders.deleteServiceByIdAndType(electronicSeal.id, 'electronicSeals')
                                    }}
                                    handleDownload={() => handleDownload(electronicSeal)}
                                />
                            ))}
                    </SSslList>
                </Fragment>
            ) : null}

            {stateOrders.services.wildcards && stateOrders.services.wildcards.length > 0 ? (
                <Fragment>
                    <SectionHeader title='Wildcards' />
                    <SSslList id='wildcard-list'>
                        {stateOrders.services.wildcards &&
                            stateOrders.services.wildcards.map(wildcard => (
                                <ProductTypeWildcardComponent
                                    key={wildcard.id}
                                    title={wildcard.domainList ? wildcard.domainList[0] : undefined}
                                    isValid={wildcard.isValid}
                                    handleEdit={() => {
                                        history.push(`/new-order/wildcard-config/${wildcard.id}`)
                                    }}
                                    handleDelete={() => {
                                        actionsOrders.deleteServiceByIdAndType(wildcard.id, 'wildcards')
                                    }}
                                    handleDownload={() => handleDownload(wildcard)}
                                />
                            ))}
                    </SSslList>
                </Fragment>
            ) : null}

            {stateOrders.services.timestamps && stateOrders.services.timestamps.length > 0 && (
                <>
                    <SectionHeader title='Selo Temporal' />
                    <SSslList id='electronic-seal-list'>
                        {stateOrders.services.timestamps &&
                            stateOrders.services.timestamps.map(timestamp => (
                                <ProductTypeTimestampComponent
                                    key={timestamp.id}
                                    title={timestamp.emailCertificate ? timestamp.emailCertificate : undefined}
                                    isValid={timestamp.isValid}
                                    handleEdit={() => {
                                        history.push(`/new-order/timestamp-config/${timestamp.id}`)
                                    }}
                                    handleDelete={() => {
                                        actionsOrders.deleteServiceByIdAndType(timestamp.id, 'timestamps')
                                    }}
                                    handleDownload={() => handleDownload(timestamp)}
                                />
                            ))}
                    </SSslList>
                </>
            )}

            <ActionBarComponent backText='Voltar aos pedidos' backLink='/orders'>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!handleDisabled()}
                    endIcon={<ArrowForward />}
                    onClick={() => {
                        handleNext()
                    }}
                >
                    Seguinte
                </Button>
            </ActionBarComponent>
        </Fragment>
    )
}

StartNewOrderStep.propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}

export default StartNewOrderStep
