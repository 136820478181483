/* eslint-disable react/no-array-index-key */
import React, { useEffect, useReducer } from 'react'

import { CircularProgress, Grow } from '@material-ui/core'
// import { Box, Typography, CircularProgress, Grow } from '@material-ui/core'
// import { formatterCurrency } from '../../utils/numeral.util'
import {
    SCircular,
    SCardWrapper,
    SLink,
    SList,
    SListItem,
    SListItemText,
    WrapperPrimary,
} from './ProtocolDashboard.styles'
import MessageComponent from '../../components/templates/Message/Message.component'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'

import { useLoginStore } from '../../store/Login/Login.store'
import { protocolDashboardReducer, INITIAL_STATE, PROTOCOL_DASHBOARD_TYPES } from './ProtocolDashboard.service'

function ProtocolDashboard() {
    const { stateLogin } = useLoginStore()

    const [stateProtocolDashboard, dispatch] = useReducer(protocolDashboardReducer, INITIAL_STATE)

    useEffect(() => {
        if (stateLogin.activeScope.id) {
            dispatch({
                type: PROTOCOL_DASHBOARD_TYPES.GET.REQUEST,
                payload: { dispatch, id: stateLogin.activeScope.id },
            })
        }
    }, [stateLogin.activeScope.id])

    function renderDashboard() {
        return (
            <div>
                {stateProtocolDashboard && Object.keys(stateProtocolDashboard).length > 0 ? (
                    <SCardWrapper>
                        {stateProtocolDashboard.data.map((serviceSection, index) => {
                            const timeoutChange = Number(`1${index}00`)
                            return (
                                <Grow
                                    in
                                    style={{ transformOrigin: '0 0 0' }}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...{ timeout: timeoutChange }}
                                    key={index}
                                >
                                    <SLink to={serviceSection.link} className='link-list' key={index}>
                                        <h3>{serviceSection.title}</h3>
                                        <SList component='nav' aria-label='order list'>
                                            {serviceSection.services &&
                                                serviceSection.services.map((service, serviceIndex) => (
                                                    <SListItem key={serviceIndex}>
                                                        <WrapperPrimary primaryColor={service.color}>
                                                            <SListItemText primary={service.name} />
                                                        </WrapperPrimary>
                                                        <SListItemText secondary={service.total} />
                                                    </SListItem>
                                                ))}
                                        </SList>
                                    </SLink>
                                </Grow>
                            )
                        })}
                    </SCardWrapper>
                ) : (
                    <SCircular>
                        <CircularProgress />
                    </SCircular>
                )}
            </div>
        )
    }

    function renderContent() {
        return stateProtocolDashboard.error ? (
            <MessageComponent
                type='error'
                link='/protocol-dashboard'
                message={stateProtocolDashboard.message}
                textBack='Tentar Novamente'
            />
        ) : (
            renderDashboard()
        )
    }

    return (
        <ContentComponent>
            <PageTitleComponent title='Dashboard' />
            {stateProtocolDashboard.isLoading ? (
                <SCircular>
                    <CircularProgress size={40} />
                    <span>A Carregar</span>
                </SCircular>
            ) : (
                renderContent()
            )}
        </ContentComponent>
    )
}

export default ProtocolDashboard
