import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@material-ui/core/Modal'

import ModalBodyComponent from './ModalBody.component'
import ModalFooterComponent from './ModalFooter.component'

import { SWrapper, SCustomModal, SHeader, STitle, SClose } from './Modal.style'

const propTypes = {
    large: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    open: PropTypes.bool,
}

const defaultProps = {
    large: false,
    title: '',
    children: [],
    className: '',
    onClose: () => {},
    onClick: () => {},
    open: false,
}

function ModalComponent({ large, title, children, className, onClose, onClick, open }) {
    return (
        <Modal className={className} onClose={onClose} open={open}>
            <SWrapper>
                <SCustomModal large={large}>
                    <SHeader>
                        <STitle>{title}</STitle>
                        <SClose onClick={onClick} />
                    </SHeader>
                    {children}
                </SCustomModal>
            </SWrapper>
        </Modal>
    )
}

ModalComponent.propTypes = propTypes
ModalComponent.defaultProps = defaultProps

export default ModalComponent

export { ModalBodyComponent, ModalFooterComponent }
