/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import { Button } from '@material-ui/core'

import { Delete, CameraAlt } from '@material-ui/icons'
import ScaleImageComponent from '../ScaleImage/ScaleImage.component'

import { SDropContainerImage, SAvatar, SPreview } from './UploadScaledImage.style'

const propTypes = {
    personPhoto: PropTypes.string,
    isXmlPhoto: PropTypes.bool,
    handlePhoto: PropTypes.func,
    setMaxSize: PropTypes.func,
    setImagesTipes: PropTypes.func,
}

const defaultProps = {
    personPhoto: '',
    isXmlPhoto: false,
    handlePhoto: () => {},
}

function UploadScaledImageComponent({ personPhoto, isXmlPhoto, handlePhoto, setMaxSize, setImagesTipes }) {
    const [imgOriginal, setImgOriginal] = useState(null)
    const [imgPreview, setImgPreview] = useState(null)

    useEffect(() => {
        if (personPhoto) {
            if (isXmlPhoto) {
                setImgOriginal(personPhoto)
            } else {
                setImgPreview(personPhoto)
            }
        }
    }, [isXmlPhoto, personPhoto])

    function handleConvertToBase64(file) {
        const allowdedImages = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg']
        const includesAllowdedImages = allowdedImages.includes(file[0].type)

        const reader = new FileReader()
        reader.readAsDataURL(file[0])
        const imageSizeUpload = file[0].size

        setMaxSize(imageSizeUpload)
        setImagesTipes(includesAllowdedImages)

        reader.onload = event => {
            if (imageSizeUpload <= 1048576 && includesAllowdedImages) {
                setImgOriginal(event.target.result)
            } else {
                setImgOriginal(null)
                setImgPreview(null)
                handlePhoto('')
            }
        }
    }

    function handleDelete() {
        setImgOriginal(null)
        setImgPreview(null)
        handlePhoto('')
    }

    function handleScaledImg(imgScaled) {
        handlePhoto(imgScaled)
        setImgOriginal(imgScaled)
        setImgPreview(imgScaled)
    }

    function renderPreviewImg() {
        return (
            <SAvatar hasPhoto={Boolean(imgPreview)}>
                <img src={imgPreview} alt='Avatar' width='auto' />
                <Button className='add-avatar' variant='outlined' color='primary' onClick={handleDelete}>
                    <Delete />
                </Button>
            </SAvatar>
        )
    }

    if (imgPreview) {
        return renderPreviewImg()
    }

    return imgOriginal ? (
        <ScaleImageComponent img={imgOriginal} handleScaledImg={handleScaledImg} />
    ) : (
        <Dropzone
            // accept='image/png,image/jpeg,image/jpg,image/svg'
            onDropAccepted={handleConvertToBase64}
            multiple={false}
        >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <SDropContainerImage {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
                    <input {...getInputProps()} />
                    <SAvatar hasPhoto={Boolean(imgPreview)}>
                        {imgPreview && <SPreview src={imgPreview.preview} />}
                        <Button className='add-avatar' variant='contained'>
                            {imgPreview ? <Delete onClick={() => handleDelete()} /> : <CameraAlt />}
                        </Button>
                    </SAvatar>
                </SDropContainerImage>
            )}
        </Dropzone>
    )
}

UploadScaledImageComponent.propTypes = propTypes
UploadScaledImageComponent.defaultProps = defaultProps

export default UploadScaledImageComponent
