export const INITIAL_STATE = {
    PINPUK_AUTH: {
        fullName: { value: '', validation: ['validateNotEmptyUtil'] },
        taxIdentificationNumber: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'],
            mask: 'maskNIFUtil',
        },
        numberIdentificationDocument: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateAlphaLatinNumberUtil'],
        },
        expirationDateDocument: {
            value: null,
            validation: ['validateDateUtil'],
        },
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
    },
    PINPUK_SELECTION: {
        pinPukSendType: { value: null, validation: ['validateNotEmptyUtil'] },
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
        personPhoneNumber: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
            mask: 'maskPhoneUtil',
        },
        address: { value: '', validation: ['validateNotEmptyUtil'] },
        postalCode: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validatePostalCodeUtil'],
            mask: 'maskPostalCodeUtil',
        },
        location: { value: '', validation: ['validateNotEmptyUtil', 'validateLocationZoneNameUtil'] },
        validationCode: { value: '', validation: ['validateNotEmptyUtil'] },
    },
}

export const ENDPOINTS = {
    PINPUK_AUTHENTICATION: 'pinpuk/authentication',
    MOBILE_PHONE_VALIDATION: 'pinpuk/mobilePhoneValidation',
    PINPUK_SELECTION: 'pinpuk/selection',
}
