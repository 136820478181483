/* eslint-disable no-shadow */
import styled, { css } from 'styled-components'

export const SPaper = styled.div`
    border-radius: 8px;
    padding: 20px 30px;
    margin: ${props => (!props.margin ? '0' : '20px 0')};
    width: 100%;
    border: ${props => (props.noborder ? 'none' : '1px solid')};
    border-color: ${props => props.theme.palette.grey.main};
    position: relative;
    transition: all 3s;

    ${props =>
        props.fHeight &&
        css`
            height: 93%;
        `}

    ${props =>
        !props.nobg &&
        css`
            background-color: ${props => (props.nobackground ? props.nobackground : props.theme.palette.grey.xLight)};
        `}
    &:not(:last-child) {
        margin-bottom: 30px;
    }
`

export const SBody = styled.div`
    margin-bottom: 20px;
`

export const SButton = styled.button`
    box-shadow: none;
    border: 1px solid ${props => props.theme.palette.grey.light};
    color: ${props => (props.disabled ? props.theme.palette.grey.light : props.theme.palette.primary.main)};
    border-radius: 4px;
    font-weight: bold;
    width: 100%;
    height: 120px;
    padding: 16px 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    font-size: 12px;
    white-space: pre-wrap;
    text-transform: uppercase;

    &:hover {
        border: 1px solid
            ${props => (props.disabled ? props.theme.palette.grey.light : props.theme.palette.primary.main)};
        background-color: ${props => (props.disabled ? 'inherit' : props.theme.palette.primary.hover)};
    }
`

export const SFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    button {
        margin-left: 15px;
    }
`
