/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import { Paper } from '@material-ui/core'

import axios from 'axios'
import apiService from '../../../services/api.service'
import { useAlertStore } from '../../../store/Alert/Alert.store'

import { options as optionsTable, localization as localizationOptions } from '../../../config/Table.config'

const propTypes = {
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    components: PropTypes.objectOf(PropTypes.any),
    options: PropTypes.objectOf(PropTypes.any),
    localization: PropTypes.objectOf(PropTypes.any),
    icons: PropTypes.objectOf(PropTypes.any),
    isLoading: PropTypes.bool,
    onRowClick: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.object),
    endPoint: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    exportCSV: PropTypes.exact({
        isFromAPI: PropTypes.bool,
        filename: PropTypes.string,
        exportEndPoint: PropTypes.string,
    }),
    tableRef: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    title: '',
    components: null,
    options: null,
    localization: null,
    icons: null,
    isLoading: false,
    onRowClick: null,
    actions: null,
    endPoint: '',
    data: null,
    exportCSV: null,
    tableRef: null,
}

const FIELDS_SORT_NAME = [
    { name: 'status.label', sortName: 'status' },
    { name: 'status.updatedDate', sortName: 'statusUpdatedDate' },
]

function DataTableComponent(props) {
    const {
        title,
        data,
        columns,
        components,
        options,
        localization,
        icons,
        isLoading,
        actions,
        onRowClick,
        endPoint,
        exportCSV,
        tableRef,
    } = props

    const { actionsAlert } = useAlertStore()

    // setup to cancel a request if needed
    const { CancelToken } = axios
    const source = CancelToken.source()

    useEffect(() => {
        return () => {
            // cancel the request (the message parameter is optional)
            source.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleSortFieldName(fieldName) {
        const field = FIELDS_SORT_NAME.find(item => item.name === fieldName)
        return field ? field.sortName : fieldName
    }

    function handleGetTableData(query) {
        const searchParameter = query.search ? query.search : ''
        return new Promise(resolve => {
            let queryURL = `searchString=${searchParameter}&limit=${query.pageSize}&page=${query.page}`

            if (query.orderBy) {
                // Append sort field name to url
                queryURL += `&fields=${handleSortFieldName(query.orderBy.field)}&sort=${query.orderDirection}`
            }

            // * Checks if the endpoint already have some params, before constructing the full url
            const url = endPoint.includes('?') ? `${endPoint}&${queryURL}` : `${endPoint}?${queryURL}`

            apiService
                .get(url, {
                    cancelToken: source.token,
                })
                .then(response => {
                    const { headers } = response

                    resolve({
                        data: response.data.payload,
                        page: query.page,
                        totalCount: Number(headers['x-total-count']),
                    })
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        actionsAlert.showAlert(err.response.data)
                    }
                })
        })
    }

    function handleGetCSV(url, filename) {
        apiService.get(url).then(response => {
            const { payload } = response.data

            if (payload) {
                const csvMetadata = 'data:text/csv;charset=utf-8;base64,77u/'
                const hasMetadata = payload.includes(csvMetadata)

                const linkSource = hasMetadata ? payload : `${csvMetadata}${payload}`
                const downloadLink = document.createElement('a')

                downloadLink.href = linkSource
                downloadLink.download = `${filename}.csv`

                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            } else {
                // eslint-disable-next-line no-alert
                alert('Erro ao realizar a exportação dos dados.')
            }
        })
    }

    function defaultCSVExportFilename(string) {
        return string
            .trim()
            .toLowerCase()
            .split(' ')
            .join('-')
    }

    function configCSVOptionsObject() {
        const csvConfig = {
            ...optionsTable,
            ...options,
            padding: 'dense',
            exportButton: true,
        }

        const currentFilename = exportCSV.filename ? exportCSV.filename : defaultCSVExportFilename(title)
        const exportCSVEndPoint = exportCSV.exportEndPoint ? exportCSV.exportEndPoint : endPoint

        if (exportCSV.isFromAPI && exportCSVEndPoint) {
            return {
                ...csvConfig,
                exportCsv: () => handleGetCSV(`${exportCSVEndPoint}/export?searchString=`, currentFilename),
            }
        }

        return {
            ...csvConfig,
            exportFileName: exportCSV.filename ? exportCSV.filename : currentFilename,
        }
    }

    function buildOptionsConfigObject() {
        if (exportCSV) {
            return configCSVOptionsObject()
        }
        return { ...optionsTable, ...options }
    }

    return (
        <MaterialTable
            title={title}
            data={endPoint ? query => handleGetTableData(query) : data}
            tableRef={tableRef}
            columns={columns}
            components={{ Container: cProps => <Paper {...cProps} elevation={0} />, ...components }}
            options={buildOptionsConfigObject()}
            icons={{ ...optionsTable.tableIcons, ...icons }}
            isLoading={isLoading}
            onRowClick={onRowClick}
            localization={{ ...localizationOptions, ...localization }}
            actions={actions}
        />
    )
}

DataTableComponent.propTypes = propTypes
DataTableComponent.defaultProps = defaultProps

export default DataTableComponent
