import { useReducer } from 'react'
import apiService from '../services/api.service'
import { useAlertStore } from '../store/Alert/Alert.store'

const Types = {
    FETCH_INIT: 'FETCH_INIT',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILURE: 'FETCH_FAILURE',
}

const INITIAL_STATE = {
    isLoading: false,
    error: { hasError: false, message: null },
    data: null,
    url: null,
}

function dataFetchReduce(state, action) {
    switch (action.type) {
        case Types.FETCH_INIT:
            return { ...INITIAL_STATE, isLoading: true, url: action.payload }
        case Types.FETCH_SUCCESS:
            return { ...state, isLoading: false, error: { hasError: false, message: null }, data: action.payload }
        case Types.FETCH_FAILURE:
            return { ...state, isLoading: false, error: { hasError: true, message: action.payload } }
        default:
            throw new Error()
    }
}

function useServiceCallApi() {
    const { actionsAlert } = useAlertStore()
    const [requestState, dispatch] = useReducer(dataFetchReduce, INITIAL_STATE)

    async function callService(endPoint, fetchSettings, config) {
        dispatch({ type: Types.FETCH_INIT, payload: endPoint })
        try {
            const result = await apiService[fetchSettings.requestType](endPoint, fetchSettings.data)
            dispatch({ type: Types.FETCH_SUCCESS, payload: result.data })

            // Show snackbar success with message
            if (fetchSettings.showSnackBarOnSuccess) {
                actionsAlert.showAlert(result.data)
            }
        } catch (err) {
            // Show snackbar error with error message
            if (err.response && err.response.data) {
                if (fetchSettings.showSnackBarOnSuccess) {
                    actionsAlert.showAlert(err.response.data)
                }
                dispatch({ type: Types.FETCH_FAILURE, payload: err.response.data })
            }
        }
    }
    return [requestState, callService]
}

export default useServiceCallApi
