import styled from 'styled-components'
import { Check, Warning } from '@material-ui/icons'

export const SSuccessIcon = styled(Check)`
    && {
        color: ${props => props.theme.palette.success.main};
    }
`
export const SWarningIcon = styled(Warning)`
    && {
        color: ${props => props.theme.palette.warning.main};
    }
`
export const SSslItem = styled.div`
    border: 1px solid ${props => props.theme.palette.grey.light};
    background-color: ${props => props.theme.palette.grey.xLight};
    color: ${props => props.theme.palette.grey.main};
    padding: 8px 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px;
    position: relative;
    transition: all 0.2s ease-in-out;
    width: calc(50% - 16px);

    &:hover {
        border: 1px solid ${props => props.theme.palette.primary.main};
        background-color: ${props => props.theme.palette.primary.hover};
    }

    .card-name {
        display: block;
        margin-left: 8px;
    }

    .card-actions {
        position: absolute;
        right: 8px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.palette.grey.light} !important;
    }
`
