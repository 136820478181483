import React from 'react'

import { Grid } from '@material-ui/core'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import Paper, { PaperHeaderComponent, PaperBodyComponent } from '../../components/templates/Paper/Paper.component'
import SectionHeaderComponent from '../../components/templates/SectionHeader/SectionHeader.component'

import { CONTACT_INFO } from '../../constants/dictionary/dictionary'

import ContactForm from '../../forms/Contact/Contact.form'

import SContactInfo from './Contact.style'

function ContactPage() {
    return (
        <ContentComponent>
            <PageTitleComponent title='Contacto' />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <ContactForm />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Paper noborder>
                        <PaperHeaderComponent title='Endereço' />

                        <PaperBodyComponent>
                            <SContactInfo>
                                <SectionHeaderComponent title='Morada' />
                                <span>{CONTACT_INFO.ADDRESS}</span>
                                <span>{CONTACT_INFO.POSTAL_CODE}</span>
                                <span>
                                    <strong>Telefone: </strong>
                                    {CONTACT_INFO.PHONE}
                                </span>
                                <SectionHeaderComponent title='Correio eletrónico' />
                                <span>
                                    <strong>{CONTACT_INFO.LABEL} </strong>
                                    {CONTACT_INFO.EMAIL}
                                </span>
                            </SContactInfo>
                        </PaperBodyComponent>
                    </Paper>
                </Grid>
            </Grid>
        </ContentComponent>
    )
}

export default ContactPage
