import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import { ProfileReducer, initialState } from './Profile.reducer'
import useActions from './Profile.actions'
import applyMiddleware from './Profile.middleware'
import { useAlertStore } from '../Alert/Alert.store'

const propTypes = {
    children: PropTypes.objectOf(PropTypes.any).isRequired,
}

const ProfileContext = createContext(null)

export function ProfileProvider({ children }) {
    const { actionsAlert } = useAlertStore()
    const [stateProfile, dispatch] = useReducer(ProfileReducer, initialState)
    const actionsProfile = useActions(stateProfile, applyMiddleware(dispatch, actionsAlert))
    const value = { stateProfile, actionsProfile }

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}
ProfileProvider.propTypes = propTypes

export const useProfileStore = () => useContext(ProfileContext)
