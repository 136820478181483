import React, { memo } from 'react'

import PropTypes from 'prop-types'

import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import 'moment/locale/pt'

moment.locale('pt')

function DatePickerComponent({
    dateId = '',
    dateLabel = '',
    helperText = '',
    isFullWidth = false,
    isRequired = false,
    isDisabled = false,
    error = false,
    margin = 'dense',
    inputVariant = 'outlined',
    dateValue = null,
    onDatePickerChange = () => {},
    onBlur = () => {},
    allowPastDate,
}) {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                variant='inline'
                minDateMessage='A Data não pode ser inferior a atual'
                maxDateMessage='A Data não pode ser superior ao limite'
                id={dateId}
                name={dateId}
                label={dateLabel}
                helperText={helperText}
                disablePast={!allowPastDate}
                disableFuture={false}
                fullWidth={isFullWidth}
                required={isRequired}
                disabled={isDisabled}
                error={error}
                format='DD/MM/YYYY'
                margin={margin}
                inputVariant={inputVariant}
                value={dateValue}
                onChange={date => {
                    if (date) {
                        onDatePickerChange(date.format('L'))
                    }
                    return null
                }}
                onBlur={onBlur}
                allowKeyboardControl
                autoOk
            />
        </MuiPickersUtilsProvider>
    )
}

DatePickerComponent.propTypes = {
    margin: PropTypes.string,
    dateId: PropTypes.string,
    dateLabel: PropTypes.string,
    dateValue: PropTypes.number,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    inputVariant: PropTypes.string,
    isFullWidth: PropTypes.bool,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onDatePickerChange: PropTypes.func,
    onBlur: PropTypes.func,
    allowPastDate: PropTypes.bool,
}

export default memo(DatePickerComponent)
