import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Box, Button, Fade, Grid, CircularProgress } from '@material-ui/core'
import SectionHeader from '../../components/templates/SectionHeader/SectionHeader.component'
import ItemDataComponent from '../../components/templates/ItemData/ItemData.component'
import PaperComponent, {
    PaperHeaderComponent,
    PaperBodyComponent,
} from '../../components/templates/Paper/Paper.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import Feedback from '../../components/templates/Feedback/Feedback'

import { SCircular, SPaperRow, SPaperFooterComponent, SPaperComponent } from './NewOrderReview.styles'
import { useOrdersStore } from '../../store/Orders/Orders.store'
import { useLoginStore } from '../../store/Login/Login.store'

function NewOrderReviewStep({ setActiveStep }) {
    const { stateLogin } = useLoginStore()
    const { stateOrders, actionsOrders } = useOrdersStore()
    const { entity, paymentInfo, cart, services } = stateOrders
    const [showMessage, setShowMessage] = useState(false)

    useEffect(() => {
        if (stateOrders.success === true || stateOrders.success === false) {
            setShowMessage(true)
        }
    }, [stateOrders.success])

    function getPayload() {
        // * Creates an array newServices with every registered service
        let newServices = []

        Object.keys(services).forEach(type => {
            if (type !== 'types') {
                newServices = newServices.concat(services[type])
            }
        })

        let itens = {}
        // * For each registered service, format the service for payload and add to itens
        newServices.forEach(service => {
            const durationId = service.duration.id
            let newService = {}

            console.log()
            switch (service.basicType) {
                case 'TOKEN':
                    newService = {
                        useAlias: service.useAlias,
                        givenName: service.givenName,
                        aliasReason: service.aliasReason,
                        surname: service.surname,
                        personName: service.personName,
                        documentType: service.documentType,
                        documentNumber: service.documentNumber,
                        documentValidity: service.documentValidity.valueOf(),
                        personTaxIdentificationNumber: service.taxIdentificationNumber,
                        nameOnCard: service.nameOnCard,
                        role: service.role,
                        personEmail: service.personEmail,
                        personPhoneNumber: service.personPhoneNumber,
                        personPhoto: service.personPhoto,
                        settingFile: { name: service.file.name, base64: service.file.base64 },
                        orgUnit:
                            service.orgUnitSelected && service.orgUnitSelected.id !== 0
                                ? service.orgUnitSelected
                                : null,
                        durationId,
                        pinPukSendType: { id: service.pinPukSendType.id },
                        cardReaderRequest: service.cardReaderRequest,
                        pinPukAddress: service.pinPukAddress,
                        pinPukPostalCode: service.pinPukPostalCode,
                        pinPukLocation: service.pinPukLocation,
                        limitedRoles: service.limitedRoles,
                        address: service.address,
                    }
                    break
                case 'SSL':
                    newService = {
                        platformId: service.server.id,
                        methodId: service.validation.id,
                        domains: service.domainList,
                        techContactIsRequester: service.sameValue,
                        requester: {
                            name: service.applicantName,
                            email: service.applicantEmail,
                            phoneNumber: service.applicantPhoneNumber,
                            documentType: service.applicantDocumentType,
                            documentNumber: service.applicantDocumentNumber,
                            documentValidity: service.applicantDocumentValidity.valueOf(),
                        },
                        technician: {
                            name: service.technicalName,
                            email: service.technicalEmail,
                            phoneNumber: service.technicalPhoneNumber,
                            documentType: service.technicalDocumentType,
                            documentNumber: service.technicalDocumentNumber,
                            documentValidity: service.technicalDocumentValidity.valueOf(),
                        },
                        csrFile: {
                            name: service.csrFile.name,
                            base64: service.csrFile.base64.replace('data:application/octet-stream;', ''),
                        },
                        pdfFile: service.pdfFile
                            ? {
                                  name: service.pdfFile.name,
                                  base64: service.pdfFile.base64,
                              }
                            : {},
                    }
                    break
                case 'WILDCARD':
                    newService = {
                        platformId: service.server.id,
                        methodId: service.validation.id,
                        domains: service.domainList,
                        techContactIsRequester: service.sameValue,
                        requester: {
                            name: service.applicantName,
                            email: service.applicantEmail,
                            phoneNumber: service.applicantPhoneNumber,
                            documentType: service.applicantDocumentType,
                            documentNumber: service.applicantDocumentNumber,
                            documentValidity: service.applicantDocumentValidity.valueOf(),
                        },
                        technician: {
                            name: service.technicalName,
                            email: service.technicalEmail,
                            phoneNumber: service.technicalPhoneNumber,
                            documentType: service.technicalDocumentType,
                            documentNumber: service.technicalDocumentNumber,
                            documentValidity: service.technicalDocumentValidity.valueOf(),
                        },
                        csrFile: {
                            name: service.csrFile.name,
                            base64: service.csrFile.base64.replace('data:application/octet-stream;', ''),
                        },
                        pdfFile: service.pdfFile
                            ? {
                                  name: service.pdfFile.name,
                                  base64: service.pdfFile.base64,
                              }
                            : {},
                    }
                    break
                case 'ELECTRONIC_SEAL':
                    newService = {
                        supportDataInfo:
                            service.supportType === 'SmartCard'
                                ? {
                                      supportType: service.supportType,
                                      emailCertificate: service.emailCertificate,
                                      logo: service.logo,
                                  }
                                : {
                                      supportType: service.supportType,
                                      emailCertificate: service.emailCertificate,
                                  },
                        techContactIsRequester: service.sameValue,
                        requester: {
                            name: service.applicantName,
                            email: service.applicantEmail,
                            phoneNumber: service.applicantPhoneNumber,
                            documentType: service.applicantDocumentType,
                            documentNumber: service.applicantDocumentNumber,
                            documentValidity: service.applicantDocumentValidity.valueOf(),
                        },
                        technician: {
                            name: service.technicalName,
                            email: service.technicalEmail,
                            phoneNumber: service.technicalPhoneNumber,
                            documentType: service.technicalDocumentType,
                            documentNumber: service.technicalDocumentNumber,
                            documentValidity: service.technicalDocumentValidity.valueOf(),
                        },
                        csrFile:
                            service.supportType === 'HSM'
                                ? {
                                      name: service.csrFile.name,
                                      base64: service.csrFile.base64.replace('data:application/octet-stream;', ''),
                                  }
                                : {},
                        pdfFile: service.pdfFile
                            ? {
                                  name: service.pdfFile.name,
                                  base64: service.pdfFile.base64,
                              }
                            : {},
                    }
                    break
                case 'TIMESTAMP':
                    newService = {
                        emailCertificate: service.emailCertificate,
                        requester: {
                            name: service.applicantName,
                            email: service.applicantEmail,
                            phoneNumber: service.applicantPhoneNumber,
                            documentType: service.applicantDocumentType,
                            documentNumber: service.applicantDocumentNumber,
                            documentValidity: service.applicantDocumentValidity.valueOf(),
                        },
                        pdfFile: service.pdfFile
                            ? {
                                  name: service.pdfFile.name,
                                  base64: service.pdfFile.base64,
                              }
                            : {},
                    }
                    break
                default:
                    break
            }

            itens = {
                ...itens,
                [durationId]: {
                    id: durationId,
                    qty: itens[durationId] && itens[durationId].qty ? itens[durationId].qty + 1 : 1,
                    configs:
                        itens[durationId] && itens[durationId].configs
                            ? itens[durationId].configs.concat(newService)
                            : new Array(newService),
                },
            }
        })
        // * Transform itens in an array, removing the unnecessary keys
        const newItens = Object.keys(itens).map(key => itens[key])

        return {
            commitmentNumber: paymentInfo.commitmentNumber || '',
            orderNumber: paymentInfo.orderNumber || '',
            docketNumber: paymentInfo.docketNumber || '',
            itens: newItens,
            billingFile: paymentInfo.file ? { name: paymentInfo.file.name, base64: paymentInfo.file.base64 } : {},
            billingEntity: paymentInfo.showEntity
                ? {
                      taxIdentificationNumber: paymentInfo.nipc.replace(/\s/g, ''),
                      name: paymentInfo.entityName,
                      address: paymentInfo.entityAddress,
                      location: paymentInfo.entityLocation,
                      postalCode: paymentInfo.postalCode,
                  }
                : {},
        }
    }

    function handleBack() {
        delete stateOrders.success
        delete stateOrders.message
        if (stateLogin.activeScope.paymentInfo === 0) {
            setActiveStep(0)
        } else {
            setActiveStep(1)
        }
    }

    function handleSubmit() {
        const payload = getPayload()
        actionsOrders.registerOrders(payload)
    }

    // * Renders the row of an item inside the section
    function renderItemRow(item) {
        const itemQty = item.quantity
        const itemDuration = item.duration
        const itemType = item.type
        const itemTotalPrice = (item.quantity * item.price).toFixed(2)

        // * Checks if the item is a timestamp service.
        if (stateOrders.services && stateOrders.services.timestamps) {
            const fullItem = _.find(stateOrders.services.timestamps, function(timestamp) {
                const { duration } = timestamp
                // * Returns the full item timestamp from the stateOrders array, based on the item inside the cart.
                return (
                    duration.duration === item.duration && duration.price === item.price && duration.type === item.type
                )
            })

            // * Checks if the full item has timestampDescription, so can render the timestamp service properly!
            if (fullItem && fullItem.timestampDescription) {
                return (
                    <Box display='flex' key={fullItem.id}>
                        <ItemDataComponent itemValue={`${fullItem.timestampDescription} - Quantidade ${itemQty}`} />
                        <span className='Total'>{`${itemTotalPrice} €`}</span>
                    </Box>
                )
            }
        }
        // * If the item isn't a timestamp, renders normally based on the duration information from the cart.
        return (
            <Box display='flex' key={`${itemType}${itemDuration}${itemQty}`}>
                <ItemDataComponent itemValue={`${itemType} - ${itemDuration} ano(s) - Quantidade ${itemQty}`} />
                <span className='Total'>{`${itemTotalPrice} €`}</span>
            </Box>
        )
    }
    // * Renders a section of the order review
    function renderSection() {
        return (
            <Fragment>
                {Object.keys(cart).map((key, index) => {
                    if (key !== 'totalPrice') {
                        if (key === 'cards') {
                            return (
                                <Fragment key={`${key}-${index}`}>
                                    <SectionHeader title='Cartões' />
                                    {Object.keys(cart[key]).map(item => {
                                        return item !== 'totalPrice' ? renderItemRow(cart[key][item]) : null
                                    })}
                                </Fragment>
                            )
                        }
                        if (key === 'ssls') {
                            return (
                                <Fragment key={`${key}-${index}`}>
                                    <SectionHeader title='SSLs' />
                                    {Object.keys(cart[key]).map(item => {
                                        return item !== 'totalPrice' ? renderItemRow(cart[key][item]) : null
                                    })}
                                </Fragment>
                            )
                        }
                        if (key === 'wildcards') {
                            return (
                                <Fragment key={`${key}-${index}`}>
                                    <SectionHeader title='Wildcards' />
                                    {Object.keys(cart[key]).map(item => {
                                        return item !== 'totalPrice' ? renderItemRow(cart[key][item]) : null
                                    })}
                                </Fragment>
                            )
                        }
                        if (key === 'electronicSeals') {
                            return (
                                <Fragment key={`${key}-${index}`}>
                                    <SectionHeader title='Selo Eletrónico' />
                                    {Object.keys(cart[key]).map(item => {
                                        return item !== 'totalPrice' ? renderItemRow(cart[key][item]) : null
                                    })}
                                </Fragment>
                            )
                        }
                        if (key === 'timestamps') {
                            return (
                                <Fragment key={`${key}-${index}`}>
                                    <SectionHeader title='Selo Temporal' />
                                    {Object.keys(cart[key]).map(item => {
                                        return item !== 'totalPrice' ? renderItemRow(cart[key][item]) : null
                                    })}
                                </Fragment>
                            )
                        }
                    }
                    return null
                })}
            </Fragment>
        )
    }
    // * Renders the full grid with the complete order data for review
    function renderOrderReview() {
        return (
            <Fragment>
                {stateLogin.activeScope.paymentInfo !== 0 && (
                    <Grid item xs={12} md={6}>
                        <PaperComponent noborder>
                            <PaperHeaderComponent title='Resumo de Pagamento' />
                            <PaperBodyComponent>
                                {paymentInfo.commitmentNumber && (
                                    <SPaperRow column>
                                        <ItemDataComponent
                                            itemKey='N˚de Compromisso:'
                                            itemValue={paymentInfo.commitmentNumber}
                                        />
                                        <ItemDataComponent
                                            itemKey='N˚da Encomenda:'
                                            itemValue={paymentInfo.orderNumber}
                                        />
                                        <ItemDataComponent
                                            itemKey='N˚de Cabimento:'
                                            itemValue={paymentInfo.docketNumber}
                                        />
                                    </SPaperRow>
                                )}

                                <SPaperRow column>
                                    <h3>Entidade Solicitante</h3>
                                    <ItemDataComponent itemKey='Entidade:' itemValue={entity.entityName} />
                                    <ItemDataComponent itemKey='NIPC:' itemValue={entity.nipc} />
                                    <ItemDataComponent itemKey='Morada:' itemValue={entity.entityAddress} />
                                    <ItemDataComponent itemKey='Código Postal:' itemValue={entity.postalCode} />
                                    <ItemDataComponent itemKey='Localidade:' itemValue={entity.entityLocation} />
                                </SPaperRow>

                                <SPaperRow column>
                                    <h3>Entidade Pagadora</h3>
                                    <ItemDataComponent itemKey='Entidade:' itemValue={paymentInfo.entityName} />
                                    <ItemDataComponent itemKey='NIPC:' itemValue={paymentInfo.nipc} />
                                    <ItemDataComponent itemKey='Morada:' itemValue={paymentInfo.entityAddress} />
                                    <ItemDataComponent itemKey='Código Postal:' itemValue={paymentInfo.postalCode} />
                                    <ItemDataComponent itemKey='Localidade:' itemValue={paymentInfo.entityLocation} />
                                </SPaperRow>
                            </PaperBodyComponent>
                        </PaperComponent>
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <SPaperComponent noborder fullScreen>
                        <PaperHeaderComponent title='Resumo do Pedido' />
                        <SPaperRow column>{renderSection()}</SPaperRow>
                        <SPaperFooterComponent>
                            <span>Montante</span> <span>{cart.totalPrice} €</span>
                        </SPaperFooterComponent>
                    </SPaperComponent>
                </Grid>
                <ActionBarComponent handleBack={handleBack} backText='Voltar'>
                    <Button variant='contained' color='primary' onClick={handleSubmit}>
                        Guardar
                    </Button>
                </ActionBarComponent>
            </Fragment>
        )
    }

    function handleBackToNewOrderPage() {
        // 1. reset order state
        actionsOrders.resetData()
        // 2. change to step 0
        setActiveStep(0)
    }

    // * Renders the content or the message after submit
    function renderContent() {
        return !showMessage
            ? renderOrderReview()
            : showMessage && (
                  <Grid item xs={12}>
                      <Feedback type={stateOrders.success ? 'success' : 'error'} />
                      {stateOrders.success ? (
                          <ActionBarComponent backText='Criar novo pedido' handleBack={handleBackToNewOrderPage} />
                      ) : (
                          <ActionBarComponent backText='Voltar' handleBack={handleBack} />
                      )}
                  </Grid>
              )
    }

    return (
        <Fade in>
            <Grid container spacing={4}>
                {stateOrders.isLoading ? (
                    <Grid item xs={12}>
                        <SCircular>
                            <CircularProgress size={50} />
                            <span>A registar pedido...</span>
                        </SCircular>
                    </Grid>
                ) : (
                    renderContent()
                )}
            </Grid>
        </Fade>
    )
}

NewOrderReviewStep.propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}

export default NewOrderReviewStep
