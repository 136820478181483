import React, { Fragment, useState } from 'react'

import { Stepper, Step, StepLabel } from '@material-ui/core'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import { useLoginStore } from '../../store/Login/Login.store'

import SStepperContainer from './NewOrder.styles'

import StartNewOrderStep from './StartNewOrder.step'
import PaymentInfoStep from './PaymentInfo.step'
import NewOrderReviewStep from './NewOrderReview.step'

function NewOrderPage() {
    const { stateLogin } = useLoginStore()
    const [steps] = useState(
        stateLogin.activeScope.paymentInfo === 0
            ? [
                  { id: 1, label: 'Escolher Itens' },
                  { id: 3, label: 'Revisar Pedido' },
              ]
            : [
                  { id: 1, label: 'Escolher Itens' },
                  { id: 2, label: 'Informações Faturação' },
                  { id: 3, label: 'Revisar Pedido' },
              ]
    )

    const [activeStep, setActiveStep] = useState(0)

    function renderStepContent() {
        switch (activeStep) {
            case 0:
                return <StartNewOrderStep setActiveStep={setActiveStep} />
            case 1:
                return <PaymentInfoStep setActiveStep={setActiveStep} />
            case 2:
                return <NewOrderReviewStep setActiveStep={setActiveStep} />
            default:
                return <StartNewOrderStep />
        }
    }

    return (
        <Fragment>
            <ContentComponent>
                <PageTitleComponent title='Novo Pedido' />

                <SStepperContainer>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(step => (
                            <Step key={step.id}>
                                <StepLabel className='step-label'>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </SStepperContainer>

                {renderStepContent()}
            </ContentComponent>
        </Fragment>
    )
}

export default NewOrderPage
