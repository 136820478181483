import { createTypes, createRequestTypes } from '../../utils/typesGenerator.util'

const PROFILE_TYPES = {
    RESET_DATA: createTypes('profile/RESET_DATA'),
    SHOW_LOADING: createTypes('profile/SHOW_LOADING'),
    HIDE_LOADING: createTypes('profile/HIDE_LOADING'),
    PROFILE: createRequestTypes('profile/PROFILE'),
    UPDATE_EMAIL: createRequestTypes('profile/UPDATE_EMAIL'),
    UPDATE_PHONE_NUMBER: createRequestTypes('profile/UPDATE_PHONE_NUMBER'),
    VALIDATE_CODE: createRequestTypes('profile/VALIDATE_CODE'),
    UPDATE_PROFILE: createRequestTypes('profile/UPDATE_PROFILE'),
}

export default PROFILE_TYPES
