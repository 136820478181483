import React from 'react'
import PropTypes from 'prop-types'

import { SRow, SIconHeader, SContent } from './SectionHeader.style'

const propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
}

const defaultProps = {
    icon: null,
    title: '',
    subtitle: '',
}

const SectionHeaderComponent = ({ icon, title, subtitle }) => {
    return (
        <SRow>
            {icon && <SIconHeader>{icon}</SIconHeader>}
            <SContent hasIcon={icon ? 1 : 0}>
                <h3 className='title'>{title}</h3>
                {subtitle && <span className='subtitle'>{subtitle}</span>}
            </SContent>
        </SRow>
    )
}

SectionHeaderComponent.propTypes = propTypes
SectionHeaderComponent.defaultProps = defaultProps

export default SectionHeaderComponent
