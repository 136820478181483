import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import NewOrderTimestampConfigForm from '../../forms/NewOrderTimestampConfig/NewOrderTimestampConfig.form'

import { useOrdersStore } from '../../store/Orders/Orders.store'

function ConfigTimestampStep(props) {
    const { match } = props
    const { params } = match

    const { stateOrders } = useOrdersStore()
    const [timestamps] = useState(stateOrders.services.timestamps.find(item => item.id === params.id))

    return (
        <Fade in>
            <ContentComponent>
                <PageTitleComponent title='Configuração do Selo Temporal Qualificado' />
                <NewOrderTimestampConfigForm activeTimestamp={timestamps} stateOrders={stateOrders} />
            </ContentComponent>
        </Fade>
    )
}

ConfigTimestampStep.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

export default ConfigTimestampStep
