export const getChipColor = ({ theme, type, typecolor }) => {
    switch (typecolor) {
        case 'invoices':
            switch (type) {
                case 3:
                    return theme.palette.warning.dark
                case 4:
                    return theme.palette.success.dark
                case 7:
                    return theme.palette.blue.main
                default:
                    return theme.palette.grey.light
            }
        case 'orders':
            switch (type) {
                case 1:
                    return theme.palette.grey.dark
                case 2:
                    return theme.palette.blue.main
                case 3:
                    return theme.palette.warning.dark
                case 4:
                    return theme.palette.grey.main
                case 5:
                    return theme.palette.success.dark
                case 6:
                    return theme.palette.error.dark
                default:
                    return theme.palette.grey.light
            }
        case 'deliveries':
            switch (type) {
                case 1:
                    return theme.palette.warning.main
                case 2:
                    return theme.palette.success.dark
                default:
                    return theme.palette.grey.light
            }
        case 'certificateAction':
            switch (type) {
                case 1:
                    return theme.palette.success.main
                case 2:
                    return theme.palette.error.dark
                case 3:
                    return theme.palette.warning.main
                default:
                    return theme.palette.grey.light
            }
        case 'orderItens':
            switch (type) {
                case 1:
                    return theme.palette.grey.dark
                case 2:
                    return theme.palette.blue.main
                case 3:
                    return theme.palette.success.main
                case 4:
                    return theme.palette.warning.dark
                case 5:
                    return theme.palette.grey.light
                case 6:
                    return theme.palette.error.dark
                default:
                    return theme.palette.grey.light
            }
        case 'orderItemsStatus':
            switch (type) {
                case 1:
                    return theme.palette.blue.main
                case 2:
                    return theme.palette.warning.dark
                case 3:
                    return theme.palette.success.main
                case 4:
                    return theme.palette.grey.dark
                case 5:
                    return theme.palette.error.dark
                case 6:
                    return theme.palette.error.dark
                default:
                    return theme.palette.grey.light
            }
        default:
            return theme.palette.grey.light
    }
}
