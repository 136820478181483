import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, Grid, TextField, CircularProgress } from '@material-ui/core'

import useValidationForm from '../../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../../hooks/useServiceCallApi.hook'

import MessageComponent from '../../../components/templates/Message/Message.component'
import Callout from '../../../components/templates/Callout/Callout'

import { STitle, SLink, SAccessActions, SCircular } from './RecoveryPassword.styles'

import { INITIAL_STATE_HASH } from './constants'

function RecoveryPasswordHashForm() {
    const location = useLocation()
    const action = new URLSearchParams(location.search).get('action')
    const hash = new URLSearchParams(location.search).get('hash')
    const [blurTriggered, setBlurTriggered] = useState(false)
    const [requestState, callService] = useServiceCallApi(null)

    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE_HASH)

    function handleSavePassword() {
        const newPassword = values.password.value
        callService('users/choosepassword', {
            requestType: 'post',
            data: {
                hash,
                action,
                newPassword,
            },
        })
    }

    function handleRepeatPasswordBlur(event) {
        handleBlur(event)
        setBlurTriggered(true)
    }

    function renderContent() {
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                    <span>A Enviar Solicitação</span>
                </SCircular>
            )
        }

        if (requestState.url === 'users/choosepassword') {
            if (requestState.error.hasError) {
                return (
                    <MessageComponent
                        type='error'
                        link='/'
                        textBack='Login'
                        errorHeaderMessage='Solicitação inválida!'
                        message={requestState.error.message}
                    />
                )
            }

            return (
                <MessageComponent
                    type='success'
                    link='/'
                    textBack='Login'
                    successHeaderMessage='Palavra-passe definida!'
                    message={requestState.data.message}
                />
            )
        }

        return (
            <form>
                <STitle>Por favor, informe a nova palavra-passe</STitle>
                <Grid item xs={12}>
                    <TextField
                        id='password'
                        name='password'
                        label='Nova palavra-passe'
                        type='password'
                        margin='dense'
                        variant='outlined'
                        value={values.password.value}
                        error={touched.password ? Boolean(errors.password) : false}
                        helperText={touched.password ? errors.password : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='repeatPassword'
                        name='repeatPassword'
                        label='Repita palavra-passe'
                        type='password'
                        margin='dense'
                        variant='outlined'
                        value={values.repeatPassword.value}
                        error={
                            (values.repeatPassword.value.length !== 0 &&
                                values.password.value.length !== 0 &&
                                values.repeatPassword.value.length >= values.password.value.length) ||
                            blurTriggered
                                ? Boolean(errors.repeatPassword)
                                : false
                        }
                        helperText={
                            (values.repeatPassword.value.length !== 0 &&
                                values.password.value.length !== 0 &&
                                values.repeatPassword.value.length >= values.password.value.length) ||
                            blurTriggered
                                ? errors.repeatPassword
                                : ''
                        }
                        onChange={handleChange}
                        onBlur={handleRepeatPasswordBlur}
                        fullWidth
                    />
                </Grid>
                <SAccessActions>
                    <SLink to='/'>Voltar para o Login</SLink>
                    <Button variant='contained' color='primary' disabled={!isValidForm} onClick={handleSavePassword}>
                        Guardar
                    </Button>
                </SAccessActions>
                <Callout>
                    A palavra-passe deve conter um mínimo de 9 caracteres, entre os quais letras maiúsculas (A...Z),
                    minúsculas (a...z) e números (0...9).
                </Callout>
            </form>
        )
    }

    return renderContent()
}

export default RecoveryPasswordHashForm
