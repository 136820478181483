import styled from 'styled-components'
import AvatarEditor from 'react-avatar-editor'

// eslint-disable-next-line import/prefer-default-export
export const SAvatarEditor = styled(AvatarEditor)`
    && {
        width: 138px !important;
        height: 200px !important;
        object-fit: cover;
        max-width: 100%;
        top: -10px;
        position: relative;
    }
`
export const InputRange = styled.input`
    max-width: 100%;
`
