/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import DataTablePagination from '../../../components/templates/DataTablePagination/DataTablePagination.component'
import FilterDatePicker from '../../../components/templates/DataTablePagination/FilterDatePicker.component'

import ModalFilesDownload from './ModalFilesDownload'
import ModalUploadFiles from './ModalUploadFiles'

import DownloadFileCSV from '../../../components/templates/DownloadFile/DownloadFileCSV'
import { transformTimestampToSimpleDate, transformTimestampToDate } from '../../../utils/date.util'

import AuthService from '../../../services/auth.service'

import { useAlertStore } from '../../../store/Alert/Alert.store'

import api from '../../../services/api.service'

import { ItemChip } from '../Certificates.styles'

const auth = new AuthService(sessionStorage)

function CardList() {
    const { actionsAlert } = useAlertStore()
    const [statusList, setStatusList] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
    const [cardId, setCardId] = useState('')
    const [checked, setChecked] = useState(false)
    const [status, setStatus] = useState('')

    const showCheckBoxOtherEntities = Boolean(
        _.find(auth.getPermissions(), { featureName: 'View_Certs_From_Other_Entities' })
    )

    async function getStatusList() {
        try {
            const response = await api.get(`/tokens/statusList`)
            const { payload } = response.data

            const normalizedStatuses = payload.reduce((acc, curr) => {
                acc[curr.label] = curr.label

                return acc
            }, {})

            setStatusList(normalizedStatuses)
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data.message)
            }
        }
    }

    useEffect(() => {
        getStatusList()
    }, [])

    function handleModalFilesDownload(rowData) {
        setStatus(rowData && rowData.status)
        setIsModalOpen(true)
        setCardId(rowData && rowData.id)
    }

    function handleCloseModal() {
        setIsModalOpen(!isModalOpen)
    }

    function handleModalFilesUpload(id) {
        setIsUploadModalOpen(true)
        setCardId(id)
    }

    const COLUMNS = [
        {
            field: 'id',
            title: 'nº do cartão',
            type: 'numeric',
            headerStyle: {
                textAlign: 'start',
            },
            cellStyle: {
                textAlign: 'start',
            },
        },
        {
            field: 'originRequestNumber',
            title: 'nº do pedido',
        },
        {
            field: 'personName',
            title: 'nome no cartão',
        },
        {
            field: 'role',
            title: 'cargo',
        },
        {
            field: 'entityName',
            title: 'entidade',
        },
        {
            title: 'Download de ficheiro para alterar estado',
            field: 'downloadFiles',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                return (
                    <IconButton color='primary' component='span' onClick={() => handleModalFilesDownload(rowData)}>
                        <CloudDownloadIcon />
                    </IconButton>
                )
            },
            filtering: false,
        },
        {
            title: 'Upload de ficheiro para alterar estado',
            field: 'uploadFile',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                padding: '10px',
                textAlign: 'center',
            },
            render: rowData => {
                return (
                    <IconButton color='primary' component='span' onClick={() => handleModalFilesUpload(rowData.id)}>
                        <CloudUploadIcon />
                    </IconButton>
                )
            },
            filtering: false,
        },
        {
            field: 'creationDate',
            title: 'Emissão',
            cellStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ creationDate }) => (creationDate ? transformTimestampToDate(creationDate) : ''),
        },
        {
            field: 'expirationDate',
            title: 'Expiração',
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            cellStyle: {
                maxWidth: '10%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ expirationDate }) => (expirationDate ? transformTimestampToDate(expirationDate) : ''),
        },
        {
            field: 'updatedDate',
            title: 'data do estado',
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            cellStyle: {
                maxWidth: '10%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ updatedDate }) => (updatedDate ? transformTimestampToSimpleDate(updatedDate) : ''),
        },
        {
            field: 'status.label',
            title: 'estado',
            headerStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            cellStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            lookup: statusList,
            render: rowData =>
                rowData && rowData.status ? <ItemChip label={rowData.status.label} type={rowData.status.id} /> : '',
        },
    ]

    return (
        <>
            {showCheckBoxOtherEntities && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            name='myOrders'
                            color='primary'
                        />
                    }
                    label='Exibir certificados de outras Entidades Participantes do Protocolo'
                />
            )}

            <Grid container justify='flex-end'>
                <DownloadFileCSV
                    endpoint='/tokens/client'
                    exportCSV={{ isFromAPI: true, filename: 'certificados-cartoes' }}
                />
            </Grid>
            <DataTablePagination
                columns={COLUMNS}
                endPoint='/tokens/client'
                certificateFromOtherEntities={checked}
                showCheckBoxOtherEntities={showCheckBoxOtherEntities}
                options={{
                    filtering: true,
                    sorting: true,
                    search: false,
                    filterCellStyle: {
                        padding: '16px 16px 16px 6px',
                    },
                }}
            />

            {isModalOpen && (
                <ModalFilesDownload
                    open={isModalOpen}
                    orderId={cardId}
                    onCloseModal={handleCloseModal}
                    endpoint='tokens'
                    status={status}
                />
            )}

            {isUploadModalOpen && (
                <ModalUploadFiles
                    open={isUploadModalOpen}
                    onCloseModal={() => setIsUploadModalOpen(false)}
                    endpoint='tokens'
                    orderId={cardId}
                />
            )}
        </>
    )
}

export default memo(CardList)
