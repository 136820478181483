/* eslint-disable react/prop-types */
import React from 'react'

import { Grid } from '@material-ui/core'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'

import ModalComponent from '../../../components/templates/Modal/Modal.component'
import ModalBodyComponent from '../../../components/templates/Modal/ModalBody.component'

import api from '../../../services/api.service'
import { useAlertStore } from '../../../store/Alert/Alert.store'

import { SPaper, SBody, SButton } from './ModalFilesDownload.styles'

const CSV_METADATA = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'

function ModalFilesDownload({ open, orderId, onCloseModal, endpoint, status }) {
    const { actionsAlert } = useAlertStore()

    console.log('cardStatus', status)

    async function handleOpenTemplate(declarationType) {
        const exportURL = `${endpoint}/get${declarationType}Doc/${orderId}`

        let certificateDocumentType = ''

        switch (declarationType) {
            case 'Revogation':
                certificateDocumentType = 'Revogação'
                break
            case 'Suspension':
                certificateDocumentType = 'Suspensão'
                break
            default:
                certificateDocumentType = 'Reativação'
                break
        }

        try {
            const response = await api.get(exportURL)
            const { payload } = response.data
            if (Object.keys(payload).length !== 0) {
                const linkSource = `${CSV_METADATA}${payload.docx}`

                const downloadLink = document.createElement('a')
                downloadLink.href = linkSource
                downloadLink.download = `Declaração de ${certificateDocumentType} ${orderId}.docx`

                downloadLink.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
            }
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data.message)
            }
        }
    }

    return (
        <ModalComponent
            title='Download de ficheiros para pedidos de manutenção'
            open={open}
            onClick={() => onCloseModal()}
        >
            <ModalBodyComponent>
                <p>Selecione um tipo de ficheiro para fazer download, preencha, assine e depois envie.</p>
                <SPaper noborder>
                    <SBody>
                        <Grid container direction='row' justify='center' spacing={2}>
                            <Grid item xs={12} md={4} lg={4}>
                                <SButton onClick={() => handleOpenTemplate('Revogation')}>
                                    <CloudDownloadOutlinedIcon style={{ marginBottom: '5px' }} />
                                    Declaração de Revogação
                                </SButton>
                            </Grid>
                            {endpoint === 'tokens' || endpoint === 'tokensEletronicSeal' ? (
                                <>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <SButton onClick={() => handleOpenTemplate('Suspension')}>
                                            <CloudDownloadOutlinedIcon style={{ marginBottom: '5px' }} />
                                            Declaração de Suspensão
                                        </SButton>
                                    </Grid>

                                    {status && status.label === 'Suspenso' ? (
                                        <Grid item xs={12} md={4} lg={4}>
                                            <SButton onClick={() => handleOpenTemplate('Reactivation')}>
                                                <CloudDownloadOutlinedIcon style={{ marginBottom: '5px' }} />
                                                Declaração de Reativação
                                            </SButton>
                                        </Grid>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </SBody>
                </SPaper>
            </ModalBodyComponent>
        </ModalComponent>
    )
}

export default ModalFilesDownload
