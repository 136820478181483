import React, { memo } from 'react'
import PropTypes from 'prop-types'

import DataTableComponent from '../../../../components/templates/DataTable/DataTable.component'
import DownloadFile from '../../../../components/templates/DownloadFile/DownloadFile'

import ENDPOINTS from '../../constants'

function TemplateTable({ data }) {
    const columns = [
        { title: 'TIPO', field: 'title' },
        { title: 'DESCRICAO', field: 'description' },
        {
            title: 'DOWNLOAD',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                return Object.keys(rowData).length !== 0 && rowData.constructor === Object ? (
                    <DownloadFile endpoint={`${ENDPOINTS.DOWNLOAD_TEMPLATE_BY_ID}${rowData.id}`} />
                ) : null
            },
        },
    ]

    return (
        <DataTableComponent
            title='Lista de Templates'
            columns={columns}
            data={data}
            options={{
                search: false,
            }}
        />
    )
}

TemplateTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

TemplateTable.defaultProps = { data: [] }

export default memo(TemplateTable)
