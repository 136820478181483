// import { createNumberMask, createTextMask } from 'redux-form-input-masks'

export const isAlphaLatinCharacter = value => {
    return /^([A-Za-z áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'-\s]*)$/.test(value)
}

export const isAlphaLatinNumberCharacter = value => {
    return /^([A-Za-z0-9\-ºª áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.\s]*)$/.test(value)
}

export const testAlpha = value => {
    return /^[a-z0-9]*$/i.test(value)
}

export const testNumber = value => {
    return /^[0-9]*$/i.test(value)
}

export const isValidOrganizationName = value => {
    return /^([A-Za-z0-9 .+´`'&ºª\-áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]*)$/.test(value)
}

export const maskPhone = '999 999 999'

export const maskNIF = '999 999 999'

export const maskPostalCode = '9999-999'

/**
 * Masks a String replacing # with correponding value,
 * example: maskString('1111', '## ##') => 11 11
 * @param  {String} value the string to format
 * @param  {String} pattern the pattern format using a string of hashtags
 */
export function maskString(value, pattern) {
    try {
        if (value === null || pattern === null) {
            return null
        }

        const valueString = value.toString()
        let index = -1

        if (valueString.length !== pattern.replace(/\s+/g, '').length) {
            throw new Error('value and pattern passed to maskString function must have the same number of characters')
        }

        return pattern.replace(/#/g, () => {
            // eslint-disable-next-line no-plusplus
            index++
            return valueString[index]
        })
    } catch (error) {
        console.error(error.name, error.message)
        return null
    }
}
