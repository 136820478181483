import styled, { keyframes } from 'styled-components'
import { IconButton } from '@material-ui/core'
import bgWelcome from '../../../assets/images/commons/bg-welcome.svg'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const ToolbarLinks = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    height: 59px;
    left: 5px;
    top: 5px;
    z-index: 1300;
    @media (max-width: 620px) {
        top: 0;
    }
`

export const SIconButton = styled(IconButton)`
    && {
        margin-left: 8px;
        height: 48px;
        color: ${props => props.theme.palette.grey.main};
        @media (min-width: 599px) {
            display: none;
        }
    }
`

// ----------------------
// Sidebar
// ----------------------

export const SSidebar = styled.div`
    && {
        position: relative;
        z-index: 1300;

        .brand {
            width: 100%;
            display: flex;
            justify-content: center;
            height: 60px;
            text-decoration: none;
            background-color: ${props => props.theme.palette.white};
            transition: all 0.3s;

            @media (max-width: 620px) {
                height: 40px;
            }
        }
    }
    @media print {
        display: none;
    }
`

export const SLoginSidebar = styled.div`
    width: 35%;
    height: 100vh;
    padding: 10px 50px;
    color: ${props => props.theme.palette.white};
    background: ${props => props.theme.palette.primary.main} url(${bgWelcome}) no-repeat bottom right/cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
        text-align: center;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.2;

        img {
            width: 65%;
            margin: 20px 0 60px 0;
        }

        a {
            text-decoration: none;
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: ${props => props.theme.palette.white};
            margin-bottom: 15px;
        }

        svg {
            margin-left: 5px;
        }
    }

    .footer_h4 {
        margin: 5px 0;
    }

    @media (max-width: 960px) {
        display: none;
    }
`

export const SMenuList = styled.ul`
    padding: 0;
    display: block;
    margin-top: 90px;
    @media (max-width: 599px) {
        margin-top: 70px;
    }
`

export const SMenuItem = styled.li`
    width: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;

    && button {
        border: none;
    }

    ::after {
        width: 3px;
        height: 100%;
        content: '';
        display: block;
        background-color: ${props => props.theme.palette.primary.main};
        position: absolute;
        right: 0;
        transition: all 0.3s linear;
        transform: translate3d(6px, 0, 0);
    }

    :hover,
    :active,
    :focus {
        &:after {
            transform: translate3d(0, 0, 0);
        }
    }

    .MenuItemLink {
        display: flex;
        align-items: center;
        flex-flow: column wrap;
        justify-content: center;
        height: 60px;
        padding: 0 5px;
        background-color: ${props => props.theme.palette.white};
        width: 98px;
        text-align: center;
        text-decoration: none;
        color: ${props => props.theme.palette.grey.main};
        cursor: pointer;

        svg {
            fill: ${props => props.theme.palette.grey.main};
        }

        @media (max-width: 599px) {
            flex-flow: row wrap;
            height: auto;
            padding: 8px 16px;
            justify-content: flex-start;
            font-weight: 700;
        }

        &.active,
        :hover,
        :focus,
        :active {
            color: ${props => props.theme.palette.primary.main};

            svg {
                fill: ${props => props.theme.palette.primary.main};
            }
        }

        .MenuItemTitle {
            text-transform: uppercase;
            display: block;
            margin-top: 5px;
            font-size: 10px;

            @media (max-width: 599px) {
                margin: 0 0 0 8px;
            }
        }
    }
`

// ----------------------
// Submenu
// ----------------------

export const SNavTitle = styled.h3`
    font-size: 22px;
    @media (max-width: 599px) {
        font-size: 10px;
        font-weight: 600;
        display: none;
    }
`

export const SSubMenu = styled.div`
    width: 380px;
    padding: 0;
    background-color: ${props => props.theme.palette.primary.main};
    display: flex;
    height: 100vh;
    flex-direction: column;
    @media (min-width: 620px) {
        &&:after {
            content: '';
            position: fixed;
            left: 380px;
            width: calc(100vw - 380px);
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.7);
            animation: ${fadeIn} 2.5s linear;
        }
    }

    @media (max-width: 599px) {
        width: auto;
        background-color: ${props => props.theme.palette.white};
        height: auto;
    }

    .SubMenuTitle {
        color: ${props => props.theme.palette.white};
        margin: 50px 0 40px 80px;
        width: calc(100% - 80px);
        display: flex;
        align-items: center;
        padding-left: 30px;
        text-transform: uppercase;
        text-indent: 10px;

        @media (max-width: 599px) {
            display: none;
        }
    }

    .SubMenuList {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-left: 80px;
        list-style: none;
        text-align: left;
        width: calc(100% - 80px);
        padding: 0 30px;

        @media (max-width: 599px) {
            margin: 0;
            width: 100%;
            padding: 0;
        }
    }
`

export const SSubMenuItem = styled.li`
    display: block;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.palette.primary.light};

    @media (max-width: 599px) {
        border-bottom: none;
    }

    .SubMenuLink {
        color: ${props => props.theme.palette.white};
        display: block;
        width: 100%;
        padding: 16px 8px;
        text-decoration: none;
        transition: all 0.2s linear;
        :hover,
        :active,
        :focus {
            transform: translate3d(5px, 0, 0);
        }
        @media (max-width: 599px) {
            color: ${props => props.theme.palette.grey.main};
            padding: 8px 16px 8px 24px;
            text-transform: uppercase;
            font-size: 10px;
        }
    }
`

export const SubMenuMobile = styled.div`
    margin-left: ${props => (props.open ? '0' : '-200px')};
    opacity: ${props => (props.open ? '1' : '0')};
    transition: margin 0.2s, opacity 0.8s;
`
