import React, { useState } from 'react'

import { Box, Tab } from '@material-ui/core'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'

import AuthService from '../../services/auth.service'

import CardListTab from './tabs/CardList.tab'
import SSLListTab from './tabs/SSLList.tab'
import WildcardListTab from './tabs/WildcardList.tab'
import ElectronicSealListTab from './tabs/ElectronicSealList.tab'
import { STabs } from './Certificates.styles'

const auth = new AuthService(sessionStorage)

function Certificates() {
    const [tabValue, setTabValue] = useState(0)
    const permissions = auth.getPermissions()

    const permissionSSL = permissions.find(permission => permission.featureName === 'CertSSL')
    const permissionWildcard = permissions.find(permission => permission.featureName === 'CertSSLWildcard')
    const permissionSelo = permissions.find(permission => permission.featureName === 'ListSealClientServices')

    return (
        <ContentComponent>
            <PageTitleComponent title='Certificados' />

            <STabs
                value={tabValue}
                onChange={(event, newTabValue) => setTabValue(newTabValue)}
                indicatorColor='primary'
            >
                <Tab label='Cartões' />
                {permissionSSL && <Tab label='SSL' />}
                {permissionWildcard && <Tab label='Wildcard' />}
                {permissionSelo && <Tab label='Selos' />}
            </STabs>

            {tabValue === 0 && (
                <Box component='div' py={3} px={4}>
                    <CardListTab />
                </Box>
            )}

            {tabValue === 1 && (
                <Box component='div' py={3} px={4}>
                    <SSLListTab />
                </Box>
            )}

            {tabValue === 2 && (
                <Box component='div' py={3} px={4}>
                    <WildcardListTab />
                </Box>
            )}

            {tabValue === 3 && (
                <Box component='div' py={3} px={4}>
                    <ElectronicSealListTab />
                </Box>
            )}
        </ContentComponent>
    )
}

export default Certificates
