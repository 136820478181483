import styled from 'styled-components'
import PaperComponent from '../../components/templates/Paper/Paper.component'

export const SEntitiesSelect = styled.div`
    width: 50%;
    display: flex;
    position: relative;
    margin: 0 auto 24px auto;
    @media (max-width: 992px) {
        width: 100%;
    }
`

// Product List and Product Item
export const SProductsBar = styled(PaperComponent)`
    margin-bottom: 24px;
    padding: 8px 20px;
`
export const SProductsList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 992px) {
        justify-content: center;
    }
`
// Card List and Card Item
export const SCardList = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

export const SSslList = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const SPriceOrder = styled.div`
    background-color: ${props => props.theme.palette.grey.dark};
    position: absolute;
    right: 0;
    top: 200px;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 6px 0 0 6px;
    padding: 16px;
    color: ${props => props.theme.palette.grey.light};
    @media (max-width: 992px) {
        top: 50px;
    }
`

export const SWrapperButton = styled.div`
    position: absolute;
    right: 70px;
    top: 340px;
    background-color: ${props => props.theme.palette.grey.midLight};
    cursor: pointer;
`
