import styled, { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
  from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
`
export const SPaper = styled.div`
    border-radius: 8px;
    padding: 20px 30px;
    width: 100%;
    height: ${props => (props.fullScreen ? '100%' : 'auto')};
    border: ${props => (props.noborder ? 'none' : '1px solid')};
    border-color: ${props => props.theme.palette.grey.main};
    position: relative;
    transition: all 3s;
    animation: ${fadeIn} 0.5s linear;

    ${props =>
        !props.nobg &&
        css`
            background-color: ${props.theme.palette.grey.xLight};
            input[type='text'],
            input[type='email'],
            input[type='tel'],
            input[type='number'],
            .MuiSelect-selectMenu,
            select {
                background-color: ${props => props.theme.palette.white};
            }
        `}
    &:not(:last-child) {
        margin-bottom: 30px;
    }
`
export const SBody = styled.div`
    margin-bottom: 20px;
`

export const SFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    button {
        margin-left: 15px;
    }
`
export const SHeader = styled.div`
    display: flex;
`
export const PaperTitle = styled.h3`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 1px solid ${props => (props.divider ? props.theme.palette.grey.main : 'transparent')};
    .materialIcons {
        color: ${props => props.theme.palette.grey.light};
        margin-right: 10px;
    }
`
