import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { SRowItem } from './ItemData.styles'

const propTypes = {
    children: PropTypes.node,
    itemKey: PropTypes.node,
    itemValue: PropTypes.node,
    vertical: PropTypes.bool,
    isEditField: PropTypes.bool,
    onEditClick: PropTypes.func,
    name: PropTypes.string,
}

const defaultProps = {
    children: [],
    itemKey: '',
    itemValue: '',
    vertical: false,
    isEditField: false,
    onEditClick: null,
    name: '',
}

const ItemDataComponent = props => {
    const { children, itemKey, itemValue, vertical, isEditField, onEditClick, name } = props
    return (
        <SRowItem isVertical={vertical}>
            <span className='title'>{itemKey}</span>
            <div className='text'>
                {itemValue}
                {isEditField && (
                    <IconButton onClick={event => onEditClick(event, name)}>
                        <EditIcon fontSize='small' />
                    </IconButton>
                )}
            </div>
            {children}
        </SRowItem>
    )
}

ItemDataComponent.propTypes = propTypes
ItemDataComponent.defaultProps = defaultProps

export default ItemDataComponent
