import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

export const SPaper = styled.div`
    max-width: 500px;
`

export const SLogo = styled.div`
    margin-bottom: 10px;
    width: 55px;
    height: auto;
    @media (max-width: 1024px) {
        width: 50px;
        margin-bottom: 16px;
    }
    img {
        max-width: 100%;
    }
`

export const STitle = styled.div`
    font-weight: 300;
    margin-top: 20px;
`

export const AccessRecovery = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    span {
        font-size: 16px;
    }
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`
export const SCircular = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 16px;
        font-size: 16px;
    }
`

export const SButton = styled(Button)`
    && {
        margin: 8px 0 4px 0;
    }
`

export const SAccessActions = styled.div`
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const SLink = styled(Link)`
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`
