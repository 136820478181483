import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Link, NavLink, useLocation } from 'react-router-dom'

import {
    Dashboard,
    VerifiedUser,
    Settings,
    AddShoppingCart,
    ChatBubble,
    ContactSupport,
    AccountCircle,
    LibraryBooks,
    CallMade,
} from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu'
import { Drawer, Box, useTheme, Grid } from '@material-ui/core'

import IconTimestampMenu from '../../../assets/icons/IconTimestampMenu'
import IconInvoiceMenu from '../../../assets/icons/IconInvoiceMenu'

import {
    SLoginSidebar,
    SMenuItem,
    SMenuList,
    SNavTitle,
    SSidebar,
    SSubMenu,
    SSubMenuItem,
    SIconButton,
    ToolbarLinks,
    SubMenuMobile,
} from './Sidebar.style'

import logoECCE from '../../../assets/images/commons/logo-ecce.svg'
import loginImage from '../../../assets/images/login-image.svg'

import { useLoginStore } from '../../../store/Login/Login.store'
import { useTableStore } from '../../../store/Table/Table.store'

import { LOGIN } from '../../../constants/dictionary/dictionary'

const subNavTitle = { icon: <Settings />, title: 'Gestão' }
const MOBILE_SIZE = 600

function SidebarComponent(props) {
    const { container } = props
    const [left, setLeft] = useState(false)
    const location = useLocation()
    const { stateLogin } = useLoginStore()
    const { tableActions } = useTableStore()
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [dimensions, setDimensions] = useState(window.innerWidth)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [sidebarMenuList, setSidebarMenuList] = useState([])
    const [sidebarMenuListDEV, setSidebarMenuListDEV] = useState([])

    useEffect(() => {
        if (
            stateLogin.activeScope &&
            stateLogin.activeScope.permissions &&
            stateLogin.activeScope.permissions.length > 0
        ) {
            setSidebarMenuList([
                {
                    id: 'NSHOP',
                    name: 'Novo pedido',
                    link: '/new-order',
                    icon: <AddShoppingCart />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Pedidos' })),
                },
                {
                    id: 'SHOP',
                    name: 'Pedidos Efetuados',
                    link: '/orders',
                    icon: <LibraryBooks />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Pedidos' })),
                },
                {
                    id: 'INVOI',
                    name: 'Faturas',
                    link: '/invoices',
                    icon: <IconInvoiceMenu />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'FaturasEntidade' })),
                },
                {
                    id: 'PRODU',
                    name: 'Certificados',
                    link: '/certificates',
                    icon: <VerifiedUser />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Certificados' })),
                },
                {
                    id: 'TIME',
                    name: 'Selos Temporais',
                    link: '/timestamps',
                    icon: <IconTimestampMenu />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'ListTimestampServices' })),
                },
                {
                    id: 'CONTA',
                    name: 'Contacto',
                    link: '/contact',
                    icon: <ChatBubble />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Contactos' })),
                },
                {
                    id: 'FAQ',
                    name: 'FAQ',
                    link: '/faq',
                    icon: <ContactSupport />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'FAQ' })),
                },
                {
                    id: 'DASH',
                    name: 'Dashboard',
                    link: '/protocol-dashboard',
                    icon: <Dashboard />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Dashboard' })),
                },
                {
                    id: 'PROFI',
                    name: 'Perfil',
                    link: '/profile',
                    icon: <AccountCircle />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Perfil_Geral' })),
                },
            ])
            setSidebarMenuListDEV([
                {
                    id: 'NSHOP',
                    name: 'Novo pedido',
                    link: '/new-order',
                    icon: <AddShoppingCart />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Pedidos' })),
                },
                {
                    id: 'SHOP',
                    name: 'Pedidos Efetuados',
                    link: '/orders',
                    icon: <LibraryBooks />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Pedidos' })),
                },
                {
                    id: 'INVOI',
                    name: 'Faturas',
                    link: '/invoices',
                    icon: <IconInvoiceMenu />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'FaturasEntidade' })),
                },
                {
                    id: 'PRODU',
                    name: 'Certificados Emitidos',
                    link: '/certificates',
                    icon: <VerifiedUser />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Certificados' })),
                },
                {
                    id: 'TIME',
                    name: 'Selos Temporais',
                    link: '/timestamps',
                    icon: <IconTimestampMenu />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'ListTimestampServices' })),
                },
                {
                    id: 'CONTA',
                    name: 'Contacto',
                    link: '/contact',
                    icon: <ChatBubble />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Contactos' })),
                },
                {
                    id: 'FAQ',
                    name: 'FAQ',
                    link: '/faq',
                    icon: <ContactSupport />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'FAQ' })),
                },
                {
                    id: 'DASH',
                    name: 'Dashboard',
                    link: '/protocol-dashboard',
                    icon: <Dashboard />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Dashboard' })),
                },
                {
                    id: 'PROFI',
                    name: 'Perfil',
                    link: '/profile',
                    icon: <AccountCircle />,
                    sub: [],
                    show: Boolean(_.find(stateLogin.activeScope.permissions, { featureName: 'Perfil_Geral' })),
                },

                {
                    id: 'MANAG',
                    name: 'Gestão',
                    link: 'null',
                    icon: <Settings />,
                    subNavTitle: [{ icon: <Settings />, title: 'Gestão' }],
                    sub: [
                        {
                            id: 'CONSU',
                            name: 'Histórico de Consumo',
                            link: '/dashboard',
                        },
                        {
                            id: 'MANAG_ANALI',
                            name: 'Aprovação de Pedidos',
                            link: '/orders-approval',
                        },
                        {
                            id: 'MANAG_ENTIT',
                            name: 'Dados da Entidade',
                            link: '/entity',
                        },
                        {
                            id: 'MANAG_USERS',
                            name: 'Utilizadores',
                            link: '/users',
                        },
                        {
                            id: 'MANAG_FINAN',
                            name: 'Financeiro',
                            link: '/financial',
                        },
                    ],
                    show: true,
                },
            ])
        }
    }, [stateLogin.activeScope])

    useEffect(() => {
        let timeoutId = null

        function handleResize() {
            clearTimeout(timeoutId)

            timeoutId = setTimeout(() => {
                setDimensions(window.innerWidth)
            }, 150)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        dimensions < MOBILE_SIZE ? setIsMobile(true) : setIsMobile(false)
    }, [dimensions])

    const handleMenuOpen = () => {
        tableActions.resetTableHistory()
        setIsMenuOpen(!isMenuOpen)
    }

    const handleDrawer = open => () => {
        tableActions.resetTableHistory()
        setLeft(open)
    }

    function renderSubMenuList(menuList) {
        return menuList.map(navItem =>
            navItem.sub.map(navItemSub => (
                <SSubMenuItem key={navItemSub.id}>
                    <Link
                        to={navItemSub.link}
                        className='SubMenuLink'
                        onClick={isMobile ? () => setMobileOpen(!mobileOpen) : null}
                    >
                        {navItemSub.name}
                    </Link>
                </SSubMenuItem>
            ))
        )
    }

    function renderSideList() {
        return (
            <SSubMenu>
                <Box className='SubMenuTitle'>
                    {subNavTitle.icon}
                    <SNavTitle>{subNavTitle.title}</SNavTitle>
                </Box>
                <ul className='SubMenuList'>
                    {process.env.NODE_ENV === 'production'
                        ? renderSubMenuList(sidebarMenuList)
                        : renderSubMenuList(sidebarMenuListDEV)}
                </ul>
            </SSubMenu>
        )
    }

    function handleForceRefreshPage(url) {
        tableActions.resetTableHistory()

        if (location.pathname === url) {
            window.location.reload()
        }

        if (isMobile) {
            setMobileOpen(!mobileOpen)
        } else {
            handleDrawer(false)
        }
    }

    function handleMenuItemClick() {
        tableActions.resetTableHistory()

        if (isMobile) {
            setMobileOpen(!mobileOpen)
        } else {
            handleDrawer(false)
        }
    }

    function renderMainSidebarItems(list) {
        return list.map(menuList =>
            menuList.show && menuList.sub.length ? (
                <SMenuItem key={menuList.id}>
                    <button
                        className='MenuItemLink'
                        type='button'
                        onClick={isMobile ? handleMenuOpen : handleDrawer(!left)}
                    >
                        {menuList.icon}
                        <span className='MenuItemTitle'>{menuList.name}</span>
                    </button>
                </SMenuItem>
            ) : (
                menuList.show && (
                    <SMenuItem key={menuList.id}>
                        {menuList.link === '/new-order' ? (
                            <NavLink
                                to={menuList.link}
                                key={menuList.id}
                                className='MenuItemLink'
                                onClick={() => handleForceRefreshPage(menuList.link)}
                            >
                                {menuList.icon}
                                <span className='MenuItemTitle'>{menuList.name}</span>
                            </NavLink>
                        ) : (
                            <NavLink
                                to={menuList.link}
                                key={menuList.id}
                                className='MenuItemLink'
                                onClick={() => handleMenuItemClick()}
                            >
                                {menuList.icon}
                                <span className='MenuItemTitle'>{menuList.name}</span>
                            </NavLink>
                        )}
                    </SMenuItem>
                )
            )
        )
    }

    function renderMainNavList() {
        return (
            <nav role='navigation' aria-label='folders'>
                <SMenuList>
                    {process.env.NODE_ENV === 'production'
                        ? renderMainSidebarItems(sidebarMenuList)
                        : renderMainSidebarItems(sidebarMenuListDEV)}
                </SMenuList>
            </nav>
        )
    }

    function renderTopBarLinks() {
        return (
            <ToolbarLinks>
                <Link to='/orders' className='brand'>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </Link>
                <SIconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={() => setMobileOpen(!mobileOpen)}
                >
                    <MenuIcon />
                </SIconButton>
            </ToolbarLinks>
        )
    }

    function renderMainSidebar() {
        return isMobile ? (
            <SSidebar>
                {renderTopBarLinks()}
                <Drawer
                    container={container}
                    variant='temporary'
                    open={mobileOpen}
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    onClose={() => setMobileOpen(!mobileOpen)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {renderMainNavList()}
                    <SubMenuMobile open={isMenuOpen}>{renderSideList()}</SubMenuMobile>
                </Drawer>
            </SSidebar>
        ) : (
            <>
                <SSidebar>
                    {renderTopBarLinks()}
                    <Drawer variant='permanent' open>
                        {renderMainNavList()}
                    </Drawer>
                </SSidebar>
                <Drawer variant='persistent' open={left} onClose={handleDrawer(false)}>
                    <div tabIndex={0} role='button' onClick={handleDrawer(false)} onKeyDown={handleDrawer(false)}>
                        {renderSideList()}
                    </div>
                </Drawer>
            </>
        )
    }

    function renderLoginSidebar() {
        return (
            <SLoginSidebar>
                <Grid className='header'>
                    <h1>{LOGIN.HEADER_MESSAGE}</h1>
                </Grid>

                <Grid className='body'>
                    <h1>{LOGIN.BODY_MESSAGE}</h1>
                    <img src={loginImage} alt='Sidebar' />

                    <a href='https://www.ecce.gov.pt/repositorio/'>
                        Repositório da ICP
                        <CallMade />
                    </a>
                    <Link to='/search-certificate/'>
                        Certificados de Cifras
                        <CallMade />
                    </Link>
                </Grid>

                <Grid className='footer'>
                    {LOGIN.FOOTER_MESSAGE && <h4 className='footer_h4'>{LOGIN.FOOTER_MESSAGE}</h4>}
                    <h4 className='footer_h4'>{LOGIN.FOOTER_MESSAGE_FIXED}</h4>
                </Grid>
            </SLoginSidebar>
        )
    }

    return stateLogin.isAuthenticated ? renderMainSidebar() : renderLoginSidebar()
}

export default SidebarComponent
