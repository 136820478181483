/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'

import { Button } from '@material-ui/core'

import FModal from '../templates/Modal/Modal.component'
import FModalBody from '../templates/Modal/ModalBody.component'
import FModalFooter from '../templates/Modal/ModalFooter.component'

const FConfirmationModal = ({
    open,
    handleModalClose,
    handleModalConfirm,
    confirmText,
    cancelationText,
    bodyText,
    titleText,
}) => {
    return (
        <FModal open={open} title={titleText} onClick={() => handleModalClose()}>
            <FModalBody>{bodyText}</FModalBody>
            <FModalFooter>
                <Fragment>
                    <Button variant='contained' size='small' onClick={() => handleModalClose()}>
                        {cancelationText}
                    </Button>
                    <Button variant='contained' color='primary' size='small' onClick={() => handleModalConfirm()}>
                        {confirmText}
                    </Button>
                </Fragment>
            </FModalFooter>
        </FModal>
    )
}
export default FConfirmationModal
