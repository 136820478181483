import PROFILE_TYPES from './Profile.types'
import apiService from '../../services/api.service'

async function asyncGetProfile(dispatch, actionsAlert) {
    try {
        // Show loading
        dispatch({ type: PROFILE_TYPES.SHOW_LOADING.SUCCESS })
        // Get main data
        const response = await apiService.get('profile/main')
        // Show main data
        dispatch({ type: PROFILE_TYPES.PROFILE.GET.SUCCESS, payload: response.data.payload })
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        } else {
            actionsAlert.showAlert({ message: err.message, result: -1 })
        }
    } finally {
        // Hide loading
        dispatch({ type: PROFILE_TYPES.HIDE_LOADING.SUCCESS })
    }
}

async function asyncUpdateEmail(dispatch, actionsAlert, data) {
    try {
        // Show loading
        dispatch({ type: PROFILE_TYPES.SHOW_LOADING.SUCCESS })
        // Update service call
        const postResponse = await apiService.post('profile/changeemail', data)
        // Get main data
        const response = await apiService.get('profile/main')
        // Show updated main data
        dispatch({ type: PROFILE_TYPES.UPDATE_EMAIL.POST.SUCCESS, payload: response.data.payload })
        // Show alert success
        actionsAlert.showAlert(postResponse.data)
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        } else {
            actionsAlert.showAlert({ message: err.message, result: -1 })
        }
    } finally {
        // Hide loading
        dispatch({ type: PROFILE_TYPES.HIDE_LOADING.SUCCESS })
    }
}

async function asyncUpdatePhoneNumber(dispatch, actionsAlert, data) {
    try {
        // Show loading
        dispatch({ type: PROFILE_TYPES.SHOW_LOADING.SUCCESS })
        // Update service call
        const postResponse = await apiService.post('profile/changepersonphonenumber', data)
        // Get main data
        const response = await apiService.get('profile/main')
        // show updated main data
        dispatch({ type: PROFILE_TYPES.UPDATE_PHONE_NUMBER.POST.SUCCESS, payload: response.data.payload })
        // Show alert success
        actionsAlert.showAlert(postResponse.data)
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        } else {
            actionsAlert.showAlert({ message: err.message, result: -1 })
        }
    } finally {
        // Hide loading
        dispatch({ type: PROFILE_TYPES.HIDE_LOADING.SUCCESS })
    }
}

async function asyncValidateCode(dispatch, actionsAlert, data) {
    try {
        // Show loading
        dispatch({ type: PROFILE_TYPES.SHOW_LOADING.SUCCESS })
        // Update service call
        const postResponse = await apiService.post('profile/changepersonphoneconfirmation', data)
        // Get main data
        const response = await apiService.get('profile/main')
        // Show updated main data
        dispatch({ type: PROFILE_TYPES.VALIDATE_CODE.POST.SUCCESS, payload: response.data.payload })
        // Show alert success
        actionsAlert.showAlert(postResponse.data)
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        } else {
            actionsAlert.showAlert({ message: err.message, result: -1 })
        }
    } finally {
        // Hide loading
        dispatch({ type: PROFILE_TYPES.HIDE_LOADING.SUCCESS })
    }
}

async function asyncUpdateProfile(dispatch, actionsAlert, data) {
    try {
        // Show loading
        dispatch({ type: PROFILE_TYPES.SHOW_LOADING.SUCCESS })
        // Update service call
        const postResponse = await apiService.post('profile/update', data)
        // Get main data
        const response = await apiService.get('profile/main')
        // Show main data
        dispatch({ type: PROFILE_TYPES.UPDATE_PROFILE.POST.SUCCESS, payload: response.data.payload })
        // Show alert success
        actionsAlert.showAlert(postResponse.data)
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        } else {
            actionsAlert.showAlert({ message: err.message, result: -1 })
        }
    } finally {
        // Hide loading
        dispatch({ type: PROFILE_TYPES.HIDE_LOADING.SUCCESS })
    }
}

//* applyMiddleware Receives the action and triggers the correspondent function
const applyMiddleware = (dispatch, actionsAlert) => action => {
    switch (action.type) {
        case PROFILE_TYPES.PROFILE.GET.REQUEST:
            return asyncGetProfile(dispatch, actionsAlert, action.payload)
        case PROFILE_TYPES.UPDATE_EMAIL.POST.REQUEST:
            return asyncUpdateEmail(dispatch, actionsAlert, action.payload)
        case PROFILE_TYPES.UPDATE_PHONE_NUMBER.POST.REQUEST:
            return asyncUpdatePhoneNumber(dispatch, actionsAlert, action.payload)
        case PROFILE_TYPES.VALIDATE_CODE.POST.REQUEST:
            return asyncValidateCode(dispatch, actionsAlert, action.payload)
        case PROFILE_TYPES.UPDATE_PROFILE.POST.REQUEST:
            return asyncUpdateProfile(dispatch, actionsAlert, action.payload)
        default:
            return dispatch(action)
    }
}
export default applyMiddleware
