export function getUrlParam(param) {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has(param)) {
        return urlParams.get(param)
    }

    return null
}

export function hasParam(param) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.has(param)
}
