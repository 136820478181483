/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useHistory, Prompt } from 'react-router-dom'
import PropTypes from 'prop-types'

import InputMask from 'react-input-mask'
import {
    Button,
    FormControlLabel,
    InputAdornment,
    RadioGroup,
    Radio,
    Grid,
    Typography,
    TextField,
    Checkbox,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import api from '../../services/api.service'

import UploadScaledImageComponent from '../../components/templates/UploadScaledImage/UploadScaledImage.component'
import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'
import DatePickerComponent from '../../components/templates/DatePicker/DatePicker.component'
import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'

import FConfirmationModal from '../../components/FConfirmModal/FConfirmModal'

import useValidationForm from '../../hooks/useValidationForm.hook'

import { customSelectColors } from '../../styles/themes'

import { maskNIFUtil, maskPhoneUtil, maskPostalCodeUtil } from '../../utils'

import { SSelect, SCreatableSelect, STipIcon, STooltip, SRequired, ErrorComponent } from './NewOrderCardConfig.styles'
import { useOrdersStore } from '../../store/Orders/Orders.store'

import getDocumentTypes from '../../utils/documentTypes.util'

import { PINPUK_TYPE_SEND } from '../../constants/selections/selections'
import {
    NEW_ORDER_PSEUDONYM_TOOLTIP,
    NEW_ORDER_PINPUK_TOOLTIP,
    MSG_CONFIRM_PAGE_EXIT,
    INSTITUICAO,
} from '../../constants/dictionary/dictionary'

const defaultDuration = {
    id: 0,
    label: 'Duração do Cartão',
    price: '',
}

const useStyles = makeStyles({
    removePaddingTop: {
        paddingTop: '0 !important',
    },
    removePaddingBottom: {
        paddingBottom: '0 !important',
    },
    spaceCaptchSection: {
        marginTop: '10px !important',
        marginBottom: '10px !important',
    },
})

function NewOrderCardConfigForm({ activeCard }) {
    const history = useHistory()
    const classes = useStyles()
    const { stateOrders, actionsOrders } = useOrdersStore()
    // const { stateApiStatusService } = useApiStateStore()

    const [defaultServiceType] = useState(_.find(stateOrders.services.types, { basicType: 'TOKEN' }).type)
    const [files, setFiles] = useState(activeCard.file ? [activeCard.file] : [])
    const [entity] = useState(stateOrders.entity)
    const [isPseudonymActive, setIsPseudonymActive] = useState(activeCard.useAlias ? activeCard.useAlias : false)
    const [isCardReaderActive, setIsCardReaderActive] = useState(
        // activeCard.cardReaderRequest ? activeCard.cardReaderRequest : false
        activeCard.config ? activeCard.config.cardReaderRequest : false
    )
    const [documentTypeOptions, setDocumentTypeOptions] = useState([])
    const tokensList = stateOrders.services.types.filter(item => item.basicType === 'TOKEN')
    const [jobsSelect, setJobsSelect] = useState([])
    const [searchJob, setSearchJob] = useState('')
    const [isModalValidationOpen, setIsModalValidationOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [promptClicked, setPromptCliked] = useState(false)

    const [maxSize, setMaxSize] = useState(0)
    const [imagesTipes, setImagesTipes] = useState(true)

    const [initialSendType, setInitialSendType] = useState([])

    const timeoutRef = useRef(null)

    const isLimitedRoles = entity.limitedRoles

    useEffect(() => {
        async function setDocumentTypes() {
            try {
                const documentTypes = await getDocumentTypes()
                setDocumentTypeOptions(documentTypes)
            } catch (err) {
                console.log(err)
            }
        }

        setDocumentTypes()
    }, [])

    async function getJobsList() {
        try {
            const response = await api.get(
                `jobtitles?&filterValues=${
                    searchJob && searchJob.length >= 3 ? searchJob : ''
                }&limit=1000&filterFields=description`
            )
            const { payload } = response.data
            const jobtitlePayload = payload.map(jobtitle => {
                return {
                    id: jobtitle.id,
                    name: jobtitle.description,
                }
            })

            setJobsSelect(jobtitlePayload)
        } catch (err) {
            if (err.response && err.response.data) {
                console.log('err', err)
            }
        }
    }

    useEffect(() => {
        if (searchJob.length > 3) {
            clearTimeout(timeoutRef.current)

            timeoutRef.current = setTimeout(() => {
                getJobsList(searchJob)
            }, 500)
        }
    }, [searchJob])

    useEffect(() => {
        actionsOrders.getOrgUnits()
    }, [entity])

    const selectOrgUnitOptions = stateOrders.orgUnits

    function getInitialDuration(duration) {
        // * Looks inside the tokensList the package that has the same duration IDss
        const pack = tokensList.map(item => {
            if (duration) {
                // return item.durations[0].id === duration
                const tokenDuration = item.durations.filter(itemDuration => itemDuration.id === duration)
                return tokenDuration[0]
            }
            return null
        })
        // * returns an object with the selected package for the selectbox
        if (pack && pack.length > 0) {
            return { ...pack[0], label: `${pack[0].duration} ano(s)` }
        }
        return {}
    }

    function getInitialPrice(duration) {
        const pack = tokensList.map(item => {
            if (duration) {
                // return item.durations[0].id === duration
                const tokenDuration = item.durations.filter(itemDuration => itemDuration.id === duration)
                return tokenDuration[0]
            }
            return null
        })

        if (pack && pack.length > 0) {
            return pack[0].price
        }
        return ''
    }

    function getRole(role) {
        if (role) {
            return { id: role, label: role, name: role }
        }

        return {}
    }

    useEffect(() => {
        function VerifySendPinPukType() {
            if (PINPUK_TYPE_SEND.length === 1 && PINPUK_TYPE_SEND[0].id === 3) {
                setInitialSendType({
                    id: PINPUK_TYPE_SEND[0].id,
                    label: PINPUK_TYPE_SEND[0].label,
                })
            }

            if (PINPUK_TYPE_SEND.length === 1 && PINPUK_TYPE_SEND[0].id !== 3) {
                setInitialSendType({
                    id: PINPUK_TYPE_SEND[0].id,
                    label: PINPUK_TYPE_SEND[0].label,
                })
            }
        }

        VerifySendPinPukType()
    }, [])

    const parsePhoto =
        activeCard.personPhoto && activeCard.personPhoto.includes('data:image')
            ? activeCard.personPhoto
            : `data:image/jpeg;base64,${activeCard.personPhoto}`

    const photoVerification = activeCard.personPhoto === undefined ? '' : parsePhoto

    const INITIAL_STATE = {
        id: { value: activeCard.id || '' },
        personPhoto: {
            value: activeCard.config
                ? activeCard.config.personPhoto.includes('data:image')
                    ? activeCard.config.personPhoto
                    : `data:image/jpeg;base64,${activeCard.config.personPhoto}`
                : photoVerification || '',
            validation: ['validateNotEmptyUtil'],
        },
        taxIdentificationNumber: {
            value: activeCard.config
                ? activeCard.config.personTaxIdentificationNumber
                : activeCard.taxIdentificationNumber || '',
            validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'],
            mask: 'maskNIFUtil',
        },
        personName: {
            value: activeCard.config ? activeCard.config.personName : activeCard.personName || '',
            validation: ['validatePersonNameUtil'],
        },
        personPhoneNumber: {
            value: activeCard.config ? activeCard.config.personPhoneNumber : activeCard.personPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
            mask: 'maskPhoneUtil',
        },
        personEmail: {
            value: activeCard.config ? activeCard.config.personEmail : activeCard.personEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        documentType: {
            value: activeCard.config
                ? activeCard.config.documentType
                : activeCard.documentType || { id: 0, label: 'Tipo de Documento' },
            validation: ['validateDocumentTypeUtil'],
        },
        documentNumber: {
            value: activeCard.config ? activeCard.config.documentNumber : activeCard.documentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        documentValidity: {
            value: activeCard.config ? activeCard.config.documentValidity : activeCard.documentValidity || null,
            validation: ['validateDateUtil'],
        },
        type: {
            value: activeCard.config ? activeCard.config.solicitationType : defaultServiceType,
        },
        duration: {
            value: activeCard.config ? getInitialDuration(activeCard.stateId) : activeCard.duration || defaultDuration,
            validation: ['validateCardDurationUtil'],
        },
        price: {
            value: activeCard.config ? getInitialPrice(activeCard.stateId) : activeCard.price || '',
        },
        nameOnCard: {
            value: activeCard.config ? activeCard.config.nameOnCard : activeCard.nameOnCard || '',
            validation: ['validateNameOnCardWhenPseudonymUncheckedUtil'],
        },
        entityName: { value: activeCard.entityName || entity.entityName, validation: ['validateNotEmptyUtil'] },
        orgUnitSelected: {
            value: activeCard.config
                ? activeCard.config.orgUnit
                : activeCard.orgUnitSelected || {
                      id: 0,
                      name: 'Escolhe a Unidade Orgânica',
                      label: 'Escolhe a Unidade Orgânica',
                      value: 'Escolhe a Unidade Orgânica',
                  },
        },
        role: {
            value: activeCard.config
                ? getRole(activeCard.config.role)
                : activeCard.role && {
                      name: activeCard.role,
                      label: activeCard.role,
                      value: activeCard.role,
                  },
            validation: [
                'validateRoleUtil',
                'validateAlphaLatinNumberRoleUtil',
                'validateNotEmptyUtil',
                { validator: 'validateMaxLengthUtil', args: [40] },
            ],
        },
        isValid: { value: activeCard.config ? activeCard.isValid : activeCard.isValid || false },
        useAlias: {
            value: activeCard.config ? activeCard.config.useAlias : activeCard.useAlias || false,
        },
        aliasReason: {
            // value: '',
            value: activeCard.config ? activeCard.config.aliasReason : activeCard.aliasReason || '',
        },
        givenName: {
            // value: activeCard.givenName || '',
            value: activeCard.config ? activeCard.config.givenName : activeCard.givenName || '',
            validation: ['validateNotEmptyUtil', 'validatePseudonymLengthUtil'],
        },
        surname: {
            value: activeCard.config ? activeCard.config.surname : activeCard.surname || '',
            // value: activeCard.surname || '',
            validation: ['validateNotEmptyUtil', 'validatePseudonymLengthUtil'],
        },
        pinPukSendType: {
            value: activeCard.config ? activeCard.config.pinPukSendType : activeCard.pinPukSendType,
            validation: ['validateNotEmptyUtil'],
        },
        cardReaderRequest: {
            value: activeCard.config ? activeCard.config.cardReaderRequest : activeCard.cardReaderRequest || false,
        },
        pinPukAddress: {
            value: activeCard.config ? activeCard.config.pinPukAddress : activeCard.pinPukAddress || '',
            validation: ['validatePostalCodeUtil'],
        },
        pinPukPostalCode: {
            value: activeCard.config ? activeCard.config.pinPukPostalCode : activeCard.pinPukPostalCode || '',
            validation: ['validatePostalCodeUtil'],
            mask: 'maskPostalCodeUtil',
        },
        pinPukLocation: {
            value: activeCard.config ? activeCard.config.pinPukLocation : activeCard.pinPukLocation || '',
            validation: ['validateLocationZoneNameUtil'],
        },
        address: {
            value: activeCard.config
                ? activeCard.config.address.address
                : (activeCard.address && activeCard.address.address) || '',
        },
        postalCode: {
            value: activeCard.config
                ? activeCard.config.address.postalCode
                : (activeCard.address && activeCard.address.postalCode) || '',
            validation: ['validatePostalCodeUtil'],
            mask: 'maskPostalCodeUtil',
        },
        location: {
            value: activeCard.config
                ? activeCard.config.address.location
                : (activeCard.address && activeCard.address.location) || '',
            validation: ['validateLocationZoneNameUtil'],
        },
    }

    const {
        values,
        errors,
        touched,
        isValidForm,
        handleChange,
        handleBlur,
        setValues,
        setTouched,
        setErrors,
    } = useValidationForm(INITIAL_STATE)

    function renderMailForm() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} classes={{ root: classes.removePaddingBottom }}>
                    <TextField
                        name='pinPukAddress'
                        label='Morada não institucional'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.pinPukAddress.value}
                        error={touched.pinPukAddress ? Boolean(errors.pinPukAddress) : false}
                        helperText={touched.pinPukAddress ? errors.pinPukAddress : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} classes={{ root: classes.removePaddingTop }}>
                    <InputMask
                        mask={maskPostalCodeUtil}
                        value={values.pinPukPostalCode.value}
                        maskPlaceholder={null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {() => (
                            <TextField
                                name='pinPukPostalCode'
                                label='Código Postal'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={touched.pinPukPostalCode ? Boolean(errors.pinPukPostalCode) : false}
                                helperText={touched.pinPukPostalCode ? errors.pinPukPostalCode : ''}
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>

                <Grid item xs={8} classes={{ root: classes.removePaddingTop }}>
                    <TextField
                        name='pinPukLocation'
                        label='Localidade'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.pinPukLocation.value}
                        error={touched.pinPukLocation ? Boolean(errors.pinPukLocation) : false}
                        helperText={touched.pinPukLocation ? errors.pinPukLocation : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
            </Grid>
        )
    }

    function handleCardDurations(cardTypes, value) {
        // ? This function filters the cardTypes array to find the selected
        // ? one by comparing the value with the type of each item,
        // ? then it returns the durations array with a new attribute called
        // ? label for rendering on the SSelect component
        const { durations } = cardTypes.filter(item => {
            return item.type === value
        })[0]

        return durations.map(item => ({
            ...item,
            label: item.duration !== 1 ? `${item.duration} ano(s)` : `${item.duration} ano`,
        }))
    }

    // GETS AN ARRAY OF CARDTYPES FROM THE stateOrders
    const cardTypes = stateOrders.services.types.filter(item => item.basicType === 'TOKEN')
    // Creating local durations array
    const [cardDurationOptions, setCardDurationOptions] = useState(handleCardDurations(cardTypes, defaultServiceType))

    function handleRadioChange(event) {
        const { value } = event.target
        // GET THE DURATIONS OF THE SELECTED CARD TYPE
        const newDurations = handleCardDurations(cardTypes, value)
        setCardDurationOptions(newDurations)
        setIsCardReaderActive(false)
        setValues({
            ...values,
            type: { ...values.type, value },
            price: { ...values.price, value: null },
            duration: { ...values.duration, value: defaultDuration },
            cardReaderRequest: { value: false },
        })
    }

    function handleDatePickerChange(dateString, field) {
        if (dateString) {
            const selectedDate = dateString.split('/')
            const selectedDateString = new Date(
                Number(selectedDate[2]),
                Number(selectedDate[1]) - 1,
                Number(selectedDate[0])
            )
            const selectedDateTimestamp = selectedDateString.getTime()
            const today = new Date()
            const yesterdayTimestamp = today.setDate(today.getDate() - 1)
            const selectedDateIsSameOrBeforeThanYesterday = selectedDateTimestamp <= yesterdayTimestamp

            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
        } else {
            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: null,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: null,
                },
            })
        }
    }

    function handleDatePickerBlur(event, field) {
        if (event) {
            handleBlur({
                target: {
                    name: field,
                },
            })
        }
    }

    function handlePhoto(data) {
        const event = {
            target: {
                name: 'personPhoto',
                value: data,
            },
        }
        handleChange(event)
    }

    function handleNameBlur(event) {
        const fullName = `${values.givenName.value.trim()} ${values.surname.value.trim()}`

        // const nameOnCardEvent = {
        //     target: {
        //         name: 'nameOnCard',
        //         value: event.target.value,
        //     },
        // }
        handleChange(event)
        // handleChange(nameOnCardEvent)
        setValues({
            ...values,
            personName: { ...values.personName, value: fullName },
            nameOnCard: { ...values.nameOnCard, value: fullName },
        })
        setTouched({
            ...touched,
            // personName: true,
            nameOnCard: true,
            givenName: true,
            surname: true,
        })
    }

    function handleSelectChange(name, obj, action = null) {
        if (obj) {
            const event = {
                target: {
                    name: name,
                    value: obj,
                },
            }

            switch (name) {
                case 'documentType':
                    handleChange(event)
                    if (event.target.value && event.target.value.id === 1) {
                        setValues({
                            ...values,
                            documentType: {
                                ...values.documentType,
                                value: event.target.value,
                            },
                            documentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 3) {
                        setValues({
                            ...values,
                            documentType: {
                                ...values.documentType,
                                value: event.target.value,
                            },
                            documentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 5) {
                        setValues({
                            ...values,
                            documentType: {
                                ...values.documentType,
                                value: event.target.value,
                            },
                            documentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                            },
                        })
                    } else {
                        setValues({
                            ...values,
                            documentType: {
                                ...values.documentType,
                                value: event.target.value,
                            },
                            documentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                            },
                        })
                    }
                    break
                case 'duration':
                    handleChange(event)
                    setValues({
                        ...values,
                        price: { ...values.price, value: event.target.value.price },
                        duration: { ...values.duration, value: event.target.value },
                    })
                    break
                default:
                    handleChange(event)
                    break
            }
        }
        if (name === 'orgUnitSelected' && action === 'clear') {
            const value = {
                id: 0,
                name: 'Selecione ou informe a unidade orgânica',
                label: 'Selecione ou informe a unidade orgânica',
                value: 'Selecione ou informe a unidade orgânica',
            }
            const event = {
                target: {
                    name,
                    value,
                },
            }
            handleChange(event)
            setValues({
                ...values,
                orgUnitSelected: {
                    ...values.orgUnitSelected,
                    value,
                },
            })
        }

        if (name === 'role' && action === 'clear') {
            const value = {
                id: 0,
                name: `Selecione ${isLimitedRoles ? 'o cargo' : 'ou informe o cargo'}`,
                label: `Selecione ${isLimitedRoles ? 'o cargo' : 'ou informe o cargo'}`,
                value: `Selecione ${isLimitedRoles ? 'o cargo' : 'ou informe o cargo'}`,
            }
            const event = {
                target: {
                    name,
                    value,
                },
            }
            handleChange(event)
            setValues({
                ...values,
                role: {
                    ...values.role,
                    value,
                    validation: [
                        'validateNotEmptyUtil',
                        'validateRoleUtil',
                        'validateAlphaLatinNumberRoleUtil',
                        { validator: 'validateMaxLengthUtil', args: [40] },
                    ],
                },
            })
        }

        if (name === 'role' && action === 'select-option') {
            const value = {
                id: obj.id,
                name: obj.name,
                label: obj.name,
                value: obj.name,
            }

            const event = {
                target: {
                    name: 'role',
                    value: value,
                },
            }
            handleChange(event)
            setValues({
                ...values,
                role: {
                    ...values.role,
                    value,
                    validation: [
                        'validateRoleUtil',
                        'validateAlphaLatinNumberRoleUtil',
                        'validateNotEmptyUtil',
                        { validator: 'validateMaxLengthUtil', args: [40] },
                    ],
                },
            })
        }
    }

    function handleSelectBlur(name, obj) {
        const event = {
            target: {
                name: name,
                value: obj.target.value,
            },
        }

        handleBlur(event)
    }

    function handleCreate(inputValue) {
        const obj = {
            name: inputValue,
            value: inputValue,
            label: inputValue,
        }
        // TODO: Update the orgUnit object to the correct payload
        const event = {
            target: {
                name: 'orgUnitSelected',
                value: obj,
            },
        }
        handleChange(event)
        setValues({ ...values, orgUnitSelected: { ...values.orgUnitSelected, value: obj } })
    }

    function handleCreateRole(inputValue) {
        const obj = {
            name: inputValue,
            value: inputValue,
            label: inputValue,
        }

        const event = {
            target: {
                name: 'role',
                value: obj,
            },
        }

        setValues({
            ...values,
            role: {
                ...values.role,
                value: obj,
                validation: [
                    'validateNotEmptyUtil',
                    'validateRoleUtil',
                    'validateAlphaLatinNumberRoleUtil',
                    { validator: 'validateMaxLengthUtil', args: [40] },
                ],
            },
        })

        handleChange(event)
    }

    function handleCheckboxChange(event) {
        const { checked } = event.target

        setIsPseudonymActive(!isPseudonymActive)
        handleChange(event)
        setValues({ ...values, useAlias: { value: checked } })

        if (checked) {
            setErrors({ ...errors, aliasReason: 'Campo Obrigatório' })
        }

        if (!checked) {
            setTouched({ ...touched, nameOnCard: true })
        }
    }

    function handleCardReader(event) {
        const { checked } = event.target

        setIsCardReaderActive(!isCardReaderActive)
        handleChange(event)
        setValues({ ...values, cardReaderRequest: { value: checked } })
    }

    function renderPaperSendPinkPuk() {
        if (PINPUK_TYPE_SEND.length === 1 && PINPUK_TYPE_SEND[0].id !== 3) {
            return null
        }

        if (PINPUK_TYPE_SEND.length === 1 && PINPUK_TYPE_SEND[0].id === 3) {
            return (
                <Grid item xs={12}>
                    <PaperComponent noborder fullscreen>
                        <PaperHeaderComponent title='Onde o titular pretende receber os códigos PIN e PUK?' />
                        {renderMailForm()}
                    </PaperComponent>
                </Grid>
            )
        }

        return (
            <Grid item xs={12}>
                <PaperComponent noborder fullscreen>
                    <PaperHeaderComponent title='Onde o titular pretende receber os códigos PIN e PUK?'>
                        <STooltip placement='top' title={NEW_ORDER_PINPUK_TOOLTIP} arrow>
                            <STipIcon />
                        </STooltip>
                    </PaperHeaderComponent>
                    <Grid item xs={12}>
                        <SSelect
                            name='pinPukSendType'
                            getOptionValue={option => option}
                            placeholder='Selecione uma opção'
                            margin='dense'
                            variant='outlined'
                            options={PINPUK_TYPE_SEND}
                            value={values.pinPukSendType.value}
                            error={touched.pinPukSendType ? Boolean(errors.pinPukSendType) : false}
                            helperText={touched.pinPukSendType ? errors.pinPukSendType : ''}
                            onChange={event => {
                                handleSelectChange('pinPukSendType', event)
                            }}
                            onBlur={event => {
                                handleSelectBlur('pinPukSendType', event)
                            }}
                            isSearchable
                            fullWidth
                            required
                            theme={customSelectColors}
                        />
                    </Grid>
                    {values &&
                        values.pinPukSendType &&
                        values.pinPukSendType.value &&
                        values.pinPukSendType.value.id === 3 &&
                        renderMailForm()}
                </PaperComponent>
            </Grid>
        )
    }

    function renderPaperInstitutionalAddress() {
        const pinPukTypeIdSelected =
            values.pinPukSendType && values.pinPukSendType.value && values.pinPukSendType.value.id

        // pinPukTypeIdSelected = 2 => 'Levantar no CEGER'
        if (INSTITUICAO === 'IGFEJ' || (INSTITUICAO === 'Ceger' && pinPukTypeIdSelected !== 2)) {
            return (
                <Grid item xs={12}>
                    <PaperComponent noborder fullscreen>
                        <PaperHeaderComponent title='Informe a morada institucional para o envio do cartão' />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    name='address'
                                    label='Morada institucional'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.address.value}
                                    error={touched.address ? Boolean(errors.address) : false}
                                    helperText={touched.address ? errors.address : ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputMask
                                    mask={maskPostalCodeUtil}
                                    value={values.postalCode.value}
                                    maskPlaceholder={null}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {() => (
                                        <TextField
                                            name='postalCode'
                                            label='Código Postal'
                                            type='text'
                                            margin='dense'
                                            variant='outlined'
                                            error={touched.postalCode ? Boolean(errors.postalCode) : false}
                                            helperText={touched.postalCode ? errors.postalCode : ''}
                                            fullWidth
                                        />
                                    )}
                                </InputMask>
                            </Grid>

                            <Grid item xs={8}>
                                <TextField
                                    name='location'
                                    label='Localidade'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.location.value}
                                    error={touched.location ? Boolean(errors.location) : false}
                                    helperText={touched.location ? errors.location : ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </PaperComponent>
                </Grid>
            )
        }

        return null
    }

    useEffect(() => {
        if (INSTITUICAO === 'Ceger') {
            if (values.pinPukSendType.value && values.pinPukSendType.value.id !== 2) {
                setValues({
                    ...values,
                    address: { value: values.address.value, validation: ['validateNotEmptyUtil'] },
                    postalCode: {
                        ...values.postalCode,
                        value: values.postalCode.value,
                        validation: ['validateNotEmptyUtil', ...values.postalCode.validation],
                    },
                    location: {
                        value: values.location.value,
                        validation: ['validateNotEmptyUtil', ...values.location.validation],
                    },
                })
            } else {
                setValues({
                    ...values,
                    address: { value: null },
                    postalCode: {
                        ...values.postalCode,
                        value: null,
                        validation: ['validatePostalCodeUtil'],
                        mask: 'maskPostalCodeUtil',
                    },
                    location: {
                        value: null,
                        validation: ['validateLocationZoneNameUtil'],
                    },
                })
            }
        } else {
            setValues({
                ...values,
                address: { value: values.address.value, validation: ['validateNotEmptyUtil'] },
                postalCode: {
                    ...values.postalCode,
                    value: values.postalCode.value,
                    validation: ['validateNotEmptyUtil', ...values.postalCode.validation],
                },
                location: {
                    value: values.location.value,
                    validation: ['validateNotEmptyUtil', ...values.location.validation],
                },
            })
        }
    }, [values.pinPukSendType.value, values.address.value])

    useEffect(() => {
        if (values.pinPukSendType.value && values.pinPukSendType.value.id === 3) {
            setValues({
                ...values,
                pinPukAddress: { value: values.pinPukAddress.value, validation: ['validateNotEmptyUtil'] },
                pinPukPostalCode: {
                    ...values.pinPukPostalCode,
                    value: values.pinPukPostalCode.value,
                    validation: ['validateNotEmptyUtil', ...values.pinPukPostalCode.validation],
                },
                pinPukLocation: {
                    value: values.pinPukLocation.value,
                    validation: ['validateNotEmptyUtil', ...values.pinPukLocation.validation],
                },
            })
        }
    }, [])

    useEffect(() => {
        if (values.useAlias.value) {
            setValues({
                ...values,
                nameOnCard: {
                    value: values.nameOnCard.value,
                    validation: ['validateNameOnCardWhenPseudonymCheckedUtil'],
                },
                aliasReason: {
                    value: values.aliasReason.value,
                    validation: ['validateNotEmptyUtil', { validator: 'validateMinLengthUtil', args: [20] }],
                },
                givenName: {
                    ...values.givenName,
                    validation: ['validateNotEmptyUtil', 'validatePseudonymLengthUtil'],
                },
                surname: {
                    ...values.surname,
                    validation: ['validateNotEmptyUtil', 'validatePseudonymLengthUtil'],
                },
            })
        } else {
            setValues({
                ...values,
                nameOnCard: {
                    value: values.nameOnCard.value,
                    validation: ['validateNameOnCardWhenPseudonymUncheckedUtil'],
                },
                aliasReason: {
                    value: '',
                },
                givenName: {
                    ...values.givenName,
                    value: values.givenName.value,
                    validation: [...values.givenName.validation, 'validateNotEmptyUtil'],
                },
                surname: {
                    ...values.surname,
                    value: values.surname.value,
                    validation: [...values.surname.validation],
                },
            })
        }
    }, [values.useAlias.value])

    const payloadSend = {
        id: values.id.value,
        basicType: 'TOKEN',
        isValid: true,
        personPhoto: values.personPhoto.value.substring(values.personPhoto.value.indexOf(',') + 1),
        taxIdentificationNumber: values.taxIdentificationNumber.value.replace(/\s/g, ''),
        personName: values.personName.value,
        personPhoneNumber: values.personPhoneNumber.value.replace(/\s/g, ''),
        personEmail: values.personEmail.value,
        documentType: values.documentType.value,
        documentNumber: values.documentNumber.value,
        documentValidity: values.documentValidity.value,
        type: values.type.value,
        duration: values.duration.value,
        price: values.price.value,
        nameOnCard: values.nameOnCard.value,
        entityName: values.entityName.value,
        orgUnitSelected: values.orgUnitSelected.value,
        role: values.role.value && values.role.value.name,
        file: files[0],
        useAlias: values.useAlias.value,
        aliasReason: values.aliasReason.value,
        givenName: values.givenName.value,
        surname: values.surname.value,
        pinPukSendType: initialSendType && initialSendType.id ? initialSendType : values.pinPukSendType.value,
        cardReaderRequest: values.cardReaderRequest.value,
        pinPukAddress: values.pinPukAddress ? values.pinPukAddress.value : null,
        pinPukPostalCode: values.pinPukPostalCode ? values.pinPukPostalCode.value : null,
        pinPukLocation: values.pinPukLocation ? values.pinPukLocation.value : null,
        address: {
            address: values.address && values.address.value ? values.address.value : null,
            postalCode: values.address && values.address.value ? values.postalCode.value : null,
            location: values.address && values.address.value ? values.location.value : null,
        },
    }

    async function handleSave() {
        setPromptCliked(true)

        const payloadVerification = {
            address: {
                address: values.address && values.address.value ? values.address.value : null,
                postalCode: values.address && values.address.value ? values.postalCode.value : null,
                location: values.address && values.address.value ? values.location.value : null,
            },
            givenName: values.givenName.value,
            aliasReason: values.aliasReason.value,
            surname: values.surname.value,
            cardReaderRequest: values.cardReaderRequest.value,
            documentNumber: values.documentNumber.value,
            documentType: values.documentType.value,
            durationId: values.duration.value.duration,
            documentValidity: values.documentValidity.value,
            nameOnCard: values.nameOnCard.value,
            personEmail: values.personEmail.value,
            personName: values.personName.value,
            personPhoneNumber: values.personPhoneNumber.value.replace(/\s/g, ''),
            personPhoto: values.personPhoto.value,
            pinPukSendType: values.pinPukSendType.value.id,
            role: values.role.value.name,
            personTaxIdentificationNumber: values.taxIdentificationNumber.value.replace(/\s/g, ''),
            useAlias: values.useAlias.value,
            orgUnit: values.orgUnitSelected.value
                ? {
                      name: values.orgUnitSelected.value.name,
                      value: values.orgUnitSelected.value.name,
                      label: values.orgUnitSelected.value.name,
                  }
                : {
                      id: 0,
                      name: 'Selecione ou informe a unidade orgânica',
                      label: 'Selecione ou informe a unidade orgânica',
                      value: 'Selecione ou informe a unidade orgânica',
                  },
            pinPukAddress: values.pinPukAddress ? values.pinPukAddress.value : null,
            pinPukPostalCode: values.pinPukPostalCode ? values.pinPukPostalCode.value : null,
            pinPukLocation: values.pinPukLocation ? values.pinPukLocation.value : null,
            settingFile: {
                name: files[0].name,
                base64: files[0].base64,
            },
        }

        try {
            const { data } = await api.post('orders/validateCardConfig', payloadVerification)

            if (data.result === 0) {
                actionsOrders.setServiceByType(payloadSend, 'cards', history)
            }
        } catch (err) {
            if (err.response && err.response.data) {
                const messageError = err.response.data.message

                setModalMessage(messageError.split('<br>'))
                // setModalMessage(err.response.data.message)
                setIsModalValidationOpen(true)
            }
        }
    }

    function handleConfirmModal() {
        actionsOrders.setServiceByType(payloadSend, 'cards', history)
    }

    function handleDownload() {
        const payloadDownload = {
            nipc: entity.nipc,
            config: {
                address: {
                    address: values.address && values.address.value ? values.address.value : null,
                    postalCode: values.address && values.address.value ? values.postalCode.value : null,
                    location: values.address && values.address.value ? values.location.value : null,
                },
                givenName: values.givenName.value,
                aliasReason: values.aliasReason.value,
                surname: values.surname.value,
                cardReaderRequest: values.cardReaderRequest.value,
                documentNumber: values.documentNumber.value,
                documentType: values.documentType.value,
                durationId: values.duration.value.duration,
                documentValidity: values.documentValidity.value,
                nameOnCard: values.nameOnCard.value,
                personEmail: values.personEmail.value,
                personName: values.personName.value,
                personPhoneNumber: values.personPhoneNumber.value.replace(/\s/g, ''),
                personPhoto: values.personPhoto.value,
                pinPukSendType:
                    initialSendType && initialSendType.id
                        ? { id: initialSendType.id }
                        : { id: values.pinPukSendType.value.id },
                role: values.role.value.name,
                personTaxIdentificationNumber: values.taxIdentificationNumber.value.replace(/\s/g, ''),
                useAlias: values.useAlias.value,
                orgUnit: {
                    name: values.orgUnitSelected.value.name,
                    value: values.orgUnitSelected.value.name,
                    label: values.orgUnitSelected.value.name,
                },
                pinPukAddress: values.pinPukAddress ? values.pinPukAddress.value : null,
                pinPukPostalCode: values.pinPukPostalCode ? values.pinPukPostalCode.value : null,
                pinPukLocation: values.pinPukLocation ? values.pinPukLocation.value : null,
                settingFile: {
                    name: files[0].name,
                    base64: files[0].base64,
                },
            },
        }

        api.post('orders/cardConfig', payloadDownload).then(response => {
            const { payload } = response.data

            const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${payload.docx}`

            // Download file
            const anchorElement = document.createElement('a')
            anchorElement.href = linkSource
            anchorElement.download = 'Configuracoes_Pedido.docx'
            anchorElement.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
        })
    }

    return (
        <form>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Dados do titular' />
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12} lg={3}>
                                        <h4>Fotografia a cores</h4>

                                        <UploadScaledImageComponent
                                            personPhoto={values.personPhoto.value}
                                            handlePhoto={handlePhoto}
                                            maxSize={maxSize}
                                            setMaxSize={setMaxSize}
                                            setImagesTipes={setImagesTipes}
                                        />
                                        {maxSize > 1048576 && (
                                            <ErrorComponent errorColor>
                                                Não é permitido imagem com mais de 1Mb
                                            </ErrorComponent>
                                        )}
                                        {!imagesTipes && (
                                            <ErrorComponent errorColor>
                                                Fotos somente em formato JPG, PNG, SVG, JPEG
                                            </ErrorComponent>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={9}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <InputMask
                                                    mask={maskNIFUtil}
                                                    value={values.taxIdentificationNumber.value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {() => (
                                                        <TextField
                                                            id='taxIdentificationNumber'
                                                            name='taxIdentificationNumber'
                                                            label='NIF'
                                                            margin='dense'
                                                            variant='outlined'
                                                            error={
                                                                touched.taxIdentificationNumber
                                                                    ? Boolean(errors.taxIdentificationNumber)
                                                                    : false
                                                            }
                                                            helperText={
                                                                touched.taxIdentificationNumber
                                                                    ? errors.taxIdentificationNumber
                                                                    : ''
                                                            }
                                                            fullWidth
                                                        />
                                                    )}
                                                </InputMask>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <InputMask
                                                    mask={maskPhoneUtil}
                                                    value={values.personPhoneNumber.value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {() => (
                                                        <TextField
                                                            id='personPhoneNumber'
                                                            name='personPhoneNumber'
                                                            label='Telefone'
                                                            margin='dense'
                                                            variant='outlined'
                                                            error={
                                                                touched.personPhoneNumber
                                                                    ? Boolean(errors.personPhoneNumber)
                                                                    : false
                                                            }
                                                            helperText={
                                                                touched.personPhoneNumber
                                                                    ? errors.personPhoneNumber
                                                                    : ''
                                                            }
                                                            fullWidth
                                                        />
                                                    )}
                                                </InputMask>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <STooltip
                                                    placement='top'
                                                    title='Esse campo será preenchido com a composição do Nome próprio e o Apelido'
                                                    arrow
                                                >
                                                    <TextField
                                                        id='personName'
                                                        name='personName'
                                                        label='Nome Completo'
                                                        margin='dense'
                                                        variant='outlined'
                                                        value={values.personName.value}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </STooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id='personEmail'
                                                    name='personEmail'
                                                    label='E-mail'
                                                    type='email'
                                                    margin='dense'
                                                    variant='outlined'
                                                    value={values.personEmail.value}
                                                    error={touched.personEmail ? Boolean(errors.personEmail) : false}
                                                    helperText={touched.personEmail ? errors.personEmail : ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <SSelect
                                                    id='documentType'
                                                    name='documentType'
                                                    getOptionValue={option => option}
                                                    placeholder='Tipo de Documento'
                                                    margin='dense'
                                                    variant='outlined'
                                                    options={documentTypeOptions}
                                                    value={values.documentType.value}
                                                    error={touched.documentType ? Boolean(errors.documentType) : false}
                                                    helperText={touched.documentType ? errors.documentType : ''}
                                                    onChange={event => {
                                                        handleSelectChange('documentType', event)
                                                    }}
                                                    onBlur={event => {
                                                        handleSelectBlur('documentType', event)
                                                    }}
                                                    isSearchable
                                                    fullWidth
                                                    required
                                                    theme={customSelectColors}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextField
                                            id='documentNumber'
                                            name='documentNumber'
                                            label='Nº de Documento'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.documentNumber.value}
                                            error={touched.documentNumber ? Boolean(errors.documentNumber) : false}
                                            helperText={touched.documentNumber ? errors.documentNumber : ''}
                                            inputProps={
                                                values.documentType.value && values.documentType.value.id === 1
                                                    ? { maxLength: 12 }
                                                    : { maxLength: 9 }
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                                        <DatePickerComponent
                                            id='documentValidity'
                                            name='documentValidity'
                                            dateLabel='Validade do Documento'
                                            dateValue={values.documentValidity.value}
                                            error={touched.documentValidity ? Boolean(errors.documentValidity) : false}
                                            helperText={touched.documentValidity ? errors.documentValidity : ''}
                                            onDatePickerChange={event =>
                                                handleDatePickerChange(event, 'documentValidity')
                                            }
                                            onBlur={event => handleDatePickerBlur(event, 'documentValidity')}
                                            isFullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </PaperComponent>
                        </Grid>
                        <Grid item xs={12}>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Adicionar Ficheiro .PDF' />
                                <Typography component='div' variant='subtitle2' mb={1}>
                                    Insira um arquivo que comprove seu vinculo com a entidade.
                                </Typography>

                                <UploadFileComponent
                                    id='files'
                                    initialFiles={files}
                                    handleFiles={fileList => setFiles(fileList)}
                                    isSingleFile
                                />
                            </PaperComponent>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Dados do cartão' />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <RadioGroup
                                            defaultValue={defaultServiceType}
                                            aria-label='position'
                                            name='type'
                                            value={values.type.value}
                                            onChange={handleRadioChange}
                                            row
                                        >
                                            {_.find(stateOrders.services.types, {
                                                basicType: 'TOKEN',
                                                type: 'Novo',
                                            }) && (
                                                <FormControlLabel
                                                    value='Novo'
                                                    control={<Radio color='primary' />}
                                                    label='Novo'
                                                    labelPlacement='end'
                                                />
                                            )}
                                            {_.find(stateOrders.services.types, {
                                                basicType: 'TOKEN',
                                                type: 'Renovação',
                                            }) && (
                                                <FormControlLabel
                                                    value='Renovação'
                                                    control={<Radio color='primary' />}
                                                    label='Renovação'
                                                    labelPlacement='end'
                                                />
                                            )}
                                        </RadioGroup>
                                    </Grid>
                                    {values.type.value === 'Novo' && (
                                        <Grid item xs={6}>
                                            <Grid container alignItems='center' justify='space-between'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name='cardReaderRequest'
                                                            checked={isCardReaderActive}
                                                            onChange={handleCardReader}
                                                            color='primary'
                                                            size='small'
                                                        />
                                                    }
                                                    label='Preciso de Leitor de Cartão'
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <SSelect
                                            id='duration'
                                            name='duration'
                                            getOptionValue={option => option}
                                            placeholder='Duração do Cartão'
                                            margin='dense'
                                            variant='outlined'
                                            options={cardDurationOptions}
                                            className={
                                                touched.documentType && errors.documentType
                                                    ? 'inputMarginDense selectError'
                                                    : 'inputMarginDense'
                                            }
                                            value={values.duration.value}
                                            error={touched.duration ? Boolean(errors.duration) : false}
                                            helperText={touched.duration ? errors.duration : ''}
                                            onChange={event => {
                                                handleSelectChange('duration', event)
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('duration', event)
                                            }}
                                            theme={customSelectColors}
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <TextField
                                            id='price'
                                            name='price'
                                            label='Valor'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.price.value ? values.price.value : ''}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: <InputAdornment position='start'>€</InputAdornment>,
                                            }}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems='center'
                                        justify='space-between'
                                        style={{ padding: '5px' }}
                                    >
                                        <Grid item>
                                            <Typography component='div' variant='subtitle2'>
                                                Nome completo (conforme documento de identificação)
                                            </Typography>
                                        </Grid>

                                        {entity.pseudonym && (
                                            <Grid item>
                                                <Grid container alignItems='center' justify='space-between'>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name='useAlias'
                                                                checked={isPseudonymActive}
                                                                onChange={handleCheckboxChange}
                                                                color='primary'
                                                                size='small'
                                                            />
                                                        }
                                                        label='Pseudónimo'
                                                    />

                                                    <STooltip
                                                        placement='top'
                                                        title={NEW_ORDER_PSEUDONYM_TOOLTIP}
                                                        arrow
                                                        interactive
                                                        disableFocusListener
                                                        disableTouchListener
                                                    >
                                                        <STipIcon />
                                                    </STooltip>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>

                                    {isPseudonymActive && (
                                        <Grid item xs={12}>
                                            <TextField
                                                id='aliasReason'
                                                name='aliasReason'
                                                label='Justificação para Pseudónimo'
                                                margin='dense'
                                                variant='outlined'
                                                value={values.aliasReason.value}
                                                error={touched.aliasReason ? Boolean(errors.aliasReason) : false}
                                                helperText={touched.aliasReason ? errors.aliasReason : ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={6}>
                                        <TextField
                                            id='givenName'
                                            name='givenName'
                                            label='Nome(s) Próprio(s)'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.givenName.value}
                                            error={touched.givenName ? Boolean(errors.givenName) : false}
                                            helperText={touched.givenName ? errors.givenName : ''}
                                            onChange={handleChange}
                                            onBlur={handleNameBlur}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='surname'
                                            name='surname'
                                            label='Apelido(s)'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.surname.value}
                                            error={touched.surname ? Boolean(errors.surname) : false}
                                            helperText={touched.surname ? errors.surname : ''}
                                            onChange={handleChange}
                                            onBlur={handleNameBlur}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={6}>
                                        <TextField
                                            id='nameOnCard'
                                            name='nameOnCard'
                                            label='Nome para assinatura/Nome impresso no cartão'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.nameOnCard.value}
                                            error={touched.nameOnCard ? Boolean(errors.nameOnCard) : false}
                                            helperText={touched.nameOnCard ? errors.nameOnCard : ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <TextField
                                            id='entityName'
                                            name='entityName'
                                            label='Entidade'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.entityName.value}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <SCreatableSelect
                                            id='orgUnitSelected'
                                            name='orgUnitSelected'
                                            placeholder='Selecione ou informe a unidade orgânica'
                                            margin='dense'
                                            variant='outlined'
                                            formatCreateLabel={inputValue => `Criar ${inputValue}`}
                                            options={selectOrgUnitOptions}
                                            getOptionLabel={option => option.name}
                                            value={values.orgUnitSelected.value}
                                            error={touched.orgUnitSelected ? Boolean(errors.orgUnitSelected) : false}
                                            helperText={touched.orgUnitSelected ? errors.orgUnitSelected : ''}
                                            onCreateOption={handleCreate}
                                            isLoading={stateOrders.isLoading}
                                            onChange={(obj, event) => {
                                                if (event.action === 'clear') {
                                                    handleSelectChange('orgUnitSelected', obj, event.action)
                                                } else {
                                                    handleSelectChange('orgUnitSelected', obj)
                                                }
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('orgUnitSelected', event)
                                            }}
                                            theme={customSelectColors}
                                            isClearable
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        {isLimitedRoles ? (
                                            <>
                                                <SSelect
                                                    id='role'
                                                    name='role'
                                                    label='Cargo'
                                                    placeholder='Selecione o cargo'
                                                    options={jobsSelect}
                                                    value={values.role.value}
                                                    error={touched.role ? Boolean(errors.role) : false}
                                                    helperText={touched.role ? errors.role : ''}
                                                    onChange={(option, { action }) => {
                                                        setSearchJob(option)

                                                        if (action === 'select-option') {
                                                            handleSelectChange('role', option, action)
                                                        }

                                                        if (action === 'clear') {
                                                            setSearchJob('')
                                                            getJobsList('')
                                                            handleSelectChange('role', option, action)
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.name}
                                                    theme={customSelectColors}
                                                    fullWidth
                                                    required
                                                    noOptionsMessage={() => 'Sem Opções disponíveis'}
                                                    isSearchable
                                                    onInputChange={inputValue => setSearchJob(inputValue)}
                                                    isClearable
                                                />
                                                <SRequired validate={touched.role && errors.role}>
                                                    {errors.role}
                                                </SRequired>
                                            </>
                                        ) : (
                                            <>
                                                <TextField
                                                    name='role'
                                                    label='Cargo'
                                                    type='text'
                                                    margin='dense'
                                                    variant='outlined'
                                                    value={values.role.value && values.role.value.name}
                                                    error={touched.role ? Boolean(errors.role) : false}
                                                    helperText={touched.role ? errors.role : ''}
                                                    onChange={e => handleCreateRole(e.target.value)}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                />
                                                <SRequired
                                                    validate={
                                                        touched.role &&
                                                        values.role.value &&
                                                        values.role.value.name === ''
                                                    }
                                                >
                                                    Campo obrigatório
                                                </SRequired>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </PaperComponent>
                        </Grid>

                        {renderPaperSendPinkPuk()}

                        {renderPaperInstitutionalAddress()}
                    </Grid>
                </Grid>
            </Grid>
            <ActionBarComponent backLink='/new-order' backText='Voltar'>
                {files.length === 1 && values.personPhoto.value.length > 1 && isValidForm && (
                    <Button variant='outlined' color='primary' onClick={handleDownload}>
                        Download Formulário
                    </Button>
                )}

                <Button
                    variant='contained'
                    color='primary'
                    disabled={
                        !isValidForm ||
                        files.length !== 1 ||
                        values.personPhoto.value.length < 1 ||
                        values.price.value < 0 ||
                        (values.role.value && values.role.value.name === 'Selecione o cargo') ||
                        (values.role.value && values.role.value.name === '')
                    }
                    onClick={handleSave}
                >
                    Guardar
                </Button>
            </ActionBarComponent>

            {isModalValidationOpen && (
                <FConfirmationModal
                    open={isModalValidationOpen}
                    handleModalClose={setIsModalValidationOpen}
                    handleModalConfirm={handleConfirmModal}
                    titleText='Informação'
                    cancelationText='Não'
                    confirmText='Sim'
                    bodyText={modalMessage}
                />
            )}

            <Prompt
                // when={stateApiStatusService !== StatusService.FINISHED || !promptClicked}
                message={(location, action) => {
                    if (action === 'PUSH' && !promptClicked) {
                        return MSG_CONFIRM_PAGE_EXIT
                    }

                    return null
                }}
            />
        </form>
    )
}

NewOrderCardConfigForm.propTypes = {
    activeCard: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NewOrderCardConfigForm
