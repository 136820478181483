import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import NewOrderWildcardConfigForm from '../../forms/NewOrderWildcardConfig/NewOrderWildcardConfig.form'

import { useOrdersStore } from '../../store/Orders/Orders.store'

const propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    params: {},
}

function ConfigWildcardStep(props) {
    const { match } = props
    const { params } = match
    // ORDERS STORE
    const { stateOrders } = useOrdersStore()
    // Search Wildcardss arrays on state by wildcard id
    const [wildcard] = useState(stateOrders.services.wildcards.find(item => item.id === params.id))

    return (
        <Fade in>
            <ContentComponent>
                <PageTitleComponent title='Configuração do Wildcard' />
                <NewOrderWildcardConfigForm activeWildcard={wildcard} stateOrders={stateOrders} />
            </ContentComponent>
        </Fade>
    )
}

ConfigWildcardStep.propTypes = propTypes
ConfigWildcardStep.defaultProps = defaultProps

export default ConfigWildcardStep
