import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { SContainer, SBarActions, SBackButton } from './ActionBar.styles'

const propTypes = {
    children: PropTypes.node,
    backLink: PropTypes.string,
    backText: PropTypes.node,
    hiddenBack: PropTypes.bool,
    handleBack: PropTypes.func,
}

const defaultProps = {
    children: null,
    backLink: '',
    backText: '',
    handleBack: () => {},
    hiddenBack: false,
}

function ActionBarComponent({ children, hiddenBack, backLink, backText, handleBack }) {
    let buttonBack
    if (backLink.length > 0) {
        buttonBack = (
            <Button startIcon={<ArrowBack />}>
                <Link to={backLink}>{backText}</Link>
            </Button>
        )
    } else {
        buttonBack = (
            <Button startIcon={<ArrowBack />} onClick={handleBack}>
                {backText}
            </Button>
        )
    }

    return (
        <SContainer>
            <SBackButton hiddenBack={hiddenBack}>{buttonBack}</SBackButton>
            <SBarActions>{children}</SBarActions>
        </SContainer>
    )
}

ActionBarComponent.propTypes = propTypes
ActionBarComponent.defaultProps = defaultProps

export default ActionBarComponent
