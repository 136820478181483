import React, { Fragment, useState } from 'react'

import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'

import { SItem, SHeader, STitle, SContent, SIconButton } from './Faq.styles'

function Faq() {
    const FAQ_LIST = [
        {
            open: false,
            id: 1,
            title: 'O que é um certificado digital?',
            content: () => {
                return (
                    <p>
                        Um certificado digital é um ficheiro electrónico que contém diversos dados, como os dados do
                        titular e a sua chave pública e que por sua vez é assinado por uma entidade certificadora. Um
                        certificado digital poderá actuar um pouco à semelhança de um documento de identidade. O
                        certificado digital associa uma chave criptográfica a uma identidade, de tal forma que esta
                        fique ligada aos documentos electrónicos sobre os quais se aplica o dito certificado.
                    </p>
                )
            },
        },
        {
            open: false,
            id: 2,
            title: 'Para que serve um certificado digital?',
            content: () => {
                return (
                    <div>
                        <p>Pode utilizar-se um certificado digital para:</p>
                        <ul>
                            <li>
                                Autenticar a identidade de um titular de forma electrónica perante terceiros ou serviços
                                e aplicações.
                            </li>
                            <li>
                                Assinar digitalmente de forma que se garanta a integridade dos dados e a sua
                                procedência.
                            </li>
                            <li>
                                Cifrar dados para que só o destinatário de um determinado documento ou ficheiro possa
                                aceder ao seu conteúdo.
                            </li>
                        </ul>
                        <p>A utilização de certificados digital garante:</p>
                        <ol>
                            <li>A identidade do emissor e do receptor da informação (autenticação das partes)</li>
                            <li>Que a informação não foi manipulada (integridade)</li>
                            <li>Que só o emissor e receptor conseguem ver a informação (confidencialidade)</li>
                            <li>
                                Que o titular de uma mensagem não possa negar que efectivamente a assinou (não-repúdio)
                            </li>
                        </ol>
                    </div>
                )
            },
        },
        {
            open: false,
            id: 3,
            title: 'Que elementos contém um certificado digital?',
            content: () => {
                return (
                    <ul>
                        <li>A identidade do titular.</li>
                        <li>A chave pública do titular.</li>
                        <li>Dados próprios do certificado: número de série, data de emissão…</li>
                        <li>A identidade da entidade certificadora que o emitiu</li>
                        <li>A assinatura da entidade certificadora</li>
                    </ul>
                )
            },
        },
        {
            open: false,
            id: 4,
            title: 'Como consultar a validade de um certificado?',
            content: () => {
                return (
                    <div>
                        <p>Descarregando as listas de certificados revogados - CRL's.</p>
                        <p>
                            A CRL é um documento digital que contém a lista de todos os números de série dos
                            certificados emitidos pela ECCE e que estão revogados
                        </p>
                        <p>Os certificados revogados são publicados nas CRL's assinadas pela ECCE.</p>
                        <p>
                            É possível descarregar a CRL mais recente através de um navegador de Internet e comprovar
                            posteriormente a validade de um certificado digital. Muitas das aplicações que estão
                            preparadas para a utilização de certificação digital (Office, Adobe, Mail, etc), verificam
                            automaticamente a validade de um certificado através da consulta das CRLs
                        </p>
                    </div>
                )
            },
        },
        {
            open: false,
            id: 5,
            title: 'O que significa revogar um certificado?',
            content: () => {
                return (
                    <div>
                        <p>Revogar um certificado é anular a sua validade antes da data de caducidade do mesmo.</p>
                        <p>
                            A revogação pode ser solicitada pelo titular a qualquer momento e em especial quando o
                            titular suspeite que a segurança da sua chave privada tenha sido comprometida.
                        </p>
                        <p>Para verificar se o certificado é válido, o receptor deve consultar a sua validade.</p>
                    </div>
                )
            },
        },
        {
            open: false,
            id: 6,
            title: 'Em que suporte são armazenados os certificados digitais?',
            content: () => {
                return (
                    <p>
                        Os certificados digitais são armazenados em cartões criptográficos, disponibilizados pela ECCE.
                    </p>
                )
            },
        },
        {
            open: false,
            id: 7,
            title: 'O que é um cartão inteligente / criptográfico?',
            content: () => {
                return (
                    <p>
                        É um cartão com microprocessador que tem funções criptográficas no próprio microprocessador. É
                        neste cartão que são armazenadas as chaves privadas dos certificados disponibilizados aos
                        utilizadores. Estes cartões são protegidos por um código PIN que está apenas na posse do titular
                        do mesmo e por um código PUK que permite desbloquear o cartão em caso de bloqueio do PIN.
                    </p>
                )
            },
        },
    ]

    const [currentOpenFAQId, setCurrentOpenFAQId] = useState(0)

    function handleClick(id) {
        if (id === currentOpenFAQId) {
            setCurrentOpenFAQId(0)
        } else {
            setCurrentOpenFAQId(id)
        }
    }

    function renderFAQItem() {
        return FAQ_LIST.map(faq => (
            <SItem key={faq.id}>
                <SHeader>
                    <STitle onClick={() => handleClick(faq.id)}>{faq.title}</STitle>
                    <SIconButton onClick={() => handleClick(faq.id)}>
                        {faq.open ? <ExpandLess /> : <ExpandMore />}
                    </SIconButton>
                </SHeader>
                <Collapse in={faq.id === currentOpenFAQId} timeout='auto' unmountOnExit>
                    <SContent>{faq.content()}</SContent>
                </Collapse>
            </SItem>
        ))
    }

    return (
        <Fragment>
            <ContentComponent>
                <PageTitleComponent title='FAQ' />
                <Fragment>{renderFAQItem()}</Fragment>
            </ContentComponent>
        </Fragment>
    )
}

export default Faq
