export const Types = {
    SET_INITIAL_DATA: 'SET_INITIAL_DATA',
}

export const INITIAL_STATE = {
    hash: null,
    validationHash: null,
    distinguishedName: {},
    email: null,
    smartcardNumber: null,
    expirationDate: null,
    issueDate: null,
}

export function reducer(state, action) {
    const {
        hash,
        validationHash,
        distinguishedName,
        email,
        smartcardNumber,
        expirationDate,
        issueDate,
    } = action.payload

    switch (action.type) {
        case Types.SET_INITIAL_DATA:
            return {
                ...state,
                hash: hash,
                validationHash: validationHash,
                distinguishedName: distinguishedName,
                email: email,
                smartcardNumber: smartcardNumber,
                expirationDate: expirationDate,
                issueDate: issueDate,
            }
        default:
            return state
    }
}
