/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Button, Grid, TextField, CircularProgress } from '@material-ui/core'

import InputMask from 'react-input-mask'

import apiService from '../../../services/api.service'

import { useAlertStore } from '../../../store/Alert/Alert.store'
import useValidationForm from '../../../hooks/useValidationForm.hook'

import FCaptcha from '../../../components/FCaptcha/FCaptcha.component'

import { INITIAL_STATE_NO_HASH } from './constants'
import { maskNIFUtil } from '../../../utils'

import { STitle, SLink, SAccessActions, SCircular } from './RecoveryPassword.styles'

function RecoveryPasswordNoHashForm(props) {
    const { setSuccess, setMessage } = props
    const { actionsAlert } = useAlertStore()
    const [isLoading, setIsLoading] = useState(false)

    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE_NO_HASH)

    const refCaptcha = useRef()

    async function handleRequestRecovery() {
        const requestPayload = {
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
            recovery: {
                nif: values.nif.value.replace(/\s/g, ''),
                email: values.personEmail.value,
            },
        }

        if (isValidForm) {
            try {
                setIsLoading(true)
                const { data } = await apiService.post('users/requestrecovery', requestPayload)
                setMessage(data.message)
                setSuccess(true)
            } catch (err) {
                setMessage(err.response.data.message)
                setSuccess(false)
                actionsAlert.showAlert(err.response.data)
            } finally {
                setIsLoading(false)
            }
        }
        return null
    }

    return isLoading ? (
        <SCircular>
            <CircularProgress size={30} />
            <span>A Enviar Solicitação</span>
        </SCircular>
    ) : (
        <form>
            <STitle>Por favor, indique o seu número de identificação fiscal</STitle>
            <Grid item xs={12}>
                <InputMask mask={maskNIFUtil} value={values.nif.value} onChange={handleChange} onBlur={handleBlur}>
                    {() => (
                        <TextField
                            id='nif'
                            name='nif'
                            label='Número de Identificação Fiscal'
                            type='text'
                            margin='dense'
                            variant='outlined'
                            error={touched.nif ? Boolean(errors.nif) : false}
                            helperText={touched.nif ? errors.nif : ''}
                            fullWidth
                        />
                    )}
                </InputMask>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='personEmail'
                    name='personEmail'
                    label='E-mail'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    value={values.personEmail.value}
                    error={touched.personEmail ? Boolean(errors.personEmail) : false}
                    helperText={touched.personEmail ? errors.personEmail : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
            </Grid>
            <Grid container direction='row' alignItems='flex-end' spacing={1}>
                <Grid item xs={12}>
                    <FCaptcha
                        refCaptcha={refCaptcha}
                        value={values.yourFirstCaptchaUserInput.value}
                        error={touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false}
                        helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SAccessActions>
                <SLink to='/'>Voltar para o Login</SLink>
                <Button variant='contained' color='primary' disabled={!isValidForm} onClick={handleRequestRecovery}>
                    Enviar Solicitação
                </Button>
            </SAccessActions>
        </form>
    )
}

RecoveryPasswordNoHashForm.propTypes = {
    setSuccess: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
}

export default RecoveryPasswordNoHashForm
