import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const SStepperModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    p {
        max-width: 350px;
    }
`

export const SButton = styled(Button)`
    && {
        margin: 8px 0 4px 4px;
        background: ${props => props.theme.palette.grey.light}
        padding-left: 10px;
        min-width: 100px;
`
