import Select from 'react-select'
import styled from 'styled-components'

const SelectStyle = `
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : 'none')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
    div:nth-child(3){
        z-index: 11 !important;
    }
`

const SSelect = styled(Select)`
    ${SelectStyle};
`

export default SSelect
