import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
`

export const SWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
`

export const SCustomModal = styled.div`
    width: ${props => (props.large ? '70%' : '50%')};
    height: auto;
    max-height: 90vh;
    outline: none;
    position: relative;
    border-radius: 8px;
    background-color: ${props => props.theme.palette.grey.xLight};
    padding: 20px 30px;
    transition: all 0.7s;
    animation: ${fadeIn} 0.5s linear;
    overflow-x: hidden;

    @media (max-width: 1600px) {
        width: ${props => (props.large ? '70%' : '50%')};
    }

    @media (max-width: 992px) {
        width: ${props => (props.large ? '90%' : '70%')};
    }
`

export const SHeader = styled.div`
    display: flex;
    position: relative;
`

export const SBody = styled.div`
    margin-bottom: 20px;
    position: relative;
`

export const SFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    button {
        margin-left: 15px;
    }
`

export const STitle = styled.h3`
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 1px solid ${props => props.theme.palette.grey.main};
    .materialIcons {
        color: ${props => props.theme.palette.grey.main};
        margin-right: 10px;
    }
`

export const SClose = styled.span`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 0;
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    text-indent: 100px;
    right: 0;
    top: 10px;
    &:before,
    &:after {
        width: 15px;
        height: 2px;
        content: '';
        background-color: ${props => props.theme.palette.grey.main};
        left: 5px;
        top: 10px;
        position: absolute;
        transition: background-color 0.2s ease-in-out;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before,
        &:after {
            background-color: ${props => props.theme.palette.black};
        }
    }
`
