import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import TableReducer, { INITIAL_STATE } from './Table.reducer'
import useActions from './Table.actions'

const TableContext = createContext(null)

export function TableProvider({ children }) {
    const [tableState, dispatch] = useReducer(TableReducer, INITIAL_STATE)

    const tableActions = useActions(tableState, dispatch)
    const value = { tableState, tableActions }

    return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

TableProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const useTableStore = () => useContext(TableContext)
