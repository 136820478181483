export const INITIAL_STATE = {
    SEARCH_CERT: {
        email: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
    },
}

export const ENDPOINTS = {
    // TODO: Alterar endpoint
    SEARCH_CERTIFICATE_AUTHENTICATION: 'tokens/searchPublicCertificate',
}
