import styled from 'styled-components'

export const SProductItem = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.theme.palette.grey.light};
    border-radius: 4px;
    color: ${props => props.theme.palette.blue.dark};
    text-transform: uppercase;
    padding: 8px 16px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    min-width: 136px;
    height: 56px;
    text-decoration: none;
    margin: 8px;

    @media screen and (max-width: 992px) {
        width: calc(20% - 16px);
        flex-direction: column;
        text-align: center;
        height: 112px;
    }

    .product-icon {
        width: 30px;
        margin-right: 16px;
        @media screen and (max-width: 992px) {
            margin: 0 0 16px 0;
        }
    }

    .product-name {
        line-height: 15px;
        max-width: 100px;
    }

    &:hover {
        border: 1px solid
            ${props => (props.disabled ? props.theme.palette.grey.light : props.theme.palette.primary.main)};
        background-color: ${props => (props.disabled ? 'inherit' : props.theme.palette.primary.hover)};
    }

    .product-description {
        padding: 8px 16px;
        border-radius: 6px;
    }

    .popover {
        pointer-events: none;
    }
`
