/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useState } from 'react'

import { Fade, Step, StepLabel, Stepper, CircularProgress } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import MessageComponent from '../../components/templates/Message/Message.component'

import EntityForm from '../../forms/Entity/Entity.form'
import UserForm from '../../forms/User/User.form'

import PageNotFound from '../PageNotFound/PageNotFound.page'

import { SPaper, SLogo, SCircular, SReturn } from './RequestUser.styles'
import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import { useRequestUserStore } from '../../store/RequestUser/RequestUser.store'
import UploadDocumentForm from '../../forms/UploadDocument/UploadDocument.form'

function RequestUserPage() {
    // LOCAL STATE
    const [steps] = useState([
        { id: 0, title: 'Dados do Utilizador' },
        { id: 1, title: 'Dados da Entidade' },
        { id: 2, title: 'Upload de Documentos' },
    ])
    const [activeStep, setActiveStep] = useState(0)

    // REQUESTUSER STATE
    const { stateRequestUser, actionsRequestUser } = useRequestUserStore()

    function renderUser() {
        return <UserForm setActiveStep={setActiveStep} />
    }

    function renderEntity() {
        return <EntityForm setActiveStep={setActiveStep} />
    }

    function renderUploadDocument() {
        return <UploadDocumentForm setActiveStep={setActiveStep} />
    }

    function renderMessage() {
        const { success, error } = stateRequestUser
        return error.code ? (
            <Fragment>
                <MessageComponent type='error' message={error.message} />
                <SReturn
                    onClick={() => {
                        actionsRequestUser.resetErrorSuccess()
                        setActiveStep(2)
                    }}
                >
                    <ArrowBack />
                    <span>Retornar</span>
                </SReturn>
            </Fragment>
        ) : (
            <MessageComponent
                type='success'
                link='/'
                message={success.message}
                successHeaderMessage='Solicitação de acesso registada, verifique seu email!'
                textBack='Login'
            />
        )
    }

    function renderDone() {
        const { success, error } = stateRequestUser
        const isLoading = Object.keys(success).length === 0 && Object.keys(error).length === 0

        return isLoading ? (
            <SCircular>
                <CircularProgress size={50} />
                <span>A registar solicitação...</span>
            </SCircular>
        ) : (
            renderMessage()
        )
    }

    function renderStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return renderUser()
            case 1:
                return renderEntity()
            case 2:
                return renderUploadDocument()
            case 3:
                return renderDone()
            default:
                return <PageNotFound />
        }
    }

    return (
        <Fade in>
            <SPaper>
                {activeStep !== steps.length && (
                    <Fragment>
                        <SLogo>
                            <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                        </SLogo>
                        <h3>Solicitação de Acesso</h3>

                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map(step => {
                                return (
                                    <Step key={step.id}>
                                        <StepLabel>{step.title}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Fragment>
                )}

                {renderStepContent(activeStep)}
            </SPaper>
        </Fade>
    )
}

export default RequestUserPage
