import React from 'react'
import PropTypes from 'prop-types'

import { Grid, TextField, Button } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import InputMask from 'react-input-mask'

import { maskNIFUtil, maskPostalCodeUtil } from '../../utils'

import { useRequestUserStore } from '../../store/RequestUser/RequestUser.store'
import useValidationForm from '../../hooks/useValidationForm.hook'

import SActions from './Entity.styles'

function EntityForm({ setActiveStep }) {
    const { stateRequestUser, actionsRequestUser } = useRequestUserStore()
    const INITIAL_STATE = {
        entityTaxIdentificationNumber: {
            value: stateRequestUser.entity.entityTaxIdentificationNumber || '',
            validation: ['validateNotEmptyUtil', 'validateNipcUtil'],
            mask: 'maskNIFUtil',
        },
        entityName: {
            value: stateRequestUser.entity.entityName || '',
            validation: ['validateNotEmptyUtil', 'validateEntityNameUtil'],
        },
        organizationalUnitName: {
            value: stateRequestUser.entity.organizationalUnitName || '',
            validation: ['validateOrganicUnitNameUtil'],
        },
        entityAddress: { value: stateRequestUser.entity.entityAddress || '', validation: ['validateNotEmptyUtil'] },
        entityLocation: {
            value: stateRequestUser.entity.entityLocation || '',
            validation: ['validateNotEmptyUtil', 'validateLocationZoneNameUtil'],
        },
        entityPostalCode: {
            value: stateRequestUser.entity.entityPostalCode || '',
            validation: ['validateNotEmptyUtil', 'validatePositiveNumberUtil', 'validatePostalCodeUtil'],
            mask: 'maskPostalCodeUtil',
        },
    }

    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE)

    function getPayload() {
        return {
            entityTaxIdentificationNumber: values.entityTaxIdentificationNumber.value.replace(/\s/g, ''),
            entityName: values.entityName.value,
            organizationalUnitName: values.organizationalUnitName.value,
            entityAddress: values.entityAddress.value,
            entityLocation: values.entityLocation.value,
            entityPostalCode: values.entityPostalCode.value,
        }
    }
    function handleNext() {
        actionsRequestUser.persistEntityOnState(getPayload())
        setActiveStep(2)
    }
    function handleBack() {
        actionsRequestUser.persistEntityOnState(getPayload())
        setActiveStep(0)
    }
    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputMask
                        mask={maskNIFUtil}
                        value={values.entityTaxIdentificationNumber.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {() => (
                            <TextField
                                id='entityTaxIdentificationNumber'
                                name='entityTaxIdentificationNumber'
                                label='NIPC'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={
                                    touched.entityTaxIdentificationNumber
                                        ? Boolean(errors.entityTaxIdentificationNumber)
                                        : false
                                }
                                helperText={
                                    touched.entityTaxIdentificationNumber ? errors.entityTaxIdentificationNumber : ''
                                }
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id='entityName'
                        name='entityName'
                        label='Nome da Entidade'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.entityName.value}
                        error={touched.entityName ? Boolean(errors.entityName) : false}
                        helperText={touched.entityName ? errors.entityName : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='organizationalUnitName'
                        name='organizationalUnitName'
                        label='Unidade Orgânica'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.organizationalUnitName.value}
                        error={touched.organizationalUnitName ? Boolean(errors.organizationalUnitName) : false}
                        helperText={touched.organizationalUnitName ? errors.organizationalUnitName : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='entityAddress'
                        name='entityAddress'
                        label='Morada'
                        type='textarea'
                        margin='dense'
                        variant='outlined'
                        value={values.entityAddress.value}
                        error={touched.entityAddress ? Boolean(errors.entityAddress) : false}
                        helperText={touched.entityAddress ? errors.entityAddress : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={5}>
                    <InputMask
                        mask={maskPostalCodeUtil}
                        value={values.entityPostalCode.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {() => (
                            <TextField
                                id='entityPostalCode'
                                name='entityPostalCode'
                                label='Código Postal'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={touched.entityPostalCode ? Boolean(errors.entityPostalCode) : false}
                                helperText={touched.entityPostalCode ? errors.entityPostalCode : ''}
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={7}>
                    <TextField
                        id='entityLocation'
                        name='entityLocation'
                        label='Localidade'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.entityLocation.value}
                        error={touched.entityLocation ? Boolean(errors.entityLocation) : false}
                        helperText={touched.entityLocation ? errors.entityLocation : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SActions>
                <Button variant='contained' color='default' onClick={handleBack} startIcon={<ArrowBack />}>
                    Voltar
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValidForm}
                    endIcon={<ArrowForward />}
                    onClick={handleNext}
                >
                    A Seguir
                </Button>
            </SActions>
        </form>
    )
}

EntityForm.propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}

export default EntityForm
