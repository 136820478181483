export const INITIAL_STATE_HASH = {
    password: { value: '', validation: ['validateNotEmptyUtil', 'validatePasswordRequirementsUtil'] },
    repeatPassword: { value: '', validation: ['validateNotEmptyUtil', 'validateStringEqualUtil'] },
}

export const INITIAL_STATE_NO_HASH = {
    nif: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'],
        mask: 'maskNIFUtil',
    },
    personEmail: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
    },
    yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
}
