import React from 'react'
import PropTypes from 'prop-types'

import { SBody } from './Modal.style'

const propTypes = {
    children: PropTypes.node,
}

const defaultProps = {
    children: [],
}

const ModalBodyComponent = ({ children }) => {
    let test = ''

    if (Array.isArray(children)) {
        test = children.map(child => {
            return (
                <div key={child}>
                    {child}
                    {'\n'}
                </div>
            )
        })

        return <SBody>{test}</SBody>
    }

    return <SBody>{children}</SBody>
}

ModalBodyComponent.propTypes = propTypes
ModalBodyComponent.defaultProps = defaultProps

export default ModalBodyComponent
