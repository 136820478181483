import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import MessageComponent from '../../components/templates/Message/Message.component'
import RecoveryPasswordHashForm from './forms/RecoveryPasswordHash.form'
import RecoveryPasswordNoHashForm from './forms/RecoveryPasswordNoHash.form'

import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import { SLogo, SPaper } from './RecoveryPassword.styles'

function RecoveryPassword() {
    const location = useLocation()
    const hash = new URLSearchParams(location.search).get('hash')
    const action = new URLSearchParams(location.search).get('action')

    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)

    return (
        <>
            {!success ? (
                <SPaper>
                    <SLogo>
                        <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                    </SLogo>
                    {action === 'recover' && <h3>Recuperação de Palavra-passe</h3>}
                    {action === 'create' && <h3>Criação de Palavra-passe</h3>}
                    {hash ? (
                        <RecoveryPasswordHashForm />
                    ) : (
                        <RecoveryPasswordNoHashForm setSuccess={setSuccess} setMessage={setMessage} message={message} />
                    )}
                </SPaper>
            ) : (
                <MessageComponent
                    type='success'
                    link='/'
                    successHeaderMessage='Recuperação solicitada, verifique seu email!'
                    message={message}
                    textBack='Login'
                />
            )}
        </>
    )
}

export default RecoveryPassword
