import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const SLogo = styled.div`
    margin-bottom: 10px;
    width: 55px;
    height: auto;
    @media (max-width: 1024px) {
        width: 50px;
        margin-bottom: 16px;
    }
    img {
        max-width: 100%;
    }
`
export const SPaper = styled.div`
    max-width: 500px;
`
export const SActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 10px;
    button {
        margin-left: 15px;
    }
`
export const SLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
    .action {
        position: absolute;
        left: 0;
        font-size: 12px;
    }
`
export const SCircular = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-top: 50px;
        font-size: 20px;
    }
`

export const SReturn = styled.div`
    margin-top: 40px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
    span {
        margin-left: 20px;
    }
`
