import { validateNameLength, validateNotEmpty, validateAlphaLatin } from './validate.util'

export function validatePseudonymLength(values) {
    const { givenName, surname } = values
    const totalLength = givenName.length + surname.length

    return totalLength && totalLength > 64 ? `${totalLength}/64 (Nome Próprio e Apelido devem ter 64 caracteres)` : null
}

export function validateNameOnCardWhenPseudonymChecked(values) {
    const { nameOnCard, givenName, surname } = values
    const givenAndSurname = `${givenName} ${surname}`

    let wordsMatched = []
    let anyWordWhichDontBelongToPersonName
    if (nameOnCard && givenAndSurname) {
        const splittedGivenAndSurname = givenAndSurname
            .toLowerCase()
            .split(/\s/g)
            .filter(Boolean)

        const splittedNameOnCard = nameOnCard
            .toLowerCase()
            .split(/\s/g)
            .filter(Boolean)

        anyWordWhichDontBelongToPersonName = splittedNameOnCard.find(word => !splittedGivenAndSurname.includes(word))
        wordsMatched = [...new Set(splittedNameOnCard.filter(word => splittedGivenAndSurname.includes(word)))]
    }

    if (nameOnCard && nameOnCard.length > 25) return validateNameLength(nameOnCard, 25)
    if (anyWordWhichDontBelongToPersonName !== undefined || wordsMatched.length < 2)
        return (
            validateNotEmpty(nameOnCard) ||
            validateAlphaLatin(nameOnCard) ||
            `Deve conter 2 ou mais vocábulos existentes em Nome Próprio + Apelido.`
        )
}

export function validateNameOnCardWhenPseudonymUnchecked(values) {
    const { personName, value } = values

    let wordsMatched = []
    let anyWordWhichDontBelongToPersonName
    if (personName && value) {
        const splittedNameOnCard = value
            .toLowerCase()
            .split(/\s/g)
            .filter(Boolean)

        const splittedPersonName = personName
            .toLowerCase()
            .split(/\s/g)
            .filter(Boolean)

        anyWordWhichDontBelongToPersonName = splittedNameOnCard.find(word => !splittedPersonName.includes(word))
        wordsMatched = [...new Set(splittedNameOnCard.filter(word => splittedPersonName.includes(word)))]
    }

    if (value && value.length > 25) return validateNameLength(value, 25)
    if (anyWordWhichDontBelongToPersonName !== undefined || wordsMatched.length < 2)
        return (
            validateNotEmpty(value) ||
            validateAlphaLatin(value) ||
            `Deve conter 2 ou mais vocábulos existentes em Nome Completo.`
        )
}

export function validateGivenAndSurnameWhenPseudonymUnchecked(values) {
    const { personName, givenName, surname } = values
    const givenAndSurname = `${givenName} ${surname}`

    let wordsMatched = []
    let anyWordWhichDontBelongToPersonName
    if (personName && givenAndSurname) {
        const splittedGivenAndSurname = givenAndSurname
            .toLowerCase()
            .split(/\s/g)
            .filter(Boolean)

        const splittedPersonName = personName
            .toLowerCase()
            .split(/\s/g)
            .filter(Boolean)

        anyWordWhichDontBelongToPersonName = splittedGivenAndSurname.find(word => !splittedPersonName.includes(word))
        wordsMatched = [...new Set(splittedGivenAndSurname.filter(word => splittedPersonName.includes(word)))]
    }

    if (anyWordWhichDontBelongToPersonName !== undefined || wordsMatched.length < 2) {
        return (
            validateNotEmpty(givenAndSurname) ||
            validateAlphaLatin(givenAndSurname) ||
            `Nome(s) Próprio(s) e Apelido(s) devem conter 2 ou mais vocábulos existentes em Nome Completo.`
        )
    }
}
