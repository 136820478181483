import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import NewOrderElectronicSealConfigForm from '../../forms/NewOrderElectronicSealConfig/NewOrderElectronicSealConfig.form'

import { useOrdersStore } from '../../store/Orders/Orders.store'

const propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    params: {},
}

function ConfigElectronicSealStep(props) {
    const { match } = props
    const { params } = match
    // ORDERS STORE
    const { stateOrders } = useOrdersStore()
    // Search SEALs arrays on state by seal id
    const [electronicSeals] = useState(stateOrders.services.electronicSeals.find(item => item.id === params.id))

    return (
        <Fade in>
            <ContentComponent>
                <PageTitleComponent title='Configuração do Selo Eletrónico Qualificado' />
                <NewOrderElectronicSealConfigForm activeElectronicSeal={electronicSeals} stateOrders={stateOrders} />
            </ContentComponent>
        </Fade>
    )
}

ConfigElectronicSealStep.propTypes = propTypes
ConfigElectronicSealStep.defaultProps = defaultProps

export default ConfigElectronicSealStep
