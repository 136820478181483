import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import { LoginReducer, initialState } from './Login.reducer'
import useActions from './Login.actions'
import applyMiddleware from './Login.middleware'
import { useAlertStore } from '../Alert/Alert.store'

const propTypes = {
    children: PropTypes.objectOf(PropTypes.any).isRequired,
}

const LoginContext = createContext(null)

export function LoginProvider({ children }) {
    const { actionsAlert } = useAlertStore()
    const [stateLogin, dispatch] = useReducer(LoginReducer, initialState)
    const actionsLogin = useActions(stateLogin, applyMiddleware(dispatch, actionsAlert))
    const value = { stateLogin, actionsLogin }

    return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
}
LoginProvider.propTypes = propTypes

export const useLoginStore = () => useContext(LoginContext)
