export const INITIAL_STATE = {
    tabValue: 0,
    currentPhoneNumber: '',
    notifications: {
        emailNotification: false,
        smsNotification: false,
        newsletterNotification: false,
    },
    authenticationChoice: '',
    authenticationSelectedEmail: {},
    templates: [],
    proposals: [],
    refreshingProposals: false,
}

export const Types = {
    SET_TAB_VALUE: 'SET_TAB_VALUE',
    SET_INITIAL_VALUES: 'SET_INITIAL_VALUES',
    SET_CURRENT_PHONE_NUMBER: 'SET_CURRENT_PHONE_NUMBER',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    SET_AUTHENTICATION_CHOICE: 'SET_AUTHENTICATION_CHOICE',
    SET_SET_AUTHENTICATION_SELECTED_EMAIL: 'SET_SET_AUTHENTICATION_SELECTED_EMAIL',
    SET_PRE_PROTOCOL_TEMPLATES_LIST: 'SET_PRE_PROTOCOL_TEMPLATES_LIST',
    SET_PRE_PROTOCOL_PROPOSALS_LIST: 'SET_PRE_PROTOCOL_PROPOSALS_LIST',
    SET_REFRESHING_PROPOSALS: 'SET_REFRESHING_PROPOSALS',
}

export function profilePageReducer(state, action) {
    switch (action.type) {
        case Types.SET_TAB_VALUE:
            return { ...state, tabValue: action.payload }
        case Types.SET_INITIAL_VALUES:
            return { ...state, ...action.payload }
        case Types.SET_CURRENT_PHONE_NUMBER:
            return { ...state, currentPhoneNumber: action.payload }
        case Types.SET_NOTIFICATIONS:
            return { ...state, notifications: action.payload }
        case Types.SET_AUTHENTICATION_CHOICE:
            return { ...state, authenticationChoice: action.payload }
        case Types.SET_SET_AUTHENTICATION_SELECTED_EMAIL:
            return { ...state, authenticationSelectedEmail: action.payload }
        case Types.SET_PRE_PROTOCOL_TEMPLATES_LIST:
            return { ...state, templates: action.payload }
        case Types.SET_PRE_PROTOCOL_PROPOSALS_LIST:
            return { ...state, proposals: action.payload }
        case Types.SET_REFRESHING_PROPOSALS:
            return { ...state, refreshingProposals: action.payload }
        default:
            return state
    }
}
