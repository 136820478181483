import React, { forwardRef } from 'react'
import { SaveAlt, Clear, Search, ChevronRight, ChevronLeft, FirstPage, LastPage } from '@material-ui/icons'
import pt from 'date-fns/locale/pt'

export const options = {
    search: true,
    debounceInterval: 1000,
    exportAllData: true,
    exportButton: false,
    draggable: false,
    sorting: true,
    showTitle: false,
    actionsColumnIndex: -1,
    emptyRowsWhenPaging: false,
    initialPage: 0,
    pageSizeOptions: [10, 15, 100],
    pageSize: 10,
    padding: 'dense',
    headerStyle: {
        textTransform: 'uppercase',
        padding: '4px',
        fontSize: 12,
        letterSpacing: 1,
        lineHeight: 1.5,
    },
    cellStyle: {
        padding: '8px',
    },
    tableIcons: {
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        Filter: forwardRef(() => <div />),
    },
}

export const localization = {
    body: {
        dateTimePickerLocalization: pt,
        emptyDataSourceMessage: 'Nenhum conteúdo registado',
        addTooltip: 'Adicionar Item',
        deleteTooltip: 'Remover Linha',
        editTooltip: 'Editar',
        filterRow: {
            filterTooltip: 'Filtrar',
        },
        editRow: {
            deleteText: 'Tem certeza de que deseja excluir esta linha?',
            saveTooltip: 'Salvar',
            cancelTooltip: 'Cancelar',
        },
    },
    grouping: {
        placeholder: '',
    },
    header: {
        actions: 'Ações',
    },
    pagination: {
        labelDisplayedRows: '{from} - {to} de {count}',
        labelRowsSelect: 'registros',
        labelRowsPerPage: 'Colunas por página',
        firstAriaLabel: 'Página anterior',
        firstTooltip: 'Ir para página anterior',
        previousAriaLabel: 'Ir para próxima página',
        previousTooltip: 'Ir para próxima página',
        nextAriaLabel: 'Próxima',
        nextTooltip: 'Próxima',
        lastAriaLabel: 'Última',
        lastTooltip: 'Última',
    },
    toolbar: {
        addRemoveColumns: 'Adicionar Colunas',
        nRowsSelected: '{0} colunas selecionadas',
        showColumnsTitle: '',
        showColumnsAriaLabel: '',
        exportTitle: 'Exportar',
        exportAriaLabel: 'Exportar',
        exportName: 'Exportar para .CSV',
        searchTooltip: 'Pesquisar',
        searchPlaceholder: 'Pesquisar',
    },
}
