export default function downloadJsonFile(json) {
    const photoMetadata = 'data:image/png;base64,'
    const photo = json.photo.includes(photoMetadata) ? json.photo : photoMetadata + json.photo
    const downloadJson = {
        PERSON_NAME: json.personName,
        NAME_ON_CARD: json.nameOnCard,
        DOCUMENT_TYPE: json.documentType,
        DOCUMENT_NUMBER: json.documentNumber,
        DOCUMENT_VALIDITY: json.documentValidity,
        TAX_IDENTIFICATION_NUMBER: json.personTaxIndentificationNumber,
        PHONE_NUMBER: json.contactInformation.phoneNumber,
        PERSON_PHONE_NUMBER: json.contactInformation.personPhoneNumber,
        AUTHENTICATION_TYPE: json.authenticationType,
    }

    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(downloadJson))}`
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', 'PROFILE_DATA.json')
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.setAttribute('href', photo)
    downloadAnchorNode.setAttribute('download', 'PROFILE_PHOTO.png')
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
}
