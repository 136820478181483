import React from 'react'
import PropTypes from 'prop-types'
import { MTableToolbar } from 'material-table'
import { transformTimestampToDate } from '../../../utils/date.util'
import DataTableComponent from '../../../components/templates/DataTable/DataTable.component'
import { ItemChip, SToolbar } from '../Order.styles'

const wildcardColumns = [
    {
        field: 'serviceType',
        title: 'SERVIÇO',
    },
    {
        field: 'validity',
        title: 'VALIDADE (ANOS)',
    },
    {
        field: 'price',
        title: 'VALOR',
        render: ({ price }) => (price ? `${price.toFixed(2)} €` : ''),
    },
    {
        field: 'domain',
        title: 'DOMÍNIO',
    },
    {
        field: 'step',
        title: 'ETAPA',
        render: ({ step }) => step.label,
    },
    {
        field: 'statusUpdatedDate',
        title: 'DATA DO ESTADO',
        render: ({ status }) => (status.updatedDate ? transformTimestampToDate(status.updatedDate) : ''),
    },
    {
        field: 'statusLabel',
        title: 'ESTADO',
        headerStyle: {
            textAlign: 'center',
        },
        render: rowData => <ItemChip typecolor='orderItens' label={rowData.status.label} type={rowData.status.id} />,
    },
]

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
    data: null,
}

function WildcardTable(props) {
    const { data } = props
    return (
        <DataTableComponent
            title='Wildcard'
            options={{ showTitle: true }}
            columns={wildcardColumns}
            data={data}
            components={{
                Toolbar: props => (
                    <SToolbar>
                        <MTableToolbar {...props} />
                    </SToolbar>
                ),
            }}
        />
    )
}

WildcardTable.propTypes = propTypes
WildcardTable.defaultProps = defaultProps

export default WildcardTable
