import React from 'react'
import PropTypes from 'prop-types'

import { SBody } from './Paper.style'

const propTypes = {
    children: PropTypes.node,
}

const defaultProps = {
    children: [],
}

const PaperBodyComponent = ({ children }) => <SBody>{children}</SBody>

PaperBodyComponent.propTypes = propTypes
PaperBodyComponent.defaultProps = defaultProps

export default PaperBodyComponent
