import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Chip from '@material-ui/core/Chip'

export const STabs = styled(Tabs)`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.palette.grey.light};
    align-items: center;
`
export const ItemChip = styled(Chip)`
    && {
        font-size: 12px;
        color: ${props => props.theme.palette.white};
        font-weight: 600;
        width: 100px;
        background-color: ${props => {
            switch (props.type) {
                case 1:
                    return props.theme.palette.success.main
                case 2:
                    return props.theme.palette.error.dark
                case 3:
                    return props.theme.palette.warning.main
                case 4:
                    return props.theme.palette.grey.main
                default:
                    return props.theme.palette.grey.light
            }
        }};
    }
`
