/* eslint-disable array-callback-return */
import uuid from 'uuid'
import ORDERS_TYPES from './Orders.types'
import apiService from '../../services/api.service'

// * Defines the applyMiddleware reducer in the end of the file,
// * because it needs to come after the functions declaration
function setNewService(dispatch, payload) {
    const { state, name } = payload

    let service = []

    const manyTypes = {
        ...state.services,
    }

    if (typeof name === 'object') {
        name.map(item => {
            let type = ''
            // * Translate the name to the types
            if (item.basicType === 'TOKEN') {
                type = 'cards'
            }
            if (item.basicType === 'SSL') {
                type = 'ssls'
            }
            if (item.basicType === 'WILDCARD') {
                type = 'wildcards'
            }
            if (item.basicType === 'ELECTRONIC_SEAL') {
                type = 'electronicSeals'
            }
            if (item.basicType === 'TIMESTAMP') {
                type = 'timestamps'
            }

            if (!manyTypes[type]) {
                const newServiceConfig = [
                    {
                        id: uuid.v4(),
                        isValid: false,
                        config: item.configs[0],
                        stateId: item.id,
                    },
                ]

                manyTypes[type] = newServiceConfig
            } else {
                const newServiceConfig = {
                    id: uuid.v4(),
                    isValid: false,
                    config: item.configs[0],
                    stateId: item.id,
                }
                manyTypes[type].push(newServiceConfig)
            }
        })

        dispatch({ type: ORDERS_TYPES.SERVICE_TYPE.POST.SUCCESS, payload: { ...manyTypes } })
    } else {
        let type = ''

        // * Translate the name to the types
        if (name === 'TOKEN') {
            type = 'cards'
        }
        if (name === 'SSL') {
            type = 'ssls'
        }
        if (name === 'WILDCARD') {
            type = 'wildcards'
        }
        if (name === 'ELECTRONIC_SEAL') {
            type = 'electronicSeals'
        }
        if (name === 'TIMESTAMP') {
            type = 'timestamps'
        }

        if (state.services[type]) {
            service = state.services[type]
        }
        const newServiceConfig = {
            id: uuid.v4(),
            isValid: false,
        }

        service.push(newServiceConfig)
        const newServices = {
            ...state.services,
            [type]: service,
        }

        dispatch({ type: ORDERS_TYPES.SERVICE_TYPE.POST.SUCCESS, payload: { ...newServices } })
    }
}

async function asyncGetServices(dispatch, actionsAlert) {
    try {
        const response = await apiService.get(`services/client`)
        const payload = response.data.payload.map(service => ({ ...service, isInfoOpen: false }))

        dispatch({ type: ORDERS_TYPES.SERVICES.GET.SUCCESS, payload })
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

function setServiceByType(dispatch, payload) {
    const { state, item, type, history } = payload

    const newPayload = state.services[type].map(service => {
        if (service.id === item.id) {
            return item
        }
        return service
    })

    dispatch({ type: ORDERS_TYPES.SERVICE.POST.SUCCESS, payload: { type: type, item: newPayload } })
    history.push('/new-order')
}

function deleteServiceById(dispatch, payload) {
    const { state, id, type } = payload
    let service = []
    if (state.services[type]) {
        service = state.services[type]
    } else {
        // TODO: Dispatch ERROR because there is nothing to delete
    }

    const indexToRemove = service.map(item => item.id).indexOf(id)
    if (indexToRemove >= 0) {
        service.splice(indexToRemove, 1)
    }
    const newServices = {
        ...state.services,
        [type]: service,
    }

    dispatch({ type: ORDERS_TYPES.SERVICE.PUT.SUCCESS, payload: { ...newServices } })
}

async function asyncGetOrgUnits(dispatch, actionsAlert) {
    try {
        // Show loading
        dispatch({ type: ORDERS_TYPES.SHOW_LOADING.SUCCESS })

        // API(GET) - Get organic units by entity id
        const response = await apiService.get(`scope/entity/orgunits`)
        const { payload } = response.data

        // Hide loading
        dispatch({ type: ORDERS_TYPES.HIDE_LOADING.SUCCESS })

        dispatch({ type: ORDERS_TYPES.ORG_UNITS.GET.SUCCESS, payload })
    } catch (err) {
        // Hide loading
        dispatch({ type: ORDERS_TYPES.HIDE_LOADING.SUCCESS })

        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

function updateCart(dispatch, payload) {
    const newServicesTypes = Object.keys(payload.state.services).filter(service => service !== 'types')
    const services = {}
    newServicesTypes.forEach(function(type) {
        services[type] = payload.state.services[type]
    })

    let newCart = {}

    if (Object.keys(services).length > 0) {
        Object.keys(services).forEach(type => {
            services[type].forEach(service => {
                if (service && !service.config) {
                    if (Object.keys(service).length > 2) {
                        const serviceId = service.duration.id
                        const serviceType = service.type
                        const serviceDuration = service.duration.duration
                        const servicePrice = service.price
                        const typeTotalPrice = newCart[type] && newCart[type].totalPrice ? newCart[type].totalPrice : 0
                        const serviceQuantity =
                            newCart[type] && newCart[type][serviceId] && newCart[type][serviceId].quantity > 0
                                ? newCart[type][serviceId].quantity
                                : 0
                        newCart = {
                            ...newCart,
                            [type]: {
                                ...newCart[type],
                                [serviceId]: {
                                    type: serviceType,
                                    duration: serviceDuration,
                                    quantity: serviceQuantity + 1,
                                    price: servicePrice,
                                },
                                totalPrice: typeTotalPrice + servicePrice,
                            },
                            totalPrice: newCart.totalPrice ? newCart.totalPrice + servicePrice : servicePrice,
                        }
                    }
                }
            })
        })
    }

    dispatch({ type: ORDERS_TYPES.CART.POST.SUCCESS, payload: { newCart } })
}

function updateCartPrice(dispatch, payload) {
    const { state } = payload
    let totalPrice = 0
    if (state.cart.cards && state.cart.cards.totalPrice) {
        totalPrice += state.cart.cards.totalPrice
    }
    if (state.cart.ssls && state.cart.ssls.totalPrice) {
        totalPrice += state.cart.ssls.totalPrice
    }
    dispatch({ type: ORDERS_TYPES.CART.GET.SUCCESS, payload: totalPrice })
}

async function asyncGetEntity(dispatch, actionsAlert) {
    // const { id } = payloadscope
    try {
        const response = await apiService.get(`scope/entity`)

        const data = !response.data.payload ? { ...response.data.payload, name: '' } : response.data.payload

        dispatch({ type: ORDERS_TYPES.ENTITY.GET.SUCCESS, payload: data })
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

async function asyncSetOrders(dispatch, payload, actionsAlert) {
    dispatch({ type: ORDERS_TYPES.SHOW_LOADING.SUCCESS })
    const { orders } = payload
    try {
        await apiService.post(`orders`, orders)
        // TODO: Fix payload with request response
        dispatch({
            type: ORDERS_TYPES.ORDERS.POST.SUCCESS,
            payload: { message: 'Pedido realizado com sucesso.' },
        })
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }

        // TODO: Fix payload with request response
        dispatch({
            type: ORDERS_TYPES.ORDERS.POST.ERROR,
            payload: { message: 'Erro ao realizar o pedido.' },
        })
    } finally {
        dispatch({ type: ORDERS_TYPES.HIDE_LOADING.SUCCESS })
    }
}

//* applyMiddleware Receives the action and triggers the correspondent function
const applyMiddleware = (dispatch, actionsAlert) => action => {
    switch (action.type) {
        case ORDERS_TYPES.SERVICE_TYPE.POST.REQUEST:
            return setNewService(dispatch, action.payload)
        case ORDERS_TYPES.SERVICE.PUT.REQUEST:
            return deleteServiceById(dispatch, action.payload)
        case ORDERS_TYPES.SERVICES.GET.REQUEST:
            return asyncGetServices(dispatch, actionsAlert)
        case ORDERS_TYPES.SERVICE.POST.REQUEST:
            return setServiceByType(dispatch, action.payload)
        case ORDERS_TYPES.ORG_UNITS.GET.REQUEST:
            return asyncGetOrgUnits(dispatch, actionsAlert)
        case ORDERS_TYPES.CART.POST.REQUEST:
            return updateCart(dispatch, action.payload)
        case ORDERS_TYPES.CART.GET.REQUEST:
            return updateCartPrice(dispatch, action.payload)
        case ORDERS_TYPES.ENTITY.GET.REQUEST:
            return asyncGetEntity(dispatch, actionsAlert)
        case ORDERS_TYPES.ORDERS.POST.REQUEST:
            return asyncSetOrders(dispatch, action.payload, actionsAlert)
        default:
            return dispatch(action)
    }
}
export default applyMiddleware
