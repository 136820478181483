import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { SFeedback, SBoxDone, SIconItem } from './styles'
import IconCheck from '../../../assets/icons/icon-check.svg'
import IconError from '../../../assets/icons/icon-error.svg'

const FeedbackTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
}

const propTypes = {
    type: PropTypes.oneOf(Object.values(FeedbackTypes)).isRequired,
}

function FeedBack(props) {
    const { type } = props

    return (
        <SFeedback>
            <SBoxDone>
                {type === FeedbackTypes.SUCCESS ? (
                    <Fragment>
                        <h2>Muito Obrigado!</h2>
                        <SIconItem src={IconCheck} className='icon-check' />
                    </Fragment>
                ) : (
                    <Fragment>
                        <h2>Desculpe-nos!</h2>
                        <SIconItem src={IconError} className='icon-error' />
                    </Fragment>
                )}
            </SBoxDone>
        </SFeedback>
    )
}

FeedBack.propTypes = propTypes

export default FeedBack
