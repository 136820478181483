/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'

import { useLoginStore } from './store/Login/Login.store'
import { useApiStateStore } from './store/ApiStatusServices/ApiStatusServices.store'

import Contact from './pages/Contact/Contact.page'
import Confirmation from './pages/Confirmation/Confirmation.page'
import ConfirmationKey from './pages/ConfirmationKey/ConfirmationKey.page'
import Faq from './pages/Faq/Faq.page'
// import Financial from './pages/Financial/Financial'
// import Invoices from './pages/Invoices/Invoices'
import Login from './pages/Login/Login.page'
import Order from './pages/Order/Order.page'
import Invoices from './pages/Invoices/Invoices.page'
import Certificates from './pages/Certificates/Certificates.page'
import NewOrder from './pages/NewOrder/NewOrder.page'
import ConfigCardStep from './pages/NewOrder/ConfigCard.step'
import ConfigSSLStep from './pages/NewOrder/ConfigSSL.step'
import ConfigElectronicSealStep from './pages/NewOrder/ConfigElectronicSeal.step'
import ConfigWildcardStep from './pages/NewOrder/ConfigWildcard.step'
import ConfigTimestampStep from './pages/NewOrder/ConfigTimestamp.step'
import { OrdersProvider } from './store/Orders/Orders.store'
import { ProfileProvider } from './store/Profile/Profile.store'
import { RequestUserProvider } from './store/RequestUser/RequestUser.store'
import RecoveryPassword from './pages/RecoveryPassword/RecoveryPassword.page'
import RequestUser from './pages/RequestUser/RequestUser.page'
import Orders from './pages/Orders/Orders.page'
import ProfilePage from './pages/Profile/Profile.page'
import Saml from './pages/Saml/Saml.page'
import ProtocolDashboard from './pages/ProtocolDashboard/ProtocolDashboard.page'
import Timestamps from './pages/Timestamps/Timestamps.page'
import Timestamp from './pages/Timestamp/Timestamp.page'
import TimestampUser from './pages/TimestampUser/TimestampUser.page'
import TimestampPassword from './pages/TimestampPassword/TimestampPassword.page'
import TimestampConsumer from './pages/TimestampConsumer/TimestampConsumer.page'
import PinPukAuth from './pages/PinPuk/PinPukAuth.page'
import PinPukSelection from './pages/PinPuk/PinPukSelection.page'
import SmartcardAuth from './pages/Smartcard/SmartcardAuth.page'
import SmartcardActivation from './pages/Smartcard/SmartcardActivation.page'

import SearchCertificate from './pages/SearchCertificate/SearchCertificate.page'

import PageNotFound from './pages/PageNotFound/PageNotFound.page'
// import ServiceUnavailable from './pages/ServiceUnavailable/ServiceUnavailable.page'

const PrivateRoutePropTypes = {
    component: PropTypes.instanceOf(Function).isRequired,
    location: PropTypes.objectOf(PropTypes.any),
    state: PropTypes.objectOf(PropTypes.any).isRequired,
}
const PrivateRouteDefaultProps = {
    location: {},
}
const LoginRoutePropTypes = {
    component: PropTypes.instanceOf(Function).isRequired,
    location: PropTypes.objectOf(PropTypes.any),
    state: PropTypes.objectOf(PropTypes.any).isRequired,
    path: PropTypes.string.isRequired,
}
const LoginRouteDefaultProps = {
    location: {},
}
const PrivateRoute = ({ component: Component, state, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (state.isAuthenticated) {
                // *If it's the newOrder page, uses the NewOrder Provider
                if (props.location.pathname.startsWith('/new-order')) {
                    return (
                        <OrdersProvider>
                            <Component {...props} />
                        </OrdersProvider>
                    )
                }

                // *If it's the profile page, uses the Profile Provider
                if (props.location.pathname.startsWith('/profile')) {
                    return (
                        <ProfileProvider>
                            <Component {...props} />
                        </ProfileProvider>
                    )
                }

                return <Component {...props} />
            }
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }}
    />
)

const LoginRoute = ({ component: Component, state, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!state.isAuthenticated) {
                // *If it's the request page, uses the Request User Provider
                return props.location.pathname === '/request' ? (
                    <RequestUserProvider>
                        <Component {...props} />
                    </RequestUserProvider>
                ) : (
                    <Component {...props} />
                )
            }
            return <Redirect to={{ pathname: 'orders', state: { from: props.location } }} />
        }}
    />
)

const Routes = () => {
    const { stateLogin } = useLoginStore()
    const { actionsApiStatusService } = useApiStateStore()
    const history = useHistory()

    useEffect(() => {
        // This use effect observes any navigation change
        if (history.location.pathname) {
            // Reset api status service state
            actionsApiStatusService.resetApiStatusService()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname])

    return (
        <Fragment>
            <Suspense fallback={<div>A CARREGAR...</div>}>
                <Switch>
                    {/* <LoginRoute state={stateLogin} exact path='/' component={ServiceUnavailable} /> */}
                    <LoginRoute state={stateLogin} exact path='/' component={Login} />
                    <LoginRoute state={stateLogin} path='/choose-password' component={RecoveryPassword} />
                    <LoginRoute state={stateLogin} path='/confirmation' component={Confirmation} />
                    <LoginRoute state={stateLogin} path='/confirmation-profile-change-email' component={Confirmation} />
                    <LoginRoute state={stateLogin} path='/confirmation-key' component={ConfirmationKey} />
                    <PrivateRoute state={stateLogin} path='/contact' component={Contact} />
                    <PrivateRoute state={stateLogin} path='/orders' exact component={Orders} />
                    <PrivateRoute state={stateLogin} path='/timestamps' exact component={Timestamps} />
                    <LoginRoute state={stateLogin} path='/timestamp/main-user' component={TimestampUser} />
                    <LoginRoute state={stateLogin} path='/timestamp/recoverpass' component={TimestampPassword} />
                    <LoginRoute state={stateLogin} path='/timestamp/consumer-user' component={TimestampConsumer} />
                    <LoginRoute state={stateLogin} path='/choose-pinpuk' component={PinPukAuth} />
                    <LoginRoute state={stateLogin} path='/choose-pinpuk-selection' component={PinPukSelection} />
                    <PrivateRoute state={stateLogin} path='/faq' component={Faq} />
                    <PrivateRoute state={stateLogin} path='/invoices' component={Invoices} />
                    <PrivateRoute state={stateLogin} path='/certificates' component={Certificates} />
                    <PrivateRoute state={stateLogin} path='/timestamp/:id' component={Timestamp} />
                    <PrivateRoute state={stateLogin} path='/new-order' exact component={NewOrder} />
                    <PrivateRoute state={stateLogin} path='/order/:id' component={Order} />
                    <PrivateRoute state={stateLogin} path='/new-order/card-config/:id' component={ConfigCardStep} />
                    <PrivateRoute state={stateLogin} path='/new-order/ssl-config/:id' component={ConfigSSLStep} />
                    <LoginRoute state={stateLogin} path='/search-certificate' component={SearchCertificate} />
                    <PrivateRoute
                        state={stateLogin}
                        path='/new-order/electronic-seal-config/:id'
                        component={ConfigElectronicSealStep}
                    />
                    <PrivateRoute
                        state={stateLogin}
                        path='/new-order/wildcard-config/:id'
                        component={ConfigWildcardStep}
                    />
                    <PrivateRoute
                        state={stateLogin}
                        path='/new-order/timestamp-config/:id'
                        component={ConfigTimestampStep}
                    />
                    <LoginRoute state={stateLogin} path='/smartcard/authentication' component={SmartcardAuth} />
                    <LoginRoute state={stateLogin} path='/smartcard/activation' component={SmartcardActivation} />
                    <LoginRoute state={stateLogin} path='/request' component={RequestUser} />
                    <LoginRoute state={stateLogin} path='/recovery' component={RecoveryPassword} />
                    <PrivateRoute state={stateLogin} path='/profile' component={ProfilePage} />
                    <LoginRoute state={stateLogin} path='/saml' component={Saml} />
                    <PrivateRoute state={stateLogin} path='/protocol-dashboard' component={ProtocolDashboard} />
                    <PrivateRoute state={stateLogin} component={PageNotFound} />
                </Switch>
            </Suspense>
        </Fragment>
    )
}

PrivateRoute.propTypes = PrivateRoutePropTypes
PrivateRoute.defaultProps = PrivateRouteDefaultProps
LoginRoute.propTypes = LoginRoutePropTypes
LoginRoute.defaultProps = LoginRouteDefaultProps

export default Routes
