/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import { transformTimestampToSimpleDateUtil } from '../../../utils'
import { transformTimestampToDate } from '../../../utils/date.util'

import AuthService from '../../../services/auth.service'

import ModalFilesDownload from './ModalFilesDownload'
import ModalUploadFiles from './ModalUploadFiles'

import DownloadFileCSV from '../../../components/templates/DownloadFile/DownloadFileCSV'
import DataTablePagination from '../../../components/templates/DataTablePagination/DataTablePagination.component'
import FilterDatePicker from '../../../components/templates/DataTablePagination/FilterDatePicker.component'
import DownloadFile from '../../../components/templates/DownloadFile/DownloadFile'

import { ItemChip } from '../Certificates.styles'

const auth = new AuthService(sessionStorage)

function WildcardList() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
    const [wildcardId, setwildcardId] = useState('')
    const [checked, setChecked] = useState(false)

    const showCheckBoxOtherEntities = Boolean(
        _.find(auth.getPermissions(), { featureName: 'View_Certs_From_Other_Entities' })
    )

    function handleModalFilesDownload(id) {
        setIsModalOpen(true)
        setwildcardId(id)
    }

    function handleCloseModal() {
        setIsModalOpen(!isModalOpen)
    }

    function handleModalFilesUpload(id) {
        setIsUploadModalOpen(true)
        setwildcardId(id)
    }

    const COLUMNS = [
        {
            field: 'originRequestNumber',
            title: 'nº do pedido',
        },
        {
            field: 'domain',
            title: 'domínio',
        },
        {
            field: 'entityName',
            title: 'entidade',
        },
        {
            field: 'typeLabel',
            title: 'tipo',
        },
        {
            field: 'duration',
            title: 'DURAÇÃO (anos)',
            headerStyle: {
                textTransform: 'none',
            },
        },
        {
            title: 'Download de ficheiro para alterar estado',
            field: 'downloadFiles',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                return (
                    <IconButton color='primary' component='span' onClick={() => handleModalFilesDownload(rowData.id)}>
                        <CloudDownloadIcon />
                    </IconButton>
                )
            },
            filtering: false,
        },
        {
            title: 'Upload de ficheiro para alterar estado',
            field: 'uploadFile',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                padding: '10px',
                textAlign: 'center',
            },
            render: rowData => {
                return (
                    <IconButton color='primary' component='span' onClick={() => handleModalFilesUpload(rowData.id)}>
                        <CloudUploadIcon />
                    </IconButton>
                )
            },
            filtering: false,
        },
        {
            field: 'creationDate',
            title: 'Emissão',
            cellStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ creationDate }) => (creationDate ? transformTimestampToDate(creationDate) : ''),
        },
        {
            field: 'expirationDate',
            title: 'expiração',
            cellStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ expirationDate }) => (expirationDate ? transformTimestampToDate(expirationDate) : ''),
        },
        {
            field: 'updatedDate',
            title: 'data do estado',
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ status }) =>
                status && status.updatedDate ? transformTimestampToSimpleDateUtil(status.updatedDate) : '',
        },
        {
            field: 'status.label',
            title: 'estado',
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            },
            lookup: { Ativo: 'Ativo', Inativo: 'Inativo' },
            customSort: () => {
                return null
            },
            render: rowData =>
                rowData.status ? <ItemChip label={rowData.status.label} type={rowData.status.id} /> : '',
        },
        {
            title: 'DOWNLOAD',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData =>
                rowData.hasFile ? (
                    <DownloadFile endpoint={`/tokensssl/client/${rowData.id}/downloadCertificate`} />
                ) : null,
        },
    ]

    return (
        <>
            {showCheckBoxOtherEntities && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            name='myOrders'
                            color='primary'
                        />
                    }
                    label='Exibir certificados de outras Entidades Participantes do Protocolo'
                />
            )}

            <Grid container justify='flex-end'>
                <DownloadFileCSV
                    endpoint='/tokensssl/wildcard/client'
                    exportCSV={{ isFromAPI: true, filename: 'certificados-wildcard' }}
                />
            </Grid>
            <DataTablePagination
                columns={COLUMNS}
                endPoint='tokensssl/wildcard/client'
                certificateFromOtherEntities={checked}
                showCheckBoxOtherEntities={showCheckBoxOtherEntities}
                options={{
                    search: false,
                    filtering: true,
                    sorting: true,
                    filterCellStyle: {
                        padding: '16px 16px 16px 6px',
                    },
                }}
            />
            {isModalOpen && (
                <ModalFilesDownload
                    open={isModalOpen}
                    orderId={wildcardId}
                    onCloseModal={handleCloseModal}
                    endpoint='tokensssl'
                />
            )}

            {isUploadModalOpen && (
                <ModalUploadFiles
                    open={isUploadModalOpen}
                    onCloseModal={() => setIsUploadModalOpen(false)}
                    endpoint='tokensssl'
                    orderId={wildcardId}
                />
            )}
        </>
    )
}

export default WildcardList
