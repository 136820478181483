import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Grid, TextField, CircularProgress } from '@material-ui/core'
import { SAccessActions, SLink, SLogo, SPaper, STitle, SCircular } from './ConfirmationKey.styles'
import logoECCE from '../../assets/images/commons/logo-ecce.svg'
import { useLoginStore } from '../../store/Login/Login.store'
import { validateNotEmptyUtil } from '../../utils'

import Callout from '../../components/templates/Callout/Callout'

import { CONFIRMATION_KE_INFO_MESSAGE } from '../../constants/dictionary/dictionary'

function ConfirmationKeyPage() {
    const history = useHistory()
    const { stateLogin, actionsLogin } = useLoginStore()
    const [key, setKey] = useState('')
    const [formValid, setFormValid] = useState(true)

    function handleProcess(event) {
        event.preventDefault()
        if (formValid) {
            actionsLogin.doCheck(history, key)
        }
    }

    function handleChange(event) {
        setKey(event.target.value)
        if (event.target.value.length >= 1) {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }

    return (
        <Fragment>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>

                {stateLogin.isLoading ? (
                    <SCircular>
                        <CircularProgress size={30} />
                        <span>A Enviar Solicitação</span>
                    </SCircular>
                ) : (
                    <Fragment>
                        <h3>Código de acesso</h3>

                        <STitle>
                            Por favor, insira a chave de confirmação de 6 dígitos enviados para o seu
                            {stateLogin.user &&
                            stateLogin.user.twoFactor &&
                            stateLogin.user.twoFactor.channel === 'EMAIL'
                                ? ' e-mail '
                                : ' telemóvel '}
                            {stateLogin.user.twoFactor.maskedValue}
                        </STitle>
                        <form
                            onSubmit={e => {
                                e.preventDefault()
                            }}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    id='key'
                                    name='key'
                                    value={key}
                                    label='Código'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    onChange={e => handleChange(e)}
                                    error={!formValid && Boolean(validateNotEmptyUtil(key))}
                                    helperText={!formValid && validateNotEmptyUtil(key)}
                                    fullWidth
                                />
                            </Grid>
                        </form>
                        <SAccessActions>
                            <SLink to='/'>Voltar para o Login</SLink>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                type='submit'
                                onClick={e => handleProcess(e)}
                                disabled={!formValid || key.length < 1}
                            >
                                Prosseguir
                            </Button>
                        </SAccessActions>
                        {stateLogin.user &&
                            stateLogin.user.twoFactor &&
                            stateLogin.user.twoFactor.channel === 'EMAIL' && (
                                <Callout>{CONFIRMATION_KE_INFO_MESSAGE}</Callout>
                            )}
                    </Fragment>
                )}
            </SPaper>
        </Fragment>
    )
}

export default ConfirmationKeyPage
