/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { OutlinedInput, MenuItem } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import { useLoginStore } from '../../../store/Login/Login.store'

import { STopBar, STopBarNav, SLogOut, SSelect } from './Header.styles'
import HeaderDialog from './Header.dialog'

function HeaderComponent() {
    const { stateLogin, actionsLogin } = useLoginStore()
    const history = useHistory()
    const [openDialog, setOpenDialog] = useState(false)
    const [activeScope, setActiveScope] = useState(stateLogin.activeScope)
    function handleEntityStatus(e) {
        const scope = e.target.value
        setActiveScope(scope)
        setOpenDialog(true)
    }

    useEffect(() => {
        if (!stateLogin.activeScope || !Object.keys(stateLogin.activeScope).length) {
            actionsLogin.getActiveScope()
        }
    }, [stateLogin.activeScope])

    function compare(a, b) {
        // To put scopes mainEntity in alphabetical order
        if (a.mainEntity < b.mainEntity) return -1
        if (a.mainEntity > b.mainEntity) return 1
        return 0
    }

    return (
        <STopBar>
            <STopBarNav>
                {stateLogin.scopes && stateLogin.scopes.length > 1 && (
                    <div>
                        <span>Protocolo:</span>
                        <SSelect
                            id='scopes-select'
                            key='activeScope'
                            items={stateLogin.scopes.sort(compare)}
                            value={stateLogin.activeScope ? stateLogin.activeScope : null || ''}
                            input={<OutlinedInput id='activeScope' name='activeScope' labelWidth={137} />}
                            onChange={e => handleEntityStatus(e)}
                        >
                            {stateLogin.scopes.length > 0 &&
                                stateLogin.scopes.map(item => (
                                    <MenuItem key={item.id} value={item}>
                                        {item.scopeName}
                                    </MenuItem>
                                ))}
                        </SSelect>
                    </div>
                )}
                <SLogOut onClick={() => actionsLogin.doLogout(history)}>
                    Sair
                    <ExitToApp />
                </SLogOut>
            </STopBarNav>
            <HeaderDialog isOpen={openDialog} scope={activeScope} history={history} handleOpen={setOpenDialog} />
        </STopBar>
    )
}

export default HeaderComponent
