import styled from 'styled-components'

const SActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 10px;
    button {
        margin-left: 15px;
    }
`

export default SActions
