import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

export const SMessage = styled.div`
    background-color: #fff;
`
export const SBoxDone = styled.div`
    height: 60vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon-check,
    .icon-error {
        margin: 50px;
    }
    .error-message {
        display: block;
        margin-bottom: 30px;
        font-size: 16px;
    }
    span,
    h2 {
        text-align: center;
    }
`
export const SLink = styled(LinkRouter)`
    margin-top: 40px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
    span {
        margin-left: 20px;
    }
`
export const SIconItem = styled.img`
    width: 60px;
    margin: 0 30px 0;
`
