import styled from 'styled-components'

const SContactInfo = styled.div`
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    span {
        display: block;
        margin-bottom: 5px;
    }
`
export default SContactInfo
