import styled from 'styled-components'

export const AccessRecovery = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    span {
        font-size: 16px;
    }
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`

export const SCircular = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 16px;
        font-size: 16px;
    }
`
