import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, CircularProgress } from '@material-ui/core'
import { Block, Check } from '@material-ui/icons'

import { transformTimestampToDate } from '../../utils/date.util'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import ItemDataComponent from '../../components/templates/ItemData/ItemData.component'
import ModalComponent from '../../components/templates/Modal/Modal.component'
import ModalBodyComponent from '../../components/templates/Modal/ModalBody.component'

import { SCircular, SPaperRow, CardWrapper, CardAvatar, ApprovalButton } from './Order.styles'

import getOrderService from './Order.service'

import SslTable from './orderTables/Ssl.table'
import CardTable from './orderTables/Card.table'
import WildcardTable from './orderTables/Wildcard.table'
import ElectronicSealTable from './orderTables/ElectronicSeal.table'
import TimestampTable from './orderTables/Timestamp.table'

const isMobile = window.innerWidth < 600

const propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    params: {},
}

function OrderPage(props) {
    const { match } = props
    const { params } = match

    const [order, setOrder] = useState({})
    const [open, setOpen] = React.useState(false)
    const [card, setCard] = useState({})

    useEffect(() => {
        async function setNewOrder() {
            const newOrder = await getOrderService(params.id)
            if (newOrder) setOrder(newOrder)
        }
        setNewOrder()
    }, [params.id])

    const handleOpen = (event, rowData) => {
        setCard(rowData)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    function renderModalComponent() {
        return (
            <ModalComponent title='Dados do cartão' open={open} onClick={handleClose} small noborder>
                <ModalBodyComponent>
                    <CardWrapper>
                        <Box mr={2}>
                            <CardAvatar alt={card.personName} src={card.photo} variant='square' />
                        </Box>
                        <Box flex='1 1 0'>
                            <ItemDataComponent itemKey='Nome Completo:' itemValue={card.personName} />
                            <ItemDataComponent itemKey='Nome no cartão:' itemValue={card.nameOnCard} />
                            <ItemDataComponent itemKey='Cargo:' itemValue={card.role} />
                            <ItemDataComponent itemKey='Email:' itemValue={card.email} />
                            <ItemDataComponent itemKey='Entidade:' itemValue={card.entityName} />
                        </Box>
                    </CardWrapper>
                </ModalBodyComponent>
            </ModalComponent>
        )
    }

    function renderOrderDetail() {
        return (
            <PaperComponent noborder>
                <PaperHeaderComponent title='Informações do pedido' />

                <SPaperRow>
                    <ItemDataComponent
                        itemKey='Data do Pedido:'
                        itemValue={transformTimestampToDate(order.createdDate)}
                    />
                    <ItemDataComponent itemKey='Solicitante:' itemValue={order.requesterName} />
                    <ItemDataComponent
                        itemKey='NIPC Entidade Solicitante:'
                        itemValue={order.requesterEntityTaxIdentificationNumber}
                    />
                    <ItemDataComponent itemKey='Entidade Solicitante:' itemValue={order.requesterEntityName} />
                </SPaperRow>

                <SPaperRow>
                    <ItemDataComponent
                        itemKey='NIPC Entidade Responsável:'
                        itemValue={order.responsibleEntityTaxIdentificationNumber}
                    />
                    <ItemDataComponent itemKey='Entidade Responsável:' itemValue={order.responsibleEntityName} />
                    <ItemDataComponent itemKey='Estado:' itemValue={order.status ? order.status.label : ''} />
                    <ItemDataComponent
                        itemKey='Data do estado:'
                        itemValue={transformTimestampToDate(order.updatedDate)}
                    />
                </SPaperRow>

                <SPaperRow>
                    <ItemDataComponent itemKey='N˚ do compromisso:' itemValue={order.commitmentNumber} />
                    <ItemDataComponent itemKey='N˚ da encomenda:' itemValue={order.orderNumber} />
                    <ItemDataComponent itemKey='N˚ do cabimento:' itemValue={order.docketNumber} />
                    <ItemDataComponent itemKey='N˚ da fatura:' itemValue={order.invoiceNumber} />
                </SPaperRow>
            </PaperComponent>
        )
    }

    function renderActionButtons() {
        return (
            <Box>
                <Button variant='contained' title='Reprovar Pedido' color='primary'>
                    {isMobile ? <Block /> : 'Reprovar Pedido'}
                </Button>
                <ApprovalButton variant='contained' title='Aprovar Pedido'>
                    {isMobile ? <Check /> : 'Aprovar Pedido'}
                </ApprovalButton>
            </Box>
        )
    }

    return (
        <ContentComponent>
            <PageTitleComponent title='Pedido' />
            {(order && !Object.keys(order)) || Object.keys(order).length < 1 ? (
                <SCircular>
                    <CircularProgress size={40} />
                    <span>A Carregar Pedido</span>
                </SCircular>
            ) : (
                <Fragment>
                    {renderOrderDetail()}
                    {order.itemsToken.length > 0 && (
                        <Box pb={2}>
                            <CardTable
                                data={order.itemsToken}
                                onRowClick={(event, rowData) => handleOpen(event, rowData)}
                            />
                        </Box>
                    )}
                    {order.itemsSsl.length > 0 && (
                        <Box pb={2}>
                            <SslTable data={order.itemsSsl} />
                        </Box>
                    )}

                    {order.itemsWildcard.length > 0 && (
                        <Box pb={2}>
                            <WildcardTable data={order.itemsWildcard} />
                        </Box>
                    )}
                    {order.itemsSeal.length > 0 && (
                        <Box pb={2}>
                            <ElectronicSealTable data={order.itemsSeal} />
                        </Box>
                    )}
                    {order.itemsTimestamp.length > 0 && (
                        <Box pb={2}>
                            <TimestampTable data={order.itemsTimestamp} />
                        </Box>
                    )}

                    {renderModalComponent()}
                </Fragment>
            )}

            <ActionBarComponent backText='Pedidos' backLink='/orders'>
                {Object.keys(order).length >= 1 && order.status.description === 'PENDENTE' && renderActionButtons()}
            </ActionBarComponent>
        </ContentComponent>
    )
}

OrderPage.propTypes = propTypes
OrderPage.defaultProps = defaultProps

export default OrderPage
