import React from 'react'

import { ItemChip } from '../Profile.styles'
import DataTablePagination from '../../../components/templates/DataTablePagination/DataTablePagination.component'

const columns = [
    { title: 'Entidade', field: 'entityName' },
    { title: 'Unidade Orgânica', field: 'organicUnitName' },
    { title: 'Cargo', field: 'role' },
    {
        title: 'Estado',
        field: 'status',
        // eslint-disable-next-line react/prop-types
        render: ({ active }) => <ItemChip label={active ? 'Ativo' : 'Inativo'} type={active ? 1 : 2} />,
    },
]

function AllocationListTab() {
    return (
        <DataTablePagination
            title='Lista de Alocação por Entidade'
            columns={columns}
            endPoint='profile/allocationsList'
        />
    )
}

export default AllocationListTab
