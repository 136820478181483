import React, { useEffect, useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'

import { Captcha, captchaSettings } from 'reactjs-captcha'

import { CircularProgress, TextField } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

import { BASE_URL } from '../../services/api.service'

import { AccessRecovery, SCircular } from './FCaptcha.styles'

const propTypes = {
    value: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    refCaptcha: PropTypes.objectOf(PropTypes.any).isRequired,
}

const defaultProps = {
    helperText: '',
}

function FCaptcha(props) {
    const { value, error, helperText, onChange, onBlur, refCaptcha } = props

    const [captchaLoading, setCaptchaLoading] = useState(true)
    const [renderCaptcha, setRenderCaptcha] = useState(false)

    const timeOutRef = useRef(null)
    const intervalRef = useRef(null)

    // * Function that triggers captcha loading
    function loadCaptcha() {
        // * Turns on a timeout to display the option to reload the Captcha
        timeOutRef.current = setTimeout(() => {
            setCaptchaLoading(false)
        }, 7500)

        // * Setup configurations for captcha initialization
        captchaSettings.set({
            captchaEndpoint: `${BASE_URL}simple-captcha-endpoint`,
        })

        // * DIV in which the captcha component will be inserted
        const captchaDiv = document.getElementById('BDC_CaptchaComponent')
        const captchaInputLabel = document.getElementById('yourFirstCaptchaUserInput-label')
        refCaptcha.current.displayHtml('yourFirstCaptchaStyle')

        // * Hides the captcha component until it finishes loading
        captchaDiv.style.display = 'none'
        // * Add backgroundColor white to label for fixing overlap on input border
        captchaInputLabel.style.backgroundColor = 'white'

        // * Interval function that will check if the Captcha Image has finished loading
        intervalRef.current = setInterval(function() {
            const captchaImage = document.getElementById('yourFirstCaptchaStyle_CaptchaImage')
            if (captchaImage && captchaImage.complete) {
                captchaDiv.style.display = ''
                setRenderCaptcha(true)
                // * Cancels the captchaLoadingTimeout
                clearTimeout(timeOutRef.current)
                setCaptchaLoading(false)
                // * Stops the execution of the interval function after captcha image loaded
                clearInterval(intervalRef.current)
            }
        }, 1000)
    }

    // * Loads captcha on page initial render.
    useEffect(() => {
        loadCaptcha()

        return () => {
            if (timeOutRef.current !== null) {
                clearTimeout(timeOutRef.current)
            }

            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Captcha
                captchaStyleName='yourFirstCaptchaStyle'
                ref={captcha => {
                    if (captcha) {
                        refCaptcha.current = captcha
                    }
                }}
            />
            <TextField
                id='yourFirstCaptchaUserInput'
                name='yourFirstCaptchaUserInput'
                label='Digite os caracteres da imagem: '
                margin='dense'
                variant='outlined'
                style={!renderCaptcha ? { display: 'none' } : null}
                value={value}
                error={error}
                helperText={helperText}
                onChange={onChange}
                onBlur={onBlur}
                fullWidth
            />
            {!renderCaptcha &&
                (captchaLoading ? (
                    <SCircular>
                        <CircularProgress size={30} />
                        <span>A Carregar CAPTCHA</span>
                    </SCircular>
                ) : (
                    <AccessRecovery
                        // * If the captcha didn't load, reload the page.
                        onClick={() => {
                            setCaptchaLoading(true)
                            loadCaptcha()
                        }}
                    >
                        <Warning />
                        <span>Erro. Clique aqui para tentar novamente.</span>
                    </AccessRecovery>
                ))}
        </Fragment>
    )
}

FCaptcha.propTypes = propTypes
FCaptcha.defaultProps = defaultProps

export default FCaptcha
