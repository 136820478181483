import PROFILE_TYPES from './Profile.types'

const useActions = (state, dispatch) => ({
    resetData: () => {
        dispatch({ type: PROFILE_TYPES.RESET_DATA.SUCCESS })
    },
    showLoading: () => {
        dispatch({ type: PROFILE_TYPES.SHOW_LOADING.SUCCESS })
    },
    hideLoading: () => {
        dispatch({ type: PROFILE_TYPES.HIDE_LOADING.SUCCESS })
    },
    getProfile: () => {
        dispatch({ type: PROFILE_TYPES.PROFILE.GET.REQUEST })
    },
    updateEmail: email => {
        dispatch({ type: PROFILE_TYPES.UPDATE_EMAIL.POST.REQUEST, payload: email })
    },
    updatePhoneNumber: data => {
        dispatch({ type: PROFILE_TYPES.UPDATE_PHONE_NUMBER.POST.REQUEST, payload: data })
    },
    validateCode: data => {
        dispatch({ type: PROFILE_TYPES.VALIDATE_CODE.POST.REQUEST, payload: { code: data } })
    },
    updateProfile: data => {
        dispatch({ type: PROFILE_TYPES.UPDATE_PROFILE.POST.REQUEST, payload: data })
    },
})

export default useActions
