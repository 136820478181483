import TABLE_TYPES from './Table.types'

const useActions = (state, dispatch) => ({
    resetTableHistory: () => {
        dispatch({ type: TABLE_TYPES.RESET_TABLE_HISTORY.SUCCESS })
    },
    setTableHistory: data => {
        dispatch({ type: TABLE_TYPES.TABLE_HISTORY.SUCCESS, payload: data })
    },
    setAllowSaveTableHistory: data => {
        dispatch({ type: TABLE_TYPES.ALLOW_SAVE_HISTORY.SUCCESS, payload: data })
    },
    setDateFilter: data => {
        dispatch({ type: TABLE_TYPES.SET_DATE_FILTER.SUCCESS, payload: data })
    },
    setTableSearchAndFilterQueryToExportCSV: data => {
        dispatch({ type: TABLE_TYPES.TABLE_QUERY_TO_EXPORT_CSV.SUCCESS, payload: data })
    },
})

export default useActions
