import apiService from '../../services/api.service'

// TYPES FOR ACTIONS
export const PROTOCOL_DASHBOARD_TYPES = {
    GET: {
        REQUEST: 'protocolDashboard/SET_REQUEST',
        ERROR: 'protocolDashboard/SET_ERROR',
        SUCCESS: 'protocolDashboard/SET_SUCCESS',
    },
}
// INITIAL STATE FOR REDUCER
export const INITIAL_STATE = {
    isLoading: true,
    data: {},
    balance: {},
    currentBalance: 0,
    initialBalance: 0,
    percentlBalance: 0,
    orders: {},
    totalOrder: 0,
    statusOrder: [],
    invoices: {},
    totalInvoice: 0,
    statusInvoice: [],
    services: {},
    totalService: 0,
    statusService: [],
}

// ASYNC FUNCTION FOR REQUEST
async function getProtocolDashboardByAmbitoId(dispatch, id) {
    try {
        const { data } = await apiService.get(`dashboard/client/`)
        const { payload } = data
        dispatch({ type: PROTOCOL_DASHBOARD_TYPES.GET.SUCCESS, payload })
    } catch (err) {
        if (err.response && err.response.data) {
            const { message } = err.response.data
            dispatch({ type: PROTOCOL_DASHBOARD_TYPES.GET.ERROR, payload: { message } })
        }
    }
}

// REDUCER TO MANIPULATE THE STATE
export function protocolDashboardReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case PROTOCOL_DASHBOARD_TYPES.GET.REQUEST:
            getProtocolDashboardByAmbitoId(action.payload.dispatch, action.payload.id)
            return INITIAL_STATE
        case PROTOCOL_DASHBOARD_TYPES.GET.SUCCESS:
            return {
                isLoading: false,
                error: false,
                message: '',
                data: action.payload,
            }
        case PROTOCOL_DASHBOARD_TYPES.GET.ERROR:
            return {
                isLoading: false,
                error: true,
                message: action.payload.message,
            }
        default:
            return state
    }
}
