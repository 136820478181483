/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import DataTablePagination from '../../../components/templates/DataTablePagination/DataTablePagination.component'
import FilterDatePicker from '../../../components/templates/DataTablePagination/FilterDatePicker.component'
import DownloadFile from '../../../components/templates/DownloadFile/DownloadFile'

import { transformTimestampToSimpleDateUtil } from '../../../utils'
import { transformTimestampToDate } from '../../../utils/date.util'

import AuthService from '../../../services/auth.service'

import DownloadFileCSV from '../../../components/templates/DownloadFile/DownloadFileCSV'
import ModalFilesDownload from './ModalFilesDownload'
import ModalUploadFiles from './ModalUploadFiles'

import { ItemChip } from '../Certificates.styles'

const auth = new AuthService(sessionStorage)

function ElectronicSealList() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
    const [seloId, setseloId] = useState('')
    const [checked, setChecked] = useState(false)
    const [status, setStatus] = useState('')

    const showCheckBoxOtherEntities = Boolean(
        _.find(auth.getPermissions(), { featureName: 'View_Certs_From_Other_Entities' })
    )

    function handleModalFilesDownload(rowData) {
        setStatus(rowData && rowData.status)
        setIsModalOpen(true)
        setseloId(rowData.id)
    }

    function handleCloseModal() {
        setIsModalOpen(!isModalOpen)
    }

    function handleModalFilesUpload(id) {
        setIsUploadModalOpen(true)
        setseloId(id)
    }

    const COLUMNS = [
        {
            field: 'originRequestNumber',
            title: 'nº do pedido',
        },
        {
            field: 'certificateMail',
            title: 'email do certificado',
        },
        {
            field: 'supportName',
            title: 'suporte',
        },

        {
            field: 'typeLabel',
            title: 'Tipo',
        },
        {
            field: 'duration',
            title: 'Duração',
        },
        {
            title: 'Obter ficheiros para pedido de manutenção',
            field: 'downloadFiles',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                return (
                    <IconButton color='primary' component='span' onClick={() => handleModalFilesDownload(rowData)}>
                        <CloudDownloadIcon />
                    </IconButton>
                )
            },
            filtering: false,
        },
        {
            title: 'Enviar pedido de manutenção',
            field: 'uploadFile',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                return (
                    <IconButton color='primary' component='span' onClick={() => handleModalFilesUpload(rowData.id)}>
                        <CloudUploadIcon />
                    </IconButton>
                )
            },
            filtering: false,
        },
        {
            field: 'creationDate',
            title: 'Emissão',
            cellStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ creationDate }) => transformTimestampToDate(creationDate),
        },
        {
            field: 'expirationDate',
            title: 'Expiração',
            cellStyle: {
                width: '10%',
                maxWidth: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ expirationDate }) => transformTimestampToDate(expirationDate),
        },
        {
            field: 'status.updatedDate',
            title: 'Data do Estado',
            type: 'date',
            filterComponent: props => <FilterDatePicker {...props} />,
            render: ({ status }) => transformTimestampToSimpleDateUtil(status.updatedDate),
        },
        {
            field: 'status.label',
            title: 'Estado',
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            },
            render: rowData =>
                rowData.status ? <ItemChip label={rowData.status.label} type={rowData.status.id} /> : '',
        },
        {
            title: 'DOWNLOAD',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                let result = null
                if (rowData.certAvailable && !!rowData.certAvailable) {
                    result = <DownloadFile endpoint={`/tokensEletronicSeal/client/${rowData.id}/downloadCertificate`} />
                }
                if (!rowData.certAvailable && rowData.supportName === 'HSM') {
                    result = (
                        <Tooltip title='A processar' placement='right'>
                            <CloudDownloadIcon />
                        </Tooltip>
                    )
                }
                return result
            },
        },
    ]

    return (
        <>
            {showCheckBoxOtherEntities && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            name='myOrders'
                            color='primary'
                        />
                    }
                    label='Exibir certificados de outras Entidades Participantes do Protocolo'
                />
            )}

            <Grid container justify='flex-end'>
                <DownloadFileCSV
                    endpoint='tokensEletronicSeal/client'
                    exportCSV={{ isFromAPI: true, filename: 'certificados-selos-eletronicos' }}
                />
            </Grid>
            <DataTablePagination
                columns={COLUMNS}
                endPoint='tokensEletronicSeal/client'
                certificateFromOtherEntities={checked}
                showCheckBoxOtherEntities={showCheckBoxOtherEntities}
                options={{
                    search: false,
                    filtering: true,
                    sorting: true,
                    filterCellStyle: {
                        padding: '16px 16px 16px 6px',
                    },
                }}
            />

            {isModalOpen && (
                <ModalFilesDownload
                    open={isModalOpen}
                    orderId={seloId}
                    onCloseModal={handleCloseModal}
                    endpoint='tokensEletronicSeal'
                    status={status}
                />
            )}

            {isUploadModalOpen && (
                <ModalUploadFiles
                    open={isUploadModalOpen}
                    onCloseModal={() => setIsUploadModalOpen(false)}
                    endpoint='tokensEletronicSeal'
                    orderId={seloId}
                />
            )}
        </>
    )
}

export default ElectronicSealList
