import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Grid, Box, CircularProgress, Tab } from '@material-ui/core'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import ItemDataComponent from '../../components/templates/ItemData/ItemData.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'

import TableUsers from './tables/TableUsers'
import TableRequests from './tables/TableRequests'

import getTimestampById from './Timestamp.service'

import { transformTimestampToDateUtil } from '../../utils/index'

import { useTableStore } from '../../store/Table/Table.store'

import { SPaperRow, SCircular, STabs } from './Timestamp.styles'

const propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    params: {},
}

function Timestamp(props) {
    const [timestamp, setTimestamp] = useState({})
    const [tabValue, setTabValue] = useState(0)
    const { tableActions } = useTableStore()

    const { match } = props
    const { params } = match

    useEffect(() => {
        async function getTimestamp() {
            const timestampDetails = await getTimestampById(params.id)
            if (timestampDetails) {
                setTimestamp(timestampDetails)
            }
        }
        getTimestamp()
    }, [params.id])

    function showLoading() {
        if (Object.keys(timestamp).length > 0) {
            return false
        }
        return true
    }

    return (
        <ContentComponent>
            <PageTitleComponent title='Selo Temporal' />

            <STabs
                value={tabValue}
                onChange={(event, newTabValue) => {
                    tableActions.resetTableHistory()
                    setTabValue(newTabValue)
                }}
                indicatorColor='primary'
            >
                <Tab label='detalhe do selo' />
                <Tab label='lista de Pedidos' />
                <Tab label='lista de Utilizadores' />
            </STabs>

            {tabValue === 0 && (
                <Box component='div' py={3} px={4}>
                    {showLoading() ? (
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <SCircular>
                                    <CircularProgress size={40} />
                                </SCircular>
                            </Grid>
                        </Grid>
                    ) : (
                        <PaperComponent noborder>
                            <PaperHeaderComponent title='Informações do selo' />
                            <SPaperRow>
                                <ItemDataComponent itemKey='Nome do serviço:' itemValue={timestamp.service} />
                                <ItemDataComponent itemKey='Quantidade disponível:' itemValue={timestamp.quantity} />
                            </SPaperRow>
                            <SPaperRow>
                                <ItemDataComponent itemKey='Email:' itemValue={timestamp.timestampMail} />
                                <ItemDataComponent
                                    itemKey='Tipo de permissão:'
                                    itemValue={timestamp.permissionType.label}
                                />
                            </SPaperRow>
                            <SPaperRow>
                                <ItemDataComponent itemKey='Estado:' itemValue={timestamp.status.label} />
                                <ItemDataComponent
                                    itemKey='Data do estado:'
                                    itemValue={transformTimestampToDateUtil(timestamp.status.updatedDate)}
                                />
                                <ItemDataComponent
                                    itemKey='Notificar novos utilizadores:'
                                    itemValue={timestamp.isToNotify ? 'Sim' : 'Não'}
                                />
                            </SPaperRow>
                        </PaperComponent>
                    )}
                </Box>
            )}

            {tabValue === 1 && (
                <Box component='div' py={3} px={4}>
                    <TableRequests timestampId={params.id} />
                </Box>
            )}

            {tabValue === 2 && (
                <Box component='div' py={3} px={4}>
                    <TableUsers timestampId={params.id} />
                </Box>
            )}

            <ActionBarComponent backLink='/timestamps' backText='Voltar' />
        </ContentComponent>
    )
}

Timestamp.propTypes = propTypes
Timestamp.defaultProps = defaultProps

export default Timestamp
