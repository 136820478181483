import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { ArrowBack } from '@material-ui/icons'

import { SMessage, SBoxDone, SIconItem, SLink } from './Message.style'
import IconCheck from '../../../assets/icons/icon-check.svg'
import IconError from '../../../assets/icons/icon-error.svg'

import { sanitizeAndParseHTML } from '../../../utils'

const propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    textBack: PropTypes.string,
    link: PropTypes.string,
    successHeaderMessage: PropTypes.string,
    errorHeaderMessage: PropTypes.string,
}

const defaultProps = {
    type: '',
    message: '',
    textBack: '',
    link: null,
    successHeaderMessage: 'Muito Obrigado!',
    errorHeaderMessage: 'Desculpe-nos!',
}

function MessageComponent(props) {
    const { type, message, textBack, link, successHeaderMessage, errorHeaderMessage } = props

    let newMessage = ''

    if (typeof message !== 'object') {
        newMessage = message.split('<br>')
    } else {
        newMessage = message.message
    }

    return (
        <SMessage>
            <SBoxDone>
                {type === 'success' ? (
                    <Fragment>
                        <h2>{successHeaderMessage}</h2>
                        <SIconItem src={IconCheck} className='icon-check' />
                    </Fragment>
                ) : (
                    <Fragment>
                        <h2>{errorHeaderMessage}</h2>
                        <SIconItem src={IconError} className='icon-error' />
                    </Fragment>
                )}
                <span>{sanitizeAndParseHTML(newMessage)}</span>
                {link && (
                    <SLink to={link}>
                        <ArrowBack />
                        <span>
                            {'Ir ao '}
                            {textBack}
                        </span>
                    </SLink>
                )}
            </SBoxDone>
        </SMessage>
    )
}

MessageComponent.propTypes = propTypes
MessageComponent.defaultProps = defaultProps

export default MessageComponent
