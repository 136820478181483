import REQUEST_USER_TYPES from './RequestUser.types'

const useActions = (state, dispatch) => ({
    resetData: () => {
        dispatch({ type: REQUEST_USER_TYPES.RESET_DATA.SUCCESS })
    },
    persistUserOnState: values => {
        dispatch({ type: REQUEST_USER_TYPES.USER.POST.REQUEST, payload: { ...values } })
    },
    persistEntityOnState: values => {
        dispatch({ type: REQUEST_USER_TYPES.ENTITY.POST.REQUEST, payload: { ...values } })
    },
    getUserByNif: nif => {
        dispatch({ type: REQUEST_USER_TYPES.USER.GET.REQUEST, payload: { nif } })
    },
    getEntityByNipc: nipc => {
        dispatch({ type: REQUEST_USER_TYPES.ENTITY.GET.REQUEST, payload: { nipc } })
    },
    registerAccess: values => {
        dispatch({ type: REQUEST_USER_TYPES.REGISTER.POST.REQUEST, payload: { ...values } })
    },
    resetErrorSuccess: () => {
        dispatch({ type: REQUEST_USER_TYPES.REGISTER.PUT.REQUEST })
    },
})

export default useActions
