import React, { memo } from 'react'

import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import { Tooltip } from '@material-ui/core'

import NotInterestedIcon from '@material-ui/icons/NotInterested'

import { SProductItem } from './ProductItem.styles'
import { StyledTheme } from '../../styles/themes'

import IconCard from '../../assets/icons/IconCard'
import IconSSL from '../../assets/icons/IconSSL'
import IconSSLEV from '../../assets/icons/IconSSLEV'
import IconWildcard from '../../assets/icons/IconWildcard'
import IconTimestamp from '../../assets/icons/IconTimestamp'
import IconEletronicSeal from '../../assets/icons/IconEletronicSeal'

const ProductDescription = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip)

function ProductItemComponent(props) {
    const { name, type, disabled } = props

    let newImage = ''
    let description = ''
    switch (type) {
        case 'TOKEN':
            newImage = <IconCard fill={StyledTheme.palette.primary.main} />
            description = 'Certificado Qualificado de Assinatura Eletrónica (pessoa singular)'
            break
        case 'SSL':
            newImage = <IconSSL fill={StyledTheme.palette.primary.main} />
            description = 'Certificado Qualificado de Autenticação de sítios web'
            break
        case 'SSL EV':
            newImage = <IconSSLEV fill={StyledTheme.palette.primary.main} />
            description = 'Certificado  qualificado  de  autenticação  de  sítios  web — Extended  validation'
            break
        case 'WILDCARD':
            newImage = <IconWildcard fill={StyledTheme.palette.primary.main} />
            description = 'Certificado de Servidor Wildcard SSL/TLS'
            break
        case 'TIMESTAMP':
            newImage = <IconTimestamp fill={StyledTheme.palette.primary.main} />
            description = 'Selos temporais qualificados'
            break
        case 'ELECTRONIC_SEAL':
            newImage = <IconEletronicSeal fill={StyledTheme.palette.primary.main} />
            description = 'Selo Eletrónico Qualificado'
            break
        default:
            break
    }

    return (
        <ProductDescription title={description}>
            <SProductItem
                href={disabled ? '' : `#${type.toLowerCase().replace(/\s/g, '-')}-list`}
                role='button'
                onClick={
                    disabled
                        ? e => {
                              e.preventDefault()
                          }
                        : // eslint-disable-next-line react/destructuring-assignment
                          props.handleClick
                }
                disabled={disabled}
            >
                {disabled ? <NotInterestedIcon className='product-icon' /> : newImage}
                <span className='product-name'>{name}</span>
            </SProductItem>
        </ProductDescription>
    )
}

ProductItemComponent.propTypes = {
    name: PropTypes.string,
    handleClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
}
ProductItemComponent.defaultProps = {
    name: '',
    handleClick: () => {},
    type: '',
    disabled: false,
}

export default memo(ProductItemComponent)
