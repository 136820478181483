import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import { OrdersReducer, initialState } from './Orders.reducer'
import useActions from './Orders.actions'
import applyMiddleware from './Orders.middleware'
import { useAlertStore } from '../Alert/Alert.store'

const OrdersContext = createContext(null)

export function OrdersProvider({ children }) {
    const { actionsAlert } = useAlertStore()
    const [stateOrders, dispatch] = useReducer(OrdersReducer, initialState)
    const actionsOrders = useActions(stateOrders, applyMiddleware(dispatch, actionsAlert))
    const value = { stateOrders, actionsOrders }

    return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
}

OrdersProvider.propTypes = {
    children: PropTypes.objectOf(PropTypes.any).isRequired,
}

export const useOrdersStore = () => useContext(OrdersContext)
