import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { SWrapper } from './styles'

const propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}

function OpenNewTabButton(props) {
    const { url, text } = props

    function openInNewTab() {
        const win = window.open(url, '_blank')
        win.focus()
    }

    return (
        <SWrapper>
            <Button variant='outlined' color='default' onClick={openInNewTab}>
                {text}
            </Button>
        </SWrapper>
    )
}

OpenNewTabButton.propTypes = propTypes

export default OpenNewTabButton
