import styled from 'styled-components'
import PaperComponent from '../../components/templates/Paper/Paper.component'

export const SCircular = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-top: 50px;
        font-size: 20px;
    }
`

export const SPaperRow = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    flex-flow: ${props => (props.column ? 'column wrap' : 'row wrap')};
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid ${props => props.theme.palette.grey.light};
    &:last-child {
        border-bottom: none;
    }

    h3 {
        color: ${props => props.theme.palette.grey.main};
        text-transform: uppercase;
        font-size: 14px;
        margin: 4px 0;
    }
`

export const SPaperComponent = styled(PaperComponent)`
    padding-bottom: 60px;
    position: relative;
`

export const SPaperFooterComponent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: ${props => props.theme.palette.grey.light};
    font-size: 20px;
    border-radius: 0 0 8px 8px;
`
