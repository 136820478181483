import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './styles'

const IconSSL = ({ fill = '#960008' }) => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='22.924' height='27.439' viewBox='0 0 22.924 27.439'>
                <g transform='translate(17027.6 3365)'>
                    <g transform='translate(-17027.6 -3365)'>
                        <path
                            d='M27.709,40.76v.463a7.611,7.611,0,0,1-.289,2.026H23.772c.058-.666.087-1.331.087-2.026V40.76h-.087a3.94,3.94,0,0,1-1.65-.376,7.858,7.858,0,0,1,.029.839c0,.724-.029,1.389-.087,2.026H17.81c-.058-.637-.087-1.3-.087-2.026s.029-1.389.087-2.026h2.952a3.847,3.847,0,0,1-.666-1.708H18.041c.463-2.576,1.331-4.052,1.881-4.052a.261.261,0,0,1,.145.029V31.7h-.145a9.508,9.508,0,1,0,9.494,9.494,2.49,2.49,0,0,0-.029-.463H27.709Zm-15.572.463a7.966,7.966,0,0,1,.289-2.026h3.647c-.058.666-.087,1.36-.087,2.026,0,.695.029,1.36.087,2.026H12.426A7.611,7.611,0,0,1,12.137,41.223Zm.984,3.763H16.3A13.354,13.354,0,0,0,17.347,48.6,7.894,7.894,0,0,1,13.121,44.985Zm3.184-7.5H13.121a7.82,7.82,0,0,1,4.255-3.618A12.657,12.657,0,0,0,16.3,37.489Zm3.618,11.549c-.55,0-1.418-1.505-1.881-4.052h3.792C21.37,47.532,20.473,49.038,19.923,49.038Zm2.576-.463a12.135,12.135,0,0,0,1.042-3.618h3.184A7.662,7.662,0,0,1,22.5,48.574Z'
                            transform='translate(-10.4 -23.277)'
                            fill={fill}
                        />
                        <path
                            d='M59.017,8.128V6.913a4.327,4.327,0,0,0-8.654-.029V8.1A2.251,2.251,0,0,0,48.8,10.241v6.165a2.261,2.261,0,0,0,2.258,2.258h7.294a2.261,2.261,0,0,0,2.258-2.258V10.27A2.315,2.315,0,0,0,59.017,8.128ZM52.071,6.884a2.6,2.6,0,1,1,5.21,0v1.1h-5.21Zm6.8,9.523a.55.55,0,0,1-.55.55H51.058a.55.55,0,0,1-.55-.55V10.27a.55.55,0,0,1,.55-.55h7.294a.55.55,0,0,1,.55.55v6.136Z'
                            transform='translate(-37.685 -2.6)'
                            fill={fill}
                        />
                        <path
                            d='M63.555,32.795,61.7,34.647l-.926-.926a.86.86,0,0,0-1.216,1.216l1.534,1.534a.839.839,0,0,0,1.216,0l2.431-2.431a.839.839,0,0,0,0-1.216A.807.807,0,0,0,63.555,32.795Z'
                            transform='translate(-45.146 -23.88)'
                            fill={fill}
                        />
                    </g>
                </g>
            </svg>
        </IconContainer>
    )
}

IconSSL.propTypes = {
    fill: PropTypes.string,
}
IconSSL.defaultProps = {
    fill: '',
}

export default memo(IconSSL)
