/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'

import PropTypes from 'prop-types'

import { useDropzone } from 'react-dropzone'
import { uniqueId } from 'lodash'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import { SContainer, SFileInfo, SFileList, SFileListItem, SFileContent } from './UploadFile.styles'

const propTypes = {
    initialFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
    handleFiles: PropTypes.func.isRequired,
    isSingleFile: PropTypes.bool,
    fileType: PropTypes.string,
    label: PropTypes.string,
}

const defaultProps = {
    isSingleFile: false,
    fileType: 'application/pdf',
    label: null,
}

const UploadFileComponent = props => {
    const { initialFiles, handleFiles, isSingleFile, fileType, label } = props
    const [files, setFiles] = useState(initialFiles)

    useEffect(() => {
        if (initialFiles && initialFiles.length !== files.length) {
            setFiles(initialFiles)
        }
    }, [initialFiles])

    useEffect(() => handleFiles(files), [files])

    function removeFile(id) {
        if (isSingleFile) {
            setFiles([])
        } else {
            const newFileList = files.filter(item => item.id !== id)
            setFiles(newFileList)
        }
    }

    const onDrop = acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = () => {
                const base64 = reader.result

                const fileToUpload = {
                    id: uniqueId(),
                    name: file.name,
                    base64,
                }

                if (files && files.length > 0) {
                    setFiles([...files, fileToUpload])
                } else {
                    setFiles([fileToUpload])
                }
            }
        })
    }

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: fileType,
        onDrop,
    })

    function renderLabel() {
        if (label) {
            return label
        }

        return isSingleFile
            ? `Selecione ou arraste o ficheiro ${fileType === '.csr' ? fileType.toUpperCase() : '.PDF'} aqui...`
            : `Selecione ou arraste os ficheiros  ${fileType === '.csr' ? fileType.toUpperCase() : '.PDF'} aqui...`
    }

    return (
        <Fragment>
            {((isSingleFile && files && files.length === 0) || !isSingleFile) && (
                <SContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                    <Fragment>
                        <input {...getInputProps()} />
                        <p>{renderLabel()}</p>
                    </Fragment>
                </SContainer>
            )}
            <SFileList>
                {files &&
                    files.length > 0 &&
                    files.map(file => (
                        <SFileListItem key={file.id}>
                            <SFileContent>
                                <SFileInfo className='file-content'>
                                    <span className='file-name'>{file.name}</span>
                                    <div className='file-info'>
                                        {file.readableSize}
                                        {!file.error ? (
                                            <Tooltip title='Excluir'>
                                                <IconButton aria-label='delete' onClick={() => removeFile(file.id)}>
                                                    <Delete fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Button>Tentar novamente</Button>
                                        )}
                                    </div>
                                </SFileInfo>
                            </SFileContent>
                        </SFileListItem>
                    ))}
            </SFileList>
        </Fragment>
    )
}

UploadFileComponent.propTypes = propTypes
UploadFileComponent.defaultProps = defaultProps

export default UploadFileComponent
