import ALERT_TYPES from './Alert.types'

export const initialState = {
    open: false,
    message: '',
    variantCode: null,
}

function AlertReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ALERT_TYPES.HIDE_ALERT.SUCCESS:
            return initialState
        case ALERT_TYPES.SHOW_ALERT.SUCCESS:
            return {
                ...state,
                open: true,
                message: action.payload.message,
                variantCode: action.payload.result,
            }
        default:
            return state
    }
}

export default AlertReducer
