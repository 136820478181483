export const INITIAL_STATE = {
    SMARTCARD_USER: {
        nif: { value: '', validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'], mask: 'maskNIFUtil' },
        smartCardNumber: {
            value: '',
            validation: [
                'validateNotEmptyUtil',
                'validateNumberUtil',
                { validator: 'validateMaxLengthUtil', args: [10] },
            ],
        },
        email: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        password: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validatePasswordRequirementsUtil'],
        },
        repeatPassword: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateStringEqualUtil'],
        },
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
    },
    EMAIL_USER: {
        email: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        password: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validatePasswordRequirementsUtil'],
        },
        repeatPassword: {
            value: '',
            validation: ['validateNotEmptyUtil', 'validateStringEqualUtil'],
        },
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
    },
}

export const ENDPOINTS = {
    CREATE_TIMESTAMP_CONSUMER_SMARTCARD_USER: '/timestamps/client/user/smartcard/register',
    CREATE_TIMESTAMP_CONSUMER_EMAIL_USER: '/timestamps/client/user/email/register',
}
