import styled, { css } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'

export const SWrapper = styled.div`
    ${props =>
        props.isAuthenticated
            ? css`
                  display: flex;
                  grid-template-columns: 80px 1fr;
                  grid-auto-rows: auto;
                  grid-gap: 0;
                  position: relative;
              `
            : css`
                  display: flex;
                  position: relative;
              `}
    @media (max-width: 1048px) {
        height: 100%;
    }
`
export const SMain = styled.div`
    ${props =>
        props.isAuthenticated
            ? css`
                  width: 100%;
                  margin-top: 60px;
                  position: relative;
                  display: flex;
                  @media print {
                      margin-top: 0;
                  }
              `
            : css`
                  background-color: ${props => props.theme.palette.white};
                  width: 65%;
                  height: 100vh;
                  overflow: scroll;
                  padding: 24px 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-flow: column nowrap;

                  @media (max-width: 1048px) {
                      width: 100%;
                  }
                  @media (max-width: 620px) {
                      height: auto;
                  }
                  .BDC_CaptchaDiv {
                      overflow: hidden !important;
                  }
              `}
`
export { CssBaseline }
