import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import { getChipColor } from '../../constants/chipColors'

export const SCircular = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-top: 16px;
        font-size: 16px;
    }
`

export const SPaperRow = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid ${props => props.theme.palette.grey.light};
    &:last-child {
        border-bottom: none;
    }

    @media (max-width: 786px) {
        flex-flow: column wrap;
    }
`

export const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
`

export const ItemChip = styled(Chip)`
    && {
        color: ${props => props.theme.palette.white};
        background-color: ${props => getChipColor(props)};
    }
`

export const CardAvatar = styled(Avatar)`
    && {
        width: 130px;
        margin-right: 16px;
        height: 150px;
        background-color: ${props => props.theme.palette.grey.light};
        border: ${props => props.theme.palette.grey.light};
    }
`

export const ApprovalButton = styled(Button)`
    && {
        background-color: ${props => props.theme.palette.success.main};
        color: ${props => props.theme.palette.white};
    }
`

export const SToolbar = styled.div`
    background-color: ${props => props.theme.palette.grey.xLight};
`
