/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react'

import { Box, Grid, Tab, Button, CircularProgress } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'

import { useProfileStore } from '../../store/Profile/Profile.store'
import { useLoginStore } from '../../store/Login/Login.store'

import Content, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import OpenNewTabButton from '../../components/templates/OpenNewTabButton/OpenNewTabButton.component'

import ProfileDetails from './component/ProfileDetails.component'
import NotificationOptions from './component/NotificationOptions.component'
import ContactInformation from './component/ContactInformation.component'
import AuthenticationSelection from './component/AuthenticationSelection.component'

import EmailListTab from './tabs/EmailList.tab'
import AllocationListTab from './tabs/AllocationList.tab'
import PreProtocolTab from './tabs/PreProtocol/PreProtocol.tab'
import ProtocolListTab from './tabs/ProtocolsList.tab'

import { validatePhoneUtil } from '../../utils/index'
import { STabs, SCircular } from './Profile.styles'
import downloadJsonFile from './Profile.service'

import ENDPOINTS from './constants'
import { profilePageReducer, INITIAL_STATE, Types } from './reducer'

function ProfilePage() {
    const { stateLogin } = useLoginStore()
    const { stateProfile, actionsProfile } = useProfileStore()
    const { profile, isLoading } = stateProfile
    const [pageState, dispatch] = useReducer(profilePageReducer, INITIAL_STATE)
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        if (pageState.tabValue === 0) {
            actionsProfile.getProfile()
        }
    }, [pageState.tabValue])

    function getAuthenticationEmail() {
        return profile && profile.emails && profile.emails.find(email => email.authenticationEmail)
    }

    useEffect(() => {
        setPermissions(stateLogin.activeScope.permissions)
    }, [isLoading])

    function checkIfProfileIsAbleToShowPreProtocol() {
        if (permissions) {
            if (permissions.some(perm => perm.featureUrl === ENDPOINTS.TEMPLATES_LIST_BY_AMBITO_ID)) {
                return true
            }
            return false
        }
    }

    useEffect(() => {
        if (Object.keys(profile).length > 0) {
            const payload = {
                currentPhoneNumber: profile.contactInformation.phoneNumber,
                notifications: profile.notifications,
                authenticationChoice: profile.authenticationType,
            }

            if (profile.emails && profile.emails.length > 0) {
                payload.authenticationSelectedEmail = getAuthenticationEmail() || {}
            }

            dispatch({ type: Types.SET_INITIAL_VALUES, payload })
        }
    }, [profile])

    function hasDiffNotificationOptions() {
        if (profile.notifications) {
            const keys = Object.keys(pageState.notifications)
            for (let i = 0; i < keys.length; i++) {
                if (pageState.notifications[keys[i]] !== profile.notifications[keys[i]]) {
                    return true
                }
            }
        }
        return false
    }

    function checkAuthOptionErrors() {
        if (pageState.authenticationChoice === 'Email') {
            if (!pageState.authenticationSelectedEmail.email || pageState.authenticationSelectedEmail.email === '') {
                return true
            }

            const initAuthEmail = getAuthenticationEmail()

            if (!initAuthEmail) {
                return false
            }

            if (initAuthEmail && initAuthEmail.email !== pageState.authenticationSelectedEmail.email) {
                return false
            }
        }

        if (profile.authenticationType !== pageState.authenticationChoice) {
            return false
        }

        return true
    }

    function checkForNotificationErrors() {
        const { emailNotification, smsNotification } = pageState.notifications

        if (hasDiffNotificationOptions() && (emailNotification || smsNotification)) {
            return false
        }
        return true
    }

    function handleSave() {
        actionsProfile.updateProfile({
            notifications: pageState.notifications,
            contactInformation: { phoneNumber: pageState.currentPhoneNumber },
            twoFactorAuthentication: {
                authenticationType: pageState.authenticationChoice,
                value:
                    pageState.authenticationChoice === 'Email'
                        ? pageState.authenticationSelectedEmail.email
                        : profile.contactInformation.personPhoneNumber,
            },
        })
    }

    function checkForContactInfoErrors() {
        if (
            profile.contactInformation &&
            profile.contactInformation.phoneNumber !== pageState.currentPhoneNumber &&
            pageState.currentPhoneNumber !== '' &&
            !validatePhoneUtil(pageState.currentPhoneNumber)
        ) {
            return false
        }

        return true
    }

    function disableSaveButton() {
        if (!checkForContactInfoErrors() || !checkForNotificationErrors() || !checkAuthOptionErrors()) {
            return false
        }
        return true
    }

    return (
        <Content>
            <PageTitleComponent title='Perfil' />
            <STabs
                value={pageState.tabValue}
                onChange={(event, newTabValue) => dispatch({ type: Types.SET_TAB_VALUE, payload: newTabValue })}
                indicatorColor='primary'
            >
                <Tab label='Meu Perfil' />
                <Tab label='Gestão de e-mails' />
                <Tab label='Alocação' />
                <Tab label='Protocolos' />

                {checkIfProfileIsAbleToShowPreProtocol() && <Tab label='Pré-Protocolo' />}
            </STabs>
            {pageState.tabValue === 0 && (
                <Box component='div' py={3}>
                    {!isLoading &&
                    Object.keys(profile).length > 0 &&
                    pageState.authenticationChoice &&
                    profile.contactInformation ? (
                        <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                                <ProfileDetails
                                    photo={profile.photo || ''}
                                    personName={profile.personName}
                                    nameOnCard={profile.nameOnCard}
                                    documentType={profile.documentType}
                                    documentNumber={profile.documentNumber}
                                    documentValidity={profile.documentValidity}
                                    personTaxIndentificationNumber={profile.personTaxIndentificationNumber}
                                />
                                <OpenNewTabButton
                                    url='https://www.ecce.gov.pt/repositorio/politica-de-privacidade/'
                                    text='Leia a nossa Política de Privacidade'
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ContactInformation
                                    phoneNumber={pageState.currentPhoneNumber}
                                    onPhoneNumberChange={value =>
                                        dispatch({ type: Types.SET_CURRENT_PHONE_NUMBER, payload: value })
                                    }
                                />
                                <AuthenticationSelection
                                    authenticationEmail={pageState.authenticationSelectedEmail}
                                    emails={profile.emails}
                                    authenticationType={pageState.authenticationChoice}
                                    onChange={dispatch}
                                    hasPersonPhoneNumber={profile.contactInformation.personPhoneNumber}
                                    isPending={!!profile.contactInformation.mobilePhonePending}
                                />
                                <NotificationOptions
                                    notifications={pageState.notifications}
                                    onNotificationChange={value =>
                                        dispatch({ type: Types.SET_NOTIFICATIONS, payload: value })
                                    }
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <SCircular>
                            <CircularProgress size={30} />
                        </SCircular>
                    )}

                    <ActionBarComponent backText='Voltar' backLink='/'>
                        <Button
                            variant='contained'
                            color='inherit'
                            startIcon={<GetApp />}
                            disabled={Object.keys(stateProfile.profile).length < 1}
                            onClick={() => {
                                downloadJsonFile(stateProfile.profile)
                            }}
                        >
                            Dados Pessoais
                        </Button>
                        <Button variant='contained' color='primary' disabled={disableSaveButton()} onClick={handleSave}>
                            Guardar
                        </Button>
                    </ActionBarComponent>
                </Box>
            )}
            {pageState.tabValue === 1 && (
                <Box component='div' py={3} px={4}>
                    <EmailListTab />
                </Box>
            )}
            {pageState.tabValue === 2 && (
                <Box component='div' py={3} px={4}>
                    <AllocationListTab />
                </Box>
            )}
            {pageState.tabValue === 3 && (
                <Box component='div' py={3} px={4}>
                    <ProtocolListTab />
                </Box>
            )}
            {checkIfProfileIsAbleToShowPreProtocol()
                ? pageState.tabValue === 4 && (
                      <Box component='div' py={3} px={4}>
                          <PreProtocolTab />
                      </Box>
                  )
                : null}
        </Content>
    )
}

export default ProfilePage
