/* eslint-disable dot-notation */
import axios from 'axios'

import AuthService from './auth.service'

const auth = new AuthService(sessionStorage)

const URL = process.env.REACT_APP_API_URL
const PORT = process.env.REACT_APP_API_PORT
const CONTEXT = process.env.REACT_APP_API_CONTEXT

export const BASE_URL = PORT !== '' ? `${URL}:${PORT}/${CONTEXT}/` : `${URL}/${CONTEXT}/`

const apiService = axios.create({
    baseURL: `${BASE_URL}`,
})

apiService.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
//* Request interceptor for custom header config
apiService.interceptors.request.use(config => {
    const payload = auth.getToken()
    const scopeId = Number(auth.getActiveScopeId())

    const newConfig = config

    if (payload) {
        newConfig.headers['Authorization'] = `Bearer ${payload}`
        newConfig.headers['XAmbitoId'] = scopeId
    }

    return newConfig
})

async function serverLogout() {
    // TODO: LOGOUT LOGIN AND ROUTE TO LOGIN PAGE
    try {
        window.location.href = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/${
            process.env.REACT_APP_API_CONTEXT
        }/users/saml/requestLogoutSamlToken?accessToken=${auth.getToken()}`
        // const res = await apiService.get('users/saml/requestLogoutSamlToken')
        auth.doLogout()
        // TODO: API POST TO LOGOUT USER ON THE BACKEND SERVER
        //* Triggers page reload, redirecting the unauthenticated user to the Login Page
        // eslint-disable-next-line no-restricted-globals
        // location.reload()
    } catch (error) {
        console.log('error.response: ', error.response)
    }
}

//* Response interceptor to verify if the Session has Expired
apiService.interceptors.response.use(undefined, error => {
    //* CHECKS IF THE SESSION HAS EXPIRED
    if (error.response && error.response.data && error.response.data.result === -2) {
        serverLogout()
        return Promise.reject(error.response)
    }
    return Promise.reject(error)
})

export default apiService
