import styled, { css } from 'styled-components'

export const SAvatar = styled.div`
    border: 1px solid ${props => (props.hasPhoto ? 'transparent' : props.theme.palette.warning.dark)};
    background-color: ${props => props.theme.palette.grey.xLight};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 135px;
    max-width: 100%;
    height: ${props => (props.hasPhoto ? '180px' : '160px')};
    border-radius: 0;
    margin-top: 8px;

    && img {
        object-position: top;
        width: 135px;
        object-fit: cover;
        height: 154px;
        bottom: ${props => (props.hasPhoto ? '9px' : '0')};
        position: relative;
        background-color: ${props => props.theme.palette.grey.light};
    }

    .add-avatar {
        position: absolute;
        bottom: ${props => (props.hasPhoto ? '20px' : '0')};
        width: 100%;
        border-radius: 0;
        padding: 5px 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.7);
        transition: all 0.2s linear;
    }
`

export const SPreview = styled.div`
    width: 100%;
    max-width: 100%;
    height: 160px;
    border-radius: 5px;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const dragActive = css`
    border-color: ${props => props.theme.palette.success.light};
`

const dragReject = css`
    border-color: ${props => props.theme.palette.error.light};
`

export const SDropContainerImage = styled.div.attrs({
    className: 'dropZone',
})`
    ${props => props.isDragActive && dragActive}
    ${props => props.isDragReject && dragReject}
`
