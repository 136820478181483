import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Checkbox, FormControlLabel } from '@material-ui/core'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import DataTablePagination from '../../components/templates/DataTablePagination/DataTablePagination.component'

import { transformTimestampToDate, transformTimestampToSimpleDate } from '../../utils/date.util'

import { useTableStore } from '../../store/Table/Table.store'

import { ItemChip } from './Orders.styles'

function Orders() {
    const [myOrders, setMyOrders] = useState(true)
    const history = useHistory()

    const { tableActions } = useTableStore()

    const columns = [
        {
            field: 'orderId',
            title: 'Número',
        },
        {
            field: 'orderDate',
            title: 'Data do Pedido',
            render: ({ orderDate }) => (orderDate ? transformTimestampToSimpleDate(orderDate) : ''),
            headerStyle: {
                textAlign: 'center',
            },
        },
        {
            field: 'entityName',
            title: 'Entidade Solicitante',
        },
        {
            field: 'invoiceNumber',
            title: 'Fatura',
        },
        {
            field: 'status',
            title: 'Estado',
            render: rowData =>
                rowData && rowData.status ? (
                    <ItemChip label={rowData.status.label} typecolor='orders' type={rowData.status.id} />
                ) : (
                    ''
                ),
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            },
        },
        {
            field: 'updatedDate',
            title: 'Data do Estado',
            render: ({ updatedDate }) => (updatedDate ? transformTimestampToDate(updatedDate) : ''),
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            },
        },
    ]

    function handleCheckboxChange() {
        setMyOrders(!myOrders)
    }

    function handleRowClick(event, rowData) {
        tableActions.setAllowSaveTableHistory(true)
        history.push(`/order/${rowData.orderId}`)
    }

    return (
        <Fragment>
            <ContentComponent>
                <PageTitleComponent title='Pedidos' />
                <FormControlLabel
                    control={
                        <Checkbox checked={myOrders} onChange={handleCheckboxChange} name='myOrders' color='primary' />
                    }
                    label='Exibir somente meus pedidos'
                />
                <DataTablePagination
                    title='Lista de pedidos'
                    columns={columns}
                    myOrders={myOrders}
                    endPoint={myOrders ? 'orders/entityProtocol' : 'orders/entityProtocol'}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                />
            </ContentComponent>
        </Fragment>
    )
}

export default Orders
