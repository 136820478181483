export const Types = {
    SET_INITIAL_DATA: 'SET_INITIAL_DATA',
    SET_ON_VALIDATION_STEP: 'SET_ON_VALIDATION_STEP',
    SET_IS_EDITING: 'SET_IS_EDITING',
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
}

export const REDUCER_INITIAL_STATE = {
    authHash: null,
    validationHash: null,
    onValidationStep: false,
    isEditing: false,
    isModalOpen: false,
}

export function reducer(state, action) {
    switch (action.type) {
        case Types.SET_INITIAL_DATA:
            return { ...state, authHash: action.payload.authHash, validationHash: action.payload.validationHash }
        case Types.SET_ON_VALIDATION_STEP:
            return { ...state, onValidationStep: action.payload.onValidationStep }
        case Types.SET_IS_EDITING:
            return { ...state, isModalOpen: false, onValidationStep: false }
        case Types.OPEN_MODAL:
            return { ...state, isModalOpen: true }
        case Types.CLOSE_MODAL:
            return { ...state, isModalOpen: false }
        default:
            return state
    }
}
