export const DEFAULT_DURATION = {
    id: 0,
    label: 'Duração do SSL',
    price: '',
}

export const DEFAULT_DOMAINS_TYPE = {
    id: 0,
    label: 'Selecione o tipo do certificado',
}

export const DEFAULT_DOCUMENT_TYPE = { id: 0, label: 'Tipo de Documento *' }
