import styled from 'styled-components'

export const SCircular = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-top: 50px;
        font-size: 30px;
    }
`
