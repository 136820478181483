import React from 'react'
import PropTypes from 'prop-types'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import { IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

import { SElectronicSealItem, SSuccessIcon, SWarningIcon } from './ProductTypeElectronicSeal.styles'

const propTypes = {
    title: PropTypes.string,
    isValid: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleDownload: PropTypes.func,
}

const defaultProps = {
    title: 'Selo Eletrónico - Favor preencher dados.',
    isValid: false,
    handleDelete: () => {},
    handleEdit: () => {},
    handleDownload: () => {},
}

// Product Type - ElectronicSeal
function ProductTypeElectronicSealComponent({ title, isValid, handleDelete, handleEdit, handleDownload }) {
    return (
        <SElectronicSealItem>
            {isValid ? <SSuccessIcon /> : <SWarningIcon />}
            <span className='card-name'>{title}</span>

            <div className='card-actions'>
                <IconButton aria-label='delete' size='small' onClick={handleDelete}>
                    <Delete fontSize='small' />
                </IconButton>
                <IconButton aria-label='edit' size='small' onClick={handleEdit}>
                    <Edit fontSize='small' />
                </IconButton>
                {isValid && (
                    <IconButton aria-label='download' size='small' onClick={handleDownload}>
                        <CloudDownloadIcon fontSize='small' />
                    </IconButton>
                )}
            </div>
        </SElectronicSealItem>
    )
}

ProductTypeElectronicSealComponent.propTypes = propTypes
ProductTypeElectronicSealComponent.defaultProps = defaultProps

export default ProductTypeElectronicSealComponent
