/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'

import OpenNewTabButton from '../../components/templates/OpenNewTabButton/OpenNewTabButton.component'

import { SActions, STextInfo, SRequired } from './UploadDocument.styles'
import { useRequestUserStore } from '../../store/RequestUser/RequestUser.store'

import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'
import { transformTimestampToReversedNewDateUtil } from '../../utils'
import FCaptcha from '../../components/FCaptcha/FCaptcha.component'
import useValidationForm from '../../hooks/useValidationForm.hook'

import { UPLOAD_INFO_MESSAGE } from '../../constants/dictionary/dictionary'

const propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}
function UploadDocumentForm({ setActiveStep }) {
    // REQUEST USER STATE
    const { stateRequestUser, actionsRequestUser } = useRequestUserStore()
    // CHECKBOXES STATES
    const [checkedTruth, setCheckedTruth] = useState(false)
    const [checkedPrivacy, setCheckedPrivacy] = useState(false)
    // UPLOAD FILE CONFIG STATES
    const [files, setFiles] = useState([])
    const refCaptcha = useRef()

    // FORM STATE
    const INITIAL_STATE = {
        yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
    }
    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE)

    function handleCheckChange(event) {
        const fieldName = event.target.name
        switch (fieldName) {
            case 'checkedTruth':
                setCheckedTruth(!checkedTruth)
                return null
            case 'checkedPrivacy':
                setCheckedPrivacy(!checkedPrivacy)
                return null
            default:
                return null
        }
    }
    function handleNext() {
        //* Creates the payload unifying the whole state and formats values to the expected
        //* structure (removing masks from forms and formatting date to expected way).
        const { user, entity } = stateRequestUser
        const payload = {
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
            solicitacaoAcesso: {
                personTaxIdentificationNumber: user.personTaxIdentificationNumber.replace(/\s/g, ''),
                personName: user.personName,
                documentType: user.documentType,
                documentNumber: user.documentNumber,
                documentValidity: transformTimestampToReversedNewDateUtil(user.documentValidity),
                personPhoneNumber: user.personPhoneNumber.replace(/\s/g, ''),
                role: user.role,
                personEmail: user.personEmail,
                entityTaxIdentificationNumber: entity.entityTaxIdentificationNumber.replace(/\s/g, ''),
                entityName: entity.entityName,
                organizationalUnitName: entity.organizationalUnitName,
                entityAddress: entity.entityAddress,
                entityLocation: entity.entityLocation,
                entityPostalCode: entity.entityPostalCode,
                reason: entity.reason,
                checkedTruth,
                checkedPrivacy,
                files: files.map(file => ({ base64: file.base64, name: file.name })),
            },
        }
        actionsRequestUser.registerAccess(payload)
        setActiveStep(3)
    }
    function handleBack() {
        setActiveStep(1)
    }

    return (
        <form>
            <STextInfo>{UPLOAD_INFO_MESSAGE}</STextInfo>
            <UploadFileComponent
                id='files'
                initialFiles={files}
                handleFiles={fileList => setFiles(fileList)}
                label='Selecione ou arraste ficheiro aqui no formato PDF'
                isSingleFile
            />
            <SRequired>Campo obrigatório</SRequired>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            name='checkedTruth'
                            value='checkedTruth'
                            checked={checkedTruth}
                            onChange={handleCheckChange}
                        />
                    }
                    label='Confirmo que os dados informados estão corretos.'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name='checkedPrivacy'
                            value='checkedPrivacy'
                            checked={checkedPrivacy}
                            onChange={handleCheckChange}
                        />
                    }
                    label='Aceito os termos da Política de Privacidade.'
                />
            </FormGroup>
            <OpenNewTabButton
                url='https://www.ecce.gov.pt/repositorio/politica-de-privacidade/'
                text='Leia a nossa Política de Privacidade'
            />
            <Grid container direction='row' alignItems='flex-end' spacing={1}>
                <Grid item xs={12}>
                    <FCaptcha
                        refCaptcha={refCaptcha}
                        value={values.yourFirstCaptchaUserInput.value}
                        error={touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false}
                        helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SActions>
                <Button variant='contained' color='default' onClick={handleBack} startIcon={<ArrowBack />}>
                    Voltar
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!files.length || !checkedTruth || !checkedPrivacy || !isValidForm}
                    endIcon={<ArrowForward />}
                    onClick={handleNext}
                >
                    Enviar Solicitação
                </Button>
            </SActions>
        </form>
    )
}

UploadDocumentForm.propTypes = propTypes

export default UploadDocumentForm
