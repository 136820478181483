import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import DataTablePagination from '../../components/templates/DataTablePagination/DataTablePagination.component'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'

import { transformTimestampToDateUtil } from '../../utils/index'

import { useTableStore } from '../../store/Table/Table.store'

import { ItemChip } from './Timestamps.styles'

const COLUMNS = [
    {
        field: 'typeLabel',
        title: 'Serviço',
    },
    {
        field: 'amountAvailable',
        title: 'Quantidade Disponível',
        headerStyle: {
            textAlign: 'center',
        },
        cellStyle: {
            textAlign: 'center',
        },
    },
    {
        field: 'timestampMail',
        title: 'Email do Selo',
    },
    {
        field: 'permissionType',
        title: 'Tipo de Permissão',
    },
    {
        field: 'notificateNewUsers',
        title: 'Notificar Novos Utilizadores',
        render: ({ notificateNewUsers }) => (notificateNewUsers ? 'Sim' : 'Não'),
    },
    {
        field: 'updatedDate',
        title: 'Data do estado',
        render: ({ status }) => (status ? transformTimestampToDateUtil(status.updatedDate) : ''),
    },
    {
        field: 'statusLabel',
        title: 'Estado',
        headerStyle: {
            textAlign: 'center',
        },
        render: ({ status }) =>
            status ? <ItemChip label={status.label} type={status.id} typecolor='timestampStatus' /> : '',
    },
]

function Timestamps() {
    const history = useHistory()
    const { tableActions } = useTableStore()

    function handleRowClick(event, rowData) {
        tableActions.setAllowSaveTableHistory(true)
        history.push(`/timestamp/${rowData.id}`)
    }

    return (
        <Fragment>
            <ContentComponent>
                <PageTitleComponent title='Selos Temporais' />
                <DataTablePagination
                    columns={COLUMNS}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    endPoint='timestamps/client'
                    exportCSV={{ isFromAPI: true, filename: 'selos-temporais' }}
                />
            </ContentComponent>
        </Fragment>
    )
}

export default Timestamps
