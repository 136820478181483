import React, { Fragment, useReducer, useEffect } from 'react'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Button } from '@material-ui/core'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import DataTablePagination from '../../components/templates/DataTablePagination/DataTablePagination.component'
import DownloadFile from '../../components/templates/DownloadFile/DownloadFile'
import Modal, { ModalBodyComponent, ModalFooterComponent } from '../../components/templates/Modal/Modal.component'
import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'

import { InvoicesTypes } from '../../constants/selections/selections'
import { transformTimestampToSimpleDateUtil, formatterCurrencyUtil } from '../../utils'

import useServiceCallApi from '../../hooks/useServiceCallApi.hook'
import { invoiceReducer, INITIAL_STATE, Types } from './reducer'

import { SItemChip } from './Invoices.styles'

function InvoicesPage() {
    const [pageState, dispatch] = useReducer(invoiceReducer, INITIAL_STATE)
    const [requestState, callService] = useServiceCallApi(null)

    const ENDPOINT = 'invoices/client/uploadPaymentFile'
    const tableRef = React.useRef()

    const COLUMNS = [
        {
            field: 'invoiceNumber',
            title: 'Número',
        },
        {
            field: 'totalValue',
            title: 'Valor (€)',
            render: rowData =>
                rowData.totalValue ? formatterCurrencyUtil.format(rowData.totalValue).replace('€', '') : null,
        },
        {
            field: 'dueDate',
            title: 'Data de vencimento',
            render: rowData => (rowData.dueDate ? transformTimestampToSimpleDateUtil(rowData.dueDate) : null),
        },
        {
            field: 'invoiceFile',
            title: 'Fatura',
            render: rowData => {
                if (rowData.invoiceFile) {
                    return <DownloadFile endpoint={`/entities/invoices/${rowData.id}/downloadInvoice`} />
                }
                return ''
            },
        },
        {
            title: 'Enviar Comprovativo',
            render: ({ status, id }) =>
                status.id === InvoicesTypes.A_PAGAMENTO && (
                    <span>
                        <CloudUploadIcon
                            color='primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() => dispatch({ type: Types.OPEN_MODAL, payload: id })}
                        />
                    </span>
                ),
        },
        {
            field: 'docketFile',
            title: 'Recibo',
            render: rowData => {
                if (rowData.docketFile) {
                    return <DownloadFile endpoint={`/entities/invoices/${rowData.id}/downloadDocket`} />
                }
                return ''
            },
        },
        {
            field: 'updatedDate',
            title: 'Data do estado',
            render: rowData => (rowData.updatedDate ? transformTimestampToSimpleDateUtil(rowData.updatedDate) : null),
        },
        {
            field: 'status',
            title: 'Estado',
            render: rowData => {
                const { status } = rowData
                return status ? (
                    <SItemChip label={status.label} type={status.id} typecolor='invoices' isactive={status.label} />
                ) : null
            },
        },
    ]

    useEffect(() => {
        if (requestState.url === ENDPOINT && !requestState.error.hasError && requestState.data) {
            // tableRef.current.onQueryChange()
            dispatch({ type: Types.STOP_LOADING })
        }
    }, [requestState])

    function handleConfirmation() {
        const customFileName = pageState.file[0].name
            .split('.')[0]
            .toLowerCase()
            .replaceAll(' ', '-')

        callService(ENDPOINT, {
            requestType: 'post',
            showSnackBarOnSuccess: true,
            data: {
                invoiceId: pageState.invoiceId,
                base64: pageState.file[0].base64,
                fileName: `${customFileName}-comprovativo.pdf`,
            },
        })

        dispatch({ type: Types.START_LOADING })

        setTimeout(() => {
            window.location.reload(false)
        }, 1000)
    }

    return (
        <ContentComponent>
            <PageTitleComponent title='Faturas' />
            <DataTablePagination
                columns={COLUMNS}
                endPoint='entities/invoices'
                tableRef={tableRef}
                isLoading={pageState.isLoading}
            />
            <Modal
                title='anexar comprovativo de pagamento'
                open={pageState.isModalOpen}
                onClick={() => dispatch({ type: Types.CLOSE_MODAL })}
            >
                <ModalBodyComponent>
                    <p>
                        {pageState.selectedRowData &&
                            `Anexar um comprovativo de pagamento para a fatura nº ${pageState.selectedRowData.invoiceNumber}`}
                    </p>
                    <UploadFileComponent
                        id='files'
                        initialFiles={pageState.file}
                        handleFiles={fileList => dispatch({ type: Types.ADD_FILE, payload: fileList })}
                        isSingleFile
                    />
                </ModalBodyComponent>
                <ModalFooterComponent>
                    <Fragment>
                        <Button variant='contained' size='small' onClick={() => dispatch({ type: Types.CLOSE_MODAL })}>
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={pageState.file.length === 0}
                            onClick={handleConfirmation}
                        >
                            Confirmar
                        </Button>
                    </Fragment>
                </ModalFooterComponent>
            </Modal>
        </ContentComponent>
    )
}

export default InvoicesPage
