import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import { useOrdersStore } from '../../store/Orders/Orders.store'
import NewOrderCardConfigForm from '../../forms/NewOrderCardConfig/NewOrderCardConfig.form'

const propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    params: {},
}

function ConfigCardStep(props) {
    const { match } = props
    const { params } = match
    // ORDERS STORE
    const { stateOrders } = useOrdersStore()
    // Search cards arrays on state by card id
    const [card] = useState(stateOrders.services.cards.find(item => item.id === params.id))

    return (
        <Fade in>
            <ContentComponent>
                <PageTitleComponent title='Configuração do Cartão' />
                <NewOrderCardConfigForm activeCard={card} stateOrders={stateOrders} />
            </ContentComponent>
        </Fade>
    )
}

ConfigCardStep.propTypes = propTypes
ConfigCardStep.defaultProps = defaultProps

export default ConfigCardStep
