export const INITIAL_STATE = {
    nipc: { value: '', validation: ['validateNotEmptyUtil', 'validateNipcUtil'], mask: 'maskNIFUtil' },
    nif: { value: '', validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'], mask: 'maskNIFUtil' },
    documentNumber: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validateNumberUtil', { validator: 'validateMaxLengthUtil', args: [10] }],
    },
    password: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validatePasswordUtil'],
    },
    repeatPassword: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validatePasswordUtil', 'validateStringEqualUtil'],
    },
    yourFirstCaptchaUserInput: { value: '', validation: ['validateNotEmptyUtil'] },
}

export const ENDPOINT = {
    CREATE_TIMESTAMP_MAIN_USER: '/timestamps/client/user/main/register',
}
