/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'

import { CssBaseline, SWrapper, SMain } from './styles/global'
import { useLoginStore } from './store/Login/Login.store'

import Routes from './routes'
import HeaderComponent from './components/templates/Header/Header.component'
import SidebarComponent from './components/templates/Sidebar/Sidebar.component'

import AlertComponent from './components/templates/Alert/Alert.component'
import { useAlertStore } from './store/Alert/Alert.store'

const App = () => {
    const { stateLogin, actionsLogin } = useLoginStore()
    const { stateAlert } = useAlertStore()

    useEffect(() => {
        actionsLogin.getIsAuthenticated()
    }, [])

    return (
        <Fragment>
            <CssBaseline />
            <SWrapper isAuthenticated={stateLogin.isAuthenticated}>
                {stateLogin.isAuthenticated && <HeaderComponent />}
                <SidebarComponent />
                <SMain isAuthenticated={stateLogin.isAuthenticated}>
                    <Routes />
                    {stateAlert.open && <AlertComponent />}
                </SMain>
            </SWrapper>
        </Fragment>
    )
}

export default App
