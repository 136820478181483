import React, { useRef, Fragment } from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, Grid, TextField } from '@material-ui/core'

import useValidationForm from '../../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../../hooks/useServiceCallApi.hook'

import MessageComponent from '../../../components/templates/Message/Message.component'
import FCaptcha from '../../../components/FCaptcha/FCaptcha.component'
import Callout from '../../../components/templates/Callout/Callout'

import { INITIAL_STATE, ENDPOINTS } from '../constants'

import { SButton, SCircular, STitle } from '../TimestampConsumer.styles'

function EmailConsumer(props) {
    const [requestState, callService] = useServiceCallApi(null)
    const refCaptcha = useRef()
    const { hash } = props
    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(
        INITIAL_STATE.EMAIL_USER
    )

    function handleSubmit() {
        const payload = {
            email: values.email.value,
            password: values.password.value,
            hash,
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
        }

        callService(ENDPOINTS.CREATE_TIMESTAMP_CONSUMER_EMAIL_USER, {
            requestType: 'post',
            data: payload,
            showSnackBarOnSuccess: true,
        })
    }

    function renderContent() {
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                    <span>A processar o pedido...</span>
                </SCircular>
            )
        }

        if (
            requestState.url &&
            requestState.data &&
            requestState.url === ENDPOINTS.CREATE_TIMESTAMP_CONSUMER_EMAIL_USER &&
            Object.keys(requestState.data).length > 0
        ) {
            return <MessageComponent type='success' message={requestState.data.message} textBack='Login' />
        }

        return (
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id='email'
                            name='email'
                            label='E-mail do utilizador'
                            type='email'
                            margin='dense'
                            variant='outlined'
                            value={values.email.value}
                            error={touched.email ? Boolean(errors.email) : false}
                            helperText={touched.email ? errors.email : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <STitle>Defina uma palavra-passe para consumo do Selo Temporal</STitle>
                        <TextField
                            id='password'
                            name='password'
                            label='Nova palavra-passe'
                            type='password'
                            margin='dense'
                            variant='outlined'
                            value={values.password.value}
                            error={touched.password ? Boolean(errors.password) : false}
                            helperText={touched.password ? errors.password : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                        <TextField
                            id='repeatPassword'
                            name='repeatPassword'
                            label='Repita palavra-passe'
                            type='password'
                            margin='dense'
                            variant='outlined'
                            value={values.repeatPassword.value}
                            error={
                                values.repeatPassword.value.length !== 0 &&
                                values.password.value.length !== 0 &&
                                values.repeatPassword.value.length >= values.password.value.length
                                    ? Boolean(errors.repeatPassword)
                                    : false
                            }
                            helperText={
                                values.repeatPassword.value.length !== 0 &&
                                values.password.value.length !== 0 &&
                                values.repeatPassword.value.length >= values.password.value.length
                                    ? errors.repeatPassword
                                    : ''
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                        <Callout>
                            A palavra-passe deve conter um mínimo de 9 caracteres, entre os quais letras maiúsculas
                            (A...Z), minúsculas (a...z) e números (0...9).
                        </Callout>
                    </Grid>
                </Grid>
                <Grid container direction='row' alignItems='flex-end' spacing={1}>
                    <Grid item xs={9}>
                        <FCaptcha
                            refCaptcha={refCaptcha}
                            value={values.yourFirstCaptchaUserInput.value}
                            error={
                                touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false
                            }
                            helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SButton
                            variant='contained'
                            color='primary'
                            disabled={!isValidForm}
                            onClick={handleSubmit}
                            fullWidth
                        >
                            Guardar
                        </SButton>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <Fragment>
            <h3>Registo de utilizador para consumo de Selo Temporal</h3>
            {renderContent()}
        </Fragment>
    )
}

EmailConsumer.propTypes = {
    hash: PropTypes.string,
}

EmailConsumer.defaultProps = {
    hash: null,
}

export default EmailConsumer
