import React, { memo, useEffect, useReducer, useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'

import useServiceCallApi from '../../../../hooks/useServiceCallApi.hook'

import UploadFileComponent from '../../../../components/templates/UploadFile/UploadFile.template'
import PaperComponent, { PaperHeaderComponent } from '../../../../components/templates/Paper/Paper.component'
import TemplateTable from './Template.table'
import ProposalTable from './Proposal.table'

import { RefreshingContainer } from '../../Profile.styles'

import ENDPOINTS from '../../constants'
import { profilePageReducer, Types, INITIAL_STATE } from '../../reducer'

function PreProtocol() {
    const [preProtocolTemplatesState, callPreProtocolService] = useServiceCallApi(null)
    const [proposalState, callProposalService] = useServiceCallApi(null)
    const [pageState, dispatch] = useReducer(profilePageReducer, INITIAL_STATE)
    const [files, setFiles] = useState([])

    useEffect(() => {
        callPreProtocolService(ENDPOINTS.TEMPLATES_LIST_BY_AMBITO_ID, { requestType: 'get' })
        callProposalService(ENDPOINTS.PROTOCOL_PROPOSAL_LIST, { requestType: 'get' })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (
            preProtocolTemplatesState.url === ENDPOINTS.TEMPLATES_LIST_BY_AMBITO_ID &&
            !preProtocolTemplatesState.isLoading &&
            preProtocolTemplatesState.data
        ) {
            dispatch({
                type: Types.SET_PRE_PROTOCOL_TEMPLATES_LIST,
                payload: preProtocolTemplatesState.data.payload,
            })
        }
    }, [preProtocolTemplatesState])

    useEffect(() => {
        if (proposalState.url === ENDPOINTS.PROTOCOL_PROPOSAL_LIST && !proposalState.isLoading && proposalState.data) {
            dispatch({
                type: Types.SET_PRE_PROTOCOL_PROPOSALS_LIST,
                payload: proposalState.data.payload,
            })
        }
    }, [proposalState])

    function handleSubmit() {
        const payload = {
            filename: files[0].name,
            base64: files[0].base64,
        }

        callPreProtocolService(ENDPOINTS.UPLOAD_PROTOCOL_PROPOSAL, {
            requestType: 'post',
            showSnackBarOnSuccess: true,
            data: payload,
        })

        dispatch({
            type: Types.SET_REFRESHING_PROPOSALS,
            payload: true,
        })

        setFiles([])
    }

    useEffect(() => {
        callProposalService(ENDPOINTS.PROTOCOL_PROPOSAL_LIST, { requestType: 'get' })

        setTimeout(() => {
            dispatch({
                type: Types.SET_REFRESHING_PROPOSALS,
                payload: false,
            })
        }, 2500)
    }, [pageState.refreshingProposals]) // eslint-disable-line

    return (
        <Grid container>
            <Grid container justify='space-between'>
                <Grid item xs={7}>
                    <TemplateTable data={pageState.templates} />
                </Grid>

                <Grid item xs={5} style={{ paddingLeft: '70px' }}>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='HISTÓRICO DE PROPOSTAS SUBMETIDAS' />
                        <Grid item xs={12}>
                            {!proposalState.isLoading && !pageState.refreshingProposals ? (
                                <ProposalTable data={pageState.proposals} />
                            ) : (
                                <RefreshingContainer>
                                    <Typography>Atualizando propostas submetidas!</Typography>
                                    <CircularProgress size={25} />
                                </RefreshingContainer>
                            )}
                        </Grid>
                    </PaperComponent>

                    <PaperComponent noborder>
                        <PaperHeaderComponent title='SUBMETER PROPOSTA' />
                        <Grid item xs={12}>
                            <UploadFileComponent
                                id='files'
                                initialFiles={files}
                                handleFiles={fileList => setFiles(fileList)}
                                label='Selecione ou arraste ficheiro aqui no formato PDF ou DOCX'
                                isSingleFile
                            />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={files.length !== 1}
                                onClick={handleSubmit}
                            >
                                {!preProtocolTemplatesState.isLoading ? 'Guardar' : <CircularProgress size={25} />}
                            </Button>
                        </Grid>
                    </PaperComponent>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default memo(PreProtocol)
