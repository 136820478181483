import apiService from '../../services/api.service'

const ConfirmationService = (function() {
    async function apiValidateEmail(prefix, hash) {
        const endPoint = prefix.includes('confirmationprofilechangeemail')
            ? 'users/validatechangeemail'
            : 'users/validateemail'

        const response = await apiService
            .post(endPoint, { hash: hash })
            .then(res => {
                return { isSuccess: true, res }
            })
            .catch(err => {
                return { isSuccess: false, err }
            })
        return response
    }
    return { apiValidateEmail }
})()

export default ConfirmationService
