import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './styles'

const IconWildcard = ({ fill = '#960008' }) => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
                <g transform='translate(-999 -183)'>
                    <g transform='translate(999 183)'>
                        <path d='M0,0H30V30H0Z' fill='none' />
                        <path
                            d='M25,23.25a2.632,2.632,0,0,0,2.487-2.75L27.5,6.75A2.642,2.642,0,0,0,25,4H5A2.642,2.642,0,0,0,2.5,6.75V20.5A2.642,2.642,0,0,0,5,23.25H0V26H30V23.25ZM5,6.75H25V20.5H5Z'
                            fill={fill}
                        />
                    </g>
                    <g transform='translate(1012 198)' fill={fill} stroke={fill} strokeLinejoin='round' strokeWidth='1'>
                        <rect width='10' height='3' stroke='none' />
                        <rect x='0.5' y='0.5' width='9' height='2' fill='none' />
                    </g>
                    <g transform='translate(2)'>
                        <circle cx='1.5' cy='1.5' r='1.5' transform='translate(1008 191)' fill={fill} />
                        <circle cx='1.5' cy='1.5' r='1.5' transform='translate(1004 191)' fill={fill} />
                        <circle cx='1.5' cy='1.5' r='1.5' transform='translate(1012 191)' fill={fill} />
                    </g>
                    <g transform='translate(1005 194.503)'>
                        <path d='M0,0H6.5V6.079H0Z' fill='none' />
                        <path
                            d='M7.659,2.736H7.4v-.5A1.275,1.275,0,0,0,6.091,1,1.275,1.275,0,0,0,4.784,2.24v.5H4.523a.511.511,0,0,0-.523.5V5.713a.511.511,0,0,0,.523.5H7.659a.511.511,0,0,0,.523-.5V3.233A.511.511,0,0,0,7.659,2.736ZM6.091,4.969a.5.5,0,1,1,.523-.5A.511.511,0,0,1,6.091,4.969ZM6.9,2.736H5.28v-.5a.811.811,0,0,1,1.62,0Z'
                            transform='translate(-2.841 0.507)'
                            fill={fill}
                        />
                    </g>
                </g>
            </svg>
        </IconContainer>
    )
}

IconWildcard.propTypes = {
    fill: PropTypes.string,
}
IconWildcard.defaultProps = {
    fill: '',
}

export default memo(IconWildcard)
