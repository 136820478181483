import React, { memo } from 'react'
import PropTypes from 'prop-types'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'

import api from '../../../services/api.service'
import { useAlertStore } from '../../../store/Alert/Alert.store'

const PDF_METADATA = 'data:application/pdf;base64,'
const DOCX_METADATA = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
// const CER_METADATA = 'data:application/x-x509-ca-cert;base64,'

function DownloadFile({ endpoint }) {
    const { actionsAlert } = useAlertStore()

    const download = async () => {
        try {
            const response = await api.get(endpoint)
            const { payload } = response.data

            if (Object.keys(payload).length !== 0) {
                let linkSource = `${payload.base64}`
                if (
                    payload.name.includes('pdf') ||
                    payload.name.includes('cer') ||
                    payload.name.includes('crt') ||
                    payload.name.includes('cer') ||
                    payload.name.includes('zip')
                )
                    linkSource = `${PDF_METADATA}${payload.base64}`
                if (payload.name.includes('docx')) linkSource = `${DOCX_METADATA}${payload.base64}`
                // if (payload.name.includes('cer') || payload.name.includes('crt'))
                //     linkSource = `${CER_METADATA}${payload.base64}`

                const downloadLink = document.createElement('a')
                downloadLink.href = linkSource
                downloadLink.download = payload.name

                downloadLink.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
            }
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data.message)
            }
        }
    }

    return (
        <IconButton color='primary' component='span' onClick={() => download()}>
            <CloudDownloadIcon />
        </IconButton>
    )
}

DownloadFile.propTypes = {
    endpoint: PropTypes.string,
}

DownloadFile.defaultProps = { endpoint: '' }

export default memo(DownloadFile)
