import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'

export const SSnackbar = styled(Snackbar)`
    margin-bottom: 20px;
    .success {
        background-color: ${props => props.theme.palette.success.main};
    }
    .error {
        background-color: ${props => props.theme.palette.error.dark};
    }
    .info {
        background-color: ${props => props.theme.palette.error.dark};
    }
    .warning {
        background-color: ${props => props.theme.palette.warning.dark};
    }
`
export const SSnackbarContent = styled(SnackbarContent)`
    .variant {
        background-color: ${props => props.theme.palette.primary.main};
    }
    .client-snackbar {
        display: flex;
        align-items: center;
    }
    .icon {
        font-size: 20px;
        margin-right: 10px;
    }
    .icon-variant {
        opacity: 0.9;
        margin-right: 10px;
    }
`
export const SIconButton = styled(IconButton)``
