import styled from 'styled-components'

export const SContainer = styled.div`
    background-color: ${props => props.theme.palette.grey.xLight};
    width: calc(100% - 80px);
    padding: 20px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    bottom: 0;
    right: 0;
    z-index: 1199;

    @media (max-width: 599px) {
        width: 100%;
    }

    @media print {
        display: none;
    }
`

export const SBarActions = styled.div`
    button {
        margin-left: 16px;
    }
`
export const SBackButton = styled.div`
    visibility: ${props => (props.hiddenBack ? 'hidden' : 'inherit')};
    a {
        text-decoration: none;
        color: ${props => props.theme.palette.grey.dark};
    }
`
