import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core'

import { LoginProvider } from './store/Login/Login.store'
import { AlertProvider } from './store/Alert/Alert.store'
import { TableProvider } from './store/Table/Table.store'
import { ApiStatusProvider } from './store/ApiStatusServices/ApiStatusServices.store'

import { GlobalStyles, StyledTheme, MaterialTheme } from './styles/themes'
import App from './App'

ReactDOM.render(
    <Router>
        <ThemeProvider theme={StyledTheme}>
            <MuiThemeProvider theme={MaterialTheme}>
                <GlobalStyles />
                <AlertProvider>
                    <TableProvider>
                        <LoginProvider>
                            <ApiStatusProvider>
                                <App />
                            </ApiStatusProvider>
                        </LoginProvider>
                    </TableProvider>
                </AlertProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    </Router>,

    // eslint-disable-next-line no-undef
    document.getElementById('root')
)
