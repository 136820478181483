function hasAtLeastOneUppercaseLetter(value) {
    return /(?=.*[A-Z])/.test(value) ? null : 'Não possui ao menos uma letra maiúscula.'
}

function hasAtLeastOneLowercaseLetter(value) {
    return /(?=.*[a-z])/.test(value) ? null : 'Não possui ao menos uma letra minúscula.'
}

function hasAtLeastOneDigit(value) {
    return /(?=.*\d)/.test(value) ? null : 'Não possui ao menos um número.'
}

function minimumCharactersLength(value) {
    return /(.{9,})/.test(value) ? null : 'Quantidade de caracteres insuficientes'
}

export function validatePasswordRequirements(value) {
    return value
        ? hasAtLeastOneUppercaseLetter(value) ||
              hasAtLeastOneLowercaseLetter(value) ||
              hasAtLeastOneDigit(value) ||
              minimumCharactersLength(value)
        : null
}
