import React from 'react'
import PropTypes from 'prop-types'
import { SHeader, PaperTitle } from './Paper.style'

const propTypes = {
    children: PropTypes.node,
    Icon: PropTypes.element,
    title: PropTypes.string,
}

const defaultProps = {
    children: [],
    Icon: null,
    title: '',
}

const PaperHeaderComponent = ({ children, Icon, title }) => {
    return (
        <SHeader>
            <PaperTitle divider>
                {Icon && <Icon className='materialIcons' />} {title}
                {children}
            </PaperTitle>
        </SHeader>
    )
}

PaperHeaderComponent.propTypes = propTypes
PaperHeaderComponent.defaultProps = defaultProps

export default PaperHeaderComponent
