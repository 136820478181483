import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import { RequestUserReducer, initialState } from './RequestUser.reducer'
import useActions from './RequestUser.actions'
import applyMiddleware from './RequestUser.middleware'
import { useAlertStore } from '../Alert/Alert.store'

const propTypes = {
    children: PropTypes.objectOf(PropTypes.any).isRequired,
}

const RequestUserContext = createContext(null)

export function RequestUserProvider({ children }) {
    const { actionsAlert } = useAlertStore()
    const [stateRequestUser, dispatch] = useReducer(RequestUserReducer, initialState)
    const actionsRequestUser = useActions(stateRequestUser, applyMiddleware(dispatch, actionsAlert))
    const value = { stateRequestUser, actionsRequestUser }

    return <RequestUserContext.Provider value={value}>{children}</RequestUserContext.Provider>
}
RequestUserProvider.propTypes = propTypes

export const useRequestUserStore = () => useContext(RequestUserContext)
