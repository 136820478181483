import LOGIN_TYPES from './Login.types'

const useActions = (state, dispatch) => ({
    resetData: () => {
        dispatch({ type: LOGIN_TYPES.RESET_DATA.SUCCESS })
    },
    showLoading: () => {
        dispatch({ type: LOGIN_TYPES.SHOW_LOADING.SUCCESS })
    },
    hideLoading: () => {
        dispatch({ type: LOGIN_TYPES.HIDE_LOADING.SUCCESS })
    },
    doLogin: (history, login, refCaptcha) => {
        dispatch({ type: LOGIN_TYPES.LOGIN.POST.REQUEST, payload: { history, login, refCaptcha } })
    },
    doCheck: (history, code) => {
        dispatch({ type: LOGIN_TYPES.CHECK.POST.REQUEST, payload: { history, code } })
    },
    doLogout: history => {
        dispatch({ type: LOGIN_TYPES.CLEAR.REQUEST, payload: { history } })
    },
    setActiveScope: (history, scope) => {
        dispatch({ type: LOGIN_TYPES.SET_ACTIVE_SCOPE.REQUEST, payload: { history, scope } })
    },
    getActiveScope: () => {
        dispatch({ type: LOGIN_TYPES.ACTIVE_SCOPE.SUCCESS })
    },
    getIsAuthenticated: () => {
        dispatch({ type: LOGIN_TYPES.AUTHENTICATED.GET.REQUEST, payload: state })
    },
})

export default useActions
