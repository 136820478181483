import styled from 'styled-components'

export const SFeedback = styled.div`
    background-color: #fff;
`

export const SBoxDone = styled.div`
    height: 60vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon-check,
    .icon-error {
        margin: 50px;
    }
    .error-message {
        display: block;
        margin-bottom: 30px;
        font-size: 16px;
    }
`

export const SIconItem = styled.img`
    font-size: 42px;
    margin: 0 30px 0;
`
