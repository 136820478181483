import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const STextInfo = styled.div`
    font-size: 13px;
    line-height: 19px;
    color: ${props => props.theme.palette.grey.dark};
    padding-bottom: 20px;
    span {
        color: ${props => props.theme.palette.grey.main};
        font-style: italic;
        font-weight: bold;
    }
`
export const SRequired = styled.span`
    color: ${props => props.theme.palette.primary.main};
    position: relative;
    top: -19px;
    font-size: 11px;
`

export const SActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 10px;
    button {
        margin-left: 15px;
    }
`
export const SLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
    .action {
        position: absolute;
        left: 0;
        font-size: 12px;
    }
`
export const SCircular = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 16px;
        font-size: 16px;
    }
`
export const ReloadCaptcha = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    span {
        font-size: 16px;
    }
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`
