import { createTypes, createRequestTypes } from '../../utils/typesGenerator.util'

const ORDERS_TYPES = {
    RESET_DATA: createTypes('orders/RESET_DATA'),
    SHOW_LOADING: createTypes('orders/SHOW_LOADING'),
    HIDE_LOADING: createTypes('orders/HIDE_LOADING'),
    CART: createRequestTypes('orders/CART'),
    SERVICE: createRequestTypes('orders/SERVICE'),
    SERVICE_TYPE: createRequestTypes('orders/SERVICE_TYPE'),
    SERVICES: createRequestTypes('orders/SERVICES'),
    PAYMENT: createRequestTypes('orders/PAYMENT'),
    PURCHASE: createRequestTypes('orders/PURCHASE'),
    ORG_UNITS: createRequestTypes('orders/ORG_UNITS'),
    ENTITY: createRequestTypes('orders/ENTITY'),
    ORDERS: createRequestTypes('orders/ORDERS'),
    PREVIEW_ORDERS: createRequestTypes('orders/PREVIEW_ORDERS'),
}

export default ORDERS_TYPES
