/* eslint-disable no-restricted-globals */
import { isAlphaLatinCharacter, isAlphaLatinNumberCharacter, isValidOrganizationName } from './mask.util'

export function validateNotEmpty(value) {
    return !value ? 'Campo obrigatório' : null
}

export function validateRole(value) {
    return (value && value.name === 'Selecione o cargo') || (value && value.name === 'Selecione ou informe o cargo')
        ? 'Campo obrigatório'
        : null
}

export function validateAlphaLatinNumberRole(value) {
    let roleResult = ''
    if (value) {
        roleResult = value.label
    }
    return value && !isAlphaLatinNumberCharacter(roleResult) ? 'Valor inválido' : null
}

export function validateMinLength(value, minLength) {
    return value && String(value).length < minLength ? `${minLength}/${String(value).length}` : null
}

export function validateMaxLength(value, maxLength) {
    if (typeof value !== 'string') {
        return value && String(value.value).length > maxLength ? `${String(value.value).length}/${maxLength}` : null
    }

    return value && String(value).length > maxLength ? `${String(value).length}/${maxLength}` : null
}

export function validateMaxChar100(value) {
    return value && String(value).length > 100 ? `${String(value).length}/100` : null
}

export function validateMaxChar1000(value) {
    return value && String(value).length > 1000 ? `${String(value).length}/1000` : null
}

export function validateEmail(value) {
    return value &&
        !/^(([^<>()[\]\\.,;:áàâãéèêíïóôõöúçñÁÀÂÃÉÊÈÍÏÓÔÕÖÚÇÑ\s@"]+(\.[^<>()[\]\\.,;:áàâãéèêíïóôõöúçñÁÀÂÃÉÊÈÍÏÓÔÕÖÚÇÑ\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
        )
        ? 'E-mail inválido'
        : null
}

export function validatePostalCode(value) {
    return !!value && value.length < 7 ? 'Informar 7 dígitos' : null
}

export function validateNumber(value) {
    return value && !/^[0-9]+$/i.test(value) ? 'Apenas números' : null
}

export function validateInteger(value) {
    return value && !/^[0-9]+$/i.test(value) ? 'Apenas números inteiros' : null
}

export function validatePhone(value) {
    return value && (value.length !== 9 || !/^[0-9]+$/i.test(value)) ? `${value.length}/9` : null
}
export function validateCellphone(value) {
    return value && value[0] !== '9' ? 'Número de telemóvel inválido' : validatePhone(value)
}

export function validateDocumentAlpha9(value) {
    return value && (value.length < 6 || !/^[a-z0-9]+$/i.test(value)) ? `${value.length}/9` : null
}

export function validatePositiveNumber(value) {
    return value && (!/^[0-9]+$/i.test(value) || value <= 0) ? 'Apenas números positivos' : null
}

export function validateNifAlpha9(value) {
    return value && (value.length !== 9 || !/^[a-z0-9]+$/i.test(value)) ? `${value.length}/9` : null
}

export function validateAlphaLatin(value) {
    return value && !isAlphaLatinCharacter(value) ? 'Valor inválido' : null
}

export function validateAlphaLatinNumber(value) {
    return value && !isAlphaLatinNumberCharacter(value) ? 'Valor inválido' : null
}

export function validadeHasAtLeastTwoNames(value) {
    return value && !/(\w.+\s).+/i.test(value) ? 'Tem de colocar pelo menos dois nomes' : null
}

export function validateEntityName(value) {
    return value && !isValidOrganizationName(value) ? 'Valor inválido no nome da Entidade' : null
}

export function validateOrganicUnitName(value) {
    return value && !isValidOrganizationName(value) ? 'Valor inválido no nome da Unidade Orgânica' : null
}

export function validateLocationZoneName(value) {
    return value && !isAlphaLatinCharacter(value) ? 'Valor inválido no nome da Localidade' : null
}

export function isFieldValid(value) {
    return !value
}

export function validateNifNumber(value) {
    if (value) {
        const formatValid = validateNifAlpha9(value)

        if (formatValid) {
            return formatValid
        }
        let hasError = 0

        if (
            !['1', '2', '3', '5', '6', '8'].includes(value.substr(0, 1)) &&
            !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(value.substr(0, 2))
        ) {
            hasError = 1
        }

        const total =
            value[0] * 9 +
            value[1] * 8 +
            value[2] * 7 +
            value[3] * 6 +
            value[4] * 5 +
            value[5] * 4 +
            value[6] * 3 +
            value[7] * 2
        const divisao = total / 11
        const modulo11 = total - parseInt(divisao, 10) * 11
        let comparador = 0
        if (modulo11 === 1 || modulo11 === 0) {
            comparador = 0
        } // excepção
        else {
            comparador = 11 - modulo11
        }

        const ultimoDigito = value.substr(8, 1) * 1
        if (ultimoDigito !== comparador) {
            hasError = 1
        }

        if (hasError === 1) {
            return 'O NIF parece estar errado'
        }

        if (value.substr(0, 1) !== '3' && value.substr(0, 1) !== '2' && value.substr(0, 1) !== '1') {
            return 'O número digitado parece não ser um NIF'
        }
    }
    return null
}

export function validateNipc(value) {
    const newValue = value === undefined ? '' : value

    if (!newValue) return validateNotEmpty(newValue)
    if (!!newValue && newValue.length < 9) return `${newValue.length}/9`

    let hasError = 0

    if (
        !['1', '2', '3', '5', '6', '8'].includes(newValue.substr(0, 1)) &&
        !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(newValue.substr(0, 2))
    ) {
        hasError = 1
    }

    const total =
        newValue[0] * 9 +
        newValue[1] * 8 +
        newValue[2] * 7 +
        newValue[3] * 6 +
        newValue[4] * 5 +
        newValue[5] * 4 +
        newValue[6] * 3 +
        newValue[7] * 2
    const divisao = total / 11
    const modulo11 = total - parseInt(divisao, 10) * 11
    let comparador = 0
    if (modulo11 === 1 || modulo11 === 0) {
        comparador = 0
    } // excepção
    else {
        comparador = 11 - modulo11
    }

    const ultimoDigito = newValue.substr(8, 1) * 1
    if (ultimoDigito !== comparador) {
        hasError = 1
    }

    if (hasError === 1) {
        return 'O NIPC parece estar errado'
    }

    if (newValue.substr(0, 1) === '2' || newValue.substr(0, 1) === '1') {
        return 'O número digitado parece não ser um NIPC'
    }
    return null
}

function getNumberFromChar(letter) {
    switch (letter.toUpperCase()) {
        case '0':
            return 0
        case '1':
            return 1
        case '2':
            return 2
        case '3':
            return 3
        case '4':
            return 4
        case '5':
            return 5
        case '6':
            return 6
        case '7':
            return 7
        case '8':
            return 8
        case '9':
            return 9
        case 'A':
            return 10
        case 'B':
            return 11
        case 'C':
            return 12
        case 'D':
            return 13
        case 'E':
            return 14
        case 'F':
            return 15
        case 'G':
            return 16
        case 'H':
            return 17
        case 'I':
            return 18
        case 'J':
            return 19
        case 'K':
            return 20
        case 'L':
            return 21
        case 'M':
            return 22
        case 'N':
            return 23
        case 'O':
            return 24
        case 'P':
            return 25
        case 'Q':
            return 26
        case 'R':
            return 27
        case 'S':
            return 28
        case 'T':
            return 29
        case 'U':
            return 30
        case 'V':
            return 31
        case 'W':
            return 32
        case 'X':
            return 33
        case 'Y':
            return 34
        case 'Z':
            return 35
        default:
            return -1
    }
}

export function validatePassportNumber(documentNumber) {
    // 1. ver se tem pelo pelo menos 8 characteres
    if (documentNumber.length === 8) {
        // verificar que dois deles são letras e o restante números
        const howManyLetters = documentNumber.match(/([A-Za-z])/g)
        // verificar que 6 são numeros
        const howManyDigits = documentNumber.match(/\d/g)
        if (howManyLetters && howManyDigits && howManyLetters.length === 2 && howManyDigits.length === 6) {
            return null
        }
    }
    // 2. ver se tem pelo menos 7 characteres
    if (documentNumber.length === 7) {
        // verificar que dois deles são letras e o restante números
        const howManyLetters = documentNumber.match(/([A-Za-z])/g)
        // verificar que 6 são numeros
        const howManyDigits = documentNumber.match(/\d/g)
        if (howManyLetters && howManyDigits && howManyLetters.length === 1 && howManyDigits.length === 6) {
            return null
        }
    }
    return 'Número de Passaporte incorreto'
}

export function validateCCNumber(documentNumber) {
    let sum = 0
    let secondDigit = false

    if (!documentNumber) return null

    if (documentNumber.length !== 12) return `${documentNumber.length}/12 (Informar nº e letras)`

    for (let i = documentNumber.length - 1; i >= 0; --i) {
        const shouldBeNumber = [11, 8, 7, 6, 5, 4, 3, 2, 1, 0]
        const verifyIsNumber = isNaN(Number(documentNumber[i]))

        if (shouldBeNumber.includes(i) && verifyIsNumber) {
            return 'Valor inválido no número de documento'
        }

        let calc = getNumberFromChar(documentNumber[i])

        if (calc === -1) return 'Valor inválido no número de documento'

        if (secondDigit) {
            calc *= 2
            if (calc > 9) calc -= 9
        }
        sum += calc
        secondDigit = !secondDigit
    }

    return sum % 10 !== 0 ? 'Cartão Cidadão incorreto' : null
}

function replaceCharAt(origString, char, index) {
    const firstPart = origString.substr(0, index)
    const lastPart = origString.substr(index + 1)

    const newString = firstPart + char + lastPart
    return newString
}

export function validateDNIEspanhol(document) {
    let documentIsValid = false
    const alphabetRestLetter = {
        0: 'T',
        1: 'R',
        2: 'W',
        3: 'A',
        4: 'G',
        5: 'M',
        6: 'Y',
        7: 'F',
        8: 'P',
        9: 'D',
        10: 'X',
        11: 'B',
        12: 'N',
        13: 'J',
        14: 'Z',
        15: 'S',
        16: 'Q',
        17: 'V',
        18: 'H',
        19: 'L',
        20: 'C',
        21: 'K',
        22: 'E',
    }

    if (!document) return null
    if (document.length !== 9) return `${document.length}/9 (Informar número de documento completo)`

    let number = document.substr(0, 8).toUpperCase()
    const firstCharacter = number.charAt(0)
    switch (firstCharacter) {
        case 'X':
            number = replaceCharAt(number, '0', 0)
            break
        case 'Y':
            number = replaceCharAt(number, '1', 0)
            break
        case 'Z':
            number = replaceCharAt(number, '2', 0)
            break
        default:
            break
    }

    const checksumLetter = document.substr(8, 9).toUpperCase()
    const rest = Number(number) % 23

    if (alphabetRestLetter[rest] === checksumLetter) documentIsValid = true

    return !documentIsValid ? 'DNI Inválido' : null
}

export function validateStringContained(string1, string2) {
    const splittedString1 = string1.split(/\s/g)

    return splittedString1.some(item => !string2.toUpperCase().includes(item.toUpperCase()))
        ? 'O Nome digitado não é parte do Nome Completo.'
        : null
}

export function validateStringEqual(string1, string2) {
    return string1 !== string2 ? 'Os valores digitados são diferentes.' : null
}

export function validateAddressEqual(address1, address2) {
    return address1 === address2
        ? 'A Morada Institucional está igual a Morada Não Institucional informada para envio dos códigos PIN e PUK'
        : null
}

export function validateNumberCaptcha(numbers) {
    return parseInt(numbers.value, 10) !== numbers.num1 + numbers.num2 ? 'Valor errado.' : null
}

export function validateDocumentType(value) {
    return (value && Object.keys(value).length === 0) || (value && value.id === 0)
        ? 'Escolha um tipo de documento.'
        : null
}

export function validateCardDuration(value) {
    return (value && Object.keys(value).length === 0) || (value && value.id === 0) ? 'Escolha uma duração.' : null
}
export function validateOrgUnit(value) {
    return (value && Object.keys(value).length === 0) || (value && value.id === 0)
        ? 'Escolha um tipo de documento.'
        : null
}
export function validateNameLength(value, length) {
    return value && String(value).length > length
        ? `${String(value).length}/${length} (Nome maior que o permitido)`
        : null
}

export function validatePersonName(value) {
    return value && value.length > 255
        ? validateNameLength(value, 255)
        : validateNotEmpty(value) || validateAlphaLatin(value)
}

export function validateNameOnCard(values) {
    const { personName, value } = values
    return personName && personName.length > 25
        ? validateNameLength(value, 25)
        : validateNotEmpty(value) || validateAlphaLatin(value) || validateStringContained(value, personName)
}
export function validateNameOnCardWithoutContained(value) {
    return value && value.length > 25
        ? validateNameLength(value, 25)
        : validateNotEmpty(value) || validateAlphaLatin(value)
}
export function validateDomainSufix(value) {
    return value.slice(-3) !== '.pt' ? 'Um dos domínios possui um sufixo inválido.' : null
}
export function validateWildcardDomainSufix(value) {
    return value.slice(-7) !== '.gov.pt' ? 'O domínio possui um sufixo inválido.' : null
}
export function validateDomainCharacters(value) {
    return value && !/^([A-Za-z0-9\- áàâãçéêíóôõÁÀÂÃÇÉÈÍÓÔÕ.\s]*)$/.test(value)
        ? 'Um dos domínios possui um caractere inválido.'
        : null
}
export function validateWildcardDomainCharacters(value) {
    return value && !/^([A-Za-z0-9\- áàâãçéêíóôõÁÀÂÃÇÉÈÍÓÔÕ*.\s]*)$/.test(value)
        ? 'O domínio possui um caractere inválido.'
        : null
}
export function validateDomainsArray(array) {
    const domainsWithError = array
        .filter(domain => {
            return domain.length > 0 && (validateDomainSufix(domain) || validateDomainCharacters(domain))
        })
        .reduce((obj, key) => {
            obj.push(key.length > 0 && (validateDomainSufix(key) || validateDomainCharacters(key)))
            return obj
        }, [])

    return domainsWithError.length > 0 ? domainsWithError[0] : null
}
export function validateWildcardDomainsArray(array) {
    const domainsWithError = array
        .filter(domain => {
            return (
                domain.length > 0 && (validateWildcardDomainSufix(domain) || validateWildcardDomainCharacters(domain))
            )
        })
        .reduce((obj, key) => {
            obj.push(key.length > 0 && (validateWildcardDomainSufix(key) || validateWildcardDomainCharacters(key)))
            return obj
        }, [])

    return domainsWithError.length > 0 ? domainsWithError[0] : null
}

export function validateTimestampPackage(value) {
    return (value && Object.keys(value).length === 0) || (value && value.id === 0) ? 'Escolha um pacote.' : null
}

export const isPasswordValid = value => {
    return /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{9,})$/.test(value)
}
export function validatePassword(value) {
    return value && !isPasswordValid(value) ? 'Valor inválido.' : null
}
