import { createTypes } from '../../utils/typesGenerator.util'

const TABLE_TYPES = {
    RESET_TABLE_HISTORY: createTypes('table/RESET_TABLE_HISTORY'),
    TABLE_HISTORY: createTypes('table/TABLE_HISTORY'),
    ALLOW_SAVE_HISTORY: createTypes('table/ALLOW_SAVE_HISTORY'),
    SET_DATE_FILTER: createTypes('table/SET_DATE_FILTER'),
    TABLE_QUERY_TO_EXPORT_CSV: createTypes('table/TABLE_QUERY_TO_EXPORT_CSV'),
}

export default TABLE_TYPES
