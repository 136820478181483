import React, { memo } from 'react'

import DataTablePagination from '../../../components/templates/DataTablePagination/DataTablePagination.component'

function EmailList() {
    const COLUMNS = [
        { title: 'entidade', field: 'entityName' },
        { title: 'cargo', field: 'role' },
        { title: 'e-mail', field: 'email' },
        { title: 'pendente de verificação', field: 'emailPending' },
        {
            title: 'e-mail de autenticação',
            field: 'authenticationEmail',
            lookup: { true: 'Sim', false: 'Não' },
            sorting: false,
            headerStyle: {
                textAlign: 'center',
            },
            cellStyle: {
                textAlign: 'center',
            },
        },
    ]

    return (
        <DataTablePagination
            title='Lista de E-mails'
            columns={COLUMNS}
            endPoint='profile/emailsList'
            options={{
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: {
                    padding: '16px 16px 16px 6px',
                },
            }}
        />
    )
}

export default memo(EmailList)
