import api from '../services/api.service'

export default async function getDocumentTypes() {
    try {
        const response = await api.get(`/documentTypes`)
        const { payload } = response.data

        return payload.map(option => {
            return {
                id: option.id,
                label: option.label,
            }
        })
    } catch (err) {
        console.log('err', err)
        return err
    }
}
