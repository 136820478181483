import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'
import NewOrderSSLConfigForm from '../../forms/NewOrderSSLConfig/NewOrderSSLConfig.form'

import { useOrdersStore } from '../../store/Orders/Orders.store'

const propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    params: PropTypes.objectOf(PropTypes.any),
}

const defaultProps = {
    params: {},
}

function ConfigSSLStep(props) {
    const { match } = props
    const { params } = match
    // ORDERS STORE
    const { stateOrders } = useOrdersStore()
    // Search SSLs arrays on state by ssl id
    const [ssl] = useState(stateOrders.services.ssls.find(item => item.id === params.id))

    return (
        <Fade in>
            <ContentComponent>
                <PageTitleComponent title='Configuração do SSL' />
                <NewOrderSSLConfigForm activeSsl={ssl} stateOrders={stateOrders} />
            </ContentComponent>
        </Fade>
    )
}

ConfigSSLStep.propTypes = propTypes
ConfigSSLStep.defaultProps = defaultProps

export default ConfigSSLStep
