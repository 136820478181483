import { createTypes, createRequestTypes } from '../../utils/typesGenerator.util'

const LOGIN_TYPES = {
    RESET_DATA: createTypes('login/RESET_DATA'),
    SHOW_LOADING: createTypes('login/SHOW_LOADING'),
    HIDE_LOADING: createTypes('login/HIDE_LOADING'),
    LOGIN: createRequestTypes('login/LOGIN'),
    CHECK: createRequestTypes('login/CHECK'),
    CLEAR: createTypes('login/CLEAR'),
    SET_ACTIVE_SCOPE: createTypes('login/SET_ACTIVE_SCOPE'),
    ACTIVE_SCOPE: createTypes('login/ACTIVE_SCOPE'),
    AUTHENTICATED: createRequestTypes('login/AUTHENTICATED'),
}

export default LOGIN_TYPES
