/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useHistory, Prompt } from 'react-router-dom'
import PropTypes from 'prop-types'

import InputMask from 'react-input-mask'

import { Help } from '@material-ui/icons'
import {
    Button,
    Grid,
    TextField,
    FormControlLabel,
    InputAdornment,
    RadioGroup,
    Radio,
    FormControl,
    Box,
    Tooltip,
    IconButton,
    Typography,
} from '@material-ui/core'

import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import DatePickerComponent from '../../components/templates/DatePicker/DatePicker.component'
import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'
import Callout from '../../components/templates/Callout/Callout'

import { SSelect } from './NewOrderTimestampConfig.style'
import { customSelectColors } from '../../styles/themes'

// import { useApiStateStore } from '../../store/ApiStatusServices/ApiStatusServices.store'
import { useOrdersStore } from '../../store/Orders/Orders.store'
import { useLoginStore } from '../../store/Login/Login.store'
import useValidationForm from '../../hooks/useValidationForm.hook'
import { maskPhoneUtil } from '../../utils'
import getDocumentTypes from '../../utils/documentTypes.util'

// import { StatusService } from '../../constants/enums'

import { MSG_CONFIRM_PAGE_EXIT } from '../../constants/dictionary/dictionary'

import { DEFAULT_PACKAGE, DEFAULT_DOMAINS_TYPE } from './constants'

function NewOrderTimestampConfigForm(props) {
    const { activeTimestamp } = props

    const history = useHistory()
    const { stateLogin } = useLoginStore()
    const { stateOrders, actionsOrders } = useOrdersStore()
    // const { stateApiStatusService } = useApiStateStore()

    const [defaultServiceType] = useState(_.find(stateOrders.services.types, { basicType: 'TIMESTAMP' }).type)
    const [pdfFiles, setPdfFiles] = useState(activeTimestamp.pdfFile ? [activeTimestamp.pdfFile] : [])
    const timestampList = stateOrders.services.types.filter(item => item.basicType === 'TIMESTAMP')
    const [documentTypeOptions, setDocumentTypeOptions] = useState([])

    useEffect(() => {
        async function setDocumentTypes() {
            try {
                const documentTypes = await getDocumentTypes()
                setDocumentTypeOptions(documentTypes)
            } catch (err) {
                console.log(err)
            }
        }

        setDocumentTypes()
    }, [])

    // * Gets the Package from the service list based on the durations array registered previously
    function getInitialPackage(duration) {
        if (duration) {
            // * Looks inside the timestampList the package that has the same duration IDss
            const pack = timestampList.filter(item => {
                if (duration) {
                    return item.durations[0].id === duration
                }
                return null
            })
            // * returns an object with the selected package for the selectbox
            if (pack && pack.length > 0) {
                return { ...pack[0], label: pack[0].description }
            }
            return {}
        }

        return DEFAULT_PACKAGE
    }

    function getInitialPrice(duration) {
        const pack = timestampList.filter(item => {
            if (duration) {
                return item.durations[0].id === duration
            }
            return null
        })

        if (pack && pack.length > 0) {
            return pack[0].durations[0].price
        }
        return ''
    }

    // * Gets initial state from the active card if it exists
    const INITIAL_STATE = {
        id: { value: activeTimestamp.config ? activeTimestamp.id : activeTimestamp.id || '' },
        isValid: { value: activeTimestamp.config ? activeTimestamp.config.isValid : activeTimestamp.isValid || false },
        type: {
            value: activeTimestamp.type || defaultServiceType,
        },
        package: {
            value: activeTimestamp.config
                ? getInitialPackage(activeTimestamp.stateId)
                : getInitialPackage(activeTimestamp.duration && activeTimestamp.duration.id),
            validation: ['validateTimestampPackageUtil'],
        },
        price: {
            value: activeTimestamp.config ? getInitialPrice(activeTimestamp.stateId) : activeTimestamp.price,
        },
        emailCertificate: {
            value: activeTimestamp.config
                ? activeTimestamp.config.emailCertificate
                : activeTimestamp.emailCertificate || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        applicantName: {
            value: activeTimestamp.config ? activeTimestamp.config.requester.name : activeTimestamp.applicantName || '',
            validation: ['validatePersonNameUtil'],
        },
        applicantEmail: {
            value: activeTimestamp.config
                ? activeTimestamp.config.requester.email
                : activeTimestamp.applicantEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        applicantPhoneNumber: {
            value: activeTimestamp.config
                ? activeTimestamp.config.requester.phoneNumber
                : activeTimestamp.applicantPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
            mask: 'maskPhoneUtil',
        },
        applicantDocumentType: {
            value: activeTimestamp.config
                ? activeTimestamp.config.requester.documentType
                : activeTimestamp.applicantDocumentType || { id: 0, label: 'Tipo de Documento' },
            validation: ['validateDocumentTypeUtil'],
        },
        applicantDocumentNumber: {
            value: activeTimestamp.config
                ? activeTimestamp.config.requester.documentNumber
                : activeTimestamp.applicantDocumentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        applicantDocumentValidity: {
            value: activeTimestamp.config
                ? activeTimestamp.config.requester.documentValidity
                : activeTimestamp.applicantDocumentValidity || null,
            validation: ['validateDateUtil'],
        },
    }

    const { values, errors, touched, isValidForm, handleChange, handleBlur, setValues, setErrors } = useValidationForm(
        INITIAL_STATE
    )

    function handlePackages(list, value) {
        const packages = list.filter(item => {
            return item.type === value
        })

        return packages.map(item => ({ ...item, label: `${item.description}` }))
    }

    const [timestampPackageOptions, setTimestampPackageOptions] = useState(
        handlePackages(timestampList, defaultServiceType)
    )

    function handleRadioChange(event) {
        const { value, name } = event.target
        switch (name) {
            case 'type':
                // GET THE PACKAGES OF THE SELECTED SEAL TYPE
                setTimestampPackageOptions(handlePackages(timestampList, value))
                setValues({
                    ...values,
                    type: { ...values.type, value },
                    domainsType: { ...values.domainsType, value: DEFAULT_DOMAINS_TYPE },
                    package: { ...values.package, value: DEFAULT_PACKAGE },
                    price: { ...values.price, value: '' },
                })
                break
            default:
                handleChange(event)
                break
        }
    }

    function handleSelectChange(name, obj) {
        if (obj) {
            const event = {
                target: {
                    name: name,
                    value: obj,
                },
            }
            switch (name) {
                case 'applicantDocumentType':
                    handleChange(event)
                    if (event.target.value && event.target.value.id === 1) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 3) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 5) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                            },
                        })
                    } else {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                            },
                        })
                    }
                    break
                case 'package':
                    handleChange(event)
                    //* Every package has only 1 object inside durations, so get the price of the first (only) element
                    setValues({
                        ...values,
                        price: { ...values.price, value: obj.durations[0].price ? obj.durations[0].price : '' },
                        package: { ...values.package, value: event.target.value },
                    })
                    break
                default:
                    handleChange(event)
                    break
            }
        }
    }

    function handleSelectBlur(name, obj) {
        const event = {
            target: {
                name: name,
                value: obj.target.value,
            },
        }

        handleBlur(event)
    }

    function handleDatePickerChange(dateString, field) {
        if (dateString) {
            const selectedDate = dateString.split('/')
            const selectedDateString = new Date(
                Number(selectedDate[2]),
                Number(selectedDate[1]) - 1,
                Number(selectedDate[0])
            )
            const selectedDateTimestamp = selectedDateString.getTime()
            const today = new Date()
            const yesterdayTimestamp = today.setDate(today.getDate() - 1)
            const selectedDateIsSameOrBeforeThanYesterday = selectedDateTimestamp <= yesterdayTimestamp

            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
        } else {
            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: null,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: null,
                },
            })
        }
    }

    function handleDatePickerBlur(event, field) {
        if (event) {
            handleBlur({
                target: {
                    name: field,
                },
            })
        }
    }

    const [isEditingService, setIsEditingService] = useState(true)

    useEffect(() => {
        Object.keys(activeTimestamp).length > 2 ? setIsEditingService(true) : setIsEditingService(false)
    }, [])

    function checkEmailCertificateDuplicate() {
        if (isEditingService) {
            const filteredServices = stateOrders.services.timestamps.filter(
                timestamp => timestamp.id !== activeTimestamp.id
            )

            const hasEqualEmails = filteredServices.some(
                timestamp => timestamp.emailCertificate === values.emailCertificate.value
            )

            if (hasEqualEmails) {
                setErrors({ ...errors, emailCertificate: 'Email já está registado no pedido para outro Selo Temporal' })
                return true
            }
        } else {
            const hasEqualEmails = stateOrders.services.timestamps.some(
                timestamp => timestamp.emailCertificate === values.emailCertificate.value
            )
            if (hasEqualEmails) {
                setErrors({ ...errors, emailCertificate: 'Email já está registado no pedido para outro Selo Temporal' })
                return true
            }
        }

        return false
    }

    function handleSave() {
        if (!checkEmailCertificateDuplicate()) {
            const payload = {
                id: values.id.value,
                basicType: 'TIMESTAMP',
                isValid: true,
                type: values.type.value,
                // * Get the duration of the selected package to save
                duration: values.package.value.durations[0],
                // duration: activeTimestamp.config
                //     ? values.package.value.durations && values.package.value.durations[0]
                //     : activeTimestamp.duration,
                // duration: values.package.value,
                price: values.price.value,
                emailCertificate: values.emailCertificate.value,
                applicantName: values.applicantName.value,
                applicantEmail: values.applicantEmail.value,
                applicantPhoneNumber: values.applicantPhoneNumber.value.replace(/\s/g, ''),
                applicantDocumentType: values.applicantDocumentType.value,
                applicantDocumentNumber: values.applicantDocumentNumber.value,
                applicantDocumentValidity: values.applicantDocumentValidity.value,
                timestampDescription: values.package.value.label,
                pdfFile: pdfFiles[0],
            }
            actionsOrders.setServiceByType(payload, 'timestamps', history)
        }
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Dados para emissão do Selo Temporal ' />
                        <FormControl component='fieldset'>
                            <RadioGroup
                                defaultValue={defaultServiceType}
                                aria-label='position'
                                name='type'
                                value={values.type.value}
                                onChange={handleRadioChange}
                                row
                            >
                                {_.find(stateOrders.services.types, { basicType: 'TIMESTAMP', type: 'Novo' }) && (
                                    <FormControlLabel
                                        value='Novo'
                                        control={<Radio color='primary' />}
                                        label='Novo'
                                        labelPlacement='end'
                                    />
                                )}
                                {_.find(stateOrders.services.types, {
                                    basicType: 'TIMESTAMP',
                                    type: 'Renovação',
                                }) && (
                                    <FormControlLabel
                                        value='Renovação'
                                        control={<Radio color='primary' />}
                                        label='Renovação'
                                        labelPlacement='end'
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                        <Grid container spacing={1}>
                            <Grid item xs={8} md={8}>
                                <SSelect
                                    id='package'
                                    name='package'
                                    getOptionValue={option => option}
                                    placeholder='Opções de Selo Temporal'
                                    options={timestampPackageOptions}
                                    className={
                                        touched.package && errors.package
                                            ? 'inputMarginDense selectError'
                                            : 'inputMarginDense'
                                    }
                                    value={values.package.value}
                                    error={touched.package ? Boolean(errors.package) : false}
                                    helperText={touched.package ? errors.package : ''}
                                    onChange={event => {
                                        handleSelectChange('package', event)
                                    }}
                                    onBlur={event => {
                                        handleSelectBlur('package', event)
                                    }}
                                    theme={customSelectColors}
                                    isSearchable
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    id='price'
                                    name='price'
                                    label='Valor'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.price.value}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position='start'>€</InputAdornment>,
                                    }}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <p>Prazo de utilização - 1 ano</p>
                                <p>Validade dos selos apostos - 5 anos</p>
                            </Grid>
                        </Grid>
                    </PaperComponent>

                    <PaperComponent noborder>
                        <PaperHeaderComponent title='E-mail do Selo Temporal' />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box component='div' display='flex' justifyContent='space-between'>
                                    <TextField
                                        id='emailCertificate'
                                        name='emailCertificate'
                                        label='E-mail do Selo Temporal'
                                        type='email'
                                        margin='dense'
                                        variant='outlined'
                                        value={values.emailCertificate.value}
                                        error={touched.emailCertificate ? Boolean(errors.emailCertificate) : false}
                                        helperText={touched.emailCertificate ? errors.emailCertificate : ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Callout>
                                <strong>E-mail principal para identificação do Selo Temporal.</strong>
                                <br />
                                Para este email serão enviadas notificações com links para definição da credencial de
                                uso do Selo Temporal, para permitir criar e alterar utilizadores deste Selo Temporal, e
                                outras notificações referentes a prazos e situação do serviço.
                            </Callout>
                        </Grid>
                    </PaperComponent>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Dados do requerente' />
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id='applicantName'
                                    name='applicantName'
                                    label='Nome'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.applicantName.value}
                                    error={touched.applicantName ? Boolean(errors.applicantName) : false}
                                    helperText={touched.applicantName ? errors.applicantName : ''}
                                    inputProps={{ maxLength: 255 }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    id='applicantEmail'
                                    name='applicantEmail'
                                    label='E-mail'
                                    type='email'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.applicantEmail.value}
                                    error={touched.applicantEmail ? Boolean(errors.applicantEmail) : false}
                                    helperText={touched.applicantEmail ? errors.applicantEmail : ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputMask
                                    mask={maskPhoneUtil}
                                    value={values.applicantPhoneNumber.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {() => (
                                        <TextField
                                            id='applicantPhoneNumber'
                                            name='applicantPhoneNumber'
                                            label='Telefone'
                                            margin='dense'
                                            variant='outlined'
                                            error={
                                                touched.applicantPhoneNumber
                                                    ? Boolean(errors.applicantPhoneNumber)
                                                    : false
                                            }
                                            helperText={touched.applicantPhoneNumber ? errors.applicantPhoneNumber : ''}
                                            fullWidth
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <SSelect
                                    id='applicantDocumentType'
                                    name='applicantDocumentType'
                                    placeholder='Tipo de Documento'
                                    margin='dense'
                                    variant='outlined'
                                    options={documentTypeOptions}
                                    theme={customSelectColors}
                                    getOptionValue={option => option}
                                    value={values.applicantDocumentType.value}
                                    error={
                                        touched.applicantDocumentType ? Boolean(errors.applicantDocumentType) : false
                                    }
                                    helperText={touched.applicantDocumentType ? errors.applicantDocumentType : ''}
                                    onChange={event => {
                                        handleSelectChange('applicantDocumentType', event)
                                    }}
                                    onBlur={event => {
                                        handleSelectBlur('applicantDocumentType', event)
                                    }}
                                    isSearchable
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id='applicantDocumentNumber'
                                    name='applicantDocumentNumber'
                                    label='Nº de Documento'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.applicantDocumentNumber.value}
                                    error={
                                        touched.applicantDocumentNumber
                                            ? Boolean(errors.applicantDocumentNumber)
                                            : false
                                    }
                                    helperText={touched.applicantDocumentNumber ? errors.applicantDocumentNumber : ''}
                                    inputProps={
                                        values.applicantDocumentType.value &&
                                        values.applicantDocumentType.value.id === 1
                                            ? { maxLength: 12 }
                                            : { maxLength: 9 }
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DatePickerComponent
                                    id='applicantDocumentValidity'
                                    name='applicantDocumentValidity'
                                    dateLabel='Validade do Documento'
                                    dateValue={values.applicantDocumentValidity.value}
                                    error={
                                        touched.applicantDocumentValidity
                                            ? Boolean(errors.applicantDocumentValidity)
                                            : false
                                    }
                                    helperText={
                                        touched.applicantDocumentValidity ? errors.applicantDocumentValidity : ''
                                    }
                                    onDatePickerChange={event =>
                                        handleDatePickerChange(event, 'applicantDocumentValidity')
                                    }
                                    onBlur={event => handleDatePickerBlur(event, 'applicantDocumentValidity')}
                                    isFullWidth
                                />
                            </Grid>
                        </Grid>
                    </PaperComponent>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Adicionar Ficheiro .PDF'>
                            <Tooltip title='Adicione um documento com a lista de Requerentes Autorizados'>
                                <IconButton aria-label='Ajuda'>
                                    <Help fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </PaperHeaderComponent>
                        <Typography component='div' variant='subtitle2' mb={1}>
                            Adicione um documento com a lista de Requerentes Autorizados
                        </Typography>

                        <UploadFileComponent
                            id='pdfFiles'
                            initialFiles={pdfFiles}
                            handleFiles={List => {
                                setPdfFiles(List)
                            }}
                            isSingleFile
                        />
                    </PaperComponent>
                </Grid>
            </Grid>

            <ActionBarComponent backLink='/new-order' backText='Voltar'>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValidForm || (stateLogin.activeScope.hasLstReqAutSsl === 0 && pdfFiles.length !== 1)}
                    onClick={handleSave}
                >
                    Guardar
                </Button>
            </ActionBarComponent>

            <Prompt
                message={(location, action) => {
                    if (
                        (action === 'PUSH' && !isValidForm) ||
                        (action === 'PUSH' && isValidForm && location.pathname !== '/new-order')
                    ) {
                        return MSG_CONFIRM_PAGE_EXIT
                    }

                    return null
                }}
            />
        </form>
    )
}

NewOrderTimestampConfigForm.propTypes = {
    activeTimestamp: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NewOrderTimestampConfigForm
