/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { memo } from 'react'
import PropTypes from 'prop-types'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { pt } from 'date-fns/locale'

import { useTableStore } from '../../../store/Table/Table.store'

function FilterDatePicker(props) {
    const { tableState, tableActions } = useTableStore()

    const columnDateInput = props.columnDef.tableData.id

    function handleDateChange(event) {
        tableActions.setDateFilter({
            date: event,
            column: props.columnDef.tableData.columnOrder,
        })
        props.onFilterChanged(props.columnDef.tableData.id, event)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
            <KeyboardDatePicker
                format='dd/MM/yyyy'
                value={
                    tableState.dateFilter && tableState.dateFilter.column === columnDateInput
                        ? tableState.dateFilter.date
                        : null
                }
                onChange={event => handleDateChange(event)}
                allowKeyboardControl
                clearable
                cancelLabel='Cancelar'
                clearLabel='Limpar'
            />
        </MuiPickersUtilsProvider>
    )
}

FilterDatePicker.propTypes = {
    onFilterChanged: PropTypes.func,
    columnDef: PropTypes.objectOf(PropTypes.any),
}

export default memo(FilterDatePicker)
