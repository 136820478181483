import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './styles'

const IconCard = ({ fill = '#960008' }) => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='29.724' height='19.964' viewBox='0 0 29.724 19.964'>
                <g transform='translate(17490 3658)'>
                    <g transform='translate(-17490 -3658)'>
                        <path
                            d='M2.218,0H27.506a2.2,2.2,0,0,1,2.218,2.218V17.746a2.2,2.2,0,0,1-2.218,2.218H2.218A2.2,2.2,0,0,1,0,17.746V2.218A2.2,2.2,0,0,1,2.218,0ZM1.6,4.88h26.53V2.218a.606.606,0,0,0-.621-.621H2.218a.606.606,0,0,0-.621.621Zm26.53,1.6H1.6V17.746a.606.606,0,0,0,.621.621H27.506a.606.606,0,0,0,.621-.621Z'
                            fill={fill}
                        />
                        <path
                            d='M56.8,111h6.122a.8.8,0,0,1,0,1.6H56.8A.8.8,0,1,1,56.8,111Z'
                            transform='translate(-51.031 -101.151)'
                            fill={fill}
                            fillRule='evenodd'
                        />
                        <path
                            d='M56.8,148h6.122a.8.8,0,0,1,0,1.6H56.8A.8.8,0,1,1,56.8,148Z'
                            transform='translate(-51.031 -134.868)'
                            fill={fill}
                            fillRule='evenodd'
                        />
                    </g>
                </g>
            </svg>
        </IconContainer>
    )
}

IconCard.propTypes = {
    fill: PropTypes.string,
}
IconCard.defaultProps = {
    fill: '',
}

export default memo(IconCard)
