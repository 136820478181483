import React, { memo } from 'react'

import { IconContainer } from './styles'

const IconInvoiceMenu = () => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <g transform='translate(-373 -256)'>
                    <path d='M0,0H14.569V15.2H0Z' transform='translate(377.633 260)' />
                    <g transform='translate(373 256)'>
                        <path d='M0,0H24V24H0Z' fill='none' />
                        <path d='M18,17H6V15H18Zm0-4H6V11H18Zm0-4H6V7H18ZM3,22l1.5-1.5L6,22l1.5-1.5L9,22l1.5-1.5L12,22l1.5-1.5L15,22l1.5-1.5L18,22l1.5-1.5L21,22V2L19.5,3.5,18,2,16.5,3.5,15,2,13.5,3.5,12,2,10.5,3.5,9,2,7.5,3.5,6,2,4.5,3.5,3,2Z' />
                    </g>
                    <g transform='translate(377 260)'>
                        <path d='M0,0H15.2V15.2H0Z' fill='rgba(0,0,0,0)' />
                        <path
                            d='M10.6,12.818A4.1,4.1,0,0,1,6.953,10.6H10.6V9.334H6.535a3.984,3.984,0,0,1,0-1.267H10.6V6.8H6.953a4.109,4.109,0,0,1,6.328-1.223L14.4,4.457A5.692,5.692,0,0,0,5.23,6.8H3V8.067H4.938a5.3,5.3,0,0,0,0,1.267H3V10.6H5.23A5.692,5.692,0,0,0,14.4,12.945l-1.127-1.121A4.057,4.057,0,0,1,10.6,12.818Z'
                            transform='translate(-1.1 -1.1)'
                            fill='#fff'
                        />
                    </g>
                </g>
            </svg>
        </IconContainer>
    )
}

export default memo(IconInvoiceMenu)
