import StatusServicesTypes from './ApiStatusServices.types'
import { StatusService } from '../../constants/enums'

export const INITIAL_STATE = {
    status: StatusService.IDLE,
    name: null,
    isOK: false,
}

function ApiStatusReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case StatusServicesTypes.RESET.SUCCESS:
            return INITIAL_STATE
        case StatusServicesTypes.PROCESSING.SUCCESS:
            return {
                ...state,
                status: StatusService.PROCESSING,
                name: action.data.name,
                isOK: false,
            }
        case StatusServicesTypes.FINISHED.SUCCESS:
            return {
                ...state,
                status: StatusService.FINISHED,
                name: action.data.name,
                isOK: action.data.isOK,
            }
        default:
            return state
    }
}

export default ApiStatusReducer
