import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './styles'

const IconSSLEV = ({ fill = '#960008' }) => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='22.925' height='27.468' viewBox='0 0 22.925 27.468'>
                <g id='icon-ssl-ev' transform='translate(-962 -183)'>
                    <g id='icon-ssl-ev-2' data-name='icon-ssl-ev' transform='translate(17989.6 3548.028)'>
                        <g id='Group_9075' data-name='Group 9075' transform='translate(-17027.6 -3365)'>
                            <path
                                id='Path_2120'
                                data-name='Path 2120'
                                d='M27.709,40.76v.463a7.611,7.611,0,0,1-.289,2.026H23.772c.058-.666.087-1.331.087-2.026V40.76h-.087a3.94,3.94,0,0,1-1.65-.376,7.858,7.858,0,0,1,.029.839c0,.724-.029,1.389-.087,2.026H17.81c-.058-.637-.087-1.3-.087-2.026s.029-1.389.087-2.026h2.952a3.847,3.847,0,0,1-.666-1.708H18.041c.463-2.576,1.331-4.052,1.881-4.052a.261.261,0,0,1,.145.029V31.7h-.145a9.508,9.508,0,1,0,9.494,9.494,2.49,2.49,0,0,0-.029-.463H27.709Zm-15.572.463a7.966,7.966,0,0,1,.289-2.026h3.647c-.058.666-.087,1.36-.087,2.026,0,.695.029,1.36.087,2.026H12.426a7.611,7.611,0,0,1-.289-2.026Zm.984,3.763H16.3A13.354,13.354,0,0,0,17.347,48.6a7.894,7.894,0,0,1-4.226-3.615Zm3.184-7.5H13.121a7.82,7.82,0,0,1,4.255-3.618A12.657,12.657,0,0,0,16.3,37.489Zm3.618,11.549c-.55,0-1.418-1.505-1.881-4.052h3.792c-.464,2.549-1.361,4.055-1.911,4.055Zm2.576-.463a12.135,12.135,0,0,0,1.042-3.618h3.184a7.662,7.662,0,0,1-4.225,3.62Z'
                                transform='translate(-10.4 -23.277)'
                                fill={fill}
                            />
                            <path
                                id='Path_2121'
                                data-name='Path 2121'
                                d='M59.017,8.128V6.913a4.327,4.327,0,0,0-8.654-.029V8.1A2.251,2.251,0,0,0,48.8,10.241v6.165a2.261,2.261,0,0,0,2.258,2.258h7.294a2.261,2.261,0,0,0,2.258-2.258V10.27a2.315,2.315,0,0,0-1.593-2.142ZM52.071,6.884a2.6,2.6,0,1,1,5.21,0v1.1h-5.21Zm6.8,9.523a.55.55,0,0,1-.55.55H51.058a.55.55,0,0,1-.55-.55V10.27a.55.55,0,0,1,.55-.55h7.294a.55.55,0,0,1,.55.55v6.136Z'
                                transform='translate(-37.685 -2.6)'
                                fill={fill}
                            />
                        </g>
                    </g>
                    <text
                        id='EV'
                        transform='translate(975 196)'
                        fill={fill}
                        fontSize='6'
                        fontFamily='Skia'
                        fontWeight='700'
                        letterSpacing='0.003em'
                    >
                        <tspan x='0' y='0'>
                            EV
                        </tspan>
                    </text>
                </g>
            </svg>
        </IconContainer>
    )
}

IconSSLEV.propTypes = {
    fill: PropTypes.string,
}
IconSSLEV.defaultProps = {
    fill: '',
}

export default memo(IconSSLEV)
