/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import ApiStatusServices, { INITIAL_STATE } from './ApiStatusServices.reducer'
import useActions from './ApiStatusServices.actions'

const propTypes = {
    children: PropTypes.node.isRequired,
}

const ApiStatusContext = createContext(null)

export function ApiStatusProvider(props) {
    const { children } = props

    const [stateApiStatusService, dispatch] = useReducer(ApiStatusServices, INITIAL_STATE)

    const actionsApiStatusService = useActions(stateApiStatusService, dispatch)
    const value = { stateApiStatusService, actionsApiStatusService }

    return <ApiStatusContext.Provider value={value}>{children}</ApiStatusContext.Provider>
}
ApiStatusProvider.propTypes = propTypes

export const useApiStateStore = () => useContext(ApiStatusContext)
