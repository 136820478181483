export const INITIAL_STATE = {
    password: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validatePasswordUtil'],
    },
    repeatPassword: {
        value: '',
        validation: ['validateNotEmptyUtil', 'validatePasswordUtil', 'validateStringEqualUtil'],
    },
}

export const ENDPOINT = {
    RECOVER_TIMESTAMP_PASSWORD: 'timestamps/client/recoverpass',
}
