import React, { memo } from 'react'
import PropTypes from 'prop-types'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import { IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

import { STimestampItem, SSuccessIcon, SWarningIcon } from './ProductTypeTimestamp.styles'

function ProductTypeTimestampComponent({
    title = 'Selo Temporal - Favor preencher dados.',
    isValid = false,
    handleDelete,
    handleEdit,
    handleDownload,
}) {
    return (
        <STimestampItem>
            {isValid ? <SSuccessIcon /> : <SWarningIcon />}
            <span className='card-name'>{title}</span>

            <div className='card-actions'>
                <IconButton aria-label='delete' size='small' onClick={handleDelete}>
                    <Delete fontSize='small' />
                </IconButton>
                <IconButton aria-label='edit' size='small' onClick={handleEdit}>
                    <Edit fontSize='small' />
                </IconButton>
                {isValid && (
                    <IconButton aria-label='download' size='small' onClick={handleDownload}>
                        <CloudDownloadIcon fontSize='small' />
                    </IconButton>
                )}
            </div>
        </STimestampItem>
    )
}

ProductTypeTimestampComponent.propTypes = {
    title: PropTypes.string,
    isValid: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleDownload: PropTypes.func,
}

export default memo(ProductTypeTimestampComponent)
