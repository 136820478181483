import styled from 'styled-components'

export const SInfoMessage = styled.div`
    display: flex;
    padding: 6px 16px;
    margin: 16px 0;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    background-color: ${props => props.theme.palette.blue.xLight};

    .MuiSvgIcon-root {
        display: inline-block;
        opacity: 0.9;
        margin: 7px 14px 7px 0;
        font-size: 22px;
        color: ${props => props.theme.palette.blue.light};
    }

    .message {
        padding: 8px 0;
        margin: 0;
    }
`
