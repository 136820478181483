import React from 'react'
import PropTypes from 'prop-types'

import { Prompt } from 'react-router-dom'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import { Avatar, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

import { MSG_CONFIRM_PAGE_EXIT } from '../../constants/dictionary/dictionary'
import { SCardItem, SSuccessIcon, SWarningIcon } from './ProductTypeCard.styles'

const propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    entity: PropTypes.string,
    isValid: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func.isRequired,
    handleDownload: PropTypes.func,
    cardId: PropTypes.string,
}

const defaultProps = {
    image: '',
    name: 'Cartão',
    entity: 'Favor preencher dados.',
    isValid: false,
    handleDelete: () => {},
    handleDownload: () => {},
}

// Product Type - Card
function ProductTypeCardComponent({
    image,
    name,
    entity,
    isValid,
    handleDelete,
    handleEdit,
    handleDownload,
    role,
    cardId,
}) {
    return (
        <>
            <SCardItem>
                <div className='card-status'>{isValid ? <SSuccessIcon /> : <SWarningIcon />}</div>
                <Avatar src={image} className='card-avatar' />
                <span className='card-name'>{name}</span>
                <span className='card-role'>{role}</span>
                <span className='card-entity'>{entity}</span>
                <div className='card-actions'>
                    <IconButton aria-label='delete' size='small' onClick={handleDelete}>
                        <Delete fontSize='small' />
                    </IconButton>
                    <IconButton aria-label='edit' size='small' onClick={handleEdit}>
                        <Edit fontSize='small' />
                    </IconButton>
                    {isValid && (
                        <IconButton aria-label='download' size='small' onClick={handleDownload}>
                            <CloudDownloadIcon fontSize='small' />
                        </IconButton>
                    )}
                </div>
            </SCardItem>

            <Prompt
                message={(location, action) => {
                    if (action === 'PUSH' && isValid && location.pathname !== `/new-order/card-config/${cardId}`) {
                        return MSG_CONFIRM_PAGE_EXIT
                    }

                    return null
                }}
            />
        </>
    )
}

ProductTypeCardComponent.propTypes = propTypes
ProductTypeCardComponent.defaultProps = defaultProps

export default ProductTypeCardComponent
