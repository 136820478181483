import styled from 'styled-components'

export const SRowItem = styled.div`
    margin: 0 16px 8px 0;
    flex: 1 1 0;
    display: flex;
    flex-direction: ${props => (props.isVertical ? 'column' : 'row')};
    &:first-child {
        margin-right: 0;
    }

    @media (max-width: 768px) {
        margin-right: 0;
        flex: 0 1 auto;
    }

    .title {
        font-weight: 700;
        display: inline-block;
        line-height: 1.5;
        margin-right: 15px;
    }

    .text {
        display: inline-block;
        line-height: 1.5;
        color: ${props => props.theme.palette.grey.main};
        font-weight: normal;
        margin-right: 15px;
        &:first-child {
            margin-right: 0;
        }
    }
`
