import LOGIN_TYPES from './Login.types'
import AuthService from '../../services/auth.service'

const auth = new AuthService(sessionStorage)
export const initialState = {
    isAuthenticated: Boolean(auth.get2SToken()) && Boolean(auth.getToken()),
    isLogged: false,
    user: {},
    entities: [],
    activeEntity: {},
    scopes: [],
    activeScope: {},
    token: {},
    error: {},
    isLoading: false,
}
export function LoginReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGIN_TYPES.RESET_DATA.SUCCESS:
            return initialState
        case LOGIN_TYPES.LOGIN.POST.SUCCESS: {
            const { payload } = action
            const { jwt } = payload
            return {
                ...state,
                isAuthenticated: false,
                isLogged: false,
                token: jwt,
                user: payload,
            }
        }
        case LOGIN_TYPES.LOGIN.POST.ERROR:
            return { ...state, isLogged: false, token: {}, error: action.payload.message }
        case LOGIN_TYPES.CHECK.POST.SUCCESS: {
            const { payload } = action
            const { jwt, scopes } = payload
            return {
                ...state,
                isAuthenticated: true,
                isLogged: true,
                token: jwt,
                user: payload,
                scopes: scopes,
                activeScope: scopes[0],
            }
        }
        case LOGIN_TYPES.CHECK.POST.ERROR:
            return { ...initialState, isAuthenticated: false, isLogged: false, error: action.error }
        case LOGIN_TYPES.CLEAR.SUCCESS:
            return { ...state, isAuthenticated: false, isLogged: false, token: {}, error: {} }
        case LOGIN_TYPES.CLEAR.ERROR:
            return { ...state, isAuthenticated: true, isLogged: true, error: action.error }
        case LOGIN_TYPES.ACTIVE_SCOPE.SUCCESS: {
            const storeScopes = auth.getScopes()
            const storeActiveScopeId = Number(auth.getActiveScopeId())
            const aScope = storeScopes.find(p => p.id === storeActiveScopeId)
            auth.setPermissions(aScope.permissions)
            return {
                ...state,
                scopes: storeScopes,
                activeScope: aScope,
            }
        }
        case LOGIN_TYPES.SET_ACTIVE_SCOPE.SUCCESS:
            auth.setPermissions(action.scope.permissions)
            return {
                ...state,
                activeScope: action.scope,
            }
        case LOGIN_TYPES.AUTHENTICATED.GET.SUCCESS:
            return {
                ...state,
                isAuthenticated: action.payload,
                isLogged: action.payload,
            }
        case LOGIN_TYPES.SHOW_LOADING.SUCCESS:
            return { ...state, isLoading: true }
        case LOGIN_TYPES.HIDE_LOADING.SUCCESS:
            return { ...state, isLoading: false }
        default:
            return state
    }
}
