import React, { memo } from 'react'

import { IconContainer } from './styles'

const IconTimestampMenu = () => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='24.032' height='21.47' viewBox='0 0 24.032 21.47'>
                <g transform='translate(-1135 -107.559)'>
                    <circle cx='9.235' cy='9.235' r='9.235' transform='translate(1135 110.559)' />
                    <g transform='translate(-2 -1)'>
                        <path
                            d='M-23586.154-2226.895a16.269,16.269,0,0,0,2.9-.452,18.95,18.95,0,0,0,2.875-1.062s1.875.684,3.377,1.063a16.364,16.364,0,0,0,2.627.452c-.012,1.38-.033,2.275,0,3.554a18.79,18.79,0,0,1-.729,3.158,10.177,10.177,0,0,1-1.605,2.828,4.97,4.97,0,0,1-2.225,1.364l-1.457.744-1.947-1.166-1.441-1.3-.8-.925-.91-1.549-.521-1.77-.143-1.8v-3.142Z'
                            transform='translate(24734.742 2337.737)'
                            fill='#fff'
                        />
                        <path
                            d='M37.77,18.651l-.223-.122-.223.122a12.482,12.482,0,0,1-5.856,1.5H31v3.883a9.721,9.721,0,0,0,6.388,9.113l.159.058.159-.058A9.721,9.721,0,0,0,44.1,24.038V20.158h-.465a12.485,12.485,0,0,1-5.856-1.5Zm5.388,5.388a8.782,8.782,0,0,1-5.612,8.172,8.783,8.783,0,0,1-5.611-8.172V21.08a13.429,13.429,0,0,0,5.612-1.488,13.43,13.43,0,0,0,5.611,1.488Z'
                            transform='translate(1116.936 90.03)'
                        />
                        <path
                            d='M-23586.156-2227.184a12.513,12.513,0,0,0,2.285-.366,14.68,14.68,0,0,0,2.266-.86s1.479.553,2.662.86a12.6,12.6,0,0,0,2.072.366c-.008,1.116-.025,1.841,0,2.875a15.407,15.407,0,0,1-.574,2.555,19.282,19.282,0,0,1-1.5,2,9.973,9.973,0,0,1-1.523,1.39l-1.148.6-1.535-.943-1.135-1.049-.629-.749-.719-1.253-.408-1.432-.115-1.456v-2.542Z'
                            transform='translate(24735.859 2338.969)'
                        />
                        <path
                            d='M1155.177,113.43l-.8.778-.884.863-.052.051-2.174,2.121-.863-.867-.673-.677'
                            transform='translate(1.778 0.385)'
                            fill='none'
                            stroke='#fff'
                            strokeWidth='2'
                        />
                    </g>
                    <path
                        d='M91.269,85.333a.66.66,0,0,0-.66.66V90.61H85.993a.66.66,0,1,0,0,1.319h5.276a.66.66,0,0,0,.66-.66V85.993a.66.66,0,0,0-.66-.66Z'
                        transform='translate(1052.965 28.965)'
                        fill='#fff'
                    />
                </g>
            </svg>
        </IconContainer>
    )
}

export default memo(IconTimestampMenu)
