/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { Fade } from '@material-ui/core'
import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import SmartcardConsumer from './components/SmartcardConsumer'
import EmailConsumer from './components/EmailConsumer'

import { getUrlParam, hasParam } from '../../utils'

import { SLogo, SPaper } from './TimestampConsumer.styles'

function TimestampConsumer() {
    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                {hasParam('timestampId') && <SmartcardConsumer timestampId={getUrlParam('timestampId')} />}
                {!hasParam('timestampId') && hasParam('hash') && <EmailConsumer hash={getUrlParam('hash')} />}
            </SPaper>
        </Fade>
    )
}

export default TimestampConsumer
