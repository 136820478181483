import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid } from '@material-ui/core'

import { transformTimestampToSimpleDate } from '../../../utils/date.util'

import PaperComponent, { PaperHeaderComponent } from '../../../components/templates/Paper/Paper.component'
import FItemData from '../../../components/templates/ItemData/ItemData.component'
import { SPaperRow, CardAvatar } from '../Profile.styles'

const propTypes = {
    photo: PropTypes.string,
    personName: PropTypes.string,
    nameOnCard: PropTypes.string,
    documentType: PropTypes.string,
    documentNumber: PropTypes.string,
    documentValidity: PropTypes.number,
    personTaxIndentificationNumber: PropTypes.string,
}

const defaultProps = {
    photo: '',
    personName: '',
    nameOnCard: '',
    documentType: '',
    documentNumber: '',
    documentValidity: null,
    personTaxIndentificationNumber: '',
}

function ProfileDetails(props) {
    const {
        photo,
        personName,
        nameOnCard,
        documentType,
        documentNumber,
        documentValidity,
        personTaxIndentificationNumber,
    } = props

    const photoMetadata = 'data:image/png;base64,'

    return (
        <PaperComponent noborder>
            <PaperHeaderComponent title='Dados do perfil' />

            <Box display='flex'>
                <CardAvatar
                    variant='square'
                    alt={personName}
                    title={personName}
                    src={photo.includes(photoMetadata) ? photo : photoMetadata + photo}
                />

                <Grid item xs={12}>
                    <Box>
                        <SPaperRow>
                            <FItemData vertical itemKey='Nome Completo:' itemValue={personName} />
                        </SPaperRow>
                        <SPaperRow>
                            <FItemData vertical itemKey='Nome no cartão:' itemValue={nameOnCard} />
                        </SPaperRow>
                        <SPaperRow>
                            <FItemData vertical itemKey='Tipo de documento:' itemValue={documentType} />
                            <FItemData vertical itemKey='Número do documento:' itemValue={documentNumber} />
                            <FItemData
                                vertical
                                itemKey='Validade:'
                                itemValue={transformTimestampToSimpleDate(documentValidity)}
                            />
                        </SPaperRow>
                        <SPaperRow>
                            <FItemData itemKey='NIF:' itemValue={personTaxIndentificationNumber} />
                        </SPaperRow>
                    </Box>
                </Grid>
            </Box>
        </PaperComponent>
    )
}

ProfileDetails.propTypes = propTypes
ProfileDetails.defaultProps = defaultProps

export default ProfileDetails
