import { useState, useEffect } from 'react'
import * as validateUtil from '../utils'

// *---------------------------------------*
// *    FORM FIELDS VALIDATION FUNCTION    *
// * Must return null or an error message. *
// *---------------------------------------*
function validateFieldErrors(values, name, value = null) {
    let fieldValue = value
    if (!fieldValue) fieldValue = values[name].value
    const { mask } = values[name]
    // * Check if the input has a mask, then removes it before validation
    if (mask && fieldValue && fieldValue.length > 0) {
        if (mask === 'maskNIFUtil' || mask === 'maskPhoneUtil') {
            fieldValue = fieldValue.replace(/\s/g, '')
        } else if (mask === 'maskPostalCodeUtil') {
            fieldValue = fieldValue.replace('-', '')
        }
    }

    const validationArray = values[name].validation

    // * Check if the input has the validation array, then checks the validations to see
    // * if the input is valid and returns the error message if it exists.
    if (validationArray) {
        if (validationArray.length >= 1) {
            for (let i = 0; validationArray.length > i; i++) {
                if (name === 'repeatPassword' && validationArray[i] === 'validateStringEqualUtil') {
                    return validateUtil[validationArray[i]](values.password.value, fieldValue)
                }

                if (name === 'address' && validationArray[i] === 'validateAddressEqual') {
                    return validateUtil[validationArray[i]](values.pinPukAddress.value, fieldValue)
                }

                if (validateUtil.isObject(validationArray[i])) {
                    const { validator, args } = validationArray[i]
                    return validateUtil[validator](fieldValue, ...args)
                }

                if (validationArray[i] === 'validatePseudonymLengthUtil') {
                    fieldValue = {
                        givenName: values.givenName.value,
                        surname: values.surname.value,
                    }
                }

                if (validationArray[i] === 'validateNameOnCardWhenPseudonymCheckedUtil') {
                    fieldValue = {
                        nameOnCard: values.nameOnCard.value,
                        givenName: values.givenName.value,
                        surname: values.surname.value,
                    }
                }

                if (validationArray[i] === 'validateNameOnCardWhenPseudonymUncheckedUtil') {
                    fieldValue = {
                        personName: values.personName.value,
                        value: fieldValue,
                    }
                }

                if (validationArray[i] === 'validateGivenAndSurnameWhenPseudonymUncheckedUtil') {
                    fieldValue = {
                        personName: values.personName.value,
                        givenName: values.givenName.value,
                        surname: values.surname.value,
                    }
                }

                if (validateUtil[validationArray[i]](fieldValue)) {
                    return validateUtil[validationArray[i]](fieldValue)
                }
            }
        }
    }
    return null
}

// *----------------------*
// * FORM VALIDATION HOOK *
// *----------------------*
function useValidationForm(initialState) {
    // * Create an object which the keys are the fields from state that
    // * Have an error and the value is the error message
    function checkErrors(state = {}, name = '', value = '') {
        return Object.keys(state)
            .filter(key => {
                return key === name ? validateFieldErrors(state, name, value) : validateFieldErrors(state, key)
            })
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: key === name ? validateFieldErrors(state, name, value) : validateFieldErrors(state, key),
                }
            }, {})
    }
    // * LOCAL STATE DECLARATIONS
    const [values, setValues] = useState(initialState)
    const [errors, setErrors] = useState(checkErrors(initialState))

    // * Create a touched object with the same keys of initialState
    // * And checks if the field already has a value
    const [touched, setTouched] = useState(
        Object.keys(initialState).reduce((obj, key) => {
            return initialState[key].value
                ? { ...obj, [key]: initialState[key].value.length !== 0 }
                : { ...obj, [key]: false }
        }, {})
    )

    //* Set to true if the number of errors is 0
    const [isValidForm, setIsValidForm] = useState(Object.keys(errors).length === 0)

    //* Whenever the errors object is empty set isValidForm to true
    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    }, [errors])

    function handleChange(event) {
        const { name, value } = event.target
        setValues({ ...values, [name]: { ...values[name], value } })
        setTouched({ ...touched, [name]: true })
        setErrors(checkErrors(values, name, value))
    }

    function handleBlur(event) {
        const { name, value } = event.target
        setTouched({ ...touched, [name]: true })
        setErrors(checkErrors(values, name, value))
    }

    function handleErrors(event) {
        const { name } = event.target
        setErrors(checkErrors(values, name))
    }

    return {
        values,
        errors,
        touched,
        isValidForm,
        handleChange,
        handleBlur,
        handleErrors,
        setValues,
        setTouched,
        setErrors,
    }
}

export default useValidationForm
