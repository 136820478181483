import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import PaperComponent, {
    PaperBodyComponent,
    PaperHeaderComponent,
} from '../../../components/templates/Paper/Paper.component'

import { PROFILE_NOTIFICATIONS_OPTIONS } from '../../../constants/dictionary/dictionary'

import { HelperText } from '../Profile.styles'

const propTypes = {
    notifications: PropTypes.shape({
        emailNotification: PropTypes.bool,
        smsNotification: PropTypes.bool,
        newsletterNotification: PropTypes.bool,
    }).isRequired,
    onNotificationChange: PropTypes.func.isRequired,
}

function NotificationOptions(props) {
    const { notifications } = props

    function handleToggle(name) {
        const updatedNotifications = { ...notifications, [name]: !notifications[name] }
        props.onNotificationChange(updatedNotifications)
    }

    return (
        <PaperComponent noborder>
            <PaperHeaderComponent title='notificações' />
            <PaperBodyComponent>
                <List>
                    <ListItem role='checkbox' dense button onClick={() => handleToggle('emailNotification')}>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                color='primary'
                                checked={notifications.emailNotification}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={PROFILE_NOTIFICATIONS_OPTIONS.VIA_EMAIL} />
                    </ListItem>

                    <ListItem role='checkbox' dense button onClick={() => handleToggle('smsNotification')}>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                color='primary'
                                checked={notifications.smsNotification}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={PROFILE_NOTIFICATIONS_OPTIONS.VIA_SMS} />
                    </ListItem>

                    <ListItem role='checkbox' dense button onClick={() => handleToggle('newsletterNotification')}>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                color='primary'
                                checked={notifications.newsletterNotification}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={PROFILE_NOTIFICATIONS_OPTIONS.NEWSLETTER} />
                    </ListItem>
                </List>
                {!notifications.emailNotification && !notifications.smsNotification && (
                    <HelperText>* selecionar no minímo uma opção de receção de notificações, email ou SMS</HelperText>
                )}
            </PaperBodyComponent>
        </PaperComponent>
    )
}

NotificationOptions.propTypes = propTypes

export default NotificationOptions
