import React from 'react'
import PropTypes from 'prop-types'
import { SFooter } from './Paper.style'

const propTypes = {
    children: PropTypes.node,
}

const defaultProps = {
    children: [],
}

const PaperFooterComponent = ({ children }) => <SFooter>{children}</SFooter>

PaperFooterComponent.propTypes = propTypes
PaperFooterComponent.defaultProps = defaultProps

export default PaperFooterComponent
