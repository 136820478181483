import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import styled, { css } from 'styled-components'
import { Grid, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Error } from '@material-ui/icons'
import { Link } from 'react-router-dom'

const SelectStyle = `
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : '0')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
    div:nth-child(3){
        z-index: 11 !important;
    }
`
export const SSelect = styled(Select)`
    ${SelectStyle};
`

export const SCreatableSelect = styled(CreatableSelect)`
    ${SelectStyle};
    z-index: 10;
`

export const SRequired = styled.span`
    color: ${props => (props.validate ? props.theme.palette.error.main : '')};
    position: relative;
    font-size: 11px;
    display: ${props => (props.validate ? 'inline' : 'none')};
`

export const ErrorComponent = styled.p`
    color: ${props => (props.errorColor ? props.theme.palette.error.main : '')};
`

export const SLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
    .action {
        position: absolute;
        left: 0;
        font-size: 12px;
    }
`

export const STipIcon = styled(Error)`
    && {
        font-size: 1.1rem;
        color: ${props => props.theme.palette.blue.main};
    }
`

export const STooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip)

export const GivenNameSurnameContainer = styled(Grid)`
    margin: -4px 0 !important;

    ${props => {
        const { helperText: givenNameHelperText } = props.children[0].props.children.props
        const { helperText: surnameHelperText } = props.children[1].props.children.props

        if (
            givenNameHelperText ===
            'Nome(s) Próprio(s) e Apelido(s) devem conter 2 ou mais vocábulos existentes em Nome Completo.'
        ) {
            return css`
                .MuiGrid-item p#surname-helper-text {
                    display: none;
                }

                .MuiGrid-item #givenName-helper-text {
                    width: 550px;
                    transform: translateX(23%);
                    text-align: center;
                }

                @media (max-width: 1440px) {
                    .MuiGrid-item #givenName-helper-text {
                        transform: translateX(-2%);
                    }
                }
            `
        }

        if (
            surnameHelperText ===
            'Nome(s) Próprio(s) e Apelido(s) devem conter 2 ou mais vocábulos existentes em Nome Completo.'
        ) {
            return css`
                .MuiGrid-item p#givenName-helper-text {
                    display: none;
                }

                .MuiGrid-item #surname-helper-text {
                    width: 550px;
                    transform: translateX(-51%);
                    text-align: center;
                }

                @media (max-width: 1440px) {
                    .MuiGrid-item #surname-helper-text {
                        transform: translateX(-53.5%);
                    }
                }
            `
        }
    }}
`
