import React, { memo } from 'react'
import PropTypes from 'prop-types'

import DataTableComponent from '../../../../components/templates/DataTable/DataTable.component'
import DownloadFile from '../../../../components/templates/DownloadFile/DownloadFile'

import { transformTimestampToSimpleDate } from '../../../../utils/date.util'

import ENDPOINTS from '../../constants'

function ProposalTable({ data }) {
    const columns = [
        {
            title: 'FICHEIRO',
            field: 'fileName',
        },
        {
            title: 'DATA DE SUBMISSÃO',
            field: 'submissionDate',
            render: rowData => {
                return <>{transformTimestampToSimpleDate(rowData.submissionDate)}</>
            },
        },
        {
            title: 'DOWNLOAD',
            sorting: false,
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                width: '6%',
                textAlign: 'center',
            },
            render: rowData => {
                return Object.keys(rowData).length !== 0 && rowData.constructor === Object ? (
                    <DownloadFile endpoint={`${ENDPOINTS.DOWNLOAD_PROPOSAL_BY_ID}${rowData.id}`} />
                ) : (
                    ''
                )
            },
        },
    ]

    return (
        <DataTableComponent
            title='Lista de Templates'
            columns={columns}
            data={data}
            options={{
                toolbar: false,
                paging: false,
                headerStyle: {
                    backgroundColor: '#F7F9FA',
                    padding: '5px 0',
                },
                rowStyle: {
                    backgroundColor: '#F7F9FA',
                },
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Nenhuma proposta submetida',
                },
            }}
        />
    )
}

ProposalTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

ProposalTable.defaultProps = { data: [] }

export default memo(ProposalTable)
