import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { CircularProgress, Fade, Grid, TextField } from '@material-ui/core'
import { useAlertStore } from '../../store/Alert/Alert.store'

import logoECCE from '../../assets/images/commons/logo-ecce.svg'
import { INITIAL_STATE, ENDPOINTS } from './constants'

import useValidationForm from '../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../hooks/useServiceCallApi.hook'

import IconCheck from '../../assets/icons/icon-check.svg'
import IconError from '../../assets/icons/icon-error.svg'

import FCaptcha from '../../components/FCaptcha/FCaptcha.component'

import { SLogo, SPaper, SCircular, SAccessActions, SButton, SBoxDone, SIconItem } from './SearchCertificate.styles'

function SearchCertificate() {
    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(
        INITIAL_STATE.SEARCH_CERT
    )
    const [requestState, callService] = useServiceCallApi(null)

    const { actionsAlert } = useAlertStore()
    const refCaptcha = useRef()

    function handleDownload(payload) {
        try {
            const linkSource = `data:application/x-x509-ca-cert;base64,${payload}`
            const downloadLink = document.createElement('a')
            const fileName = 'Certificado.cer'

            downloadLink.href = linkSource
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        } catch (err) {
            if (err.response && err.response.data) {
                actionsAlert.showAlert(err.response.data.message)
            }
        }
    }

    useEffect(() => {
        if (
            requestState.url === ENDPOINTS.SEARCH_CERTIFICATE_AUTHENTICATION &&
            !requestState.isLoading &&
            !requestState.error.hasError &&
            requestState
        ) {
            handleDownload(requestState.data.payload.certificate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestState])

    function handleSubmit() {
        const payload = {
            email: values.email.value,
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
        }

        callService(ENDPOINTS.SEARCH_CERTIFICATE_AUTHENTICATION, {
            requestType: 'post',
            showSnackBarOnSuccess: true,
            data: payload,
        })
    }

    function renderContent() {
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                    <span>A processar o pedido...</span>
                </SCircular>
            )
        }

        if (
            requestState.url === ENDPOINTS.SEARCH_CERTIFICATE_AUTHENTICATION &&
            !requestState.isLoading &&
            !requestState.error.hasError &&
            requestState
        ) {
            return (
                <>
                    <Grid item xs={12} container>
                        <SBoxDone>
                            {requestState.data.message === 'Certificado obtido com sucesso' ? (
                                <>
                                    <h2>{requestState.data.message}</h2>
                                    <SIconItem src={IconCheck} className='icon-check' />
                                </>
                            ) : (
                                <>
                                    <h2>{requestState.data.message}</h2>
                                    <SIconItem src={IconError} className='icon-error' />
                                </>
                            )}
                        </SBoxDone>
                    </Grid>
                    <SAccessActions>
                        <Link to='/'>Voltar para o Login</Link>
                    </SAccessActions>
                </>
            )
        }

        return (
            <>
                <form>
                    <h3>Informe o e-mail que deseja fazer o download dos certificados: </h3>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name='email'
                                label='E-mail'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={touched.email ? Boolean(errors.email) : false}
                                helperText={touched.email ? errors.email : ''}
                                required
                                fullWidth
                                value={values.email ? values.email.value : ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction='row' alignItems='flex-end' spacing={1}>
                        <Grid item xs={9}>
                            <FCaptcha
                                refCaptcha={refCaptcha}
                                value={values.yourFirstCaptchaUserInput ? values.yourFirstCaptchaUserInput.value : ''}
                                error={
                                    touched.yourFirstCaptchaUserInput
                                        ? Boolean(errors.yourFirstCaptchaUserInput)
                                        : false
                                }
                                helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SButton
                                variant='contained'
                                color='primary'
                                disabled={!isValidForm}
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Download
                            </SButton>
                        </Grid>
                    </Grid>
                </form>
                <SAccessActions>
                    <Link to='/'>Voltar para o Login</Link>
                </SAccessActions>
            </>
        )
    }

    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                {renderContent()}
            </SPaper>
        </Fade>
    )
}

export default SearchCertificate
