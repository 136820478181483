import styled from 'styled-components'

import Select from '@material-ui/core/Select'

export const STopBar = styled.header`
    background-color: ${props => props.theme.palette.white};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    width: calc(100% - 80px);
    right: 0;
    top: 0;
    height: 60px;
    border-bottom: 1px solid ${props => props.theme.palette.grey.midLight};
    z-index: 1199;

    @media (max-width: 599px) {
        width: 100%;
    }

    @media print {
        display: none;
    }
`
export const STopBarNav = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;
    height: 100%;
    position: relative;
    z-index: 1;
    .top-bar-nav-item {
        color: ${props => props.theme.palette.grey.light};
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        background-color: ${props => props.theme.palette.white};
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:after {
            width: 100%;
            height: 3px;
            content: '';
            display: block;
            background-color: ${props => props.theme.palette.primary.main};
            position: absolute;
            left: 0;
            bottom: 0;
            transition: all .3s linear;
            transform: translate3d(0,4px,0);
        },
        &:hover {
            color: ${props => props.theme.palette.primary.main};
            &:after {
                transform: translate3d(0,0,0);
            },
        },
    }
`

export const SSelect = styled(Select)`
    height: 30px;
    margin-left: 20px;
    margin-right: 60px;
`

export const SLogOut = styled.span`
    color: ${props => props.theme.palette.grey.main};
    display: flex;
    align-items: center;
    height: 30px;
    padding: 10px;
    transition: all 0.2s linear;
    cursor: pointer;
    svg {
        margin-left: 5px;
        width: 20px;
    }
    &:hover {
        color: ${props => props.theme.palette.primary.main};
    }
`
