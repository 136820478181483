import TABLE_TYPES from './Table.types'

export const INITIAL_STATE = {
    tableHistory: {
        page: 0,
        pageSize: 10,
        search: '',
        orderDirection: '',
        orderBy: '',
        filterFields: '',
        filterValues: '',
    },
    rowClicked: false,
    tableQueryToExportCSV: '',
    dateFilter: null,
}

function TableReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TABLE_TYPES.RESET_TABLE_HISTORY.SUCCESS:
            return INITIAL_STATE
        case TABLE_TYPES.TABLE_HISTORY.SUCCESS:
            return { ...state, tableHistory: action.payload }
        case TABLE_TYPES.ALLOW_SAVE_HISTORY.SUCCESS:
            return { ...state, rowClicked: action.payload }
        case TABLE_TYPES.SET_DATE_FILTER.SUCCESS:
            return { ...state, dateFilter: action.payload }
        case TABLE_TYPES.TABLE_QUERY_TO_EXPORT_CSV.SUCCESS:
            return { ...state, tableQueryToExportCSV: action.payload }
        default:
            return state
    }
}

export default TableReducer
