import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './styles'

const IconEletronicSeal = ({ fill = '#960008' }) => {
    return (
        <IconContainer>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                enableBackground='new 0 0 24 24'
                height='24'
                viewBox='0 0 24 24'
                width='24'
            >
                <g>
                    <rect fill='none' height='24' width='24' />
                </g>
                <g>
                    <g>
                        <path
                            fill={fill}
                            d='M23,11.99l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,11.99l2.44,2.79 l-0.34,3.7l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,11.99z M19.05,13.47l-0.56,0.65l0.08,0.85 l0.18,1.95l-1.9,0.43l-0.84,0.19l-0.44,0.74l-0.99,1.68l-1.78-0.77L12,18.85l-0.79,0.34l-1.78,0.77l-0.99-1.67l-0.44-0.74 l-0.84-0.19l-1.9-0.43l0.18-1.96l0.08-0.85l-0.56-0.65l-1.29-1.47l1.29-1.48l0.56-0.65L5.43,9.01L5.25,7.07l1.9-0.43l0.84-0.19 l0.44-0.74l0.99-1.68l1.78,0.77L12,5.14l0.79-0.34l1.78-0.77l0.99,1.68l0.44,0.74l0.84,0.19l1.9,0.43l-0.18,1.95l-0.08,0.85 l0.56,0.65l1.29,1.47L19.05,13.47z'
                        />
                        <polygon
                            fill={fill}
                            points='10.09,13.75 7.77,11.42 6.29,12.91 10.09,16.72 17.43,9.36 15.95,7.87'
                        />
                    </g>
                </g>
            </svg>
        </IconContainer>
    )
}

IconEletronicSeal.propTypes = {
    fill: PropTypes.string,
}
IconEletronicSeal.defaultProps = {
    fill: '',
}

export default memo(IconEletronicSeal)
