import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const SPaper = styled.div`
    width: 500px;
    max-width: 100%;
`
export const SLogo = styled.div`
    margin-bottom: 30px;
`
export const STitle = styled.div`
    font-weight: 300;
    margin-bottom: 10px;
`
export const SAccessActions = styled.div`
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const SLink = styled(Link)`
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`
export const SCircular = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 16px;
        font-size: 16px;
    }
`

export const ReloadCaptcha = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    span {
        font-size: 16px;
    }
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`
