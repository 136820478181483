import REQUEST_USER_TYPES from './RequestUser.types'
import apiService from '../../services/api.service'

// * Defines the applyMiddleware reducer in the end of the file,
// * because it needs to come after the functions declaration
function asyncGetUserByNif(dispatch, actionsAlert, payload) {
    const { nif } = payload
    try {
        const response = apiService.get(`info/nif/${nif}`)
        const data = !response.data.payload ? { ...response.data.payload, name: '' } : response.data.payload

        dispatch({ type: REQUEST_USER_TYPES.USER.GET.SUCCESS, data })
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

function asyncGetEntityByNipc(dispatch, actionsAlert, payload) {
    const { nipc } = payload
    try {
        const response = apiService.get(`info/nipc/${nipc}`)
        const data = !response.data.payload ? { ...response.data.payload, name: '' } : response.data.payload

        dispatch({ type: REQUEST_USER_TYPES.ENTITY.GET.SUCCESS, data })
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            actionsAlert.showAlert(err.response.data)
        }
    }
}

async function asyncRegisterAccess(dispatch, actionsAlert, payload) {
    try {
        const { data } = await apiService.post('users/register', payload)
        dispatch({
            type: REQUEST_USER_TYPES.REGISTER.POST.SUCCESS,
            success: { message: data.message },
        })
        // // Show alert OK with success message
        // actionsAlert.showAlert(data)
    } catch (err) {
        // Show alert error with error message
        if (err.response && err.response.data) {
            dispatch({
                type: REQUEST_USER_TYPES.REGISTER.POST.ERROR,
                error: { code: err.response.status, message: err.response.data.message },
            })
            // actionsAlert.showAlert(err.response.data)
        }
    }
}

//* applyMiddleware Receives the action and triggers the correspondent function
const applyMiddleware = (dispatch, actionsAlert) => action => {
    switch (action.type) {
        case REQUEST_USER_TYPES.USER.GET.REQUEST:
            return asyncGetUserByNif(dispatch, actionsAlert, action.payload)
        case REQUEST_USER_TYPES.ENTITY.GET.REQUEST:
            return asyncGetEntityByNipc(dispatch, actionsAlert, action.payload)
        case REQUEST_USER_TYPES.REGISTER.POST.REQUEST:
            return asyncRegisterAccess(dispatch, actionsAlert, action.payload)
        default:
            return dispatch(action)
    }
}
export default applyMiddleware
