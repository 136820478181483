import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, TextField, Button } from '@material-ui/core/'
import { ArrowForward } from '@material-ui/icons'
import InputMask from 'react-input-mask'

import { maskNIFUtil, maskPhoneUtil } from '../../utils'
import { useRequestUserStore } from '../../store/RequestUser/RequestUser.store'
import useValidationForm from '../../hooks/useValidationForm.hook'

import DatePickerComponent from '../../components/templates/DatePicker/DatePicker.component'
import Callout from '../../components/templates/Callout/Callout'

import { customSelectColors } from '../../styles/themes'
import { SActions, SLink, SSelect } from './User.styles'

import getDocumentTypes from '../../utils/documentTypes.util'

function UserForm({ setActiveStep }) {
    const { stateRequestUser, actionsRequestUser } = useRequestUserStore()
    const [documentTypeOptions, setDocumentTypeOptions] = useState([])

    useEffect(() => {
        async function setDocumentTypes() {
            try {
                const documentTypes = await getDocumentTypes()
                setDocumentTypeOptions(documentTypes)
            } catch (err) {
                console.log(err)
            }
        }

        setDocumentTypes()
    }, [])

    const INITIAL_STATE = {
        personTaxIdentificationNumber: {
            value: stateRequestUser.user.personTaxIdentificationNumber || '',
            validation: ['validateNotEmptyUtil', 'validateNifNumberUtil'],
            mask: 'maskNIFUtil',
        },
        personName: {
            value: stateRequestUser.user.personName || '',
            validation: ['validateNotEmptyUtil', 'validateAlphaLatinUtil'],
        },
        documentType: {
            value: stateRequestUser.user.documentType || { id: 0, label: 'Tipo de Documento' },
            validation: ['validateDocumentTypeUtil'],
        },
        documentNumber: {
            value: stateRequestUser.user.documentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        documentValidity: {
            value: stateRequestUser.user.documentValidity || null,
            validation: ['validateDateUtil'],
        },
        personPhoneNumber: {
            value: stateRequestUser.user.personPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
            mask: 'maskPhoneUtil',
        },
        role: {
            value: stateRequestUser.user.role || '',
            validation: ['validateNotEmptyUtil', 'validateAlphaLatinNumberUtil'],
        },
        personEmail: {
            value: stateRequestUser.user.personEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
    }

    // FORM STATE
    const { values, errors, touched, isValidForm, handleChange, handleBlur, setValues } = useValidationForm(
        INITIAL_STATE
    )

    function handleNext() {
        const payload = {
            personTaxIdentificationNumber: values.personTaxIdentificationNumber.value.replace(/\s/g, ''),
            personName: values.personName.value,
            documentType: values.documentType.value,
            documentNumber: values.documentNumber.value,
            documentValidity: values.documentValidity.value,
            personPhoneNumber: values.personPhoneNumber.value.replace(/\s/g, ''),
            role: values.role.value,
            personEmail: values.personEmail.value,
        }
        actionsRequestUser.persistUserOnState(payload)
        setActiveStep(1)
    }

    function handleDatePickerChange(dateString, field) {
        if (dateString) {
            const selectedDate = dateString.split('/')
            const selectedDateString = new Date(
                Number(selectedDate[2]),
                Number(selectedDate[1]) - 1,
                Number(selectedDate[0])
            )
            const selectedDateTimestamp = selectedDateString.getTime()
            const today = new Date()
            const yesterdayTimestamp = today.setDate(today.getDate() - 1)
            const selectedDateIsSameOrBeforeThanYesterday = selectedDateTimestamp <= yesterdayTimestamp

            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
        } else {
            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: null,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: null,
                },
            })
        }
    }

    function handleDatePickerBlur(event, field) {
        if (event) {
            handleBlur({
                target: {
                    name: field,
                },
            })
        }
    }

    function handleMaxLength() {
        // default length value
        let documentMaxLength = 9
        if (values.documentType.value) {
            // 'Cartão de Cidadão' max length
            if (values.documentType.value.id === 1) {
                documentMaxLength = 12
            }

            // 'Passaporte' max length
            if (values.documentType.value.id === 3) {
                documentMaxLength = 8
            }
        }

        return { maxLength: documentMaxLength }
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Callout>
                    Antes de solicitar acesso ao portal, consulte{' '}
                    <a href='https://www.ecce.gov.pt/portal-cliente/acesso/' target='_blank' rel='noopener noreferrer'>
                        aqui
                    </a>{' '}
                    os documentos/declarações que deverá ter em sua posse antes de prosseguir.
                </Callout>
                <Grid item xs={12}>
                    <InputMask
                        mask={maskNIFUtil}
                        value={values.personTaxIdentificationNumber.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maskPlaceholder={null}
                    >
                        {() => (
                            <TextField
                                id='personTaxIdentificationNumber'
                                name='personTaxIdentificationNumber'
                                label='NIF'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={
                                    touched.personTaxIdentificationNumber
                                        ? Boolean(errors.personTaxIdentificationNumber)
                                        : false
                                }
                                helperText={
                                    touched.personTaxIdentificationNumber ? errors.personTaxIdentificationNumber : ''
                                }
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='personName'
                    name='personName'
                    label='Nome Completo'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    value={values.personName.value}
                    error={touched.personName ? Boolean(errors.personName) : false}
                    helperText={touched.personName ? errors.personName : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <SSelect
                    id='documentType'
                    name='documentType'
                    placeholder='Tipo de Documento'
                    margin='dense'
                    variant='outlined'
                    getOptionValue={option => option}
                    options={documentTypeOptions}
                    className={
                        touched.documentType && errors.documentType
                            ? 'inputMarginDense selectError'
                            : 'inputMarginDense'
                    }
                    value={values.documentType.value}
                    error={touched.documentType ? Boolean(errors.documentType) : false}
                    helperText={touched.documentType ? errors.documentType : ''}
                    onChange={object => {
                        const event = {
                            target: {
                                name: 'documentType',
                                value: object,
                            },
                        }
                        handleChange(event)
                        if (object && object.id === 1) {
                            setValues({
                                ...values,
                                documentType: {
                                    ...values.documentType,
                                    value: object,
                                },
                                documentNumber: {
                                    value: '',
                                    validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                                },
                            })
                        } else if (object && object.id === 3) {
                            setValues({
                                ...values,
                                documentType: {
                                    ...values.documentType,
                                    value: object,
                                },
                                documentNumber: {
                                    value: '',
                                    validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                                },
                            })
                        } else if (object && object.id === 5) {
                            setValues({
                                ...values,
                                documentType: {
                                    ...values.documentType,
                                    value: event.target.value,
                                },
                                documentNumber: {
                                    value: '',
                                    validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                                },
                            })
                        } else {
                            setValues({
                                ...values,
                                documentType: {
                                    ...values.documentType,
                                    value: object,
                                },
                                documentNumber: {
                                    value: '',
                                    validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                                },
                            })
                        }
                    }}
                    onBlur={object => {
                        const event = {
                            target: {
                                name: 'documentType',
                                value: object,
                            },
                        }
                        handleBlur(event)
                    }}
                    theme={customSelectColors}
                    isSearchable
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='documentNumber'
                    name='documentNumber'
                    label='Número de Documento'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    value={values.documentNumber.value}
                    error={touched.documentNumber ? Boolean(errors.documentNumber) : false}
                    helperText={touched.documentNumber ? errors.documentNumber : ''}
                    inputProps={handleMaxLength()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <DatePickerComponent
                        dateId='documentValidity'
                        dateLabel='Validade do Documento'
                        name='documentValidity'
                        dateValue={values.documentValidity.value}
                        error={touched.documentValidity ? Boolean(errors.documentValidity) : false}
                        helperText={touched.documentValidity ? errors.documentValidity : ''}
                        onDatePickerChange={event => handleDatePickerChange(event, 'documentValidity')}
                        onBlur={event => handleDatePickerBlur(event, 'documentValidity')}
                        isFullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputMask
                        mask={maskPhoneUtil}
                        value={values.personPhoneNumber.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {() => (
                            <TextField
                                id='personPhoneNumber'
                                name='personPhoneNumber'
                                label='Telefone'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={touched.personPhoneNumber ? Boolean(errors.personPhoneNumber) : false}
                                helperText={touched.personPhoneNumber ? errors.personPhoneNumber : ''}
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='role'
                    name='role'
                    label='Cargo'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    value={values.role.value}
                    error={touched.role ? Boolean(errors.role) : false}
                    helperText={touched.role ? errors.role : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='personEmail'
                    name='personEmail'
                    label='E-mail Institucional'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    value={values.personEmail.value}
                    error={touched.personEmail ? Boolean(errors.personEmail) : false}
                    helperText={touched.personEmail ? errors.personEmail : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
            </Grid>
            <SActions>
                <SLink to='/'>
                    <span className='action'>Voltar para o Login</span>
                </SLink>

                <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValidForm}
                    endIcon={<ArrowForward />}
                    onClick={handleNext}
                >
                    {' '}
                    A Seguir
                </Button>
            </SActions>
        </form>
    )
}

UserForm.propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}

export default UserForm
