import styled from 'styled-components'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Button } from '@material-ui/core'

export const SPaper = styled.div`
    max-width: 500px;

    a {
        text-decoration: none;
        text-align: center;
        color: ${props => props.theme.palette.grey.dark};
        font-weight: bold;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
    }

    svg {
        margin-right: 4px;
    }
`

export const SLogo = styled.div`
    margin-bottom: 10px;
    width: 55px;
    height: auto;
    @media (max-width: 1024px) {
        width: 50px;
        margin-bottom: 16px;
    }
    img {
        max-width: 100%;
    }
`

const SelectStyle = `
    z-index: 10;
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : '0')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
`
export const SSelect = styled(Select)`
    ${SelectStyle};
`

export const SCreatableSelect = styled(CreatableSelect)`
    ${SelectStyle};
    z-index: 9;
`

export const SButton = styled(Button)`
    && {
        margin: 8px 0 4px 0;
    }
`
export const SCircular = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 16px;
        font-size: 16px;
    }
`

export const STitle = styled.div`
    font-weight: 300;
    margin-top: 20px;
`

export const SAccessActions = styled.div`
    padding: 10px 0;

    a {
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
        color: ${props => props.theme.palette.primary.main};
        width: 100%;
    }
`
export const SBoxDone = styled.div`
    height: 60vh;
    min-height: 500px;
    padding: 100px;
    margin-bottom: -150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon-check,
    .icon-error {
        margin: 50px;
    }
    .error-message {
        display: block;
        margin-bottom: 30px;
        font-size: 16px;
    }
`

export const SIconItem = styled.img`
    font-size: 42px;
    margin: 0 30px 0;
`
