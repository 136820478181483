import React from 'react'
import PropTypes from 'prop-types'

import PaperHeaderComponent from './PaperHeader.component'
import PaperBodyComponent from './PaperBody.component'
import PaperFooter from './PaperFooter.component'

import { SPaper } from './Paper.style'

const propTypes = {
    noborder: PropTypes.bool,
    nobg: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    fullScreen: PropTypes.bool,
}

const defaultProps = {
    noborder: false,
    nobg: false,
    className: '',
    children: [],
    fullScreen: false,
}

const PaperComponent = ({ noborder, nobg, className, fullScreen, children }) => (
    <SPaper className={className} noborder={noborder} fullScreen={fullScreen} nobg={nobg}>
        {children}
    </SPaper>
)

export default PaperComponent

PaperComponent.propTypes = propTypes
PaperComponent.defaultProps = defaultProps

export { PaperHeaderComponent, PaperBodyComponent, PaperFooter }
