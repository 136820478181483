import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import AlertReducer, { initialState } from './Alert.reducer'
import useActions from './Alert.actions'

const propTypes = {
    children: PropTypes.node.isRequired,
}

const AlertContext = createContext(null)

export function AlertProvider({ children }) {
    const [stateAlert, dispatch] = useReducer(AlertReducer, initialState)
    const actionsAlert = useActions(stateAlert, dispatch)
    const value = { stateAlert, actionsAlert }

    return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}
AlertProvider.propTypes = propTypes

export const useAlertStore = () => useContext(AlertContext)
