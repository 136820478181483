import { createTypes, createRequestTypes } from '../../utils/typesGenerator.util'

const REGISTER_USER_TYPES = {
    RESET_DATA: createTypes('register/RESET_DATA'),
    USER: createRequestTypes('register/USER'),
    ENTITY: createRequestTypes('register/ENTITY'),
    REGISTER: createRequestTypes('register/REGISTER'),
}

export default REGISTER_USER_TYPES
