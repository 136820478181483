import styled from 'styled-components'
import Select from 'react-select'

export const SelectStyle = `
    z-index: 10;
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : '0')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
`
export const SSelect = styled(Select)`
    ${SelectStyle};
`
