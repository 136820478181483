import React from 'react'

import DataTableComponent from '../../../components/templates/DataTable/DataTable.component'
import DownloadFile from '../../../components/templates/DownloadFile/DownloadFile'

const columns = [
    { title: 'Entidade', field: 'entityName' },
    { title: 'Protocolo', field: 'protocolId' },
    {
        title: 'Download',
        field: 'downloadProtocol',
        sorting: false,
        cellStyle: {
            textAlign: 'center',
        },
        headerStyle: {
            width: '6%',
            textAlign: 'center',
        },
        render: rowData => {
            return rowData.showFileDownload ? <DownloadFile endpoint='/profile/downloadLastProtocolFile' /> : ''
        },
    },
]

function ProtocolsList() {
    return (
        <DataTableComponent
            title='Lista de Protocolos por Entidade'
            columns={columns}
            endPoint='profile/protocolsList'
        />
    )
}

export default ProtocolsList
