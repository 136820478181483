import apiService from '../../services/api.service'

export default async function getSelectData(endpoint) {
    try {
        const response = await apiService.get(`${endpoint}`)
        const { payload } = response.data
        return payload.map(item => {
            return {
                ...item,
                label: item.description,
                value: item.description,
            }
        })
    } catch (err) {
        if (err.response && err.response.data) {
            console.log('err.response.data: ', err.response.data)
            console.log('err.response: ', err.response)
        }
        console.log(err)
        return err
    }
}
