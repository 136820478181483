import { Link } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'

export const SActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 10px;
    button {
        margin-left: 15px;
    }
`
export const SLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
    .action {
        position: absolute;
        left: 0;
        font-size: 12px;
    }
`
const SelectStyle = `
    z-index: 10;
    margin-top: 8px;
    margin-bottom: 4px;
    box-shadow: ${props => (props.helperText ? `0 0 0 1px ${props.theme.palette.warning.dark}` : '0')};
    border-radius: ${props => (props.helperText ? `4px` : '0')};
`
export const SSelect = styled(Select)`
    ${SelectStyle};
`
