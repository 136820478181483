import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import InputMask from 'react-input-mask'

import { CircularProgress, Fade, Grid, TextField } from '@material-ui/core'

import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import { maskNIFUtil, getUrlParam } from '../../utils'
import { INITIAL_STATE, ENDPOINTS } from './constants'

import useValidationForm from '../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../hooks/useServiceCallApi.hook'

import DatePickerComponent from '../../components/templates/DatePicker/DatePicker.component'
import FCaptcha from '../../components/FCaptcha/FCaptcha.component'

import { SLogo, SPaper, SButton, SCircular, STitle } from './Smartcard.styles'

function SmartcardActivation() {
    const { values, errors, touched, isValidForm, handleChange, handleBlur, setValues } = useValidationForm(
        INITIAL_STATE.SMARTCARD_AUTH
    )

    const [requestState, callService] = useServiceCallApi(null)
    const [hash, setHash] = useState(null)
    const refCaptcha = useRef()
    const history = useHistory()

    useEffect(() => {
        setHash(getUrlParam('hash'))
    }, [])

    useEffect(() => {
        if (
            requestState.url === ENDPOINTS.SMARTCART_AUTHENTICATION &&
            !requestState.isLoading &&
            !requestState.error.hasError &&
            requestState
        ) {
            const { validationHash, dn, email, smartcardNumber, expirationDate, issueDate } = requestState.data.payload

            history.push({
                pathname: '/smartcard/activation',
                state: {
                    hash: hash,
                    validationHash: validationHash,
                    distinguishedName: dn,
                    email: email,
                    smartcardNumber: smartcardNumber,
                    expirationDate: expirationDate,
                    issueDate: issueDate,
                },
            })
        }
    }, [requestState, history, hash, values])

    function handleSubmit() {
        const payload = {
            hash,
            fullName: values.fullName.value,
            taxIdentificationNumber: values.taxIdentificationNumber.value.replaceAll(' ', ''),
            smartcardNumber: values.smartcardNumber.value.replaceAll(' ', ''),
            numberIdentificationDocument: values.numberIdentificationDocument.value,
            expirationDateDocument: values.expirationDateDocument.value,
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
        }

        callService(ENDPOINTS.SMARTCART_AUTHENTICATION, {
            requestType: 'post',
            showSnackBarOnSuccess: true,
            data: payload,
        })
    }

    function handleDatePickerChange(dateString, field) {
        if (dateString) {
            const selectedDate = dateString.split('/')
            const selectedDateString = new Date(
                Number(selectedDate[2]),
                Number(selectedDate[1]) - 1,
                Number(selectedDate[0])
            )
            const selectedDateTimestamp = selectedDateString.getTime()

            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: selectedDateTimestamp,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: selectedDateTimestamp,
                },
            })
        } else {
            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: null,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: null,
                },
            })
        }
    }

    function handleDatePickerBlur(event, field) {
        if (event) {
            handleBlur({
                target: {
                    name: field,
                },
            })
        }
    }

    function renderContent() {
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                </SCircular>
            )
        }

        return (
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name='fullName'
                            label='Nome completo'
                            type='text'
                            margin='dense'
                            variant='outlined'
                            value={values.fullName.value}
                            error={touched.fullName ? Boolean(errors.fullName) : false}
                            helperText={touched.fullName ? errors.fullName : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />

                        <InputMask
                            mask={maskNIFUtil}
                            value={values.taxIdentificationNumber.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maskPlaceholder={null}
                        >
                            {() => (
                                <TextField
                                    name='taxIdentificationNumber'
                                    label='NIF'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    error={
                                        touched.taxIdentificationNumber
                                            ? Boolean(errors.taxIdentificationNumber)
                                            : false
                                    }
                                    helperText={touched.taxIdentificationNumber ? errors.taxIdentificationNumber : ''}
                                    required
                                    fullWidth
                                />
                            )}
                        </InputMask>

                        <TextField
                            name='smartcardNumber'
                            label='Número do Cartão'
                            type='text'
                            margin='dense'
                            variant='outlined'
                            error={touched.smartcardNumber ? Boolean(errors.smartcardNumber) : false}
                            helperText={touched.smartcardNumber ? errors.smartcardNumber : ''}
                            value={values.smartcardNumber.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <STitle>Documento de Identificação informado no pedido</STitle>
                        <TextField
                            name='numberIdentificationDocument'
                            label='Número'
                            type='text'
                            margin='dense'
                            variant='outlined'
                            value={values.numberIdentificationDocument.value}
                            error={
                                touched.numberIdentificationDocument
                                    ? Boolean(errors.numberIdentificationDocument)
                                    : false
                            }
                            helperText={touched.numberIdentificationDocument ? errors.numberIdentificationDocument : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 12 }}
                            required
                            fullWidth
                        />
                        <DatePickerComponent
                            name='expirationDateDocument'
                            dateLabel='Data de Validade *'
                            dateValue={values.expirationDateDocument.value}
                            error={touched.expirationDateDocument ? Boolean(errors.expirationDateDocument) : false}
                            helperText={touched.expirationDateDocument ? errors.expirationDateDocument : ''}
                            onDatePickerChange={event => handleDatePickerChange(event, 'expirationDateDocument')}
                            onBlur={event => handleDatePickerBlur(event, 'expirationDateDocument')}
                            allowPastDate
                            isFullWidth
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' alignItems='flex-end' spacing={1}>
                    <Grid item xs={9}>
                        <FCaptcha
                            refCaptcha={refCaptcha}
                            value={values.yourFirstCaptchaUserInput.value}
                            error={
                                touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false
                            }
                            helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SButton
                            variant='contained'
                            color='primary'
                            disabled={!isValidForm}
                            onClick={handleSubmit}
                            fullWidth
                        >
                            Seguinte
                        </SButton>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                <h3>
                    Autentique-se para ativar o seu <i>Smartcard</i>
                </h3>
                {renderContent()}
            </SPaper>
        </Fade>
    )
}

export default SmartcardActivation
