/* eslint-disable no-case-declarations */
import ORDERS_TYPES from './Orders.types'

export const initialState = {
    cart: {},
    services: {},
    paymentInfo: {},
    entity: {},
    isLoading: false,
    previewOrders: [],
}

export function OrdersReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ORDERS_TYPES.RESET_DATA.SUCCESS:
            return initialState
        case ORDERS_TYPES.SERVICE_TYPE.POST.SUCCESS:
            return { ...state, services: action.payload }
        case ORDERS_TYPES.SERVICES.GET.SUCCESS:
            return { ...state, services: { ...state.services, types: action.payload } }
        case ORDERS_TYPES.SERVICE.POST.SUCCESS:
            return { ...state, services: { ...state.services, [action.payload.type]: action.payload.item } }
        case ORDERS_TYPES.SERVICE.PUT.SUCCESS:
            return { ...state, services: action.payload }
        case ORDERS_TYPES.ORG_UNITS.GET.SUCCESS:
            return { ...state, orgUnits: action.payload }
        case ORDERS_TYPES.CART.GET.SUCCESS:
            return { ...state, cart: { ...state.cart, totalPrice: action.payload } }
        case ORDERS_TYPES.CART.POST.SUCCESS:
            return {
                ...state,
                cart: action.payload.newCart,
            }
        case ORDERS_TYPES.PAYMENT.POST.REQUEST:
            return { ...state, paymentInfo: action.payload }
        case ORDERS_TYPES.ENTITY.GET.SUCCESS:
            return {
                ...state,
                entity: {
                    nipc: action.payload.taxIdentificationNumber,
                    entityName: action.payload.name,
                    entityAddress: action.payload.address,
                    entityLocation: action.payload.location,
                    postalCode: action.payload.postalCode,
                    pseudonym: action.payload.pseudonym,
                    limitedRoles: action.payload.limitedRoles,
                },
            }
        case ORDERS_TYPES.SHOW_LOADING.SUCCESS:
            return { ...state, isLoading: true }
        case ORDERS_TYPES.HIDE_LOADING.SUCCESS:
            return { ...state, isLoading: false }
        case ORDERS_TYPES.ORDERS.POST.SUCCESS:
            return { ...state, success: true, message: action.payload.message }
        case ORDERS_TYPES.ORDERS.POST.ERROR:
            return { ...state, success: false, message: action.payload.message }
        case ORDERS_TYPES.PREVIEW_ORDERS.SUCCESS:
            return { ...state, previewOrders: action.data }
        default:
            return state
    }
}
