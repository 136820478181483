import React from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'

import { SSnackbar, SSnackbarContent, SIconButton } from './Alert.style'

import { useAlertStore } from '../../../store/Alert/Alert.store'

const variantTypes = { '0': 'success', '1': 'warning', '2': 'info', '-1': 'error' }

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

const variantTime = {
    success: 3000,
    warning: 8000,
    error: 8000,
    info: 8000,
}

function AlertComponent() {
    const { stateAlert, actionsAlert } = useAlertStore()
    const { open, message, variantCode } = stateAlert

    const variant = variantTypes[variantCode]

    const time = variantTime[variant]
    const Icon = variantIcon[variant]

    return (
        <SSnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            onClose={actionsAlert.hideAlert}
            autoHideDuration={time}
        >
            <SSnackbarContent
                aria-describedby='client-snackbar'
                className={variant}
                message={
                    <span className='client-snackbar'>
                        <Icon className='icon icon-variant' />
                        {message}
                    </span>
                }
                action={[
                    <SIconButton key='close' aria-label='Close' color='inherit' onClick={actionsAlert.hideAlert}>
                        <CloseIcon />
                    </SIconButton>,
                ]}
            />
        </SSnackbar>
    )
}

export default AlertComponent
