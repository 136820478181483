/* eslint-disable consistent-return */
import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory, Prompt } from 'react-router-dom'
import PropTypes from 'prop-types'

import InputMask from 'react-input-mask'

import { Help, Warning, Done, Close } from '@material-ui/icons'
import {
    Button,
    Grid,
    TextField,
    FormControlLabel,
    InputAdornment,
    RadioGroup,
    Radio,
    FormControl,
    Box,
    Tooltip,
    Typography,
    IconButton,
    Checkbox,
    CircularProgress,
} from '@material-ui/core'
import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import DatePickerComponent from '../../components/templates/DatePicker/DatePicker.component'
import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'
import NewOrderSSLConfigModal from './NewOrderWildcardConfig.modal'

import {
    SSelect,
    SDomainList,
    SDomainListItem,
    SFileMessage,
    SErrorMessage,
    SCircular,
} from './NewOrderWildcardConfig.style'

import { customSelectColors } from '../../styles/themes'

// import { useApiStateStore } from '../../store/ApiStatusServices/ApiStatusServices.store'
import { useOrdersStore } from '../../store/Orders/Orders.store'
import { useLoginStore } from '../../store/Login/Login.store'
import useValidationForm from '../../hooks/useValidationForm.hook'
import { maskPhoneUtil } from '../../utils'
import getDocumentTypes from '../../utils/documentTypes.util'

import getSelectData from './NewOrderWildcardConfig.service'

// import { StatusService } from '../../constants/enums'

import { MSG_CONFIRM_PAGE_EXIT } from '../../constants/dictionary/dictionary'

import { DEFAULT_DURATIONS, DEFAULT_DOMAINS_TYPE, DEFAULT_DOCUMENT_TYPE } from './constants'

function NewOrderWildcardConfigForm(props) {
    const { activeWildcard } = props
    const history = useHistory()
    const { stateLogin } = useLoginStore()
    const { stateOrders, actionsOrders } = useOrdersStore()
    // const { stateApiStatusService } = useApiStateStore()

    const [defaultServiceType] = useState(_.find(stateOrders.services.types, { basicType: 'WILDCARD' }).type)
    const [sameValue, setSameValue] = useState(activeWildcard.sameValue ? activeWildcard.sameValue : false)
    const [csrFiles, setCsrFiles] = useState(activeWildcard.csrFile ? [activeWildcard.csrFile] : [])
    const [pdfFiles, setPdfFiles] = useState(activeWildcard.pdfFile ? [activeWildcard.pdfFile] : [])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const wildcardList = stateOrders.services.types.filter(item => item.basicType === 'WILDCARD')
    const [wildcardServerOptions, setWildcardServerOptions] = useState([])
    const [wildcardValidationOptions, setWildcardValidationOptions] = useState([])
    const [documentTypeOptions, setDocumentTypeOptions] = useState([])
    const [loading, setLoading] = useState(() => {
        if (activeWildcard.config) {
            return true
        }
        return false
    })

    useEffect(() => {
        async function setDocumentTypes() {
            try {
                const documentTypes = await getDocumentTypes()
                setDocumentTypeOptions(documentTypes)
            } catch (err) {
                console.log(err)
            }
        }

        setDocumentTypes()
    }, [])

    useEffect(() => {
        const getWildcardSelectOptions = async () => {
            try {
                const serverOptions = await getSelectData('tokensssl/platforms')
                setWildcardServerOptions(serverOptions)
                const validationOptions = await getSelectData('tokensssl/methods')
                setWildcardValidationOptions(validationOptions)
            } catch (err) {
                console.log(err)
                return err
            }
        }

        getWildcardSelectOptions()
    }, [])

    // * Gets the Package from the service list based on the durations array registered previously
    function getInitialPackage(duration) {
        // * Looks inside the timestampList the package that has the same duration IDss
        const pack = wildcardList.filter(item => {
            if (duration) {
                return item.durations[0].id === duration
            }
            return null
        })

        // * returns an object with the selected package for the selectbox
        if (pack && pack.length > 0) {
            return { ...pack[0].durations[0], label: `${pack[0].durations[0].duration} ano(s)` }
        }
        return {}
    }

    function getInitialPrice(duration) {
        const pack = wildcardList.filter(item => {
            if (duration) {
                return item.durations[0].id === duration
            }
            return null
        })

        if (pack && pack.length > 0) {
            return pack[0].durations[0].price
        }
        return ''
    }

    function getInitialValidation(validationId) {
        const validationInitial = wildcardValidationOptions.filter(item => item.id === validationId)

        if (validationInitial && validationInitial.length > 0) {
            return { ...validationInitial[0], label: validationInitial[0].label }
        }

        return {}
    }

    const INITIAL_STATE = {
        id: { value: activeWildcard.id || '' },
        isValid: { value: activeWildcard.isValid || false },
        type: { value: activeWildcard.type || defaultServiceType },
        duration: {
            value: activeWildcard.config
                ? getInitialPackage(activeWildcard.stateId)
                : activeWildcard.duration || DEFAULT_DURATIONS,
            validation: ['validateCardDurationUtil'],
        },
        price: { value: activeWildcard.config ? getInitialPrice(activeWildcard.stateId) : activeWildcard.price || '' },
        server: {
            value: activeWildcard.server || '',
            validation: ['validateNotEmptyUtil'],
        },
        validation: {
            value: activeWildcard.config
                ? getInitialValidation(activeWildcard.config.methodId)
                : activeWildcard.validation || null,
            validation: ['validateNotEmptyUtil'],
        },
        domainList: {
            value: activeWildcard.config
                ? activeWildcard.config.domains
                : activeWildcard.domainList || new Array(1).fill(''),
            validation: ['validateWildcardDomainsArrayUtil'],
        },
        applicantName: {
            value: activeWildcard.config ? activeWildcard.config.requester.name : activeWildcard.applicantName || '',
            validation: ['validatePersonNameUtil'],
        },
        applicantEmail: {
            value: activeWildcard.config ? activeWildcard.config.requester.email : activeWildcard.applicantEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        applicantPhoneNumber: {
            value: activeWildcard.config
                ? activeWildcard.config.requester.phoneNumber
                : activeWildcard.applicantPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
            mask: 'maskPhoneUtil',
        },
        applicantDocumentType: {
            value: activeWildcard.config
                ? activeWildcard.config.requester.documentType
                : activeWildcard.applicantDocumentType || DEFAULT_DOCUMENT_TYPE,
            validation: ['validateDocumentTypeUtil'],
        },
        applicantDocumentNumber: {
            value: activeWildcard.config
                ? activeWildcard.config.requester.documentNumber
                : activeWildcard.applicantDocumentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        applicantDocumentValidity: {
            value: activeWildcard.config
                ? activeWildcard.config.requester.documentValidity
                : activeWildcard.applicantDocumentValidity || null,
            validation: ['validateDateUtil'],
        },
        technicalName: {
            value: activeWildcard.config ? activeWildcard.config.technician.name : activeWildcard.technicalName || '',
            validation: ['validatePersonNameUtil'],
        },
        technicalEmail: {
            value: activeWildcard.config ? activeWildcard.config.technician.email : activeWildcard.technicalEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        technicalPhoneNumber: {
            value: activeWildcard.config
                ? activeWildcard.config.technician.phoneNumber
                : activeWildcard.technicalPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validateCellphoneUtil'],
            mask: 'maskPhoneUtil',
        },
        technicalDocumentType: {
            value: activeWildcard.config
                ? activeWildcard.config.technician.documentType
                : activeWildcard.technicalDocumentType || DEFAULT_DOCUMENT_TYPE,
            validation: ['validateDocumentTypeUtil'],
        },
        technicalDocumentNumber: {
            value: activeWildcard.config
                ? activeWildcard.config.technician.documentNumber
                : activeWildcard.technicalDocumentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        technicalDocumentValidity: {
            value: activeWildcard.config
                ? activeWildcard.config.technician.documentValidity
                : activeWildcard.technicalDocumentValidity || null,
            validation: ['validateDateUtil'],
        },
    }

    const { values, errors, touched, isValidForm, handleChange, handleBlur, setValues, setErrors } = useValidationForm(
        INITIAL_STATE
    )

    const [disableTechnicalPhoneNumber, setDisableTechnicalPhoneNumber] = useState(false)
    useEffect(() => {
        if (sameValue && values.applicantPhoneNumber.value.charAt(0) === '9') {
            setDisableTechnicalPhoneNumber(true)
        } else {
            setDisableTechnicalPhoneNumber(false)
        }
    }, [sameValue, values.applicantPhoneNumber])

    useEffect(() => {
        function handleValidation() {
            const validationInitial = wildcardValidationOptions.filter(item => {
                if (activeWildcard.config) {
                    return item.id === activeWildcard.config.methodId
                }

                return item.id === values.validation
            })
            const serverInitial = wildcardServerOptions.filter(item => {
                if (activeWildcard.config) {
                    return item.id === activeWildcard.config.platformId
                }

                return item.id === values.server
            })

            if ((validationInitial && validationInitial.length > 0) || (serverInitial && serverInitial.length > 0)) {
                setValues({
                    ...values,
                    validation: { ...values.validation, value: validationInitial[0] },
                    server: { ...values.server, value: serverInitial[0] },
                })
            }

            return {}
        }

        if (!_.isEmpty(INITIAL_STATE.validation.value)) {
            handleValidation()
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wildcardValidationOptions, wildcardServerOptions])

    function handleCheckboxChange(event) {
        const { checked } = event.target
        setSameValue(event.target.checked)

        if (checked) {
            if (values.applicantPhoneNumber.value.charAt(0) === '9') {
                const {
                    technicalName,
                    technicalEmail,
                    technicalPhoneNumber,
                    technicalDocumentType,
                    technicalDocumentNumber,
                    technicalDocumentValidity,
                    ...rest
                } = errors
                setErrors(rest)
            } else {
                const {
                    technicalName,
                    technicalEmail,
                    technicalDocumentType,
                    technicalDocumentNumber,
                    technicalDocumentValidity,
                    ...rest
                } = errors
                setErrors(rest)
            }
        }
    }

    function setApplicantValuesToTechnicalValues(applicantValue, technicalValue) {
        if (sameValue) {
            values[`${technicalValue}`].value = values[`${applicantValue}`].value
            return values[`${technicalValue}`].value
        }

        return values[`${technicalValue}`].value
    }

    function handleDurations(list, value) {
        const { durations } = list.filter(item => {
            return item.type === value
        })[0]

        return durations.map(item => ({ ...item, label: `${item.duration} ano(s)` }))
    }

    const [wildcardDurationOptions, setWildcardDurationOptions] = useState(
        handleDurations(wildcardList, defaultServiceType)
    )

    function handleRadioChange(event) {
        const { value } = event.target
        const newDurations = handleDurations(wildcardList, value)
        setWildcardDurationOptions(newDurations)
        setValues({
            ...values,
            type: { ...values.type, value },
            domainsType: { ...values.domainsType, value: DEFAULT_DOMAINS_TYPE },
            duration: { ...values.duration, value: DEFAULT_DURATIONS },
            price: { ...values.price, value: '' },
        })
    }

    function handleSelectChange(name, obj) {
        if (obj) {
            const event = {
                target: {
                    name: name,
                    value: obj,
                },
            }
            switch (name) {
                case 'applicantDocumentType':
                    handleChange(event)
                    if (event.target.value && event.target.value.id === 1) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 3) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 5) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                            },
                        })
                    } else {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                            },
                        })
                    }
                    break
                case 'technicalDocumentType':
                    handleChange(event)
                    if (event.target.value && event.target.value.id === 1) {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 3) {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 5) {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                            },
                        })
                    } else {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                            },
                        })
                    }
                    break
                case 'duration':
                    handleChange(event)
                    setValues({
                        ...values,
                        price: { ...values.price, value: obj.price ? obj.price : '' },
                        duration: { ...values.duration, value: event.target.value },
                    })
                    break
                default:
                    handleChange(event)
                    break
            }
        }
    }

    function handleSelectBlur(name, obj) {
        const event = {
            target: {
                name: name,
                value: obj.target.value,
            },
        }

        handleBlur(event)
    }

    function handleArrayChange(index, value) {
        const newArray = values.domainList.value
        newArray[index] = value
        setValues({ ...values, domainList: { ...values.domainList, value: newArray } })
    }

    function handleArrayBlur(index, value) {
        const newArray = values.domainList.value
        newArray[index] = value
        const event = {
            target: {
                name: 'domainList',
                value: newArray,
            },
        }

        handleBlur(event)
    }

    function handleDatePickerChange(dateString, field) {
        if (dateString) {
            const selectedDate = dateString.split('/')
            const selectedDateString = new Date(
                Number(selectedDate[2]),
                Number(selectedDate[1]) - 1,
                Number(selectedDate[0])
            )
            const selectedDateTimestamp = selectedDateString.getTime()
            const today = new Date()
            const yesterdayTimestamp = today.setDate(today.getDate() - 1)
            const selectedDateIsSameOrBeforeThanYesterday = selectedDateTimestamp <= yesterdayTimestamp

            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
        } else {
            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: null,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: null,
                },
            })
        }
    }

    function handleDatePickerBlur(event, field) {
        if (event) {
            handleBlur({
                target: {
                    name: field,
                },
            })
        }
    }

    function handleSave() {
        const payload = {
            id: values.id.value,
            basicType: 'WILDCARD',
            isValid: true,
            sameValue: sameValue,
            type: values.type.value,
            duration: values.duration.value,
            price: values.price.value,
            server: values.server.value,
            validation: values.validation.value,
            domainList: values.domainList.value,
            applicantName: values.applicantName.value,
            applicantEmail: values.applicantEmail.value,
            applicantPhoneNumber: values.applicantPhoneNumber.value.replace(/\s/g, ''),
            applicantDocumentType: values.applicantDocumentType.value,
            applicantDocumentNumber: values.applicantDocumentNumber.value,
            applicantDocumentValidity: values.applicantDocumentValidity.value,
            technicalName: values.technicalName.value,
            technicalEmail: values.technicalEmail.value,
            technicalPhoneNumber: values.technicalPhoneNumber.value.replace(/\s/g, ''),
            technicalDocumentType: values.technicalDocumentType.value,
            technicalDocumentNumber: values.technicalDocumentNumber.value,
            technicalDocumentValidity: values.technicalDocumentValidity.value,
            csrFile: csrFiles[0],
            pdfFile: pdfFiles[0],
        }
        actionsOrders.setServiceByType(payload, 'wildcards', history)
    }

    function renderValidationMessage() {
        return csrFiles[0].validation.AreValid ? (
            <Fragment>
                <Done fontSize='small' className='success' />
                Ficheiro válido.
            </Fragment>
        ) : (
            <Fragment>
                <Close fontSize='small' className='error' />
                Ficheiro inválido.
            </Fragment>
        )
    }

    return (
        <form>
            {loading ? (
                <Grid item xs={12}>
                    <SCircular>
                        <CircularProgress size={50} />
                    </SCircular>
                </Grid>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Dados para emissão do certificado Wildcard' />
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        defaultValue={defaultServiceType}
                                        aria-label='position'
                                        name='type'
                                        value={values.type.value}
                                        onChange={handleRadioChange}
                                        row
                                    >
                                        {_.find(stateOrders.services.types, {
                                            basicType: 'WILDCARD',
                                            type: 'Novo',
                                        }) && (
                                            <FormControlLabel
                                                value='Novo'
                                                control={<Radio color='primary' />}
                                                label='Novo'
                                                labelPlacement='end'
                                            />
                                        )}
                                        {_.find(stateOrders.services.types, {
                                            basicType: 'WILDCARD',
                                            type: 'Renovação',
                                        }) && (
                                            <FormControlLabel
                                                value='Renovação'
                                                control={<Radio color='primary' />}
                                                label='Renovação'
                                                labelPlacement='end'
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                                <Grid container spacing={1}>
                                    <Grid item xs={8} md={8}>
                                        <SSelect
                                            id='duration'
                                            name='duration'
                                            getOptionValue={option => option}
                                            placeholder='Duração do Wildcard'
                                            options={wildcardDurationOptions}
                                            className={
                                                touched.duration && errors.duration
                                                    ? 'inputMarginDense selectError'
                                                    : 'inputMarginDense'
                                            }
                                            value={values.duration.value}
                                            error={touched.duration ? Boolean(errors.duration) : false}
                                            helperText={touched.duration ? errors.duration : ''}
                                            onChange={event => {
                                                handleSelectChange('duration', event)
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('duration', event)
                                            }}
                                            theme={customSelectColors}
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <TextField
                                            id='price'
                                            name='price'
                                            label='Valor'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.price.value}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: <InputAdornment position='start'>€</InputAdornment>,
                                            }}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SSelect
                                            id='server'
                                            name='server'
                                            getOptionValue={option => option}
                                            placeholder='Selecione a plataforma do servidor'
                                            options={wildcardServerOptions}
                                            value={values.server.value}
                                            className={
                                                touched.server && errors.server
                                                    ? 'inputMarginDense selectError'
                                                    : 'inputMarginDense'
                                            }
                                            onChange={event => {
                                                handleSelectChange('server', event)
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('server', event)
                                            }}
                                            error={touched.server ? Boolean(errors.server) : false}
                                            helperText={touched.server ? errors.server : ''}
                                            theme={customSelectColors}
                                            isLoading={wildcardServerOptions.length === 0}
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SSelect
                                            id='validation'
                                            name='validation'
                                            getOptionValue={option => option}
                                            placeholder='Método de validação DCV'
                                            options={wildcardValidationOptions}
                                            value={values.validation.value}
                                            className={
                                                touched.validation && errors.validation
                                                    ? 'inputMarginDense selectError'
                                                    : 'inputMarginDense'
                                            }
                                            onChange={event => {
                                                handleSelectChange('validation', event)
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('validation', event)
                                            }}
                                            error={touched.validation ? Boolean(errors.validation) : false}
                                            helperText={touched.validation ? errors.validation : ''}
                                            theme={customSelectColors}
                                            isLoading={wildcardValidationOptions.length === 0}
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </PaperComponent>

                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Domínio' />
                                {touched.domainList && Boolean(errors.domainList) && (
                                    <SErrorMessage component='div' variant='subtitle2' mb={1}>
                                        {errors.domainList}
                                    </SErrorMessage>
                                )}
                                <SDomainList>
                                    {values.domainList.value.map((domain, index) => {
                                        return (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <SDomainListItem key={index}>
                                                <TextField
                                                    id={String(index)}
                                                    label='Domínio'
                                                    margin='dense'
                                                    variant='outlined'
                                                    color='primary'
                                                    value={values.domainList.value[index]}
                                                    error={touched.domainList ? Boolean(errors.domainList) : false}
                                                    onChange={event => {
                                                        handleArrayChange(index, event.target.value)
                                                    }}
                                                    onBlur={event => {
                                                        handleArrayBlur(index, event.target.value)
                                                    }}
                                                    fullWidth
                                                />
                                            </SDomainListItem>
                                        )
                                    })}
                                </SDomainList>
                            </PaperComponent>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Adicionar Ficheiro .CSR'>
                                    <Tooltip title='Enviar ficheiro no formato CSR com tamanho máximo de 1Mb'>
                                        <IconButton aria-label='Ajuda'>
                                            <Help fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </PaperHeaderComponent>
                                <UploadFileComponent
                                    id='csrFiles'
                                    // initialFiles={
                                    //     activeWildcard && activeWildcard.config
                                    //         ? [{ ...activeWildcard.config.csrFile, id: activeWildcard.config.csrFile.name }]
                                    //         : csrFiles
                                    // }
                                    initialFiles={csrFiles}
                                    handleFiles={fileList => setCsrFiles(fileList)}
                                    fileType='.csr'
                                    isSingleFile
                                />
                                <Box display='flex' justifyContent='flex-end' mb={2}>
                                    <SFileMessage>
                                        {csrFiles.length > 0 &&
                                            (csrFiles[0].validation ? (
                                                renderValidationMessage()
                                            ) : (
                                                <Fragment>
                                                    <Warning fontSize='small' className='warning' />
                                                    Ficheiro pendente de validação.
                                                </Fragment>
                                            ))}
                                    </SFileMessage>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color='primary'
                                        disabled={!(csrFiles.length > 0)}
                                        onClick={() => {
                                            setIsModalOpen(true)
                                        }}
                                    >
                                        Validar .CSR
                                    </Button>
                                </Box>
                            </PaperComponent>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Dados do requerente' />
                                <Grid container item spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id='applicantName'
                                            name='applicantName'
                                            label='Nome *'
                                            type='text'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.applicantName.value}
                                            error={touched.applicantName ? Boolean(errors.applicantName) : false}
                                            helperText={touched.applicantName ? errors.applicantName : ''}
                                            inputProps={{ maxLength: 255 }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <TextField
                                            id='applicantEmail'
                                            name='applicantEmail'
                                            label='E-mail *'
                                            type='email'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.applicantEmail.value}
                                            error={touched.applicantEmail ? Boolean(errors.applicantEmail) : false}
                                            helperText={touched.applicantEmail ? errors.applicantEmail : ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <InputMask
                                            mask={maskPhoneUtil}
                                            value={values.applicantPhoneNumber.value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {() => (
                                                <TextField
                                                    id='applicantPhoneNumber'
                                                    name='applicantPhoneNumber'
                                                    label='Telefone *'
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={
                                                        touched.applicantPhoneNumber
                                                            ? Boolean(errors.applicantPhoneNumber)
                                                            : false
                                                    }
                                                    helperText={
                                                        touched.applicantPhoneNumber ? errors.applicantPhoneNumber : ''
                                                    }
                                                    fullWidth
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <SSelect
                                            id='applicantDocumentType'
                                            name='applicantDocumentType'
                                            margin='dense'
                                            variant='outlined'
                                            options={documentTypeOptions}
                                            theme={customSelectColors}
                                            getOptionValue={option => option}
                                            value={values.applicantDocumentType.value}
                                            error={
                                                touched.applicantDocumentType
                                                    ? Boolean(errors.applicantDocumentType)
                                                    : false
                                            }
                                            helperText={
                                                touched.applicantDocumentType ? errors.applicantDocumentType : ''
                                            }
                                            onChange={event => {
                                                handleSelectChange('applicantDocumentType', event)
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('applicantDocumentType', event)
                                            }}
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            id='applicantDocumentNumber'
                                            name='applicantDocumentNumber'
                                            label='Nº de Documento *'
                                            margin='dense'
                                            variant='outlined'
                                            value={values.applicantDocumentNumber.value}
                                            error={
                                                touched.applicantDocumentNumber
                                                    ? Boolean(errors.applicantDocumentNumber)
                                                    : false
                                            }
                                            helperText={
                                                touched.applicantDocumentNumber ? errors.applicantDocumentNumber : ''
                                            }
                                            inputProps={
                                                values.applicantDocumentType.value &&
                                                values.applicantDocumentType.value.id === 1
                                                    ? { maxLength: 12 }
                                                    : { maxLength: 9 }
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <DatePickerComponent
                                            id='applicantDocumentValidity'
                                            name='applicantDocumentValidity'
                                            dateLabel='Validade do Documento *'
                                            dateValue={values.applicantDocumentValidity.value}
                                            error={
                                                touched.applicantDocumentValidity
                                                    ? Boolean(errors.applicantDocumentValidity)
                                                    : false
                                            }
                                            helperText={
                                                touched.applicantDocumentValidity
                                                    ? errors.applicantDocumentValidity
                                                    : ''
                                            }
                                            onDatePickerChange={event =>
                                                handleDatePickerChange(event, 'applicantDocumentValidity')
                                            }
                                            onBlur={event => handleDatePickerBlur(event, 'applicantDocumentValidity')}
                                            isFullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </PaperComponent>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Dados do contacto técnico' />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sameValue}
                                            onChange={handleCheckboxChange}
                                            name='sameValue'
                                            color='primary'
                                        />
                                    }
                                    label='Os dados do Requerente e Contacto Técnico são os mesmos'
                                />
                                <Grid container item spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id='technicalName'
                                            name='technicalName'
                                            label='Nome *'
                                            type='text'
                                            margin='dense'
                                            variant='outlined'
                                            value={setApplicantValuesToTechnicalValues(
                                                'applicantName',
                                                'technicalName'
                                            )}
                                            error={touched.technicalName ? Boolean(errors.technicalName) : false}
                                            helperText={touched.technicalName ? errors.technicalName : ''}
                                            inputProps={{ maxLength: 255 }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={sameValue}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <TextField
                                            id='technicalEmail'
                                            name='technicalEmail'
                                            label='E-mail *'
                                            type='email'
                                            margin='dense'
                                            variant='outlined'
                                            value={setApplicantValuesToTechnicalValues(
                                                'applicantEmail',
                                                'technicalEmail'
                                            )}
                                            error={touched.technicalEmail ? Boolean(errors.technicalEmail) : false}
                                            helperText={touched.technicalEmail ? errors.technicalEmail : ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={sameValue}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <InputMask
                                            mask={maskPhoneUtil}
                                            value={
                                                sameValue && values.applicantPhoneNumber.value.charAt(0) === '9'
                                                    ? setApplicantValuesToTechnicalValues(
                                                          'applicantPhoneNumber',
                                                          'technicalPhoneNumber'
                                                      )
                                                    : values.technicalPhoneNumber.value
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={disableTechnicalPhoneNumber}
                                            readOnly={disableTechnicalPhoneNumber}
                                        >
                                            {() => (
                                                <TextField
                                                    id='technicalPhoneNumber'
                                                    name='technicalPhoneNumber'
                                                    label='Telemóvel *'
                                                    margin='dense'
                                                    variant='outlined'
                                                    error={
                                                        touched.technicalPhoneNumber
                                                            ? Boolean(errors.technicalPhoneNumber)
                                                            : false
                                                    }
                                                    helperText={
                                                        touched.technicalPhoneNumber ? errors.technicalPhoneNumber : ''
                                                    }
                                                    disabled={disableTechnicalPhoneNumber}
                                                    fullWidth
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <SSelect
                                            id='technicalDocumentType'
                                            name='technicalDocumentType'
                                            margin='dense'
                                            variant='outlined'
                                            options={documentTypeOptions}
                                            theme={customSelectColors}
                                            getOptionValue={option => option}
                                            value={setApplicantValuesToTechnicalValues(
                                                'applicantDocumentType',
                                                'technicalDocumentType'
                                            )}
                                            error={
                                                touched.technicalDocumentType
                                                    ? Boolean(errors.technicalDocumentType)
                                                    : false
                                            }
                                            helperText={
                                                touched.technicalDocumentType ? errors.technicalDocumentType : ''
                                            }
                                            onChange={event => {
                                                handleSelectChange('technicalDocumentType', event)
                                            }}
                                            onBlur={event => {
                                                handleSelectBlur('technicalDocumentType', event)
                                            }}
                                            isDisabled={sameValue}
                                            isSearchable
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField
                                            id='technicalDocumentNumber'
                                            name='technicalDocumentNumber'
                                            label='Nº de Documento *'
                                            margin='dense'
                                            variant='outlined'
                                            value={setApplicantValuesToTechnicalValues(
                                                'applicantDocumentNumber',
                                                'technicalDocumentNumber'
                                            )}
                                            error={
                                                touched.technicalDocumentNumber
                                                    ? Boolean(errors.technicalDocumentNumber)
                                                    : false
                                            }
                                            helperText={
                                                touched.technicalDocumentNumber ? errors.technicalDocumentNumber : ''
                                            }
                                            inputProps={
                                                values.technicalDocumentType.value &&
                                                values.technicalDocumentType.value.id === 1
                                                    ? { maxLength: 12 }
                                                    : { maxLength: 9 }
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={sameValue}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <DatePickerComponent
                                            id='technicalDocumentValidity'
                                            name='technicalDocumentValidity'
                                            dateLabel='Validade do Documento *'
                                            dateValue={setApplicantValuesToTechnicalValues(
                                                'applicantDocumentValidity',
                                                'technicalDocumentValidity'
                                            )}
                                            error={
                                                touched.technicalDocumentValidity
                                                    ? Boolean(errors.technicalDocumentValidity)
                                                    : false
                                            }
                                            helperText={
                                                touched.technicalDocumentValidity
                                                    ? errors.technicalDocumentValidity
                                                    : ''
                                            }
                                            onDatePickerChange={event =>
                                                handleDatePickerChange(event, 'technicalDocumentValidity')
                                            }
                                            onBlur={event => handleDatePickerBlur(event, 'technicalDocumentValidity')}
                                            isDisabled={sameValue}
                                            isFullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </PaperComponent>
                            <PaperComponent noborder>
                                <PaperHeaderComponent title='Adicionar Ficheiro .PDF'>
                                    <Tooltip title='Enviar ficheiro no formato PDF com tamanho máximo de 1Mb'>
                                        <IconButton aria-label='Ajuda'>
                                            <Help fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </PaperHeaderComponent>
                                <Typography component='div' variant='subtitle2' mb={1}>
                                    Adicione um documento com a lista de Requerentes Autorizados
                                </Typography>

                                <UploadFileComponent
                                    id='pdfFiles'
                                    // initialFiles={
                                    //     activeWildcard && activeWildcard.config
                                    //         ? [{ ...activeWildcard.config.pdfFile, id: activeWildcard.config.pdfFile.name }]
                                    //         : pdfFiles
                                    // }
                                    initialFiles={pdfFiles}
                                    handleFiles={List => setPdfFiles(List)}
                                    isSingleFile
                                />
                            </PaperComponent>
                        </Grid>
                        <NewOrderSSLConfigModal
                            // initialFile={
                            //     activeWildcard && activeWildcard.config ? [activeWildcard.config.pdfFile] : csrFiles[0]
                            // }
                            initialFile={csrFiles[0]}
                            isModalOpen={isModalOpen}
                            handleModalOpen={isOpen => setIsModalOpen(isOpen)}
                            handleFile={file => setCsrFiles([file])}
                        />
                    </Grid>

                    <ActionBarComponent backLink='/new-order' backText='Voltar'>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={
                                !isValidForm ||
                                csrFiles.length !== 1 ||
                                (csrFiles.length === 1 && !csrFiles[0].validation) ||
                                (csrFiles.length === 1 && csrFiles[0].validation && !csrFiles[0].validation.AreValid) ||
                                (stateLogin.activeScope.hasLstReqAutSsl === 0 && pdfFiles.length !== 1)
                            }
                            onClick={handleSave}
                        >
                            Guardar
                        </Button>
                    </ActionBarComponent>
                </>
            )}

            <Prompt
                message={(location, action) => {
                    if (
                        (action === 'PUSH' && !isValidForm) ||
                        (action === 'PUSH' && isValidForm && location.pathname !== '/new-order')
                    ) {
                        return MSG_CONFIRM_PAGE_EXIT
                    }

                    return null
                }}
            />
        </form>
    )
}

NewOrderWildcardConfigForm.propTypes = {
    activeWildcard: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NewOrderWildcardConfigForm
