/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Grid, CircularProgress } from '@material-ui/core'
import { Done, Close } from '@material-ui/icons'

import apiService from '../../services/api.service'

import ItemDataComponent from '../../components/templates/ItemData/ItemData.component'
import ModalComponent, { ModalBodyComponent } from '../../components/templates/Modal/Modal.component'
import { SPaperRow, SCircular } from './NewOrderElectronicSealConfig.style'

const propTypes = {
    initialFile: PropTypes.objectOf(PropTypes.any),
    isModalOpen: PropTypes.bool,
    handleModalOpen: PropTypes.func,
    handleFile: PropTypes.func,
}
const defaultProps = {
    initialFile: {},
    isModalOpen: false,
    handleModalOpen: () => {},
    handleFile: () => {},
}
function NewOrderElectronicSealConfigModal(props) {
    const { initialFile, handleFile, isModalOpen, handleModalOpen } = props
    const [file, setFile] = useState(initialFile)
    const [isLoading, setIsLoading] = useState(false)

    let openModal = isModalOpen
    useEffect(() => {
        openModal = isModalOpen
    }, [isModalOpen])
    //* Updates the local file with the parent file if they are different, when it updates
    useEffect(() => {
        if (Object.keys(initialFile) !== Object.keys(file)) {
            setFile(initialFile)
        }
    }, [initialFile])
    //* Updates the parent file with the local file, when the validation happens
    useEffect(() => {
        if (isModalOpen && file.validation) {
            handleFile(file)
        }
    }, [file])

    //* When the modal opens start the validation of the uploaded file.
    useEffect(() => {
        if (isModalOpen) {
            const validateFile = async () => {
                const { base64 } = file
                try {
                    setIsLoading(true)
                    const fileToValidate = {
                        csr: base64.replace('data:application/octet-stream;', ''),
                    }

                    const response = await apiService.post('csr/validate?type=electronicseal', fileToValidate)
                    const { payload } = response.data
                    setIsLoading(false)
                    setFile({
                        ...file,
                        validation: payload,
                    })
                } catch (err) {
                    if (err.response && err.response.data) {
                        setIsLoading(false)
                        setFile({
                            ...file,
                            validation: err.response.data,
                        })
                    }
                }
                setIsLoading(false)
                return null
            }

            validateFile()
        }
    }, [isModalOpen])

    function renderModalContent() {
        if (file && file.validation) {
            return file.validation.message ? (
                <span>{file.validation.message}</span>
            ) : (
                <Fragment>
                    <SPaperRow>
                        {file.validation.Subject.isValid ? <Done className='success' /> : <Close color='primary' />}
                        <ItemDataComponent vertical itemKey='Subject' itemValue={file.validation.Subject.value} />
                    </SPaperRow>
                    <SPaperRow>
                        {file.validation.SigningAlgorithm.isValid ? (
                            <Done className='success' />
                        ) : (
                            <Close color='primary' />
                        )}
                        <ItemDataComponent
                            itemKey='Signing Algorithm'
                            itemValue={file.validation.SigningAlgorithm.value}
                        />
                    </SPaperRow>
                    <SPaperRow>
                        <ItemDataComponent vertical itemKey='Public Key Information'>
                            <SPaperRow>
                                {file.validation.Algorithm.isValid ? (
                                    <Done className='success' />
                                ) : (
                                    <Close color='primary' />
                                )}
                                <ItemDataComponent
                                    vertical
                                    itemKey='Algorithm'
                                    itemValue={file.validation.Algorithm.value}
                                />
                            </SPaperRow>
                            <SPaperRow>
                                {file.validation.PublicKey.isValid ? (
                                    <Done className='success' />
                                ) : (
                                    <Close color='primary' />
                                )}
                                <ItemDataComponent
                                    vertical
                                    itemKey='Public Key'
                                    itemValue={file.validation.PublicKey.value}
                                />
                            </SPaperRow>
                        </ItemDataComponent>
                    </SPaperRow>
                    <SPaperRow>
                        <ItemDataComponent vertical itemKey='Extensions'>
                            <SPaperRow>
                                {file.validation.SubjectAlternativeName.isValid ? (
                                    <Done className='success' />
                                ) : (
                                    <Close color='primary' />
                                )}
                                <ItemDataComponent
                                    itemKey='Subject Alternative Name'
                                    itemValue={file.validation.SubjectAlternativeName.value}
                                />
                            </SPaperRow>
                        </ItemDataComponent>
                    </SPaperRow>
                    <SPaperRow>
                        <ItemDataComponent vertical itemKey='Validations'>
                            <SPaperRow>
                                {file.validation.SubjectAlternativeNameNumber.isValid ? (
                                    <Done className='success' />
                                ) : (
                                    <Close color='primary' />
                                )}
                                <ItemDataComponent
                                    itemKey='Número de Subjects Alternative Names'
                                    itemValue={file.validation.SubjectAlternativeNameNumber.value}
                                />
                            </SPaperRow>
                        </ItemDataComponent>
                    </SPaperRow>
                </Fragment>
            )
        }
        return null
    }

    return (
        <ModalComponent open={openModal} title='Validação do ficheiro CSR' onClick={() => handleModalOpen(false)}>
            <ModalBodyComponent>
                {isLoading ? (
                    <Grid item xs={12}>
                        <SCircular>
                            <CircularProgress size={50} />
                            <span>A validar ficheiro...</span>
                        </SCircular>
                    </Grid>
                ) : (
                    renderModalContent()
                )}
            </ModalBodyComponent>
        </ModalComponent>
    )
}

NewOrderElectronicSealConfigModal.propTypes = propTypes
NewOrderElectronicSealConfigModal.defaultProps = defaultProps

export default NewOrderElectronicSealConfigModal
