import React from 'react'
import PropTypes from 'prop-types'

import { Fade, Grid, Typography } from '@material-ui/core'
import NewOrderPaymentInfoForm from '../../forms/NewOrderPaymentInfo/NewOrderPaymentInfoForm.form'

import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'

const propTypes = {
    setActiveStep: PropTypes.func.isRequired,
}

const PaymentInfoStep = ({ setActiveStep }) => {
    return (
        <Fade in>
            <Grid container justify='center'>
                <Grid item xs={12} md={8}>
                    <PaperComponent noborder nobg>
                        <PaperHeaderComponent title='Informações para Faturação' />
                        <Typography variant='subtitle1'>
                            Preencher campos referentes ao Documento de Compromisso para faturação.
                        </Typography>

                        <NewOrderPaymentInfoForm setActiveStep={setActiveStep} />
                    </PaperComponent>
                </Grid>
            </Grid>
        </Fade>
    )
}

PaymentInfoStep.propTypes = propTypes

export default PaymentInfoStep
