import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Tabs, ExpansionPanelSummary, Chip, Avatar, ExpansionPanel } from '@material-ui/core'

export const SPaperRow = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${props => props.theme.palette.grey.light};
    &:nth-last-child(1) {
        border-bottom: none;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        min-width: auto;
        width: 100%;
    }
`

export const STextInfo = styled.div`
    font-size: 13px;
    line-height: 19px;
    color: ${props => props.theme.palette.grey.dark};
    padding-bottom: 20px;

    span {
        font-weight: 900;
    }

    h5 {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
`

export const SCircular = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const STabs = styled(Tabs)`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.palette.grey.light};
    align-items: center;
`
export const SAccessActions = styled.div`
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const SLink = styled(Link)`
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.main};
`

export const Processing = styled.span`
    font-weight: 600;
    &&::after {
        content: 'verificação pendente';
        font-style: italic;
        font-size: 10px;
        display: block;
        color: ${props => props.theme.palette.warning.dark};
    }
`

export const ItemChip = styled(Chip)`
    && {
        font-size: 12px;
        color: ${props => props.theme.palette.white};
        font-weight: 600;
        width: 100px;
        background-color: ${props => {
            if (props.type === 1) {
                return props.theme.palette.success.main
            }
            return props.theme.palette.grey.light
        }};
    }
`

export const CardAvatar = styled(Avatar)`
    && {
        width: 80px;
        height: 108px;
        margin-right: 20px;
        border: 1px solid #ccc;
    }
`

export const SExpansionPanel = styled(ExpansionPanel)`
    && {
        box-shadow: 0 0 0;
        border-radius: 8px;
        margin-bottom: 16px;
        background-color: ${props => props.theme.palette.grey.xLight};

        &:before {
            display: none;
        }
    }
`

export const SExpansionPanelSummary = styled(ExpansionPanelSummary)`
    && {
        p {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
        }
    }
`
export const HelperText = styled.div`
    text-decoration: none;
    font-size: 12px;
    color: ${props => props.theme.palette.primary.main};
`

export const SHelperText = styled.div`
    a {
        text-decoration: none;
        cursor: pointer;
        color: ${props => props.theme.palette.primary.main};
        .action {
            position: absolute;
            left: 0;
            font-size: 12px;
        }
    }
`

export const RefreshingContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 20px;

    & > .MuiCircularProgress-root {
        margin-left: 15px;
    }
`
