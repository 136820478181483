/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import MaterialTable from 'material-table'
import { TablePagination } from '@material-ui/core'

import axios from 'axios'
import api from '../../../services/api.service'

import { options as optionsTable, localization as localizationOptions } from '../../../config/Table.config'

import { useTableStore } from '../../../store/Table/Table.store'

import { TableContainer } from './styles'

function DataTablePagination(props) {
    const {
        title,
        columns,
        options,
        localization,
        components,
        icons,
        actions,
        endPoint,
        onRowClick,
        tableRef,
        certificateFromOtherEntities,
        showCheckBoxOtherEntities,
        myOrders,
    } = props
    const { tableState, tableActions } = useTableStore()

    const INITIAL_STATE = {
        data: [],
        totalCount: 0,
        pageChanged: true,
        query: {
            page: 0,
            pageSize: 10,
            search: '',
            orderDirection: '',
            orderBy: '',
            filterFields: '',
            filterValues: '',
        },
    }
    const [state, setState] = useState(INITIAL_STATE)
    const [loading, setLoading] = useState(true)

    const source = axios.CancelToken.source()

    function getTableData() {
        let url = ''

        url += `?`

        if (myOrders) {
            url += `my-orders=true`
        }

        url += `${myOrders ? '&' : ''}limit=${state.query.pageSize}&page=${state.query.page}`

        if (showCheckBoxOtherEntities) {
            url += `&certificateFromOtherEntities=${certificateFromOtherEntities}`
        }
        if (state.query.search.length !== 0) url += `&searchString=${state.query.search}`
        if (state.query.filterFields.length !== 0) url += `&filterFields=${state.query.filterFields}`
        if (state.query.filterValues.length !== 0) url += `&filterValues=${state.query.filterValues}`
        if (state.query.orderBy.length !== 0)
            url += `&sortFields=${state.query.orderBy}&sort=${state.query.orderDirection}`

        tableActions.setTableSearchAndFilterQueryToExportCSV(url)

        api.get(`${endPoint}${url}`, { cancelToken: source.token })
            .then(response => {
                const { headers } = response

                setState({
                    ...state,
                    data: response.data.payload,
                    totalCount: Number(headers['x-total-count']),
                    pageChanged: true,
                })
                setLoading(false)
            })
            .catch(error => console.log('', error))
    }

    useEffect(() => {
        if (tableState.rowClicked) {
            setState({
                ...state,
                query: tableState.tableHistory,
                pageChanged: false,
            })
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        tableActions.setTableHistory(state.query)
        getTableData()

        return () => {
            source.cancel()
        }
    }, [state.query, certificateFromOtherEntities, myOrders])

    function handleChangePage(page) {
        setLoading(true)

        if (state.pageChanged) {
            setState({
                ...state,
                query: { ...state.query, page: page },
                pageChanged: false,
            })
        }

        if (tableState.rowClicked) tableActions.setAllowSaveTableHistory(false)
    }

    function handleChangeRowsPerPage(pageSize) {
        setLoading(true)

        const newQuery = {
            page: 0,
            pageSize: pageSize,
            search: state.query.search,
            orderDirection: state.query.orderDirection,
            orderBy: state.query.orderBy,
            filterFields: state.query.filterFields,
            filterValues: state.query.filterValues,
        }

        setState({
            ...state,
            query: newQuery,
        })

        if (tableState.rowClicked) tableActions.setAllowSaveTableHistory(false)
    }

    function handleOrderChange(colId, ord) {
        setLoading(true)

        const columnField = columns.find((element, index) => (index === colId ? element : ''))
        const field = columnField && columnField !== -1 ? columnField.field : ''
        const orderDirection = ord

        setState({
            ...state,
            query: { ...state.query, orderDirection: orderDirection, orderBy: field },
        })

        if (tableState.rowClicked) {
            tableActions.setAllowSaveTableHistory(false)
        }
    }

    function handleSearch(search) {
        setLoading(true)

        if (search) {
            setState({
                ...state,
                query: {
                    page: state.query.page,
                    pageSize: state.query.pageSize,
                    search: search,
                    orderDirection: state.query.orderDirection,
                    orderBy: state.query.orderBy,
                    filterFields: state.query.filterFields,
                    filterValues: state.query.filterValues,
                },
            })
        } else {
            setState({
                ...state,
                query: { ...state.query, page: 0, search: '', orderDirection: '', orderBy: '' },
            })
        }
    }

    function handleFilters(filtersProps) {
        setLoading(true)

        const filters = filtersProps.filter(filter => filter.value.length !== 0)

        if (filters.length !== 0) {
            const filterFieldsArr = []
            const filterValuesArr = []

            filters.map(filter => {
                filterFieldsArr.push(filter.column.field)
                if (filter.column.type === 'date') {
                    const day = filter.value.getDate()
                    const month = filter.value.getMonth()
                    const year = filter.value.getFullYear()

                    const date = new Date(year, month, day)
                    filterValuesArr.push(date.getTime())
                    return
                }
                if (typeof filter.value === 'object') {
                    filterValuesArr.push(filter.value.join('~').toUpperCase())
                    return
                }
                filterValuesArr.push(filter.value)
            })

            setState({
                ...state,
                query: { ...state.query, filterFields: filterFieldsArr, filterValues: filterValuesArr },
            })
        } else {
            setState({
                ...state,
                query: { ...state.query, page: 0, filterFields: '', filterValues: '' },
            })
        }
    }

    return (
        <MaterialTable
            title={title}
            data={state.data}
            tableRef={tableRef}
            columns={columns}
            isLoading={loading}
            icons={{ ...optionsTable.tableIcons, ...icons }}
            onRowClick={onRowClick}
            localization={{ ...localizationOptions, ...localization }}
            actions={actions}
            components={{
                Container: props => <TableContainer {...props} elevation={0} />,
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={state.totalCount}
                        page={state.query.page}
                        rowsPerPage={state.query.pageSize}
                    />
                ),
                ...components,
            }}
            options={{
                ...options,
                ...optionsTable,
                pageSize: state ? state.query.pageSize : 10,
                searchText: state.query.search ? state.query.search : '',
                // eslint-disable-next-line no-unneeded-ternary
                search: options && options.search === false ? false : true,
            }}
            onChangePage={page => handleChangePage(page)}
            onChangeRowsPerPage={pageSize => handleChangeRowsPerPage(pageSize)}
            onOrderChange={(colId, ord) => handleOrderChange(colId, ord)}
            onSearchChange={search => handleSearch(search)}
            onFilterChange={filters => handleFilters(filters)}
        />
    )
}

DataTablePagination.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    components: PropTypes.objectOf(PropTypes.any),
    options: PropTypes.objectOf(PropTypes.any),
    localization: PropTypes.objectOf(PropTypes.any),
    icons: PropTypes.objectOf(PropTypes.any),
    actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.arrayOf(PropTypes.func)]),
    endPoint: PropTypes.string,
    certificateFromOtherEntities: PropTypes.bool,
    tableRef: PropTypes.objectOf(PropTypes.any),
}

DataTablePagination.defaultProps = {
    title: '',
    components: null,
    options: null,
    localization: null,
    icons: null,
    actions: null,
    endPoint: '',
    certificateFromOtherEntities: false,
    tableRef: null,
}

export default memo(DataTablePagination)
