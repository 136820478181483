export const Types = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    ADD_FILE: 'ADD_FILE',
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
}

export const INITIAL_STATE = {
    isModalOpen: false,
    file: [],
    invoiceId: null,
    isLoading: false,
}

export function invoiceReducer(state, action) {
    switch (action.type) {
        case Types.OPEN_MODAL:
            return { ...state, isModalOpen: true, invoiceId: action.payload }
        case Types.CLOSE_MODAL:
            return { ...state, isModalOpen: false, file: [], invoiceId: null }
        case Types.ADD_FILE:
            return { ...state, file: action.payload }
        case Types.START_LOADING:
            return { ...state, isLoading: true, isModalOpen: false, file: [], invoiceId: null }
        case Types.STOP_LOADING:
            return { ...state, isLoading: false }
        default:
            return { ...state }
    }
}
