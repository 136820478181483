import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import { getChipColor } from '../../constants/chipColors'

export const ItemChip = styled(Chip)`
    && {
        font-size: 12px;
        color: ${props => props.theme.palette.white};
        font-weight: 600;
        width: 100px;
        background-color: ${props => getChipColor(props)};
    }
`
