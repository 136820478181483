import apiService from '../../services/api.service'

const ContactService = (function() {
    async function apiSubmitForm(payload, actionsAlert) {
        const contactPostService = await apiService
            .post('contacts', payload)
            .then(res => {
                actionsAlert.showAlert(res.data)
                return { isSuccess: true, res }
            })
            .catch(err => {
                actionsAlert.showAlert(err.response.data)
                return { isSuccess: false, err }
            })
        return contactPostService
    }
    return { apiSubmitForm }
})()

export default ContactService
