export const DEFAULT_PACKAGE = {
    id: 0,
    label: 'Selecione o Pacote',
    price: '',
}

export const DEFAULT_DOMAINS_TYPE = {
    id: 0,
    label: 'Selecione o tipo do certificado',
}
