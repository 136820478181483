import apiService from '../../services/api.service'

export default async function getOrderService(id) {
    try {
        const response = await apiService.get(`orders/client/${id}`)
        const { payload } = response.data

        return payload
    } catch (err) {
        console.log(err)
        return null
    }
}
