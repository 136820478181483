import React from 'react'
import PropTypes from 'prop-types'

import PageTitleComponent from './PageTitle.component'

import { SContent } from './Content.style'

const propTypes = {
    children: PropTypes.node,
}

const defaultProps = {
    children: [],
}

const ContentComponent = ({ children }) => {
    return <SContent>{children}</SContent>
}

ContentComponent.propTypes = propTypes
ContentComponent.defaultProps = defaultProps

export default ContentComponent

export { PageTitleComponent }
