import styled, { css, keyframes } from 'styled-components'

const spinner = keyframes`
  from {
    transform: rotate(0deg);
    }

    to {
    transform: rotate(360deg);
    }
`

const dragActive = css`
    border-color: ${props => props.theme.palette.success.light};
`
const dragReject = css`
    border-color: ${props => props.theme.palette.error.light};
`
export const SContainer = styled.div.attrs({
    className: 'dropZone',
})`
    border: 1px dashed ${props => props.theme.palette.grey.light};
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 30px;
    text-align: center;

    transition: heigth 0.2s ease;

    ${props => props.isDragActive && dragActive}
    ${props => props.isDragReject && dragReject}
`

export const SFileList = styled.ul`
    padding-left: 0;
    margin-top: 20px;
    list-style: none;
`

export const SFileListItem = styled.li`
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${props => props.theme.palette.grey.dark};
    & + li {
        margin-top: 15px;
    }
`

export const SFileContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SFileInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .file-name {
        color: ${props => props.theme.palette.grey.main};
        font-weight: bold;
    }
    .file-info {
        font-size: 12px;
        color: ${props => props.theme.palette.grey.main};
        button {
            border: 0;
            background-color: transparent;
            color: ${props => props.theme.palette.grey.main};
            cursor: pointer;
        }
    }
`

export const SLoading = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    color: ${props => props.theme.palette.grey.main};
    .auto-renew {
        animation: ${spinner} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        color: ${props => props.theme.palette.grey.main};
    }
`
