export default class AuthService {
    // DECLARATIONS
    constructor(storage) {
        this.storage = storage
        this.USER_KEY = '@Simple4CertForceClientUser'
        this.TOKEN_KEY = '@Simple4CertForceClient'
        this.TOKEN_REFRESH_KEY = '@Simple4CertForceClientRefreshKey'
        this.TOKEN_2S_KEY = '@Simple4CertForceClient2SKey'
        this.ENTITY_KEY = '@Simple4CertForceClientEntity'
        this.ACTIVE_ENTITY_KEY = '@Simple4CertForceClientActiveEntity'
        this.SCOPES_KEY = '@Simple4CertForceClientScopes'
        this.ACTIVE_SCOPE_KEY = '@Simple4CertForceClientActiveScopeId'
        this.PERMISSIONS_KEY = '@Simple4CertForceClientPermissions'
    }

    doLogout() {
        this.clearSessionStorage()
    }

    clearSessionStorage() {
        this.storage.clear()
    }

    // -- User
    // SET
    setUser(user) {
        this.storage.setItem(this.USER_KEY, JSON.stringify(user))
    }

    // GET
    getUser() {
        const user = this.storage.getItem(this.USER_KEY)
        return user ? JSON.parse(user) : null
    }

    // REMOVE
    removeUser() {
        this.storage.removeItem(this.USER_KEY)
    }

    // -- Token
    // SET
    setToken(token) {
        this.storage.setItem(this.TOKEN_KEY, token)
    }

    setRefreshToken(token) {
        this.storage.setItem(this.TOKEN_REFRESH_KEY, token)
    }

    set2SToken(token) {
        this.storage.setItem(this.TOKEN_2S_KEY, JSON.stringify(token))
    }

    // GET
    getToken() {
        return this.storage.getItem(this.TOKEN_KEY)
    }

    getRefreshToken() {
        return this.storage.getItem(this.TOKEN_REFRESH_KEY)
    }

    get2SToken() {
        return this.storage.getItem(this.TOKEN_2S_KEY)
    }

    // REMOVE
    removeToken() {
        this.storage.removeItem(this.TOKEN_KEY)
    }

    removeRefreshToken() {
        this.storage.removeItem(this.TOKEN_REFRESH_KEY)
    }

    remove2SToken() {
        this.storage.removeItem(this.TOKEN_2S_KEY)
    }

    // -- Entity
    // SET
    setEntities(orgUnits) {
        this.storage.setItem(this.ENTITY_KEY, JSON.stringify(orgUnits))
    }

    setActiveEntity(entityId) {
        this.storage.setItem(this.ACTIVE_ENTITY_KEY, entityId)
    }

    // GET
    getEntities() {
        return this.storage.getItem(this.ENTITY_KEY)
    }

    getActiveEntity() {
        return this.storage.getItem(this.ACTIVE_ENTITY_KEY)
    }

    // REMOVE
    removeEntity() {
        this.storage.removeItem(this.ENTITY_KEY)
    }

    removeActiveEntity() {
        this.storage.removeItem(this.ACTIVE_ENTITY_KEY)
    }

    // -- Scopes
    // SET
    setScopes(scopes) {
        this.storage.setItem(this.SCOPES_KEY, JSON.stringify(scopes))
    }

    setActiveScopeId(scopeId) {
        this.storage.setItem(this.ACTIVE_SCOPE_KEY, scopeId)
    }

    // GET
    getScopes() {
        const scopes = this.storage.getItem(this.SCOPES_KEY)
        return scopes ? JSON.parse(scopes) : null
    }

    getActiveScopeId() {
        return this.storage.getItem(this.ACTIVE_SCOPE_KEY)
    }

    // REMOVE
    removeScopes() {
        this.storage.removeItem(this.SCOPES_KEY)
    }

    removeActiveScopeId() {
        this.storage.removeItem(this.ACTIVE_SCOPE_KEY)
    }

    // -- Permissions
    // SET
    setPermissions(permissions) {
        this.storage.setItem(this.PERMISSIONS_KEY, JSON.stringify(permissions))
    }

    // GET
    getPermissions() {
        const permissions = this.storage.getItem(this.PERMISSIONS_KEY)
        return permissions ? JSON.parse(permissions) : null
    }

    // REMOVE
    removePermissions() {
        this.storage.removeItem(this.PERMISSIONS_KEY)
    }

    // // -- Main Functions
    // isValidTokenService(state) {
    //     return this.getToken() || state.isLogged
    // }

    // isAuthenticated(state) {
    //     return this.get2SToken() && this.isValidTokenService(state)
    // }
}
