/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { CircularProgress } from '@material-ui/core'

import { useLoginStore } from '../../store/Login/Login.store'
import MessageComponent from '../../components/templates/Message/Message.component'
import { SCircular } from './Saml.styles'

function Saml() {
    const location = useLocation()
    const [processing, setProcessing] = useState(true)
    const [message, setMessage] = useState('Desculpa, o utilizador não foi encontrado na base de dados')
    const { stateLogin, actionsLogin } = useLoginStore()
    const history = useHistory()

    useEffect(() => {
        const token = location.search.split('?auth_token=')[1]

        if (token) {
            setTimeout(() => {
                const login = {
                    typeAuthentication: 2,
                    token,
                }
                const refCaptcha = null

                actionsLogin.doLogin(history, login, refCaptcha)
            }, 500)
        } else {
            setProcessing(false)
        }
    }, [])

    useEffect(() => {
        if (stateLogin.error.length > 0) {
            setMessage(stateLogin.error)
            setProcessing(false)
        }
    }, [stateLogin.error])

    return (
        <Fragment>
            {!processing ? (
                <MessageComponent type='error' link='/' message={message} textBack='Login' />
            ) : (
                <SCircular>
                    <CircularProgress size={80} />
                    <span>A processar a autenticação</span>
                </SCircular>
            )}
        </Fragment>
    )
}

export default Saml
