import React from 'react'

import { Grid, TextField, Button } from '@material-ui/core'

import useValidationForm from '../../hooks/useValidationForm.hook'
import ContactService from './Contact.service'

import { useAlertStore } from '../../store/Alert/Alert.store'

import { customSelectColors } from '../../styles/themes'
import { SSelect } from './Contact.styles'

const CONTACT_TYPES = [
    { value: 1, label: 'Sugestão' },
    { value: 2, label: 'Reclamação' },
    { value: 3, label: 'Dúvida' },
]

function ContactForm() {
    const { actionsAlert } = useAlertStore()

    const INITIAL_STATE = {
        type: { value: '', validation: ['validateNotEmptyUtil'] },
        subject: { value: '', validation: ['validateNotEmptyUtil', 'validateMaxChar100Util'] },
        body: { value: '', validation: ['validateNotEmptyUtil', 'validateMaxChar1000Util'] },
    }

    // FORM STATE
    const { values, setValues, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(
        INITIAL_STATE
    )

    function handleSubmit() {
        const payload = {
            type: values.type.value.value,
            subject: values.subject.value,
            body: values.body.value,
        }
        ContactService.apiSubmitForm(payload, actionsAlert).then(res => {
            if (res && res.isSuccess) {
                setValues(INITIAL_STATE)
            }
        })
    }

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SSelect
                        id='type'
                        name='type'
                        placeholder='Tipo'
                        margin='dense'
                        variant='outlined'
                        getOptionValue={option => option}
                        options={CONTACT_TYPES}
                        value={values.type.value}
                        helperText={touched.type ? errors.type : ''}
                        onChange={opt => setValues({ ...values, type: { ...INITIAL_STATE.type, value: opt } })}
                        onBlur={handleBlur}
                        isSearchable={false}
                        theme={customSelectColors}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='subject'
                        name='subject'
                        label='Assunto'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.subject.value}
                        error={touched.subject ? Boolean(errors.subject) : false}
                        helperText={touched.subject ? errors.subject : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                            maxLength: 100,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='body'
                        name='body'
                        label='Mensagem'
                        type='textarea'
                        rows='8'
                        margin='dense'
                        variant='outlined'
                        value={values.body.value}
                        error={touched.body ? Boolean(errors.body) : false}
                        helperText={touched.body ? errors.body : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                            maxLength: 1000,
                        }}
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Button variant='contained' color='primary' onClick={handleSubmit} disabled={!isValidForm} fullWidth>
                    Enviar
                </Button>
            </Grid>
        </form>
    )
}

export default ContactForm
