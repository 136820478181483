import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'
import { useHistory, Prompt } from 'react-router-dom'
import PropTypes from 'prop-types'

import InputMask from 'react-input-mask'

import { Close, Done, Help, Warning } from '@material-ui/icons'
import {
    Button,
    Grid,
    TextField,
    FormControlLabel,
    InputAdornment,
    RadioGroup,
    Radio,
    FormControl,
    Box,
    Tooltip,
    IconButton,
    Checkbox,
    Typography,
} from '@material-ui/core'
import PaperComponent, { PaperHeaderComponent } from '../../components/templates/Paper/Paper.component'
import ActionBarComponent from '../../components/templates/ActionBar/ActionBar.component'
import DatePickerComponent from '../../components/templates/DatePicker/DatePicker.component'
import UploadScaledImageComponent from '../../components/templates/UploadScaledImage/UploadScaledImage.component'
import UploadFileComponent from '../../components/templates/UploadFile/UploadFile.template'
import NewOrderElectronicSealConfigModal from './NewOrderElectronicSealConfig.modal'

import { SSelect, SFileMessage, ErrorComponent } from './NewOrderElectronicSealConfig.style'
import { customSelectColors } from '../../styles/themes'

// import { useApiStateStore } from '../../store/ApiStatusServices/ApiStatusServices.store'
import { useOrdersStore } from '../../store/Orders/Orders.store'
import { useLoginStore } from '../../store/Login/Login.store'
import useValidationForm from '../../hooks/useValidationForm.hook'
import { maskPhoneUtil } from '../../utils'
import getDocumentTypes from '../../utils/documentTypes.util'

// import { StatusService } from '../../constants/enums'
import { MSG_CONFIRM_PAGE_EXIT } from '../../constants/dictionary/dictionary'

import { DEFAULT_DURATION, DEFAULT_DOMAINS_TYPE, DEFAULT_DOCUMENT_TYPE } from './constants'

function NewOrderElectronicSealConfigForm(props) {
    const { activeElectronicSeal } = props
    const history = useHistory()
    const { stateLogin } = useLoginStore()
    const { stateOrders, actionsOrders } = useOrdersStore()
    // const { stateApiStatusService } = useApiStateStore()

    const [defaultServiceType] = useState(_.find(stateOrders.services.types, { basicType: 'ELECTRONIC_SEAL' }).type)
    const [sameValue, setSameValue] = useState(activeElectronicSeal.sameValue ? activeElectronicSeal.sameValue : false)
    const [csrFiles, setCsrFiles] = useState(activeElectronicSeal.csrFile ? [activeElectronicSeal.csrFile] : [])
    const [pdfFiles, setPdfFiles] = useState(activeElectronicSeal.pdfFile ? [activeElectronicSeal.pdfFile] : [])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const electronicSealList = stateOrders.services.types.filter(item => item.basicType === 'ELECTRONIC_SEAL')

    const [maxSize, setMaxSize] = useState(0)
    const [imagesTipes, setImagesTipes] = useState(true)

    const [documentTypeOptions, setDocumentTypeOptions] = useState([])

    useEffect(() => {
        async function setDocumentTypes() {
            try {
                const documentTypes = await getDocumentTypes()
                setDocumentTypeOptions(documentTypes)
            } catch (err) {
                console.log(err)
            }
        }

        setDocumentTypes()
    }, [])

    // * Gets the Package from the service list based on the durations array registered previously
    function getInitialDuration(duration) {
        // * Looks inside the electronicSealList the package that has the same duration IDss
        const pack = electronicSealList.map(item => {
            if (duration) {
                // return item.durations[0].id === duration
                const tokenDuration = item.durations.filter(itemDuration => itemDuration.id === duration)
                return tokenDuration[0]
            }
            return null
        })
        // * returns an object with the selected package for the selectbox
        if (pack && pack.length > 0) {
            return { ...pack[0], label: `${pack[0].duration} ano(s)` }
        }
        return {}
    }

    function getInitialPrice(duration) {
        const pack = electronicSealList.filter(item => {
            if (duration) {
                return item.durations[0].id === duration
            }
            return null
        })

        if (pack && pack.length > 0) {
            return pack[0].durations[0].price
        }
        return ''
    }

    const INITIAL_STATE = {
        id: { value: activeElectronicSeal.id || '' },
        isValid: { value: activeElectronicSeal.isValid || false },
        type: { value: activeElectronicSeal.type || defaultServiceType },
        duration: {
            value: activeElectronicSeal.config
                ? getInitialDuration(activeElectronicSeal.stateId)
                : activeElectronicSeal.duration || DEFAULT_DURATION,
            validation: ['validateCardDurationUtil'],
        },
        price: {
            value: activeElectronicSeal.config
                ? getInitialPrice(activeElectronicSeal.stateId)
                : activeElectronicSeal.price || '',
        },
        supportType: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.supportDataInfo.supportType
                : activeElectronicSeal.supportType || 'SmartCard',
        },
        logo: {
            value: activeElectronicSeal.logo || '',
        },
        emailCertificate: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.supportDataInfo.emailCertificate
                : activeElectronicSeal.emailCertificate || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        applicantName: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.requester.name
                : activeElectronicSeal.applicantName || '',
            validation: ['validatePersonNameUtil'],
        },
        applicantEmail: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.requester.email
                : activeElectronicSeal.applicantEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        applicantPhoneNumber: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.requester.phoneNumber
                : activeElectronicSeal.applicantPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
            mask: 'maskPhoneUtil',
        },
        applicantDocumentType: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.requester.documentType
                : activeElectronicSeal.applicantDocumentType || DEFAULT_DOCUMENT_TYPE,
            validation: ['validateDocumentTypeUtil'],
        },
        applicantDocumentNumber: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.requester.documentNumber
                : activeElectronicSeal.applicantDocumentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        applicantDocumentValidity: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.requester.documentValidity
                : activeElectronicSeal.applicantDocumentValidity || null,
            validation: ['validateDateUtil'],
        },
        technicalName: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.technician.name
                : activeElectronicSeal.technicalName || '',
            validation: ['validatePersonNameUtil'],
        },
        technicalEmail: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.technician.email
                : activeElectronicSeal.technicalEmail || '',
            validation: ['validateNotEmptyUtil', 'validateEmailUtil'],
        },
        technicalPhoneNumber: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.technician.phoneNumber
                : activeElectronicSeal.technicalPhoneNumber || '',
            validation: ['validateNotEmptyUtil', 'validateCellphoneUtil'],
            mask: 'maskPhoneUtil',
        },
        technicalDocumentType: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.technician.documentType
                : activeElectronicSeal.technicalDocumentType || DEFAULT_DOCUMENT_TYPE,
            validation: ['validateDocumentTypeUtil'],
        },
        technicalDocumentNumber: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.technician.documentNumber
                : activeElectronicSeal.technicalDocumentNumber || '',
            validation: ['validateNotEmptyUtil'],
        },
        technicalDocumentValidity: {
            value: activeElectronicSeal.config
                ? activeElectronicSeal.config.technician.documentValidity
                : activeElectronicSeal.technicalDocumentValidity || null,
            validation: ['validateDateUtil'],
        },
    }

    const { values, errors, touched, isValidForm, handleChange, handleBlur, setValues, setErrors } = useValidationForm(
        INITIAL_STATE
    )

    const [disableTechnicalPhoneNumber, setDisableTechnicalPhoneNumber] = useState(false)
    useEffect(() => {
        if (sameValue && values.applicantPhoneNumber.value.charAt(0) === '9') {
            setDisableTechnicalPhoneNumber(true)
        } else {
            setDisableTechnicalPhoneNumber(false)
        }
    }, [sameValue, values.applicantPhoneNumber])

    function handleCheckboxChange(event) {
        const { checked } = event.target
        setSameValue(event.target.checked)

        if (checked) {
            if (values.applicantPhoneNumber.value.charAt(0) === '9') {
                const {
                    technicalName,
                    technicalEmail,
                    technicalPhoneNumber,
                    technicalDocumentType,
                    technicalDocumentNumber,
                    technicalDocumentValidity,
                    ...rest
                } = errors
                setErrors(rest)
            } else {
                const {
                    technicalName,
                    technicalEmail,
                    technicalDocumentType,
                    technicalDocumentNumber,
                    technicalDocumentValidity,
                    ...rest
                } = errors
                setErrors(rest)
            }
        }
    }

    function setApplicantValuesToTechnicalValues(applicantValue, technicalValue) {
        if (sameValue) {
            values[`${technicalValue}`].value = values[`${applicantValue}`].value
            return values[`${technicalValue}`].value
        }

        return values[`${technicalValue}`].value
    }

    function handleDurations(list, value) {
        const { durations } = list.filter(item => {
            return item.type === value
        })[0]

        return durations.map(item => ({ ...item, label: `${item.duration} ano(s)` }))
    }

    const [electronicSealDurationOptions, setElectronicSealDurationOptions] = useState(
        handleDurations(electronicSealList, defaultServiceType)
    )

    function handlePhoto(data) {
        const event = {
            target: {
                name: 'logo',
                value: data,
            },
        }
        handleChange(event)
    }

    function handleRadioChange(event) {
        const { value, name } = event.target
        switch (name) {
            case 'type':
                setElectronicSealDurationOptions(handleDurations(electronicSealList, value))
                setValues({
                    ...values,
                    type: { ...values.type, value },
                    domainsType: { ...values.domainsType, value: DEFAULT_DOMAINS_TYPE },
                    duration: { ...values.duration, value: DEFAULT_DURATION },
                    price: { ...values.price, value: '' },
                })
                break
            case 'supportType':
                if (value === 'SmartCard') {
                    setValues({
                        ...values,
                        logo: { ...values.logo, validation: ['validateNotEmptyUtil'] },
                        supportType: { ...values.supportType, value },
                    })
                } else {
                    setValues({
                        ...values,
                        logo: { ...values.logo.value },
                        supportType: { ...values.supportType, value },
                    })
                }
                break
            default:
                handleChange(event)
                break
        }
    }

    function handleSelectChange(name, obj) {
        if (obj) {
            const event = {
                target: {
                    name: name,
                    value: obj,
                },
            }
            switch (name) {
                case 'applicantDocumentType':
                    handleChange(event)
                    if (event.target.value && event.target.value.id === 1) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 3) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 5) {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                            },
                        })
                    } else {
                        setValues({
                            ...values,
                            applicantDocumentType: {
                                ...values.applicantDocumentType,
                                value: event.target.value,
                            },
                            applicantDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                            },
                        })
                    }
                    break
                case 'technicalDocumentType':
                    handleChange(event)
                    if (event.target.value && event.target.value.id === 1) {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateCCNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 3) {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validatePassportNumberUtil'],
                            },
                        })
                    } else if (event.target.value && event.target.value.id === 5) {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDNIEspanholUtil'],
                            },
                        })
                    } else {
                        setValues({
                            ...values,
                            technicalDocumentType: {
                                ...values.technicalDocumentType,
                                value: event.target.value,
                            },
                            technicalDocumentNumber: {
                                value: '',
                                validation: ['validateNotEmptyUtil', 'validateDocumentAlpha9Util'],
                            },
                        })
                    }
                    break
                case 'duration':
                    handleChange(event)
                    setValues({
                        ...values,
                        price: { ...values.price, value: obj.price ? obj.price : '' },
                        duration: { ...values.duration, value: event.target.value },
                    })
                    break
                default:
                    handleChange(event)
                    break
            }
        }
    }

    function renderValidationMessage() {
        return csrFiles[0].validation.AreValid ? (
            <Fragment>
                <Done fontSize='small' className='success' />
                Ficheiro válido.
            </Fragment>
        ) : (
            <Fragment>
                <Close fontSize='small' className='error' />
                Ficheiro inválido.
            </Fragment>
        )
    }

    function handleSelectBlur(name, obj) {
        const event = {
            target: {
                name: name,
                value: obj.target.value,
            },
        }

        handleBlur(event)
    }

    function handleDatePickerChange(dateString, field) {
        if (dateString) {
            const selectedDate = dateString.split('/')
            const selectedDateString = new Date(
                Number(selectedDate[2]),
                Number(selectedDate[1]) - 1,
                Number(selectedDate[0])
            )
            const selectedDateTimestamp = selectedDateString.getTime()
            const today = new Date()
            const yesterdayTimestamp = today.setDate(today.getDate() - 1)
            const selectedDateIsSameOrBeforeThanYesterday = selectedDateTimestamp <= yesterdayTimestamp

            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: !selectedDateIsSameOrBeforeThanYesterday ? selectedDateTimestamp : NaN,
                },
            })
        } else {
            setValues({
                ...values,
                [field]: {
                    ...values[field],
                    value: null,
                },
            })
            handleChange({
                target: {
                    name: field,
                    value: null,
                },
            })
        }
    }

    function handleDatePickerBlur(event, field) {
        if (event) {
            handleBlur({
                target: {
                    name: field,
                },
            })
        }
    }

    function handleSave() {
        const payload = {
            id: values.id.value,
            basicType: 'ELECTRONIC_SEAL',
            isValid: true,
            sameValue: sameValue,
            type: values.type.value,
            duration: values.duration.value,
            price: values.price.value,
            emailCertificate: values.emailCertificate.value,
            logo: values.logo.value,
            supportType: values.supportType.value,
            applicantName: values.applicantName.value,
            applicantEmail: values.applicantEmail.value,
            applicantPhoneNumber: values.applicantPhoneNumber.value.replace(/\s/g, ''),
            applicantDocumentType: values.applicantDocumentType.value,
            applicantDocumentNumber: values.applicantDocumentNumber.value,
            applicantDocumentValidity: values.applicantDocumentValidity.value,
            technicalName: values.technicalName.value,
            technicalEmail: values.technicalEmail.value,
            technicalPhoneNumber: values.technicalPhoneNumber.value.replace(/\s/g, ''),
            technicalDocumentType: values.technicalDocumentType.value,
            technicalDocumentNumber: values.technicalDocumentNumber.value,
            technicalDocumentValidity: values.technicalDocumentValidity.value,
            csrFile: csrFiles[0],
            pdfFile: pdfFiles[0],
        }
        actionsOrders.setServiceByType(payload, 'electronicSeals', history)
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Dados para emissão do Selo Eletrónico ' />
                        <FormControl component='fieldset'>
                            <RadioGroup
                                defaultValue={defaultServiceType}
                                aria-label='position'
                                name='type'
                                value={values.type.value}
                                onChange={handleRadioChange}
                                row
                            >
                                {_.find(stateOrders.services.types, { basicType: 'ELECTRONIC_SEAL', type: 'Novo' }) && (
                                    <FormControlLabel
                                        value='Novo'
                                        control={<Radio color='primary' />}
                                        label='Novo'
                                        labelPlacement='end'
                                    />
                                )}
                                {_.find(stateOrders.services.types, {
                                    basicType: 'ELECTRONIC_SEAL',
                                    type: 'Renovação',
                                }) && (
                                    <FormControlLabel
                                        value='Renovação'
                                        control={<Radio color='primary' />}
                                        label='Renovação'
                                        labelPlacement='end'
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                        <Grid container spacing={1}>
                            <Grid item xs={8} md={8}>
                                <SSelect
                                    id='duration'
                                    name='duration'
                                    getOptionValue={option => option}
                                    placeholder='Duração do SSL'
                                    options={electronicSealDurationOptions}
                                    className={
                                        touched.duration && errors.duration
                                            ? 'inputMarginDense selectError'
                                            : 'inputMarginDense'
                                    }
                                    value={values.duration.value}
                                    error={touched.duration ? Boolean(errors.duration) : false}
                                    helperText={touched.duration ? errors.duration : ''}
                                    onChange={event => {
                                        handleSelectChange('duration', event)
                                    }}
                                    onBlur={event => {
                                        handleSelectBlur('duration', event)
                                    }}
                                    theme={customSelectColors}
                                    isSearchable
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    id='price'
                                    name='price'
                                    label='Valor'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.price.value}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position='start'>€</InputAdornment>,
                                    }}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </PaperComponent>

                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Dados do suporte' />
                        <FormControl component='fieldset'>
                            <RadioGroup
                                defaultValue='SmartCard'
                                aria-label='position'
                                name='supportType'
                                value={values.supportType.value}
                                onChange={handleRadioChange}
                                row
                            >
                                <FormControlLabel
                                    value='SmartCard'
                                    control={<Radio color='primary' />}
                                    label='Smart Card'
                                    labelPlacement='end'
                                />
                                <FormControlLabel
                                    value='HSM'
                                    control={<Radio color='primary' />}
                                    label='HSM'
                                    labelPlacement='end'
                                />
                            </RadioGroup>
                        </FormControl>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box component='div' display='flex' justifyContent='space-between'>
                                    {values.supportType.value === 'SmartCard' && (
                                        <Box component='div' mr={2}>
                                            <UploadScaledImageComponent
                                                personPhoto={values.logo.value}
                                                handlePhoto={handlePhoto}
                                                setMaxSize={setMaxSize}
                                                setImagesTipes={setImagesTipes}
                                            />
                                            <Typography display='block' variant='caption'>
                                                Insere a logotipo da Entidade
                                            </Typography>
                                            {maxSize > 1048576 && (
                                                <ErrorComponent errorColor>
                                                    Não é permitido imagem com mais de 1Mb
                                                </ErrorComponent>
                                            )}
                                            {!imagesTipes && (
                                                <ErrorComponent errorColor>
                                                    Fotos somente em formato JPG, PNG, SVG, JPEG
                                                </ErrorComponent>
                                            )}
                                        </Box>
                                    )}
                                    <TextField
                                        id='emailCertificate'
                                        name='emailCertificate'
                                        label='E-mail do Certificado *'
                                        type='email'
                                        margin='dense'
                                        variant='outlined'
                                        value={values.emailCertificate.value}
                                        error={touched.emailCertificate ? Boolean(errors.emailCertificate) : false}
                                        helperText={touched.emailCertificate ? errors.emailCertificate : ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </PaperComponent>
                    {values.supportType.value === 'HSM' && (
                        <PaperComponent noborder>
                            <PaperHeaderComponent title='Adicionar Ficheiro .CSR'>
                                <Tooltip title='Faça upload do ficheiro com extensão .csr. Após upload clique no botão "Validar .CSR"'>
                                    <IconButton aria-label='Ajuda'>
                                        <Help fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </PaperHeaderComponent>
                            <UploadFileComponent
                                id='csrFiles'
                                initialFiles={csrFiles}
                                handleFiles={fileList => setCsrFiles(fileList)}
                                fileType='.csr'
                                isSingleFile
                            />
                            <Box display='flex' justifyContent='flex-end' mb={2}>
                                <SFileMessage>
                                    {csrFiles.length > 0 &&
                                        (csrFiles[0].validation ? (
                                            renderValidationMessage()
                                        ) : (
                                            <Fragment>
                                                <Warning fontSize='small' className='warning' />
                                                Ficheiro pendente de validação.
                                            </Fragment>
                                        ))}
                                </SFileMessage>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    color='primary'
                                    disabled={!(csrFiles.length > 0)}
                                    onClick={() => {
                                        setIsModalOpen(true)
                                    }}
                                >
                                    Validar .CSR
                                </Button>
                            </Box>
                        </PaperComponent>
                    )}
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Adicionar Ficheiro .PDF'>
                            <Tooltip title='Adicione um documento com a lista de Requerentes Autorizados'>
                                <IconButton aria-label='Ajuda'>
                                    <Help fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </PaperHeaderComponent>
                        <Typography component='div' variant='subtitle2' mb={1}>
                            Adicione um documento com a lista de Requerentes Autorizados
                        </Typography>

                        <UploadFileComponent
                            id='pdfFiles'
                            initialFiles={pdfFiles}
                            handleFiles={fileList => setPdfFiles(fileList)}
                            isSingleFile
                        />
                    </PaperComponent>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Dados do requerente' />
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id='applicantName'
                                    name='applicantName'
                                    label='Nome *'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.applicantName.value}
                                    error={touched.applicantName ? Boolean(errors.applicantName) : false}
                                    helperText={touched.applicantName ? errors.applicantName : ''}
                                    inputProps={{ maxLength: 255 }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    id='applicantEmail'
                                    name='applicantEmail'
                                    label='E-mail *'
                                    type='email'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.applicantEmail.value}
                                    error={touched.applicantEmail ? Boolean(errors.applicantEmail) : false}
                                    helperText={touched.applicantEmail ? errors.applicantEmail : ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputMask
                                    mask={maskPhoneUtil}
                                    value={values.applicantPhoneNumber.value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {() => (
                                        <TextField
                                            id='applicantPhoneNumber'
                                            name='applicantPhoneNumber'
                                            label='Telefone *'
                                            margin='dense'
                                            variant='outlined'
                                            error={
                                                touched.applicantPhoneNumber
                                                    ? Boolean(errors.applicantPhoneNumber)
                                                    : false
                                            }
                                            helperText={touched.applicantPhoneNumber ? errors.applicantPhoneNumber : ''}
                                            fullWidth
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <SSelect
                                    id='applicantDocumentType'
                                    name='applicantDocumentType'
                                    margin='dense'
                                    variant='outlined'
                                    options={documentTypeOptions}
                                    theme={customSelectColors}
                                    getOptionValue={option => option}
                                    value={values.applicantDocumentType.value}
                                    error={
                                        touched.applicantDocumentType ? Boolean(errors.applicantDocumentType) : false
                                    }
                                    helperText={touched.applicantDocumentType ? errors.applicantDocumentType : ''}
                                    onChange={event => {
                                        handleSelectChange('applicantDocumentType', event)
                                    }}
                                    onBlur={event => {
                                        handleSelectBlur('applicantDocumentType', event)
                                    }}
                                    isSearchable
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id='applicantDocumentNumber'
                                    name='applicantDocumentNumber'
                                    label='Nº de Documento *'
                                    margin='dense'
                                    variant='outlined'
                                    value={values.applicantDocumentNumber.value}
                                    error={
                                        touched.applicantDocumentNumber
                                            ? Boolean(errors.applicantDocumentNumber)
                                            : false
                                    }
                                    helperText={touched.applicantDocumentNumber ? errors.applicantDocumentNumber : ''}
                                    inputProps={
                                        values.applicantDocumentType.value &&
                                        values.applicantDocumentType.value.id === 1
                                            ? { maxLength: 12 }
                                            : { maxLength: 9 }
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DatePickerComponent
                                    id='applicantDocumentValidity'
                                    name='applicantDocumentValidity'
                                    dateLabel='Validade do Documento *'
                                    dateValue={values.applicantDocumentValidity.value}
                                    error={
                                        touched.applicantDocumentValidity
                                            ? Boolean(errors.applicantDocumentValidity)
                                            : false
                                    }
                                    helperText={
                                        touched.applicantDocumentValidity ? errors.applicantDocumentValidity : ''
                                    }
                                    onDatePickerChange={event =>
                                        handleDatePickerChange(event, 'applicantDocumentValidity')
                                    }
                                    onBlur={event => handleDatePickerBlur(event, 'applicantDocumentValidity')}
                                    isFullWidth
                                />
                            </Grid>
                        </Grid>
                    </PaperComponent>
                    <PaperComponent noborder>
                        <PaperHeaderComponent title='Dados do contacto técnico' />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sameValue}
                                    onChange={handleCheckboxChange}
                                    name='sameValue'
                                    color='primary'
                                />
                            }
                            label='Os dados do Requerente e Contacto Técnico são os mesmos'
                        />
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id='technicalName'
                                    name='technicalName'
                                    label='Nome *'
                                    type='text'
                                    margin='dense'
                                    variant='outlined'
                                    value={setApplicantValuesToTechnicalValues('applicantName', 'technicalName')}
                                    error={touched.technicalName ? Boolean(errors.technicalName) : false}
                                    helperText={touched.technicalName ? errors.technicalName : ''}
                                    inputProps={{ maxLength: 255 }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={sameValue}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <TextField
                                    id='technicalEmail'
                                    name='technicalEmail'
                                    label='E-mail *'
                                    type='email'
                                    margin='dense'
                                    variant='outlined'
                                    value={setApplicantValuesToTechnicalValues('applicantEmail', 'technicalEmail')}
                                    error={touched.technicalEmail ? Boolean(errors.technicalEmail) : false}
                                    helperText={touched.technicalEmail ? errors.technicalEmail : ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={sameValue}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputMask
                                    mask={maskPhoneUtil}
                                    value={
                                        sameValue && values.applicantPhoneNumber.value.charAt(0) === '9'
                                            ? setApplicantValuesToTechnicalValues(
                                                  'applicantPhoneNumber',
                                                  'technicalPhoneNumber'
                                              )
                                            : values.technicalPhoneNumber.value
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={disableTechnicalPhoneNumber}
                                    readOnly={disableTechnicalPhoneNumber}
                                >
                                    {() => (
                                        <TextField
                                            id='technicalPhoneNumber'
                                            name='technicalPhoneNumber'
                                            label='Telemóvel *'
                                            margin='dense'
                                            variant='outlined'
                                            error={
                                                touched.technicalPhoneNumber
                                                    ? Boolean(errors.technicalPhoneNumber)
                                                    : false
                                            }
                                            helperText={touched.technicalPhoneNumber ? errors.technicalPhoneNumber : ''}
                                            disabled={disableTechnicalPhoneNumber}
                                            fullWidth
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <SSelect
                                    id='technicalDocumentType'
                                    name='technicalDocumentType'
                                    margin='dense'
                                    variant='outlined'
                                    options={documentTypeOptions}
                                    theme={customSelectColors}
                                    getOptionValue={option => option}
                                    value={setApplicantValuesToTechnicalValues(
                                        'applicantDocumentType',
                                        'technicalDocumentType'
                                    )}
                                    error={
                                        touched.technicalDocumentType ? Boolean(errors.technicalDocumentType) : false
                                    }
                                    helperText={touched.technicalDocumentType ? errors.technicalDocumentType : ''}
                                    onChange={event => {
                                        handleSelectChange('technicalDocumentType', event)
                                    }}
                                    onBlur={event => {
                                        handleSelectBlur('technicalDocumentType', event)
                                    }}
                                    isDisabled={sameValue}
                                    isSearchable
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    id='technicalDocumentNumber'
                                    name='technicalDocumentNumber'
                                    label='Nº de Documento *'
                                    margin='dense'
                                    variant='outlined'
                                    value={setApplicantValuesToTechnicalValues(
                                        'applicantDocumentNumber',
                                        'technicalDocumentNumber'
                                    )}
                                    error={
                                        touched.technicalDocumentNumber
                                            ? Boolean(errors.technicalDocumentNumber)
                                            : false
                                    }
                                    helperText={touched.technicalDocumentNumber ? errors.technicalDocumentNumber : ''}
                                    inputProps={
                                        values.technicalDocumentType.value &&
                                        values.technicalDocumentType.value.id === 1
                                            ? { maxLength: 12 }
                                            : { maxLength: 9 }
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={sameValue}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DatePickerComponent
                                    id='technicalDocumentValidity'
                                    name='technicalDocumentValidity'
                                    dateLabel='Validade do Documento *'
                                    dateValue={setApplicantValuesToTechnicalValues(
                                        'applicantDocumentValidity',
                                        'technicalDocumentValidity'
                                    )}
                                    error={
                                        touched.technicalDocumentValidity
                                            ? Boolean(errors.technicalDocumentValidity)
                                            : false
                                    }
                                    helperText={
                                        touched.technicalDocumentValidity ? errors.technicalDocumentValidity : ''
                                    }
                                    onDatePickerChange={event =>
                                        handleDatePickerChange(event, 'technicalDocumentValidity')
                                    }
                                    onBlur={event => handleDatePickerBlur(event, 'technicalDocumentValidity')}
                                    isDisabled={sameValue}
                                    isFullWidth
                                />
                            </Grid>
                        </Grid>
                    </PaperComponent>
                </Grid>
                <NewOrderElectronicSealConfigModal
                    initialFile={csrFiles[0]}
                    isModalOpen={isModalOpen}
                    handleModalOpen={isOpen => setIsModalOpen(isOpen)}
                    handleFile={file => setCsrFiles([file])}
                />
            </Grid>

            <ActionBarComponent backLink='/new-order' backText='Voltar'>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={
                        !isValidForm ||
                        (stateLogin.activeScope.hasLstReqAutSsl === 0 && pdfFiles.length !== 1) ||
                        (values.supportType.value === 'HSM' && csrFiles.length !== 1) ||
                        (values.supportType.value === 'HSM' && csrFiles.length === 1 && !csrFiles[0].validation) ||
                        (values.supportType.value === 'HSM' &&
                            csrFiles.length === 1 &&
                            csrFiles[0].validation &&
                            !csrFiles[0].validation.AreValid) ||
                        (values.supportType.value === 'SmartCard' && values.logo.value.length === 0)
                    }
                    onClick={handleSave}
                >
                    Guardar
                </Button>
            </ActionBarComponent>

            <Prompt
                message={(location, action) => {
                    if (
                        (action === 'PUSH' && !isValidForm) ||
                        (action === 'PUSH' && isValidForm && location.pathname !== '/new-order')
                    ) {
                        return MSG_CONFIRM_PAGE_EXIT
                    }

                    return null
                }}
            />
        </form>
    )
}

NewOrderElectronicSealConfigForm.propTypes = {
    activeElectronicSeal: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default NewOrderElectronicSealConfigForm
