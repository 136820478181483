import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './styles'

const IconTimestamp = ({ fill = '#960008' }) => {
    return (
        <IconContainer>
            <svg xmlns='http://www.w3.org/2000/svg' width='24.509' height='21.477' viewBox='0 0 24.509 22.477'>
                <g transform='translate(-1135 -107.99)'>
                    <path
                        d='M37.243,18.641l-.206-.112-.206.112a11.525,11.525,0,0,1-5.4,1.385l-.429,0V23.6a8.949,8.949,0,0,0,5.89,8.388l.147.053.147-.053a8.949,8.949,0,0,0,5.89-8.388V20.028l-.429,0a11.528,11.528,0,0,1-5.4-1.385ZM42.211,23.6a8.084,8.084,0,0,1-5.175,7.522A8.085,8.085,0,0,1,31.862,23.6V20.877a12.4,12.4,0,0,0,5.175-1.37,12.4,12.4,0,0,0,5.174,1.37Z'
                        transform='translate(1115.936 90.03)'
                        fill={fill}
                        stroke={fill}
                        strokeWidth='1'
                    />
                    <g transform='translate(1135 112)'>
                        <path
                            d='M9.234,0a9.234,9.234,0,1,0,9.234,9.234A9.234,9.234,0,0,0,9.234,0Zm0,17.148a7.915,7.915,0,1,1,7.915-7.915A7.915,7.915,0,0,1,9.234,17.148Z'
                            fill={fill}
                        />
                        <g transform='translate(3.298 3.298)'>
                            <path
                                d='M91.269,85.333a.66.66,0,0,0-.66.66v4.617H85.993a.66.66,0,1,0,0,1.319h5.276a.66.66,0,0,0,.66-.66V85.993A.66.66,0,0,0,91.269,85.333Z'
                                transform='translate(-85.333 -85.333)'
                                fill={fill}
                            />
                        </g>
                    </g>
                    <path
                        d='M-23586.152-2227.068l2.539-.4,2.525-.941,2.967.941,2.3.4a9.105,9.105,0,0,1,.262,3.294,7.382,7.382,0,0,1-1.141,3.3,7.729,7.729,0,0,1-1.426,1.538l-1.689,1.522-1.277.659-1.715-1.032-1.266-1.148-.7-.819-.8-1.371-.457-1.567-.123-1.594Z'
                        transform='translate(24734 2338)'
                        fill='#fff'
                    />
                    <path
                        d='M41.829,24.86l-.3-.808c-.27.1-.545.194-.815.276l.249.825C41.249,25.066,41.541,24.967,41.829,24.86Z'
                        transform='translate(1110.412 86.888)'
                        fill={fill}
                    />
                    <path
                        d='M35.862,27.4V26.236c.32-.051.641-.114.96-.188l-.194-.84c-.416.1-.837.172-1.252.227l-.376.05V27.4c0,.116,0,.231.009.345l.862-.044c-.005-.1-.008-.2-.008-.3Z'
                        transform='translate(1113.66 86.231)'
                        fill={fill}
                    />
                    <path
                        d='M38.489,28.173l-1.9,1.9,2.766,2.766L44.7,27.489l-1.9-1.9-3.45,3.45Zm5-.684-4.134,4.134-1.546-1.546.684-.684.862.862,3.45-3.45Z'
                        transform='translate(1112.76 86.016)'
                        fill={fill}
                    />
                </g>
            </svg>
        </IconContainer>
    )
}

IconTimestamp.propTypes = {
    fill: PropTypes.string,
}
IconTimestamp.defaultProps = {
    fill: '',
}

export default memo(IconTimestamp)
