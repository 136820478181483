import React from 'react'
import PropTypes from 'prop-types'

import { MTableToolbar } from 'material-table'
import { transformTimestampToDate } from '../../../utils/date.util'
import DataTableComponent from '../../../components/templates/DataTable/DataTable.component'

import { ItemChip, SToolbar } from '../Order.styles'

const cardColumns = [
    {
        field: 'serviceType',
        title: 'Serviço',
    },
    {
        field: 'validity',
        title: 'VALIDADE (ANOS)',
    },
    {
        field: 'price',
        title: 'VALOR',
        render: ({ price }) => (price ? `${price.toFixed(2)} €` : ''),
    },

    {
        field: 'personName',
        title: 'DETALHE',
    },
    {
        field: 'updatedDate',
        title: 'Data do estado',
        render: ({ updatedDate }) => (updatedDate ? transformTimestampToDate(updatedDate) : ''),
    },
    {
        field: 'statusLabel',
        title: 'ESTADO',
        render: rowData => (
            <ItemChip typecolor='orderItemsStatus' label={rowData.status.label} type={rowData.status.id} />
        ),
    },
]

const propTypes = {
    onRowClick: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
    data: null,
    onRowClick: null,
}

function CardTable(props) {
    const { onRowClick, data } = props
    return (
        <DataTableComponent
            title='Cartão'
            options={{ showTitle: true }}
            columns={cardColumns}
            data={data}
            onRowClick={onRowClick}
            components={{
                Toolbar: props => (
                    <SToolbar>
                        <MTableToolbar {...props} />
                    </SToolbar>
                ),
            }}
        />
    )
}

CardTable.propTypes = propTypes
CardTable.defaultProps = defaultProps

export default CardTable
