import React from 'react'

import ContentComponent, { PageTitleComponent } from '../../components/templates/Content/Content.component'

export default function PageNotFound() {
    return (
        <ContentComponent>
            <PageTitleComponent title='Página não Encontrada' />
        </ContentComponent>
    )
}
