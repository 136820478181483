/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress, Grid, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'

import { maskNIFUtil } from '../../utils'

import FCaptcha from '../../components/FCaptcha/FCaptcha.component'
import { INITIAL_STATE } from './constants'

import { SCircular, SButton } from './Login.styles'
import { useLoginStore } from '../../store/Login/Login.store'
import useValidationForm from '../../hooks/useValidationForm.hook'

function LoginForm() {
    const history = useHistory()
    const { stateLogin, actionsLogin } = useLoginStore()
    const refCaptcha = useRef()
    // FORM STATE
    const { values, errors, touched, isValidForm, handleChange, handleBlur } = useValidationForm(INITIAL_STATE)

    function handleSubmit() {
        const postData = {
            userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
            captchaId: refCaptcha.current.getCaptchaId(),
        }

        //* Removes the mask from input before submitting
        const login = { nif: values.nif.value.replace(/\s/g, ''), password: values.password.value, captcha: postData }

        actionsLogin.doLogin(history, login, refCaptcha.current)
    }

    return stateLogin.isLoading ? (
        <SCircular>
            <CircularProgress size={30} />
            <span>A Enviar Solicitação</span>
        </SCircular>
    ) : (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputMask mask={maskNIFUtil} value={values.nif.value} onChange={handleChange} onBlur={handleBlur}>
                        {() => (
                            <TextField
                                id='nif'
                                name='nif'
                                label='Número de Identificação Fiscal'
                                margin='dense'
                                variant='outlined'
                                error={touched.nif ? Boolean(errors.nif) : false}
                                helperText={touched.nif ? errors.nif : ''}
                                required
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='password'
                        name='password'
                        type='password'
                        label='Palavra-Passe'
                        margin='dense'
                        variant='outlined'
                        value={values.password.value}
                        error={touched.password ? Boolean(errors.password) : false}
                        helperText={touched.password ? errors.password : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid container direction='row' alignItems='flex-end' spacing={1}>
                    <Grid item xs={9}>
                        <FCaptcha
                            refCaptcha={refCaptcha}
                            value={values.yourFirstCaptchaUserInput.value}
                            error={
                                touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false
                            }
                            helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SButton
                            variant='contained'
                            color='primary'
                            disabled={!isValidForm}
                            onClick={e => handleSubmit(e)}
                            fullWidth
                        >
                            Entrar
                        </SButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default LoginForm
