import styled from 'styled-components'

export const SContent = styled.div`
    width: 100%;
    padding: 50px 50px 95px 130px;
    background-color: ${props => props.theme.white};

    @media (max-width: 599px) {
        padding: 40px 20px 95px;
    }

    @media print {
        padding: 40px;
    }
`
export const SPageHeader = styled.div`
    margin: 0 0 20px 0;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .page-title {
        font-weight: 300;
        padding: 0;
        margin: 0;
        font-size: 34px;
    }
    .page-description {
        text-transform: none;
        font-weight: 300;
        font-size: 20px;
    }
`
