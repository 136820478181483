import React from 'react'
import PropTypes from 'prop-types'

import { InfoOutlined } from '@material-ui/icons'
import { SInfoMessage } from './styles'

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any,
}

const defaultProps = {
    children: '',
}

function Callout(props) {
    const { children } = props

    return (
        <SInfoMessage>
            <InfoOutlined />
            <div className='message'>{children}</div>
        </SInfoMessage>
    )
}

Callout.propTypes = propTypes
Callout.defaultProps = defaultProps

export default Callout
