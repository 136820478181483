import { INSTITUICAO } from '../dictionary/dictionary'

export const deliveryTypeOptions = [
    { id: 1, label: 'Titular do Cartão' },
    { id: 2, label: 'Outro Autorizado' },
    { id: 3, label: 'Correio' },
]

export const deliveryStatusOptions = [
    { id: 1, label: 'A Entregar' },
    { id: 2, label: 'Entregue' },
    { id: 3, label: 'Não entregue' },
    { id: 4, label: 'Pendente' },
]

export const PROTOCOL_STATUS_OPTIONS = [
    { id: 1, description: 'Pendente' },
    { id: 2, description: 'Ativo' },
    { id: 3, description: 'Renovado' },
    { id: 4, description: 'Revogado' },
    { id: 5, description: 'Suspenso' },
    { id: 6, description: 'Inativo' },
]

export const PROTOCOL_TYPES_OPTIONS = [
    { id: 0, label: 'Informal' },
    { id: 1, label: 'Formal' },
]

export const PinPukSendTypes = {
    A_DEFINIR_TITULAR: 1,
    LEVANTAR_INSTITUICAO: 2,
    RECEBER_CORREIO: 3,
    RECEBER_SMS: 4,
}

export const PINPUK_TYPE_OPTIONS = [
    { id: 2, label: 'Levantar no CEGER' },
    { id: 3, label: 'Receber por Correio' },
    { id: 4, label: 'Receber por SMS' },
]

export const PINPUK_TYPE_SEND = [
    { id: 1, label: 'A definir pelo titular' },
    { id: 2, label: `Levantar no ${INSTITUICAO}` },
    { id: 3, label: 'Receber por Correio' },
]

export const InvoicesTypes = {
    PROPOSTA: 1,
    RASCUNHO: 2,
    A_PAGAMENTO: 3,
    PAGA: 4,
    CANCELADA: 5,
    ANULADA: 6,
    VALIDARPAGAMENTO: 7,
}
