import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useLoginStore } from '../../../store/Login/Login.store'

const propTypes = {
    scope: PropTypes.objectOf(PropTypes.any).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.any.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleOpen: PropTypes.func.isRequired,
}

function HeaderDialog(props) {
    const { history, scope, isOpen, handleOpen } = props
    const [open, setOpen] = useState(false)
    const { actionsLogin } = useLoginStore()

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    const handleClose = () => {
        setOpen(false)
        handleOpen(false)
    }

    const handleClickOk = () => {
        actionsLogin.setActiveScope(history, scope)
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Confirmar alteração de Âmbito?</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        As informações não submetidas nesta sessão serão perdidas. Deseja continuar com a alteração da
                        sessão para outro âmbito?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Não
                    </Button>
                    <Button onClick={handleClickOk} color='primary' autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

HeaderDialog.propTypes = propTypes

export default HeaderDialog
