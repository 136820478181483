/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import MessageComponent from '../../components/templates/Message/Message.component'
import ConfirmationService from './Confirmation.service'

import { SCircular } from './Confirmation.styles'

const propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
}

function Confirmation(props) {
    const { location } = props

    const [isSuccess, setIsSuccess] = useState(null)
    const [message, setMessage] = useState('')

    useEffect(() => {
        const prefix = location.pathname
        const hash = location.search.split('?hash=')[1]

        ConfirmationService.apiValidateEmail(prefix, hash).then(res => {
            setIsSuccess(res.isSuccess)

            if (res.isSuccess) {
                setMessage(res.res.data.message)
            } else {
                setMessage(res.err.response.data.message)
            }
        })
    }, [])

    function renderMessage() {
        return isSuccess ? (
            <MessageComponent type='success' link='/' message={message} textBack='Login' />
        ) : (
            <MessageComponent type='error' link='/' message={message} textBack='Login' />
        )
    }

    return isSuccess === null ? (
        <SCircular>
            <CircularProgress size={80} />
            <span>A Confirmar E-mail</span>
        </SCircular>
    ) : (
        renderMessage()
    )
}

Confirmation.propTypes = propTypes

export default Confirmation
