import React from 'react'
import PropTypes from 'prop-types'

import { Box, Radio, RadioGroup, Fade, FormControl, FormControlLabel, CircularProgress } from '@material-ui/core'

import Select from '../../../components/templates/Select/Select'

import PaperComponent, {
    PaperBodyComponent,
    PaperHeaderComponent,
} from '../../../components/templates/Paper/Paper.component'

import { Types } from '../reducer'

import { SCircular } from '../Profile.styles'

const propTypes = {
    authenticationEmail: PropTypes.objectOf(PropTypes.any),
    authenticationType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    emails: PropTypes.arrayOf(PropTypes.object),
    hasPersonPhoneNumber: PropTypes.string,
    isPending: PropTypes.bool,
}

const defaultProps = {
    hasPersonPhoneNumber: '',
    authenticationEmail: {},
    emails: [],
    isPending: false,
}

function AuthenticationSelection(props) {
    const { authenticationType, authenticationEmail, emails, hasPersonPhoneNumber, isPending } = props

    function showSelectionOption() {
        if (authenticationType === 'Email') {
            if (emails.length > 0) {
                return (
                    <Select
                        value={authenticationEmail}
                        placeholder='Selecione seu e-mail para autenticação'
                        onChange={event =>
                            props.onChange({ type: Types.SET_SET_AUTHENTICATION_SELECTED_EMAIL, payload: event })
                        }
                        options={emails}
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.email}
                    />
                )
            }
            return (
                <SCircular>
                    <CircularProgress size={30} />
                </SCircular>
            )
        }
        return null
    }

    return (
        <PaperComponent noborder>
            <PaperHeaderComponent title='autenticação' />
            <PaperBodyComponent>
                <p>Desejo receber o 2˚ fator de autenticação por:</p>
                <FormControl component='fieldset' fullWidth>
                    <RadioGroup
                        value={authenticationType}
                        aria-label='second-authentication'
                        name='second-authentication'
                        onChange={event =>
                            props.onChange({ type: Types.SET_AUTHENTICATION_CHOICE, payload: event.target.value })
                        }
                        row
                    >
                        <FormControlLabel
                            control={<Radio color='primary' />}
                            value='Email'
                            label='Email'
                            labelPlacement='end'
                        />
                        {hasPersonPhoneNumber && !isPending && (
                            <FormControlLabel
                                control={<Radio color='primary' />}
                                value='sms'
                                label='SMS'
                                labelPlacement='end'
                            />
                        )}
                    </RadioGroup>
                </FormControl>
                <Fade in>
                    <Box my={2}>{showSelectionOption()}</Box>
                </Fade>
            </PaperBodyComponent>
        </PaperComponent>
    )
}

AuthenticationSelection.propTypes = propTypes
AuthenticationSelection.defaultProps = defaultProps

export default AuthenticationSelection
