import styled from 'styled-components'

import Icon from '@material-ui/core/Icon/index'

export const SRow = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;
`

export const SIconHeader = styled(Icon)`
    && {
        font-size: 22px;
        color: ${props => props.theme.palette.grey.light};
    }
`

export const SContent = styled.div`
    margin-left: ${props => (props.hasIcon ? '10px' : '0')};
    color: ${props => props.theme.palette.grey.dark};
    .title {
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
        color: ${props => props.theme.palette.grey.dark};
    }
    .subtitle {
        font-size: 15px;
        color: ${props => props.theme.palette.grey.light};
    }
`
