import React, { useEffect, useReducer, useRef, Fragment } from 'react'
import { useLocation } from 'react-router-dom'

import InputMask from 'react-input-mask'

import { Fade, Grid, TextField, CircularProgress, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import logoECCE from '../../assets/images/commons/logo-ecce.svg'

import ModalComponent, {
    ModalBodyComponent,
    ModalFooterComponent,
} from '../../components/templates/Modal/Modal.component'
import MessageComponent from '../../components/templates/Message/Message.component'

import { SLogo, SPaper, SSelect, SButton, SCircular } from './PinPuk.styles'

import useValidationForm from '../../hooks/useValidationForm.hook'
import useServiceCallApi from '../../hooks/useServiceCallApi.hook'

import FCaptcha from '../../components/FCaptcha/FCaptcha.component'

import { maskPostalCodeUtil } from '../../utils/index'

import { PINPUK_TYPE_OPTIONS, PinPukSendTypes } from '../../constants/selections/selections'
import { INITIAL_STATE, ENDPOINTS } from './constants'
import { reducer, Types, REDUCER_INITIAL_STATE } from './reducers'

const useStyles = makeStyles({
    removePaddingTop: {
        paddingTop: '0 !important',
    },
    removePaddingBottom: {
        paddingBottom: '0 !important',
    },
    spaceCaptchSection: {
        marginTop: '10px !important',
        marginBottom: '10px !important',
    },
})

function PinPukSelection() {
    const location = useLocation()
    const classes = useStyles()
    const refCaptcha = useRef()
    const { values, errors, touched, setValues, handleChange, handleBlur } = useValidationForm(
        INITIAL_STATE.PINPUK_SELECTION
    )
    const [pageState, dispatch] = useReducer(reducer, REDUCER_INITIAL_STATE)
    const [requestState, callService] = useServiceCallApi(null)

    useEffect(() => {
        if (location && location.state && location.state.authHash && location.state.validationHash) {
            dispatch({
                type: Types.SET_INITIAL_DATA,
                payload: { authHash: location.state.authHash, validationHash: location.state.validationHash },
            })
        }
    }, [location])

    useEffect(() => {
        if (
            requestState.url === ENDPOINTS.MOBILE_PHONE_VALIDATION &&
            !requestState.error.hasError &&
            requestState.data &&
            Object.keys(requestState.data).length > 0
        ) {
            dispatch({ type: Types.SET_ON_VALIDATION_STEP, payload: { onValidationStep: true } })
        }
    }, [requestState])

    function handleSubmit() {
        let payload = {
            hash: pageState.authHash,
            validationHash: pageState.validationHash,
            pinPukOption: values.pinPukSendType.value.id,
        }

        if (values.pinPukSendType.value.id === PinPukSendTypes.RECEBER_SMS) {
            payload = {
                ...payload,
                mobilePhone: values.personPhoneNumber.value,
                mobilePhoneValidationCode: values.validationCode.value,
            }
        }

        if (values.pinPukSendType.value.id === PinPukSendTypes.RECEBER_CORREIO) {
            payload = {
                ...payload,
                address: values.address.value,
                postalCode: values.postalCode.value.replaceAll('-', ''),
                location: values.location.value,
            }
        }

        callService(ENDPOINTS.PINPUK_SELECTION, { requestType: 'post', data: payload })
    }

    function handleSMSSubmit() {
        const payload = {
            hash: pageState.authHash,
            validationHash: pageState.validationHash,
            mobilePhone: values.personPhoneNumber.value,
            captcha: {
                userEnteredCaptchaCode: refCaptcha.current.getUserEnteredCaptchaCode(),
                captchaId: refCaptcha.current.getCaptchaId(),
            },
        }
        // call service
        callService(ENDPOINTS.MOBILE_PHONE_VALIDATION, {
            requestType: 'post',
            showSnackBarOnSuccess: true,
            data: payload,
        })
    }

    function handleSelection(value) {
        setValues({
            ...values,
            personPhoneNumber: {
                value: '',
                validation: ['validateNotEmptyUtil', 'validatePhoneUtil'],
                mask: 'maskPhoneUtil',
            },
            address: { value: '', validation: ['validateNotEmptyUtil'] },
            postalCode: {
                value: '',
                validation: ['validateNotEmptyUtil', 'validatePostalCodeUtil'],
            },
            location: { value: '', validation: ['validateNotEmptyUtil', 'validateLocationZoneNameUtil'] },
            pinPukSendType: { value, validation: ['validateNotEmptyUtil'] },
        })
        dispatch({ type: Types.SET_ON_VALIDATION_STEP, payload: { onValidationStep: false } })
    }

    function disableSMSButton() {
        if (values && values.pinPukSendType && values.pinPukSendType.value.id === PinPukSendTypes.RECEBER_SMS) {
            if (!errors.personPhoneNumber && !errors.yourFirstCaptchaUserInput) {
                return false
            }
            return true
        }
        return true
    }

    function renderMailForm() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} classes={{ root: classes.removePaddingBottom }}>
                    <TextField
                        name='address'
                        label='Morada não institucional'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.address.value}
                        error={touched.address ? Boolean(errors.address) : false}
                        helperText={touched.address ? errors.address : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} classes={{ root: classes.removePaddingTop }}>
                    <InputMask
                        mask={maskPostalCodeUtil}
                        value={values.postalCode.value}
                        maskPlaceholder={null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {() => (
                            <TextField
                                name='postalCode'
                                label='Código Postal'
                                type='text'
                                margin='dense'
                                variant='outlined'
                                error={touched.postalCode ? Boolean(errors.postalCode) : false}
                                helperText={touched.postalCode ? errors.postalCode : ''}
                                required
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>

                <Grid item xs={8} classes={{ root: classes.removePaddingTop }}>
                    <TextField
                        name='location'
                        label='Localidade'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={values.location.value}
                        error={touched.location ? Boolean(errors.location) : false}
                        helperText={touched.location ? errors.location : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
        )
    }

    function renderSMSCodeValidationForm() {
        return (
            <Grid item xs={12} classes={{ root: classes.removePaddingBottom }}>
                <TextField
                    name='validationCode'
                    label='Código de validação'
                    type='text'
                    margin='dense'
                    variant='outlined'
                    value={values.validationCode.value}
                    error={touched.validationCode ? Boolean(errors.validationCode) : false}
                    helperText={touched.validationCode ? errors.validationCode : ''}
                    inputProps={{ maxLength: 6 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                />
            </Grid>
        )
    }

    function renderCaptha() {
        return (
            <Grid
                container
                direction='row'
                alignItems='flex-end'
                spacing={1}
                classes={{ root: classes.spaceCaptchSection }}
            >
                <Grid item xs={9}>
                    <FCaptcha
                        refCaptcha={refCaptcha}
                        value={values.yourFirstCaptchaUserInput.value}
                        error={touched.yourFirstCaptchaUserInput ? Boolean(errors.yourFirstCaptchaUserInput) : false}
                        helperText={touched.yourFirstCaptchaUserInput ? errors.yourFirstCaptchaUserInput : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SButton
                        variant='contained'
                        color='primary'
                        disabled={disableSMSButton()}
                        onClick={handleSMSSubmit}
                        fullWidth
                    >
                        Enviar SMS
                    </SButton>
                </Grid>
            </Grid>
        )
    }

    function phoneValidationStatusComponents() {
        if (requestState.isLoading) {
            return (
                <SCircular>
                    <CircularProgress size={30} />
                    <span>A processar o pedido...</span>
                </SCircular>
            )
        }

        if (pageState.onValidationStep && !requestState.error.hasErrors) {
            return renderSMSCodeValidationForm()
        }

        return renderCaptha()
    }

    function renderSMSform() {
        return (
            <Fragment>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={pageState.onValidationStep ? 11 : 12}
                        classes={{ root: classes.removePaddingBottom }}
                    >
                        <TextField
                            name='personPhoneNumber'
                            label='Telemóvel'
                            margin='dense'
                            type='text'
                            value={values.personPhoneNumber ? values.personPhoneNumber.value : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={pageState.onValidationStep}
                            inputProps={{ maxLength: 9 }}
                            variant='outlined'
                            error={touched.personPhoneNumber ? Boolean(errors.personPhoneNumber) : false}
                            helperText={touched.personPhoneNumber ? errors.personPhoneNumber : ''}
                            fullWidth
                        />
                    </Grid>
                    {pageState.onValidationStep && (
                        <Grid item xs={1}>
                            <IconButton onClick={() => dispatch({ type: Types.OPEN_MODAL })}>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Grid>
                    )}

                    {phoneValidationStatusComponents()}
                </Grid>
            </Fragment>
        )
    }

    function renderDinamicForm() {
        if (values && values.pinPukSendType && values.pinPukSendType.value) {
            switch (values.pinPukSendType.value.id) {
                case PinPukSendTypes.RECEBER_CORREIO:
                    return renderMailForm()
                case PinPukSendTypes.RECEBER_SMS:
                    return renderSMSform()
                default:
                    return null
            }
        }
        return null
    }

    function disableSaveButton() {
        if (values && values.pinPukSendType && values.pinPukSendType.value) {
            if (values.pinPukSendType.value.id === PinPukSendTypes.LEVANTAR_INSTITUICAO) {
                return false
            }

            if (values.pinPukSendType.value.id === PinPukSendTypes.RECEBER_CORREIO) {
                if (!errors.address && !errors.postalCode && !errors.location) {
                    return false
                }
                return true
            }

            if (values.pinPukSendType.value.id === PinPukSendTypes.RECEBER_SMS) {
                if (!errors.personPhoneNumber && !errors.validationCode) {
                    return false
                }
                return true
            }
        }
        return true
    }

    function renderMainForm() {
        if (requestState.url === ENDPOINTS.PINPUK_SELECTION) {
            if (requestState.isLoading) {
                return (
                    <SCircular>
                        <CircularProgress size={30} />
                        <span>A processar o pedido...</span>
                    </SCircular>
                )
            }

            if (requestState.data && !requestState.error.hasError) {
                return <MessageComponent type='success' message={requestState.data.message} />
            }
        }

        return (
            <Fragment>
                <h3>Selecione como pretende receber os códigos PIN e PUK do cartão</h3>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SSelect
                                name='pinPukSendType'
                                placeholder='Modo de receção'
                                getOptionValue={option => option}
                                options={PINPUK_TYPE_OPTIONS}
                                value={values.pinPukSendType ? values.pinPukSendType.value : null}
                                error={touched.pinPukSendType ? Boolean(errors.pinPukSendType) : false}
                                helperText={touched.pinPukSendType ? errors.pinPukSendType : ''}
                                onChange={handleSelection}
                                onBlur={handleBlur}
                                isSearchable
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {renderDinamicForm()}
                    <Grid container spacing={2}>
                        <Grid item xs={9} />
                        <Grid item xs={3}>
                            <SButton
                                variant='contained'
                                color='primary'
                                disabled={disableSaveButton()}
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Guardar
                            </SButton>
                        </Grid>
                    </Grid>
                </form>
            </Fragment>
        )
    }

    return (
        <Fade in>
            <SPaper>
                <SLogo>
                    <img src={logoECCE} alt='Logo ECCE - Entidade Certificadora Comum do Estado' />
                </SLogo>
                {renderMainForm()}
                <ModalComponent
                    title='Atenção'
                    open={pageState.isModalOpen}
                    onClick={() => dispatch({ type: Types.CLOSE_MODAL })}
                >
                    <ModalBodyComponent>
                        {values.personPhoneNumber &&
                            `Atenção que ao confirmar vai cancelar o pedido de confirmação do número ${values.personPhoneNumber.value}`}
                    </ModalBodyComponent>
                    <ModalFooterComponent>
                        <Fragment>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => dispatch({ type: Types.CLOSE_MODAL })}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={() => dispatch({ type: Types.SET_IS_EDITING })}
                            >
                                Confirmar
                            </Button>
                        </Fragment>
                    </ModalFooterComponent>
                </ModalComponent>
            </SPaper>
        </Fade>
    )
}

export default PinPukSelection
