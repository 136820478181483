import ALERT_TYPES from './Alert.types'

const useActions = (state, dispatch) => ({
    hideAlert: () => {
        dispatch({ type: ALERT_TYPES.HIDE_ALERT.SUCCESS })
    },
    showAlert: values => {
        dispatch({ type: ALERT_TYPES.SHOW_ALERT.SUCCESS, payload: values })
    },
})

export default useActions
