import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Button, TextField, Box } from '@material-ui/core'
import InputMask from 'react-input-mask'

import ModalComponent, {
    ModalBodyComponent,
    ModalFooterComponent,
} from '../../../components/templates/Modal/Modal.component'

import PaperComponent, { PaperHeaderComponent } from '../../../components/templates/Paper/Paper.component'

import { useProfileStore } from '../../../store/Profile/Profile.store'

import FItemData from '../../../components/templates/ItemData/ItemData.component'
import { SPaperRow, STextInfo } from '../Profile.styles'
import { validatePhoneUtil, maskPhoneUtil } from '../../../utils/index'
import { maskString } from '../../../utils/mask.util'

const propTypes = {
    phoneNumber: PropTypes.string,
    onPhoneNumberChange: PropTypes.func,
}

const defaultProps = {
    phoneNumber: '',
    onPhoneNumberChange: null,
}

function ContactInformation(props) {
    const { stateProfile, actionsProfile } = useProfileStore()
    const { profile } = stateProfile
    const { contactInformation } = profile
    const { personPhoneNumber, mobilePhonePending } = contactInformation

    const { phoneNumber } = props

    const [isInValidationProcess, setIsInValidationProcess] = useState(false)
    const [confirmationCode, setConfirmationCode] = useState('')

    const [newPersonPhoneNumber, setNewPersonPhoneNumber] = useState('')
    const [isPersonPhoneNumberModalOpen, setIsPersonPhoneNumberModalOpen] = useState(false)

    function handleChange(name, value) {
        if (name === 'phoneNumber') {
            props.onPhoneNumberChange(value.replace(/\s/g, ''))
        }

        if (name === 'personPhoneNumber') {
            setNewPersonPhoneNumber(value.replace(/\s/g, ''))
        }
    }

    function handleEditClick(event, name) {
        if (name === 'personPhoneNumber') {
            setIsPersonPhoneNumberModalOpen(true)
        }
    }

    function handleCancelClick(name) {
        if (name === 'personPhoneNumber') {
            setNewPersonPhoneNumber('')
            setIsPersonPhoneNumberModalOpen(false)
            setIsInValidationProcess(false)
        }
    }

    function handleSaveData(name) {
        if (name === 'personPhoneNumber') {
            setIsInValidationProcess(true)
        }
    }

    function disableSaveButton(name) {
        if (name === 'personPhoneNumber') {
            const unformatedNewPersonPhoneNumber = newPersonPhoneNumber.replace(/\s/g, '').replace(/_/g, '')

            if (
                newPersonPhoneNumber !== '' &&
                personPhoneNumber !== unformatedNewPersonPhoneNumber &&
                !validatePhoneUtil(unformatedNewPersonPhoneNumber)
            ) {
                return false
            }
        }

        return true
    }

    function handleSendChangeRequest() {
        actionsProfile.updatePhoneNumber({ personPhoneNumber: newPersonPhoneNumber, phoneNumber: null })
        setIsPersonPhoneNumberModalOpen(false)
        setIsInValidationProcess(false)
    }

    function handleConfirmationCode() {
        actionsProfile.validateCode(confirmationCode)
    }

    function handleConfirmationCodeChange(value) {
        if (value.length <= 5) {
            setConfirmationCode(value)
        }
    }

    function renderPersonPhoneNumber() {
        if (mobilePhonePending) {
            let helperText

            if (personPhoneNumber) {
                helperText = `Em processo de alteração do número ${maskString(
                    personPhoneNumber,
                    '### ### ###'
                )} para o ${maskString(mobilePhonePending, '### ### ###')}.`
            } else {
                helperText = `Em processo de associar o número ${maskString(
                    mobilePhonePending,
                    '### ### ###'
                )} ao seu perfil de utilizador.`
            }

            return (
                <Fragment>
                    <FItemData vertical itemKey='Telemóvel:' itemValue={helperText} />
                    <TextField
                        name='key'
                        label='Código de Confirmação'
                        type='text'
                        margin='dense'
                        variant='outlined'
                        value={confirmationCode || ''}
                        onChange={event => handleConfirmationCodeChange(event.target.value)}
                    />
                    <Button
                        variant='outlined'
                        color='default'
                        size='small'
                        style={{ marginTop: '13px', marginLeft: '10px' }}
                        disabled={confirmationCode.length === 0}
                        onClick={handleConfirmationCode}
                    >
                        Validar
                    </Button>
                </Fragment>
            )
        }

        if (personPhoneNumber && !mobilePhonePending) {
            return (
                <SPaperRow>
                    <FItemData
                        vertical
                        isEditField
                        name='personPhoneNumber'
                        itemKey='Telemovel:'
                        itemValue={maskString(personPhoneNumber, '### ### ###')}
                        onEditClick={handleEditClick}
                    />
                </SPaperRow>
            )
        }

        return (
            <SPaperRow>
                <FItemData
                    vertical
                    isEditField
                    name='personPhoneNumber'
                    itemKey='Telemóvel:'
                    itemValue='Sem telemóvel'
                    onEditClick={handleEditClick}
                />
            </SPaperRow>
        )
    }

    function phoneNumberHelperText(number) {
        return validatePhoneUtil(number.replace(/\s/g, '').replace(/_/g, ''))
    }
    return (
        <Fragment>
            <PaperComponent noborder>
                <PaperHeaderComponent title='informação de contato' />

                <Box display='flex'>
                    <Box>
                        <Fragment>
                            <FItemData vertical itemKey='Telefone:' />
                            <InputMask
                                mask={maskPhoneUtil}
                                value={phoneNumber}
                                onChange={event => handleChange('phoneNumber', event.target.value)}
                            >
                                {() => (
                                    <TextField
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        label='Número Fixo'
                                        margin='dense'
                                        variant='outlined'
                                        error={!!phoneNumberHelperText(phoneNumber)}
                                        helperText={phoneNumberHelperText(phoneNumber)}
                                        fullWidth
                                    />
                                )}
                            </InputMask>
                        </Fragment>
                        {renderPersonPhoneNumber()}
                    </Box>
                </Box>
            </PaperComponent>
            <ModalComponent
                open={isPersonPhoneNumberModalOpen}
                title='alteração número de telemóvel'
                onClick={() => handleCancelClick('personPhoneNumber')}
            >
                <ModalBodyComponent>
                    <STextInfo>
                        {!isInValidationProcess ? (
                            <Fragment>
                                <STextInfo>
                                    {personPhoneNumber ? (
                                        <Fragment>
                                            <p>
                                                Número de telemóvel atual:
                                                <span style={{ fontWeight: '900' }}>
                                                    {`${maskString(personPhoneNumber, '### ### ###')}`}
                                                </span>
                                            </p>
                                            Insira o novo número de telemóvel para qual pretende alterar:
                                        </Fragment>
                                    ) : (
                                        <p>Insira qual o número de telemóvel pretende associar ao seu perfill:</p>
                                    )}
                                </STextInfo>
                                <InputMask
                                    mask={maskPhoneUtil}
                                    value={newPersonPhoneNumber || ''}
                                    onChange={event => handleChange('personPhoneNumber', event.target.value)}
                                >
                                    {() => (
                                        <TextField
                                            id='personPhoneNumber'
                                            name='personPhoneNumber'
                                            label='Número Telemóvel'
                                            margin='dense'
                                            variant='outlined'
                                            error={!!phoneNumberHelperText(newPersonPhoneNumber)}
                                            helperText={phoneNumberHelperText(newPersonPhoneNumber)}
                                            fullWidth
                                        />
                                    )}
                                </InputMask>
                                <ModalFooterComponent>
                                    <Fragment>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            onClick={() => handleCancelClick('personPhoneNumber')}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            disabled={disableSaveButton('personPhoneNumber')}
                                            onClick={() => handleSaveData('personPhoneNumber')}
                                        >
                                            Confirmar
                                        </Button>
                                    </Fragment>
                                </ModalFooterComponent>
                            </Fragment>
                        ) : (
                            <STextInfo>
                                <Fragment>
                                    {personPhoneNumber ? (
                                        <p>
                                            {`Está em processo de alteração do número de telemóvel ${maskString(
                                                personPhoneNumber,
                                                '### ### ###'
                                            )}
                                            para o ${maskString(newPersonPhoneNumber, '### ### ###')}.`}
                                        </p>
                                    ) : (
                                        <p>
                                            {`Está em processo de associar o número de telemóvel ${maskString(
                                                newPersonPhoneNumber,
                                                '### ### ###'
                                            )} ao seu perfil.`}
                                        </p>
                                    )}
                                    <p>
                                        {`Após confirmação, irá ser enviado para a caixa de mensagens do telemóvel com
                                            o numero ${maskString(
                                                newPersonPhoneNumber,
                                                '### ### ###'
                                            )} um código de confirmação de 5 dígitos. Faça esta validação nas
                                            proximas 24h.`}
                                    </p>
                                    <p>
                                        Caso a alteração não seja confirmada em 24 horas este pedido será cancelado
                                        automaticamente.
                                    </p>
                                    <ModalFooterComponent>
                                        <Fragment>
                                            <Button
                                                variant='contained'
                                                size='small'
                                                onClick={() => handleCancelClick('personPhoneNumber')}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                size='small'
                                                onClick={() => handleSendChangeRequest()}
                                            >
                                                Confirmar
                                            </Button>
                                        </Fragment>
                                    </ModalFooterComponent>
                                </Fragment>
                            </STextInfo>
                        )}
                    </STextInfo>
                </ModalBodyComponent>
            </ModalComponent>
        </Fragment>
    )
}

ContactInformation.propTypes = propTypes
ContactInformation.defaultProps = defaultProps

export default ContactInformation
