import React from 'react'
import PropTypes from 'prop-types'

import DataTableComponent from '../../../components/templates/DataTable/DataTable.component'
import { transformTimestampToDateUtil } from '../../../utils/index'

const propTypes = {
    timestampId: PropTypes.string.isRequired,
}

const COLUMNS = [
    {
        field: 'id',
        title: 'nº do pedido',
    },
    {
        field: 'createdDate',
        title: 'data de produção',
        render: ({ createdDate }) => transformTimestampToDateUtil(createdDate),
    },
    {
        field: 'contractedQuantity',
        title: 'quantidade contratada',
        headerStyle: {
            textAlign: 'center',
        },
        cellStyle: {
            textAlign: 'center',
            width: '200px',
        },
    },
    {
        field: 'consumedQuantity',
        title: 'quantidade consumida',
        headerStyle: {
            textAlign: 'center',
        },
        cellStyle: {
            textAlign: 'center',
            width: '200px',
        },
    },
    {
        field: 'limitDate',
        title: 'data limite para consumo',
        render: ({ limitDate }) => transformTimestampToDateUtil(limitDate),
        headerStyle: {
            textAlign: 'center',
        },
        cellStyle: {
            textAlign: 'center',
        },
    },
]

function TableRequests(props) {
    const { timestampId } = props
    return (
        <DataTableComponent
            columns={COLUMNS}
            endPoint={`timestamps/client/${timestampId}/orders`}
            exportCSV={{ isFromAPI: true, filename: 'pedidos-selo-temporal' }}
        />
    )
}

TableRequests.propTypes = propTypes

export default TableRequests
